import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import styled, { ThemeProvider } from 'styled-components'
import { Form, FormGroup } from 'reactstrap'

import {
  BlueButton, FullHeightFlexContainer, StyledInput, StyledLabel, StyledAlert
} from './StyledComponents'
import { loginUserWithAuth, getLoginRandomItem } from '../actions/AuthActions'
import { getImageOrientation } from '../utils/ImageUtils'

import { theme } from '../../assets/theme.js'
import { media } from '../../assets/media-queries.js'
import meuralLogo from '../../assets/meural-logo-bronze.png'

const StyledFormContainer = styled.div`
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
`

const StyledLogo = styled.img`
  width: 100%;
  max-width: 280px;
`

const StyledLogoContainer = styled.div`
  width: 100%;
  padding-bottom: 40px;
  margin-bottom: 30px;
  border-bottom: 5px solid ${props => props.theme.lighterGray}
`

const StyledForm = styled(Form)`
  width: 50%;
`

const StyledImg = styled.img`
  width: ${props => props.orientation === 'vertical' ? '100%' : 'auto'};
  height: ${props => props.orientation === 'vertical' ? 'auto' : '100%'};
  position: ${props => props.orientation === 'horizontal' ? 'absolute' : 'static'};
  left: ${props => props.orientation === 'horizontal' ? `-${props.item.originalWidth / 10}px` : 0};

  ${media.desktop`
    width: auto;
  `}
`

const StyledImgContainer = styled.div`
  width: 50%;
  height: 100%;
  position: relative;
  overflow: hidden;
`

const StyledBlueButton = styled(BlueButton)`
  margin-top: 30px;
`

function mapStateToProps ({ auth }) {
  return {
    loggedIn: auth.loggedIn,
    error: auth.error,
    item: auth.item
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({ loginUserWithAuth, getLoginRandomItem }, dispatch)
}

class Auth_v1 extends Component {
  constructor (props) {
    super(props)

    this.state = {
      username: '',
      password: '',
      usernameError: null,
      passwordError: null,
      invalidLoginError: null,
      loading: false
    }

    this.handleChange = this.handleChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  componentWillMount () {
    if (this.props.loggedIn) {
      this.props.history.push('/')
    } else {
      this.props.getLoginRandomItem()
    }
  }

  componentWillReceiveProps (nextProps) {
    if (nextProps.error) {
      const errorKeys = Object.keys(nextProps.error)
      const stateKeys = Object.keys(this.state)
      const errors = {}
      if (errorKeys.length > 0) {
        errorKeys.forEach(key => {
          const errorValue = nextProps.error[key]
          // non-field error (e.g invalid credentials)
          if (!stateKeys.includes(key)) {
            errors['invalidLoginError'] = errorValue
          } else {
            errors[`${key}Error`] = errorValue
          }
        })
      }

      this.setState(errors)
    }
  }

  handleChange (e) {
    this.setState({
      [e.target.name]: e.target.value,
      [`${e.target.name}Error`]: null,
      invalidLoginError: null
    })
  }

  handleSubmit (e) {
    e.preventDefault()

    this.setState({ loading: true }, () => {
      this.props.loginUserWithAuth(this.state)
        .then(() => { if (!this.props.error) { this.props.history.push('/') } })
        .catch(() => this.setState({ loading: false }))
    })
  }

  render () {
    const alertError = this.state.invalidLoginError
                     ? <StyledAlert color='danger'>{this.state.invalidLoginError}</StyledAlert>
                     : null
    const isPasswordError = this.state.passwordError || this.state.invalidLoginError
    const isUsernameError = this.state.usernameError || this.state.invalidLoginError
    return (
      <ThemeProvider theme={theme}>
        <FullHeightFlexContainer>
          {alertError}
          <StyledFormContainer>
            <StyledForm>
              <StyledLogoContainer>
                <StyledLogo src={meuralLogo} />
              </StyledLogoContainer>
              <FormGroup>
                <StyledLabel for='username' error={isUsernameError}>
                  Email
                </StyledLabel>
                <StyledInput type='text'
                  name='username'
                  id='username'
                  error={isUsernameError}
                  value={this.state.username}
                  placeholder='email@meural.com'
                  onChange={this.handleChange} />
              </FormGroup>
              <FormGroup>
                <StyledLabel for='password' error={isPasswordError}>
                  Password
                </StyledLabel>
                <StyledInput type='password'
                  name='password'
                  id='password'
                  value={this.state.password}
                  error={isPasswordError}
                  placeholder='••••••'
                  onChange={this.handleChange} />
              </FormGroup>
              <StyledBlueButton type='submit' fullwidth='true'
                disabled={this.state.isLoading}
                onClick={this.handleSubmit}><b>Submit</b></StyledBlueButton>
            </StyledForm>
          </StyledFormContainer>
          <StyledImgContainer>
            <StyledImg
              src={this.props.item.image}
              item={this.props.item}
              orientation={getImageOrientation(this.props.item)} />
          </StyledImgContainer>
        </FullHeightFlexContainer>
      </ThemeProvider>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Auth_v1)
