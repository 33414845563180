import { ITEMS, EDITORIALS } from '../constants/ContentConstants'

const API_ORIGIN = process.env.API_ORIGIN

export const getMeuralUrl = () => {
  if (API_ORIGIN === 'https://api-dev.meural.com') {
    return 'https://my.meuraldev.netgear.com'
  } else if (API_ORIGIN === 'https://api-stg.meural.com') {
    return 'https://my.meuralstaging.netgear.com'
  } else {
    return 'https://my.meural.com'
  }
}

export const convertTypeForUrl = (type) => {
  if (type === ITEMS) {
    return 'works'
  } else if (type === EDITORIALS) {
    return 'editorial'
  } else {
    return type
  }
}
