import React from 'react'
import styled from 'styled-components'
import capitalize from 'lodash/capitalize'
import { toFrontend } from '../../constants/ContentConstants'

import {
  FlexColumnContainer, FlexContainer, GrayButton, StyledLabel
} from '../StyledComponents'

const StyledContentContainer = styled.div`
  margin-left: 20px;
`

const StyledFlexColumnContainer = styled(FlexColumnContainer)`
  margin: 20px 0;
  width: 100%;
`

const ContentPickerContainer = styled(FlexContainer)`
  justify-content: space-between;
  align-items: center;
`

export default class FeaturedContent extends React.Component {
  render () {
    let contentPicker
    if (!this.props.contentObject.id) {
      contentPicker = (
        <div>
          <GrayButton small='true' onClick={this.props.toggleModal}
            error={this.props.error}>
            Choose
          </GrayButton>
        </div>
      )
    } else {
      contentPicker = (
        <ContentPickerContainer>
          <StyledContentContainer>
            {this.props.contentObject.name}
          </StyledContentContainer>
          <GrayButton small='true' onClick={this.props.toggleModal} error={this.props.error}>
            Change
          </GrayButton>
        </ContentPickerContainer>
      )
    }
    return (
      <StyledFlexColumnContainer>
        <StyledLabel error={this.props.error}>
            Featured {this.props.contentType && capitalize(toFrontend(this.props.contentType))}
        </StyledLabel>
        {contentPicker}
      </StyledFlexColumnContainer>
    )
  }
}
