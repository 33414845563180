import React, { Component } from 'react'
import { theme } from '../../assets/theme.js'
import styled, { ThemeProvider } from 'styled-components'
import { Route, Switch, Redirect } from 'react-router-dom'

import Sidebar from './Sidebar'
import Message from './Message'
import Channels from './channels/ChannelsContainer'
import ChannelForm from './channels/ChannelForm'
import ViewChannel from './channels/ViewChannelContainer'
import Artists from './artists/ArtistsContainer'
import ArtistForm from './artists/ArtistForm'
import ViewArtist from './artists/ViewArtistContainer'
import Playlists from './playlists/PlaylistsContainer'
import ViewPlaylist from './playlists/ViewPlaylistContainer'
import PlaylistForm from './playlists/PlaylistForm'
import Providers from './providers/ProvidersContainer'
import ViewProvider from './providers/ViewProviderContainer'
import ProviderForm from './providers/ProviderForm'
import Items from './items/ItemsContainer'
import ViewItem from './items/ViewItemContainer'
import ItemForm from './items/ItemForm'
import CategoryForm from './categories/CategoryForm'
import Categories from './categories/CategoriesContainer'
import ViewCategory from './categories/ViewCategoryContainer'
import Groups from './groups/GroupsContainer'
import GroupForm from './groups/GroupForm'
import ViewGroup from './groups/ViewGroupContainer'
import Editorials from './editorials/EditorialsContainer'
import ViewRelatedContent from './relatedContent/RelatedContentContainer'
import BatchEditForm from './edit/BatchEditForm'
import ItemCropper from './edit/ItemCropper'
import EditorialForm from './editorials/EditorialForm'
import EditorialComposer from './editorials/EditorialComposer'
import ViewEditorial from './editorials/ViewEditorialContainer'
import ReviewCollection from './reviews/ReviewCollection'
import ViewHistory from './history/ViewHistory'
import { FlexContainer } from './StyledComponents'
import RelatedContentErrorsModal from './relatedContent/RelatedContentErrorsModal'
import createStore from '../../../web/redux/Store'
import { getFeatureFlag, updateAccessToken } from '../utils/Cognito'

const StyledMain = styled.main`
  background: ${props => props.theme.lighterGray};
  height: 100%;
  min-height: 100vh;
`

const ContentContainer = styled.div`
  width: ${props => props.hideSidebar ? '100%' : 'calc(100% - 250px)'}
`

export default class PrimaryLayout extends Component {
  constructor (props) {
    super(props)
    const flag = getFeatureFlag()
    if (flag === 'v2') {
      const store = createStore()
      updateAccessToken(store)
    }
  }

  render () {
    const hideSidebar = this.props.location.pathname.includes('compose')
    let sidebar
    if (!hideSidebar) {
      sidebar = (
        <Sidebar
          activeItem={this.props.location.pathname.slice(1).split('/')[0]} />
      )
    }

    return (
      <ThemeProvider theme={theme}>
        <StyledMain>
          <Message />
          <FlexContainer alignItems='flex-start'>
            {sidebar}
            <ContentContainer hideSidebar={hideSidebar}>
              <Switch>
                <Route path='/items/batch' component={BatchEditForm} />
                <Route path='/items/create' component={ItemForm} />
                <Route path='/items/:id/edit' component={ItemForm} />
                <Route path='/items/:id/crop' component={ItemCropper} />
                <Route path='/items/:id/history' component={ViewHistory} />
                <Route path='/items/:id/:childType' component={ViewRelatedContent} />
                <Route path='/items/:id' component={ViewItem} />
                <Route path='/items' component={Items} />
                <Route path='/channels/create' component={ChannelForm} />
                <Route path='/channels/:id/edit' component={ChannelForm} />
                <Route path='/channels/:id/history' component={ViewHistory} />
                <Route path='/channels/:id/:childType' component={ViewRelatedContent} />
                <Route path='/channels/:id' component={ViewChannel} />
                <Route path='/channels' component={Channels} />
                <Route path='/providers/create' component={ProviderForm} />
                <Route path='/providers/:id/edit' component={ProviderForm} />
                <Route path='/providers/:id/history' component={ViewHistory} />
                <Route path='/providers/:id/:childType' component={ViewRelatedContent} />
                <Route path='/providers/:id' component={ViewProvider} />
                <Route path='/providers' component={Providers} />
                <Route path='/playlists/create' component={PlaylistForm} />
                <Route path='/playlists/:id/edit' component={PlaylistForm} />
                <Route path='/playlists/:id/history' component={ViewHistory} />
                <Route path='/playlists/:id/:childType' component={ViewRelatedContent} />
                <Route path='/playlists/:id' component={ViewPlaylist} />
                <Route path='/playlists' component={Playlists} />
                <Route path='/artists/create' component={ArtistForm} />
                <Route path='/artists/:id/edit' component={ArtistForm} />
                <Route path='/artists/:id/history' component={ViewHistory} />
                <Route path='/artists/:id/:childType' component={ViewRelatedContent} />
                <Route path='/artists/:id' component={ViewArtist} />
                <Route path='/artists' component={Artists} />
                <Route path='/categories/create' component={CategoryForm} />
                <Route path='/categories/:id/edit' component={CategoryForm} />
                <Route path='/categories/:id/history' component={ViewHistory} />
                <Route path='/categories/:id/:childType' component={ViewRelatedContent} />
                <Route path='/categories/:id' component={ViewCategory} />
                <Route path='/categories' component={Categories} />
                <Route path='/groups/create' component={GroupForm} />
                <Route path='/groups/:id/edit' component={GroupForm} />
                <Route path='/groups/:id/history' component={ViewHistory} />
                <Route path='/groups/:id/:childType' component={ViewRelatedContent} />
                <Route path='/groups/:id' component={ViewGroup} />
                <Route path='/groups' component={Groups} />
                <Route path='/editorials/create' component={EditorialForm} />
                <Route path='/editorials/:id/edit' component={EditorialForm} />
                <Route path='/editorials/:id/compose' component={EditorialComposer} />
                <Route path='/editorials/:id/history' component={ViewHistory} />
                <Route path='/editorials/:id/:childType' component={ViewRelatedContent} />
                <Route path='/editorials/:id' component={ViewEditorial} />
                <Route path='/editorials' component={Editorials} />
                <Route path='/reviews/reviewer' component={ReviewCollection} key='reviewer' />
                <Route path='/reviews/reviewee' component={ReviewCollection} key='reviewee' />
                <Redirect to='/items' />
              </Switch>
              <RelatedContentErrorsModal />
            </ContentContainer>
          </FlexContainer>
        </StyledMain>
      </ThemeProvider>
    )
  }
}
