import * as constants from '../constants/CategoryConstants'
import { DELETE_REVIEW, RECEIVE_REVIEW } from '../constants/ReviewConstants'
import { CATEGORIES } from '../constants/ContentConstants'

const _nullCategorys = {
  error: null,
  categories: {
    isLast: false,
    data: [],
    count: null
  },
  current: {
    pendingReviews: []
  }
}

export default function CategoryReducer (state = _nullCategorys, action) {
  switch (action.type) {
    case constants.RECEIVE_CATEGORIES: {
      const { isLast, data, count } = action.categories
      const { newQuery } = action
      const newCategorys = {
        isLast: isLast,
        data: newQuery ? data : [].concat(state.categories.data, data),
        count
      }
      return Object.assign({}, state, { categories: newCategorys })
    }
    case constants.RECEIVE_CATEGORY: {
      return Object.assign({}, state, {
        current: Object.assign({}, state.current, action.category)
      })
    }
    case DELETE_REVIEW: {
      if (!action.contentType === CATEGORIES) return state

      const newPendingReviews = Object.assign({}, {
        pendingReviews: state.current.pendingReviews
          .filter(review => review.id !== action.reviewId)
      })

      return Object.assign({}, state, {
        current: Object.assign({}, state.current, newPendingReviews)
      })
    }
    case RECEIVE_REVIEW: {
      if (!action.contentType === CATEGORIES) return state

      return Object.assign({}, state, {
        current: Object.assign({}, state.current, action.review.contentObject)
      })
    }
    case constants.RECEIVE_CATEGORIES_ERROR: {
      return Object.assign({}, state, { error: action.error })
    }
    case constants.CLEAR_CATEGORIES: {
      return Object.assign({}, _nullCategorys)
    }
    default: {
      return state
    }
  }
}
