import { connect } from 'react-redux'
import { getChannel } from '../../actions/ChannelActions'
import { CHANNELS } from '../../constants/ContentConstants'

import ViewContent from '../ViewContent'

const mapStateToProps = state => {
  return {
    type: CHANNELS,
    content: state.channels.current
  }
}

const mapDispatchToProps = dispatch => ({
  getContent: payload => dispatch(getChannel(payload))
})

export default connect(mapStateToProps, mapDispatchToProps)(ViewContent)
