import humps from 'humps'
import { ASCENDING } from '../constants/SearchAndFilterConstants'

export const formatQueryString = (queryParams) => {
  const { count, page, searchString, sort, filters } = queryParams

  let query = `?count=${count}&page=${page}`

  // search
  if (searchString) query += `&search=${searchString}`

  // filter
  if (filters) {
    Object.keys(filters).forEach(filter => {
      if (filters[filter] || filters[filter] === false) {
        query += `&${humps.decamelize(filter)}=${filters[filter]}`
      }
    })
  }

  // sort
  if (sort) {
    const prefix = sort.order === ASCENDING ? '' : '-'
    if (sort.field) query += `&ordering=${prefix}${humps.decamelize(sort.field)}`
  }

  return query
}
