import * as constants from '../constants/ProviderConstants'
import * as API from '../api/Providers'
import { receiveSuccessMessage, receiveErrorMessage } from './MessageActions'
import { RECEIVE_RELATED_CONTENT } from '../constants/RelatedContentConstants'
import { PROVIDERS } from '../constants/ContentConstants'
import { getRelatedContent as getRelatedContentAction } from './RelatedContentActions'
import { getRelatedContent } from '../api/General'

export const getProviders = (params) => dispatch => {
  return API.getProviders(params).then(providers => dispatch({
    type: constants.RECEIVE_PROVIDERS, providers, newQuery: params.newQuery
  })).catch(error => dispatch({ type: constants.RECEIVE_PROVIDERS_ERROR, error }))
}

const getOnlyProvider = (id) => dispatch => {
  return API.getProvider(id).then(({ data: provider }) => dispatch({
    type: constants.RECEIVE_PROVIDER, provider
  })).catch(error => dispatch({ type: constants.RECEIVE_PROVIDERS_ERROR, error }))
}

export const getProvider = (id, childType, opts = { queryParams: null }) => dispatch => {
  if (!childType) return dispatch(getOnlyProvider(id))

  if (!opts.queryParams.newQuery) {
    return dispatch(getRelatedContentAction(PROVIDERS, id, childType, opts.queryParams))
  } else {
    return Promise.all([
      API.getProvider(id),
      getRelatedContent(PROVIDERS, id, childType, opts.queryParams)
    ]).then(values => {
      const provider = values[0].data
      const relatedContent = values[1]
      dispatch({ type: constants.RECEIVE_PROVIDER, provider })
      dispatch({
        type: RECEIVE_RELATED_CONTENT,
        content: relatedContent,
        contentType: childType,
        newQuery: opts.queryParams.newQuery
      })
    }).catch(error => dispatch({ type: constants.RECEIVE_PROVIDERS_ERROR, error }))
  }
}

export const updateProvider = (providerId, data) => dispatch => {
  return API.updateProvider(providerId, data).then(({ data: provider }) => {
    dispatch({ type: constants.RECEIVE_PROVIDER, provider })
  }).then(() => dispatch(receiveSuccessMessage('Provider updated')))
    .catch(error => {
      dispatch(receiveErrorMessage(error))
      return dispatch({ type: constants.RECEIVE_PROVIDERS_ERROR, error })
    })
}

export const createProvider = (data) => dispatch => {
  return API.createProvider(data).then(({ data: provider }) => {
    dispatch({ type: constants.RECEIVE_PROVIDER, provider })
  }).then(() => dispatch(receiveSuccessMessage('Provider created')))
    .catch(error => {
      dispatch(receiveErrorMessage(error))
      return dispatch({ type: constants.RECEIVE_PROVIDERS_ERROR, error })
    })
}

export const deleteProvider = (id) => dispatch => {
  return API.deleteProvider(id)
    .then(() => dispatch(receiveSuccessMessage('Provider deleted')))
    .catch(error => {
      dispatch({ type: constants.RECEIVE_PROVIDERS_ERROR, error })
      return Promise.reject(error)
    })
}

export const clearProviders = () => dispatch => dispatch({ type: constants.CLEAR_PROVIDERS })
