import React from 'react'
import { connect } from 'react-redux'
import { BlueButton } from '../StyledComponents'
import PremiumConfirmationModal from './PremiumConfirmationModal'
import { receiveErrorMessage } from '../../actions/MessageActions'

const mapDispatchToProps = dispatch => ({
  renderErrorMessage: message => dispatch(receiveErrorMessage(message))
})

class SaveButton extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      isPremiumModalOpen: false
    }

    this.togglePremiumModal = this.togglePremiumModal.bind(this)
    this.handleSave = this.handleSave.bind(this)
  }

  togglePremiumModal () {
    this.setState({ isPremiumModalOpen: !this.state.isPremiumModalOpen })
  }

  handleSave () {
    const { object, isScheduled, premiumDate } = this.props

    if (isScheduled && !object.premiumDate) {
      return this.props.renderErrorMessage('Please schedule a date for content to become premium')
    }

    if (isScheduled && object.premiumDate && !premiumDate && !object.isPremium) {
      return this.togglePremiumModal()
    }

    this.props.handleSave()
  }

  render () {
    return (
      <React.Fragment>
        <BlueButton small='true' marginleft='10px' onClick={this.handleSave}>
          Save
        </BlueButton>
        <PremiumConfirmationModal
          type={this.props.type}
          isOpen={this.state.isPremiumModalOpen}
          toggleModal={this.togglePremiumModal}
          object={this.props.object}
          onSave={() => this.props.handleSave().then(this.togglePremiumModal)} />
      </React.Fragment>

    )
  }
}

export default connect(null, mapDispatchToProps)(SaveButton)
