import React, { Component } from 'react'
import { connect } from 'react-redux'
import {
  Capitalize, FlexContainer, BlueButton, GrayButton,
  StyledLink, StickyHeader
} from './StyledComponents'
import pluralize from 'pluralize'
import capitalize from 'lodash/capitalize'
import styled from 'styled-components'
import SuperPicker from './edit/SuperPicker'
import { addRelatedContent } from '../actions/RelatedContentActions'
import { receiveSuccessMessage, clearMessage } from '../actions/MessageActions'
import { exportCsv } from '../api/General'
import { ITEMS, PLAYLISTS, toFrontendPlural } from '../constants/ContentConstants'
import { parseSearchAndFilter } from '../utils/SearchAndFilter'
import MainHeader from './MainHeader'

const SelectAll = styled.span`
  font-weight: bold;
  cursor: pointer;
  margin-left: 20px;
`

const SubheaderContainer = styled(FlexContainer)`
  background-color: ${props => props.theme.lighterGray};
  padding: 17px 40px;
  border-bottom: 1px solid ${props => props.theme.lightGray};
`

const mapStateToProps = ({ searchAndFilter, groups }) => ({
  searchAndFilter,
  groups: groups.groups.data
})

const mapDispatchToProps = (dispatch) => ({
  batchAddToPlaylist: (ids, playlist, contentType) => {
    return dispatch(addRelatedContent(PLAYLISTS, playlist.id, contentType, ids))
  },
  renderMessage: (message, timeout) => dispatch(receiveSuccessMessage(message, timeout)),
  clearMessage: () => dispatch(clearMessage())
})

class Chrome extends Component {
  constructor (props) {
    super(props)

    this.state = {
      currSearchAndFilter: null
    }

    this.onSelectAll = this.onSelectAll.bind(this)
    this.batchAddToPlaylist = this.batchAddToPlaylist.bind(this)
    this.exportCsvFile = this.exportCsvFile.bind(this)
  }

  componentDidMount () {
    this.setState({ currSearchAndFilter: this.props.searchAndFilter })
  }

  componentDidUpdate (prevProps, prevState) {
    if (this.props.hasSearched && !prevProps.hasSearched) {
      this.setState({ currSearchAndFilter: this.props.searchAndFilter })
    }
  }

  onSelectAll () {
    this.props.onSelectAll()
  }

  renderContentType () {
    const { type } = this.props

    return (this.props.selectedContent || []).length === 1
      ? pluralize.singular(type)
      : type
  }

  batchAddToPlaylist (playlist) {
    this.props.batchAddToPlaylist(this.props.selectedContent, playlist, ITEMS)
  }

  exportCsvFile () {
    const hasMessageTimeout = false
    this.props.renderMessage('Exporting CSV', hasMessageTimeout)
    exportCsv(this.props.type).then(result => {
      const aTag = document.createElement('a')
      aTag.href = result.url
      aTag.download = result.fileName
      aTag.click()
    }).then(() => this.props.clearMessage())
  }

  renderButtons () {
    if ((this.props.selectedContent || []).length === 0) {
      return (
        <FlexContainer>
          <GrayButton small='true' onClick={this.exportCsvFile}>
            Export CSV
          </GrayButton>
        </FlexContainer>
      )
    } else {
      return (
        <FlexContainer alignItems='flex-start'>
          <GrayButton small='true' onClick={this.props.clearSelectedContent}>
            Cancel
          </GrayButton>
          <SuperPicker
            render={(ctx) => {
              return (
                <GrayButton
                  marginleft='10px'
                  marginbottom='-15px'
                  small='true'
                  onClick={ctx.toggleModal}>
                  Add to playlist
                </GrayButton>
              )
            }}
            relatedContentType={PLAYLISTS}
            getAll
            baseType={this.props.type}
            createRelationship={this.batchAddToPlaylist}
          />
          <BlueButton marginleft='10px' small='true'>
            <StyledLink to={`/${this.props.type}/batch`}>
              Edit
            </StyledLink>
          </BlueButton>
        </FlexContainer>
      )
    }
  }

  renderHeaderText () {
    if ((this.props.selectedContent || []).length) {
      return (
        <div>
          {this.props.selectedContent.length} <Capitalize>
            {this.renderContentType()}</Capitalize> Selected
          <SelectAll onClick={this.onSelectAll}>Select All</SelectAll>
        </div>
      )
    } else {
      // TODO: Remove this if search and filter is not needed in the header
      let headerText
      const searchAndFilterText = this.state.currSearchAndFilter
        ? parseSearchAndFilter(this.state.currSearchAndFilter, this.props.groups)
        : ''

      headerText = toFrontendPlural(this.props.type)
      headerText += `${searchAndFilterText} (${this.props.count})`

      return <div />
    }
  }

  render () {
    const frontendPluralType = toFrontendPlural(this.props.type)
    return (
      <StickyHeader>
        <MainHeader
          type={this.props.type}
          text={capitalize(frontendPluralType)}
          tabs={[
            {displayName: `All ${frontendPluralType}`, route: `/${this.props.type}`}
          ]} />
        <SubheaderContainer justifyContent='space-between'>
          {this.renderHeaderText()}
          {this.renderButtons()}
        </SubheaderContainer>
      </StickyHeader>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Chrome)
