import React from 'react'
import styled from 'styled-components'
import { checkForImage } from '../../utils/ImageUtils'
import { MarginFlexContainer } from '../StyledEditComponents'
import { FlexColumnContainer } from '../StyledComponents'

const StyledSelectedContainer = styled(FlexColumnContainer)`
  width: 50%;
  margin: 20px 20px 20px 0;
`

const StyledListContainer = styled(FlexColumnContainer)`
  max-height: calc(100vh - 200px);
  overflow-y: scroll;
`

const StyledImg = styled.img`
  width: 55px;
  height: 55px;
  object-fit: cover;
`

const StyledMarginFlexContainer = styled(MarginFlexContainer)`
  max-height: 80px;
  padding: 40px 15px;
  margin-bottom: 20px;
  border-radius: 5px;
`

export default function BatchSelectedList (props) {
  const selectedList = Object.keys(props.selected).map((eachSelected, idx) => {
    const selected = props.selected[eachSelected]
    return (
      <StyledMarginFlexContainer key={idx}>
        <StyledImg src={checkForImage(selected, props.type)} />
        <div className='content-title'>{selected.name}</div>
      </StyledMarginFlexContainer>
    )
  })

  return (
    <StyledSelectedContainer>
      <h1>{props.header}</h1>
      <StyledListContainer>
        {selectedList}
      </StyledListContainer>
    </StyledSelectedContainer>
  )
}
