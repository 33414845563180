import React, { Component } from 'react'
import { connect } from 'react-redux'
import { NavLink, Badge } from 'reactstrap'
import styled from 'styled-components'
import { withRouter } from 'react-router-dom'
import capitalize from 'lodash/capitalize'
import SidebarNavItem from './SidebarNavItem'
import { logoutUser, getAllUserReviews } from '../actions/AuthActions'
import { FlexColumnContainer } from './StyledComponents'
import {
  ITEMS, PLAYLISTS, ARTISTS, CATEGORIES, GROUPS, CHANNELS, PROVIDERS,
  EDITORIALS
} from '../constants/ContentConstants'
import { isSuperCurator } from '../utils/General'
import meuralWhiteLogo from '../../assets/logo-white.png'

const NAV_ITEMS = [
  ITEMS, PLAYLISTS, ARTISTS, CATEGORIES, GROUPS, CHANNELS, PROVIDERS,
  EDITORIALS
]

const SidebarContainer = styled(FlexColumnContainer.withComponent('nav'))`
  width: 250px;
  height: 100vh;
  padding: 20px;
  background-color: ${props => props.theme.veryDarkPurple};
  position: sticky;
  top: 0;
  justify-content: space-between;
`

const NavList = styled(FlexColumnContainer.withComponent('ul'))`
  padding: 0;
  margin: 0;
  list-style-type: none;
`

const SidebarSubNavList = styled(NavList)`
  padding-bottom: 20px;
  font-size: 13px;
  margin-top: -5px;
`

const StyledNavLink = styled(NavLink)`
  width: 100%;
  color: ${props => props.theme.white};
  font-weight: ${props => props.active ? 'bold' : 'normal'};
  transition: 0.2s ease color;

  &:hover {
    color: ${props => props.theme.lightGray};
  }
`

const NavLinkWithoutPadding = styled(NavLink)`
  padding: 8px 0;
`

const NotificationBadge = styled(Badge)`
  background: ${props => props.theme.red};
  font-size: 11px;
  font-weight: normal;
  padding: 1px 7px;
  margin-left: 5px;
`

const LogoContainer = styled.li`
  padding: 49px 76px;
  margin-top: -20px;
  cursor: pointer;
`

function BadgeCount (props) {
  if (!props.count) return null

  return <NotificationBadge pill>{props.count}</NotificationBadge>
}

const mapStateToProps = ({ auth }) => ({ currentUser: auth.user })

const mapDispatchToProps = dispatch => ({
  signOut: () => dispatch(logoutUser(true)),
  getAllUserReviews: () => dispatch(getAllUserReviews())
})

class Sidebar extends Component {
  componentDidMount () {
    this.props.getAllUserReviews()
  }

  render () {
    const { currentUser } = this.props
    const reviewerReviewsCount = currentUser.pendingReviewerReviews.length
    const revieweeReviewsCount = currentUser.pendingRevieweeReviews.length
    const totalReviewsCount = reviewerReviewsCount + revieweeReviewsCount
    const totalBadge = <BadgeCount count={totalReviewsCount} />

    let forReview
    if (isSuperCurator(currentUser)) {
      forReview = (
        <li>
          <StyledNavLink href='/#/reviews/reviewer'>
            For review
            <BadgeCount count={reviewerReviewsCount} />
          </StyledNavLink>
        </li>
      )
    }

    return (
      <SidebarContainer>
        <NavList>
          <LogoContainer onClick={() => this.props.history.push('/')}>
            <img src={meuralWhiteLogo} width='100%' />
          </LogoContainer>
          <SidebarNavItem text='Content' bold isOpen>
            <SidebarSubNavList>
              {
                NAV_ITEMS.map(item => (
                  <li key={item}>
                    <StyledNavLink
                      href={'/#/' + item}
                      active={item === this.props.activeItem}>
                      {capitalize(item)}
                    </StyledNavLink>
                  </li>
                ))
              }
            </SidebarSubNavList>
          </SidebarNavItem>
        </NavList>
        <NavList>
          <SidebarNavItem text={currentUser.username} renderBadge={totalBadge}>
            <SidebarSubNavList>
              {forReview}
              <li>
                <StyledNavLink href='/#/reviews/reviewee'>
                  Pending reviews
                  <BadgeCount count={revieweeReviewsCount} />
                </StyledNavLink>
              </li>
              <li onClick={this.props.signOut}>
                <StyledNavLink href='/#/login'>Log out</StyledNavLink>
              </li>
            </SidebarSubNavList>
          </SidebarNavItem>
        </NavList>
      </SidebarContainer>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Sidebar))
