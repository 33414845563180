export const theme = {
  lighterGray: '#f5f6f8',
  midLightGray: '#f0f2f7',
  lightGray: '#dadce5',
  lighterMediumGray: '#e8e9eb',
  lightMediumGray: '#cfd0dc',
  mediumGray: '#a1a4b2',
  darkGray: '#373a3c',
  grayRGBA: opacity => `rgba(218, 220, 230, ${opacity || '1'})`,
  darkBlue: '#0068d9',
  blue: '#007bff',
  blueRGBA: opacity => `rgba(0, 123, 255, ${opacity || '1'})`,
  green: '#79c99c',
  yellow: '#edb45e',
  red: '#d46363',
  midRed: '#a04040',
  darkRed: '#721c24',
  white: '#ffffff',
  black: '#000000',
  purple: '#7313bd',
  darkPurple: '#160424',
  veryDarkPurple: '#14121a',
  orangeRGBA: opacity => `rgba(237, 180, 94, ${opacity || '1'})`
}
