import Theme from './Theme'

export default `
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: ${Theme.fontFamily.serif};
    font-weight: bold;
    margin-bottom: 1rem;
  }

  h1 {
    font-size: 6rem;
    line-height: 1.1em;
  }

  @media (max-width: 499px) {
    h1 {
      font-size: 3.9rem;
    }
  }

  h2 {
    font-size: 4.6rem;
    line-height: 1.2em;
  }

  @media (max-width: 499px) {
    h2 {
      font-size: 3.2rem;
    }
  }

  h3 {
    font-size: 3.5rem;
    line-height: 1.2em;
  }

  @media (max-width: 499px) {
    h3 {
      font-size: 2.7rem;
    }
  }

  h4 {
    font-size: 2.7rem;
    line-height: 1.3em;
  }

  @media (max-width: 499px) {
    h4 {
      font-size: 2.2rem;
    }
  }

  h5 {
    font-size: 2.1rem;
    line-height: 1.3em;
  }

  @media (max-width: 499px) {
    h5 {
      font-size: 1.9rem;
    }
  }

  h6 {
    font-size: 1.6rem;
  }

  p {
    font-family: ${Theme.fontFamily.sansSerif};
    line-height: 1.5em;
    font-weight: normal;
    font-size: 1.6rem;
    margin-bottom: 1rem;
  }

  p > a,
  .inline-link {
    text-decoration: underline;
    font-weight: bold;
  }

  p > a:hover, .inline-link:hover {
    color: ${Theme.colors.gray};
  }

  a {
    cursor: pointer;
    text-decoration: none;
    color: inherit;
  }

  ul, ol {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  button, input {
    font-family: ${Theme.fontFamily.sansSerif};
  }

  strong {
    font-weight: bold;
  }

  small {
    font-size: 80%;
  }
`
