import { combineReducers } from 'redux'
import UserReducer from './user/reducer'
import MessageReducer from './message/reducer'
import CanvasReducer from './canvas/reducer'

export default combineReducers({
  user: UserReducer,
  message: MessageReducer,
  canvas: CanvasReducer
})
