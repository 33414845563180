import React from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import capitalize from 'lodash/capitalize'
import isEmpty from 'lodash/isEmpty'
import { StyledLink, StyledTooltip, StickyHeader } from './StyledComponents'
import { ARTISTS, EDITORIALS } from '../constants/ContentConstants'
import { RELATED_CONTENT } from '../constants/RelatedContentConstants'
import pluralize from 'pluralize'
import { isSuperCurator } from '../utils/General'
import MainHeader from './MainHeader'

const EditIcon = styled.i`
  opacity: ${props => props.disable ? '0.5' : '1'};
  cursor: ${props => props.disable ? 'default' : 'pointer'};
  color: ${props => props.theme.mediumGray};
  margin-left: 10px;
  transition: 0.2s ease color;
  font-size: 38px;
  margin-right: 16px;

  &:hover {
    color: ${props => props.theme.darkGray};
  }
`

function Tooltip (props) {
  return (
    <StyledTooltip placement='bottom' target={props.target} offset='0, 5' delay={0}>
      You must cancel review to edit
    </StyledTooltip>
  )
}

function ButtonWithTooltip (props) {
  const editIcon = (
    <EditIcon
      className='material-icons'
      id={props.tooltipTarget}
      disable={!props.canEdit}>
      {props.iconName}
    </EditIcon>
  )

  let edit, tooltip
  if (props.canEdit) {
    edit = <StyledLink to={props.toLink} display='flex'>{editIcon}</StyledLink>
  } else {
    edit = editIcon
    tooltip = <Tooltip target={props.tooltipTarget} />
  }

  return (
    <React.Fragment>
      {edit}
      {tooltip}
    </React.Fragment>
  )
}

const mapStateToProps = ({ auth }) => ({ user: auth.user })

function ViewHeader (props) {
  if (isEmpty(props.content)) return <div />

  const name = props.type === ARTISTS
             ? `${props.content.firstName} ${props.content.surname}`
             : props.content.name

  const isEditorial = props.type === EDITORIALS
  const canEdit = isSuperCurator(props.user) || !props.content.pendingReviews.length

  return (
    <StickyHeader>
      <MainHeader
        showBackBtn
        type={props.type}
        text={`${capitalize(pluralize.singular(props.type))}: ${name}`}
        tabs={[
          {displayName: 'Summary', route: `/${props.type}/${props.content.id}`},
          {displayName: 'History', route: `/${props.type}/${props.content.id}/history`}
        ].concat(RELATED_CONTENT[props.type].map(relatedType => ({
          displayName: capitalize(relatedType),
          route: `/${props.type}/${props.content.id}/${relatedType}`
        })))}>
        { isEditorial &&
          <ButtonWithTooltip
            canEdit={canEdit}
            toLink={`/editorials/${props.content.id}/compose`}
            iconName='notes'
            tooltipTarget='compose-tooltip' />
        }
        <ButtonWithTooltip
          canEdit={canEdit}
          toLink={props.editLink}
          iconName='edit'
          tooltipTarget='edit-tooltip' />
      </MainHeader>
    </StickyHeader>
  )
}

export default connect(mapStateToProps, null)(ViewHeader)
