import React, { Fragment } from 'react'
import styled from 'styled-components'
import { connect } from 'react-redux'
import RadioButtons from './RadioButtons'
import { isSuperCurator } from '../../utils/General'
import { ColorBadge, Bold } from '../StyledComponents'
import DatePicker from './DatePicker'

const ColorBadgeContainer = styled(ColorBadge)`
  margin-bottom: 20px;
`

const PrivateDate = class Component extends DatePicker {}
PrivateDate.defaultProps = {
  field: 'privateDate'
}

const mapStateToProps = ({ auth }) => ({ user: auth.user })

function IsPublic (props) {
  const isPublicParams = {
    name: 'isPublic',
    value: props.isPublic,
    label: 'Publish Status',
    options: [
      { value: true, badgeColor: 'green', badgeText: 'Public', description: 'Anyone can see this' },
      { value: false, badgeColor: 'yellow', badgeText: 'Private', description: 'Only Curators can see this' }
    ]
  }

  let publicBadges
  if (isSuperCurator(props.user)) {
    publicBadges = (
      <RadioButtons content={isPublicParams}
        handleRadioChange={props.handleRadioChange} />
    )
  } else {
    const details = isPublicParams.options.find(option => option.value === props.isPublic)
    if (!details) return <div />

    publicBadges = (
      <Fragment>
        <ColorBadgeContainer color={details.badgeColor}>
          {details.badgeText}
        </ColorBadgeContainer>{details.description}
      </Fragment>
    )
  }

  return (
    <Fragment>
      {publicBadges}
      {props.isPublic && props.canSchedule &&
        <Fragment>
          <p><Bold>Scheduled to become private</Bold></p>
          <PrivateDate
            currentDateTime={props.privateDate}
            handleChange={props.handleDateTimeChange} />
        </Fragment>
      }
    </Fragment>
  )
}

export default connect(mapStateToProps, null)(IsPublic)
