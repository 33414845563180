import * as constants from '../constants/GroupConstants'
import { GROUPS } from '../constants/ContentConstants'
import { DELETE_REVIEW, RECEIVE_REVIEW } from '../constants/ReviewConstants'

const _nullGroups = {
  error: null,
  groups: {
    data: [],
    isLast: false,
    count: null
  },
  current: {
    pendingReviews: []
  }
}

export default function GroupReducer (state = _nullGroups, action) {
  switch (action.type) {
    case constants.RECEIVE_GROUPS: {
      const { isLast, data, count } = action.groups
      const { newQuery } = action
      const newGroups = {
        isLast: isLast,
        data: newQuery ? data : [].concat(state.groups.data, data),
        count
      }
      return Object.assign({}, state, { groups: newGroups })
    }
    case constants.RECEIVE_GROUP: {
      return Object.assign({}, state, {
        current: Object.assign({}, state.current, action.group)
      })
    }
    case DELETE_REVIEW: {
      if (!action.contentType === GROUPS) return state

      const newPendingReviews = Object.assign({}, {
        pendingReviews: state.current.pendingReviews
          .filter(review => review.id !== action.reviewId)
      })

      return Object.assign({}, state, {
        current: Object.assign({}, state.current, newPendingReviews)
      })
    }
    case RECEIVE_REVIEW: {
      if (!action.contentType === GROUPS) return state

      return Object.assign({}, state, {
        current: Object.assign({}, state.current, action.review.contentObject)
      })
    }
    case constants.RECEIVE_GROUPS_ERROR: {
      return Object.assign({}, state, { error: action.error })
    }
    case constants.CLEAR_GROUPS: {
      return Object.assign({}, _nullGroups)
    }
    default: {
      return state
    }
  }
}
