import { connect } from 'react-redux'
import { getItem } from '../../actions/ItemActions'
import { ITEMS } from '../../constants/ContentConstants'

import ViewContent from '../ViewContent'

const mapStateToProps = state => {
  return {
    type: ITEMS,
    content: state.items.current
  }
}

const mapDispatchToProps = dispatch => ({
  getContent: payload => dispatch(getItem(payload))
})

export default connect(mapStateToProps, mapDispatchToProps)(ViewContent)
