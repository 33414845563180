import { receiveErrorMessage, receiveSuccessMessage } from './MessageActions'
import { getAllUserReviews } from './AuthActions'
import * as constants from '../constants/ReviewConstants'
import * as API from '../api/Reviews'

export const getUserReviewsByRole = (role) => dispatch => {
  return API.getUserReviews(role).then(payload => dispatch({
    type: role === 'reviewer'
      ? constants.RECEIVE_REVIEWER_REVIEWS
      : constants.RECEIVE_REVIEWEE_REVIEWS,
    data: payload.data
  }))
}

export const createReview = (data) => dispatch => {
  return API.createReview(data)
    .then(() => dispatch(getAllUserReviews()))
    .then(() => dispatch(receiveSuccessMessage('Review submitted')))
    .catch(error => {
      dispatch(receiveErrorMessage(error))
      return error
    })
}

export const cancelReview = (reviewId, contentType) => dispatch => {
  return API.deleteReview(reviewId)
    .then(() => dispatch({ type: constants.DELETE_REVIEW, reviewId, contentType }))
    .then(() => dispatch(getAllUserReviews()))
    .then(() => dispatch(receiveSuccessMessage('Review canceled')))
    .catch(error => dispatch(receiveErrorMessage(error)))
}

export const approveReview = (reviewId, contentType) => dispatch => {
  return API.approveReview(reviewId)
    .then(({ data: review }) => {
      return dispatch({ type: constants.RECEIVE_REVIEW, review, contentType })
    })
    .then(() => dispatch(getAllUserReviews()))
    .then(() => dispatch(receiveSuccessMessage('Review approved and published')))
    .catch(error => dispatch(receiveErrorMessage(error)))
}

export const clearReviews = () => dispatch => {
  return dispatch({ type: constants.CLEAR_REVIEWS })
}
