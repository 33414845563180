import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import { ThemeProvider, StyleSheetManager } from 'styled-components'
import { Provider } from 'react-redux'
import createStore from '../../../../web/redux/Store'
import Theme from '../../../../web/styles/Theme'
import globals from '../../../../web/styles/Globals'
import EditorialBody from '../../../../web/components/Editorial/EditorialBody'

const store = createStore()

export default class ComposerPreview extends Component {
  constructor (props) {
    super(props)

    this.state = { iframe: null }

    this.setIframeNode = this.setIframeNode.bind(this)
  }

  componentDidUpdate (prevProps, prevState) {
    if ((prevState.iframe !== this.state.iframe) && this.state.iframe) {
      const iframe = this.state.iframe
      const ifDoc = iframe.contentDocument
      const ifHead = ifDoc.head
      const styleNode = ifDoc.createElement('style')
      styleNode.innerHTML = globals()
      ifHead.appendChild(styleNode)
    }
  }

  setIframeNode (node) {
    if (!this.state.iframe) {
      this.setState({ iframe: node })
    }
  }

  render () {
    const scrolling = this.props.scrolling ? 'yes' : 'no'

    return (
      <iframe ref={this.setIframeNode} width='100%' height='100%' frameBorder='0' scrolling={scrolling}>
        {
          this.state.iframe && ReactDOM.createPortal((
            <StyleSheetManager target={this.state.iframe.contentDocument.head}>
              <ThemeProvider theme={Theme}>
                <Provider store={store}>
                  <EditorialBody body={this.props.body} />
                </Provider>
              </ThemeProvider>
            </StyleSheetManager>
          ), this.state.iframe.contentDocument.body)
        }
      </iframe>
    )
  }
}
