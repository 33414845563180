import capitalize from 'lodash/capitalize'
import { ORDERING_DISPLAYS, FILTER_DISPLAYS } from '../constants/SearchAndFilterConstants'

export const parseSearchAndFilter = (searchAndFilter, groups) => {
  const { sort, filters, searchString } = searchAndFilter

  let searchAndFilterText = ''
  if (sort.field) {
    const sortField = ORDERING_DISPLAYS[sort.field]
    searchAndFilterText += ` > ${sortField}`
  }

  Object.keys(filters).forEach(filterKey => {
    const value = filters[filterKey]
    if (value) {
      let filterText
      if (filterKey === 'group') {
        const group = groups.find(group => group.id === parseInt(value))
        filterText = group && group.name
      } else {
        filterText = FILTER_DISPLAYS[filterKey].options[value].display
      }

      searchAndFilterText += ` > ${capitalize(filterText)}`
    }
  })

  if (searchString) {
    searchAndFilterText += ` > ${searchString}`
  }

  return searchAndFilterText
}
