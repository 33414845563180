import React, { Component } from 'react'
import styled from 'styled-components'
import { connect } from 'react-redux'
import AbstractModal from '../AbstractModal'
import { clearRelatedContentError } from '../../actions/RelatedContentActions'
import { clearSelectedContent } from '../../actions/SelectedContentActions'
import { ARTISTS } from '../../constants/ContentConstants'
import { GrayButton, PaddedContainer } from '../StyledComponents'

const NameAndError = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 10px 0px;
  align-items: center;
`

const Name = styled.span`
  width: 50%;
  margin-right: 10px;
`

const ErrorMessage = styled.span`
  width: 50%;
`

const mapStateToProps = ({ relatedContent }) => ({
  errors: relatedContent.errors,
  type: relatedContent.type
})

const mapDispatchToProps = (dispatch) => ({
  clearErrors: () => dispatch(clearRelatedContentError()),
  clearSelectedContent: () => dispatch(clearSelectedContent())
})

class RelatedContentErrorsModal extends Component {
  constructor (props) {
    super(props)

    this.state = {
      isOpen: false
    }

    this.toggleModal = this.toggleModal.bind(this)
  }

  toggleModal () {
    if (this.state.isOpen) {
      this.props.clearErrors()
      this.props.clearSelectedContent()
    }
  }

  static getDerivedStateFromProps (nextProps, prevState) {
    return {
      isOpen: !!nextProps.errors.length
    }
  }

  render () {
    const { type } = this.props

    return (
      <AbstractModal isOpen={this.state.isOpen} toggleModal={this.toggleModal}>
        There was a problem with performing this action on the following {`${type}`}:
        <PaddedContainer padding='10px'>
          {
            this.props.errors.map(error => {
              const name = type === ARTISTS
                ? `${error.content.firstName} ${error.content.surname}`
                : error.content.name

              return (
                <NameAndError key={error.id}>
                  <Name><b>{name}</b></Name>
                  <ErrorMessage>{error.message}</ErrorMessage>
                </NameAndError>
              )
            })
          }
        </PaddedContainer>
        <GrayButton small='true' fullwidth='true' onClick={this.toggleModal}>
          OK
        </GrayButton>
      </AbstractModal>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(RelatedContentErrorsModal)
