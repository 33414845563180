import * as constants from '../constants/EditorialConstants'
import * as API from '../api/Editorials'
import { receiveErrorMessage, receiveSuccessMessage } from './MessageActions'
import { RECEIVE_RELATED_CONTENT } from '../constants/RelatedContentConstants'
import { EDITORIALS } from '../constants/ContentConstants'
import { getRelatedContent as getRelatedContentAction } from './RelatedContentActions'
import { getRelatedContent } from '../api/General'

const getOnlyEditorial = (id) => dispatch => {
  return API.getEditorial(id).then(({ data: editorial }) => dispatch({
    type: constants.RECEIVE_EDITORIAL, editorial
  })).catch(error => dispatch({ type: constants.RECEIVE_EDITORIALS_ERROR, error }))
}

export const getEditorial = (id, childType, opts = { queryParams: null }) => dispatch => {
  if (!childType) return dispatch(getOnlyEditorial(id))

  if (!opts.queryParams.newQuery) {
    return dispatch(getRelatedContentAction(EDITORIALS, id, childType, opts.queryParams))
  } else {
    return Promise.all([
      API.getEditorial(id),
      getRelatedContent(EDITORIALS, id, childType, opts.queryParams)
    ]).then(values => {
      const editorial = values[0].data
      const relatedContent = values[1]
      dispatch({ type: constants.RECEIVE_EDITORIAL, editorial })
      dispatch({
        type: RECEIVE_RELATED_CONTENT,
        content: relatedContent,
        contentType: childType,
        newQuery: opts.queryParams.newQuery
      })
    }).catch(error => dispatch({ type: constants.RECEIVE_EDITORIALS_ERROR, error }))
  }
}

export const updateEditorial = (editorialId, data) => dispatch => {
  return API.updateEditorial(editorialId, data).then(({ data: editorial }) => {
    dispatch({ type: constants.RECEIVE_EDITORIAL, editorial })
  }).then(() => dispatch(receiveSuccessMessage('Editorial updated')))
    .catch(error => {
      dispatch(receiveErrorMessage(error))
      dispatch({ type: constants.RECEIVE_EDITORIALS_ERROR, error })
      return Promise.reject(error)
    })
}

export const createEditorial = (data) => dispatch => {
  return API.createEditorial(data).then(({ data: editorial }) => {
    dispatch({ type: constants.RECEIVE_EDITORIAL, editorial })
  }).then(() => dispatch(receiveSuccessMessage('Editorial created')))
    .catch(error => {
      dispatch(receiveErrorMessage(error))
      return dispatch({ type: constants.RECEIVE_EDITORIALS_ERROR, error })
    })
}

export const deleteEditorial = (id) => dispatch => {
  return API.deleteEditorial(id)
    .then(() => dispatch(receiveSuccessMessage('Editorial deleted')))
    .catch(error => {
      dispatch({ type: constants.RECEIVE_EDITORIALS_ERROR, error })
      return Promise.reject(error)
    })
}

export const getEditorials = (queryParams) => dispatch => {
  return API.getEditorials(queryParams)
    .then(editorials => dispatch({
      type: constants.RECEIVE_EDITORIALS, editorials, newQuery: queryParams.newQuery
    })).catch(error => dispatch({ type: constants.RECEIVE_EDITORIALS_ERROR, error }))
}

export const clearEditorials = () => dispatch => dispatch({ type: constants.CLEAR_EDITORIALS })
