import styled from 'styled-components'
import {
  Button, Badge, Input, Label, Alert, Card, UncontrolledTooltip, DropdownToggle,
  ModalBody
} from 'reactstrap'
import { Link } from 'react-router-dom'

export const RelativeContainer = styled.span`
  position: relative;
  display: block;
`

export const PaddedContainer = styled.span`
  padding: ${props => props.padding};
  display: block;
  width: ${props => props.fullWidth ? '100%' : 'auto'};
`

export const MarginContainer = styled.span`
  margin: ${props => props.margin};
  display: block;
`

export const FlexContainer = styled.div`
  display: flex;
  justify-content: ${props => props.justifyContent};
  flex-wrap: ${props => Boolean(props.wrap) ? 'wrap' : ''};
  align-items: ${props => props.alignItems || 'center'};
  width: ${props => props.fullWidth ? '100%' : 'auto'};
  height: ${props => props.fullHeight ? '100%' : 'auto'};
  margin-bottom: ${props => props.marginbottom ? props.marginbottom : '0px'};
`

export const FlexColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
`

export const FlexCenterContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

export const FlexHorizontalCenterContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: ${props => props.justifyContent};
`

export const FullHeightFlexContainer = styled(FlexContainer)`
  height: 100vh;
  justify-content: ${props => props.justifyContent || ''};
  align-items: center;
`

export const WrapContainer = styled.div`
  margin: 40px;
`

export const Grid = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-left: -10px;
  margin-top: 20px;
  width: 100%;
  flex: 1;
`

export const BlueButton = styled(Button)`
  background: ${props => props.theme.blueRGBA(1)};
  color: white;
  width: ${props => Boolean(props.fullwidth) ? '100%' : ''};
  margin-left: ${props => props.marginleft};
  margin-bottom: ${props => props.marginbottom};
  border: none;
  font-size: ${props => Boolean(props.small) ? '14px' : '20px'};
  cursor: pointer;
  font-weight: 100;
  border: ${props => props.error ? `1px solid ${props.theme.red}` : 'none'};
  opacity: ${props => props.disable ? '0.3' : '1'};

  &:hover {
    background: ${props => props.theme.darkBlue};
    color: white;
  }

  &:disabled {
    background: ${props => props.theme.blueRGBA(1)};
    opacity: 0.3;
  }
`

export const GrayButton = styled(BlueButton)`
  background: ${props => props.theme.lightGray};
  color: black;
  font-weight: 400;
  border: ${props => props.error ? `1px solid ${props.theme.red}` : 'none'};

  &:hover {
    background: ${props => props.theme.lightMediumGray};
    color: black;
  }

  &:disabled {
    background: ${props => props.theme.lighterGray};
    border: none;
  }
`

export const LightGrayButton = styled(BlueButton)`
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${props => props.theme.lighterGray};

  &:hover {
    background: ${props => props.theme.lightGray};
  }
`

export const RedButton = styled(BlueButton)`
  background: ${props => props.theme.red};

  &:hover {
    background: ${props => props.theme.midRed};
  }
`

export const Thumbnail = styled.div`
  width: ${props => `${props.size}px`};
  height: ${props => `${props.size}px`};
  border-radius: ${props => props.borderRadius};
  background-image: url(${props => props.image});
  background-size: 'cover';
  background-position: center;
  background-repeat: no-repeat;
  margin: auto;
`

const BADGE_STYLES = `
  color: white;
  text-transform: capitalize;
  font-size: 14.4px;
  margin-right: 5px;
  padding: 5px 3px;
`

export const StyledBadge = styled(Badge)`
  ${BADGE_STYLES}
`

export const GreenBadge = styled(Badge)`
  background: ${props => props.theme.green};
  ${BADGE_STYLES}
`

export const YellowBadge = styled(Badge)`
  background: ${props => props.theme.yellow};
  ${BADGE_STYLES}

`

export const RedBadge = styled(Badge)`
  background: ${props => props.theme.red};
  ${BADGE_STYLES}
`

export const GrayBadge = styled(Badge)`
  background: ${props => props.theme.lightGray};
  ${BADGE_STYLES}
`

export const PurpleBadge = styled(Badge)`
  background: ${props => props.theme.purple};
  ${BADGE_STYLES}
`

export const ColorBadge = styled(StyledBadge)`
  background: ${props => props.theme[props.color]}
`

export const HistoryBadge = styled(Badge)`
  margin-right: 20px;
  background: ${props => props.theme[props.color]};
  color: white;
  width: 40px;
`

export const StyledLink = styled(Link)`
  display: ${props => props.display ? props.display : 'block'};
  color: ${props => props.theme[props.color] || 'white'};
  transition: 0.2s ease all;

  &:hover {
    text-decoration: none;
    color: ${props => props.theme[props.hover] || props.theme[props.color] || 'white'};
  }
`

export const ViewAllLink = styled(Link)`
  color: black;
  cursor: pointer;
  margin-left: 10px;
  display: flex;
  &:hover {
    text-decoration: none;
    color: ${props => props.theme.blue};
  }
`

export const StickyHeader = styled(FlexColumnContainer)`
  position: sticky;
  top: 0;
  z-index: 10;
`

export const CountBadge = styled(Badge)`
  position: absolute;
  top: 10px;
  right: 20px;
  background: rgba(22, 4, 36, 0.8);
  font-family: HelveticaNeue;
  font-size: 18px;
  font-weight: bold;
  line-height: 1.0;
`

export const StyledInput = styled(Input)`
  border-color: ${props => props.error ? props.theme.red : props.theme.lightGray}
`

export const StyledLabel = styled(Label)`
  color: ${props => props.error ? props.theme.red : props.theme.gray};
  font-weight: 500;
  width: ${props => props.fullwidth ? '100%' : ''}
`

export const StyledAlert = styled(Alert)`
  position: absolute;
  top: 20px;
  left: 25%;
  width: 50%;
  color: ${props => props.theme.darkRed};
  padding: 10px;
  z-index: 1;
`

export const CoverImage = styled.div`
  border-radius: ${props => props.circular ? '50%' : ''};
  background-image: url(${props => props.image});
  background-size: ${props => props.backgroundSize || 'cover'};
  background-position: center;
  background-repeat: no-repeat;
  margin: auto;
`

export const GraySpan = styled.span`
  color: ${props => props.theme.mediumGray};
`

export const SquareImg = styled.div`
  width: 55px;
  height: 55px;
  background-image: url(${props => props.image});
  background-size: cover;
  background-repeat: no-repeat;
`

export const CircleImg = styled(SquareImg)`
  border-radius: 50%;
`

export const FullWidth = styled.div`
  width: 100%;
`

export const Icon = styled.i`
  font-size: ${props => props.fontSize || '30px'};
`

export const StyledCard = styled(Card)`
  padding: 10px;
  margin: 10px;
  width: 255px;
`

export const AddCard = styled(StyledCard)`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;
  background-color: transparent;
  border: 5px solid ${props => props.theme.lightGray};
  opacity: 0.5;
`

export const OverlayAction = styled.div`
  background: white;
  border-radius: 50%;
  padding: 15px;
  margin: 10px;
  opacity: ${props => props.disabled ? 0.8 : 1};
  display: flex;
  align-items: center;
  justify-content: center;
  color: black;
  &:hover {
    color: ${props => props.disabled ? props.theme.black : props.theme.blueRGBA(0.9)}};
  }
`

export const OverlayLink = styled(Link)`
  color: inherit;
  &:hover {
    color: inherit;
  }
`

export const Capitalize = styled.span`
  text-transform: capitalize
`

export const OuterHeader = styled(FlexContainer)`
  background-color: ${props => props.theme.midLightGray};
`

export const CenteredSpaceBetween = styled(FlexHorizontalCenterContainer)`
  font-size: 18px;
  justify-content: space-between;
  padding: 15px 40px;
  width: 100%;
`

export const Rectangle = styled.div`
  content: "";
  width: ${props => props.orientation === 'vertical' ? '14px' : '24px'};
  height: ${props => props.orientation === 'vertical' ? '24px' : '14px'};
  border: 2px solid ${props => props.selected ? props.theme.blue : props.theme.white};
  margin-right: ${props => props.marginright};
  cursor: pointer;
`

export const GrayButtonLink = styled(GrayButton)`
  .button-link {
    color: black;

    &:hover {
      text-decoration: none;
      color: black;
    }
  }
`

export const StyledTooltip = styled(UncontrolledTooltip)`
  font-size: 12px;
`

export const StyledDropdownToggle = styled(DropdownToggle)`
  border: 1px solid ${props => props.theme.lightMediumGray};
  background-color: ${props => props.theme.white};
  width: ${props => props.width || '129px'};
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const ModalContent = styled(ModalBody)`
  padding: 30px;
  position: relative;
`

export const ModalCloseIcon = styled.i`
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
`

export const Bold = styled.b`
  font-weight: 500;
`
