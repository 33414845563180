import React from 'react'
import { connect } from 'react-redux'
import capitalize from 'lodash/capitalize'
import pick from 'lodash/pick'
import AbstractEditForm from './AbstractEditForm'
import {
  ITEMS, CATEGORIES, PROVIDERS, ARTISTS, USERS, CHANNELS
} from '../../constants/ContentConstants'
import { batchUpdateItems, batchDeleteItems } from '../../actions/ItemActions'
import { clearSelectedContent } from '../../actions/SelectedContentActions'
import { FlexContainer } from '../StyledComponents'
import {
  EditWrapPage, StyledOuterContainer, StyledDetailContainer,
  StyledRelatedContainer, StyledFlexColumnContainer
} from '../StyledEditComponents'
import EditHeader from './EditHeader'
import IsPublic from './IsPublic'
import TextField from './TextField'
import SuperPicker from './SuperPicker'
import ArtistPicker from './ArtistPicker'
import Tags from './Tags'
import RelatedContentList from './RelatedContentList'
import ProviderContent from './ProviderContent'
import UserList from './UserList'
import BatchSelectedList from './BatchSelectedList'
import CategoryPicker from './CategoryPicker'
import {
  stripNullFromObject, mergeIntersections, convertObjNullValuesToString,
  stripEmptyStringFromArray, formatStringType
} from '../../utils/General'
import * as CategoryAPI from '../../api/Categories'
import * as ArtistAPI from '../../api/Artists'
import * as ChannelAPI from '../../api/Channels'

function mapStateToProps ({ selectedContent, items }) {
  return {
    type: ITEMS,
    selected: selectedContent.content,
    error: items.error
  }
}

function mapDispatchToProps (dispatch) {
  return {
    batchUpdate: (payload) => dispatch(batchUpdateItems(payload)),
    clearSelected: () => dispatch(clearSelectedContent()),
    destroyObjects: (items) => dispatch(batchDeleteItems(items))
  }
}

class BatchEditForm extends AbstractEditForm {
  constructor (props) {
    super(props)

    this.state = {
      object: {
        isPublic: null,
        name: '',
        artist: null,
        author: '',
        year: '',
        dimensions: '',
        description: '',
        copyright: '',
        notes: '',
        medium: '',
        tags: [],
        categories: [],
        channels: [],
        provider: null,
        user: null
      },
      categories: [],
      channels: [],
      provider: {},
      user: {},
      artist: null
    }
  }

  componentWillMount () {
    // handles refreshing on batch edit page
    if (this.props.selected.length === 0) return

    this.findCommonDataAndSetState(this.props.selected)
  }

  componentWillReceiveProps (nextProps) {
    const currSelected = this.props.selected
    const nextSelected = nextProps.selected
    // if only some items were deleted and some remain, update the form
    if (nextSelected.length !== 0 &&
        currSelected.length !== nextSelected.length) {
      this.findCommonDataAndSetState(nextProps.selected)
    }

    this.setFieldErrors(nextProps)
  }

  findCommonDataAndSetState (selected) {
    // find common values between selected objects, filter out unneeded properties
    const commonData = convertObjNullValuesToString(pick(
      mergeIntersections(selected),
      Object.keys(this.state.object)
    ))

    const { provider, user, tags } = commonData
    const newState = {
      object: Object.assign(this.state.object, commonData, {
        provider: provider && provider.id,
        user: user && user.id,
        tags: stripEmptyStringFromArray(tags)
      }),
      categories: [],
      channels: [],
      provider,
      user,
      artist: null
    }

    // fetch individual categories and artists to display to user
    Promise.all([].concat(commonData.categories.map(categoryId => {
      return CategoryAPI.getCategory(categoryId)
    }), [commonData.artist].map(artistId => {
      if (artistId) {
        return ArtistAPI.getArtist(artistId)
      } else {
        return null
      }
    }),
    commonData.channels.map(channelId => {
      return ChannelAPI.getChannel(channelId)
    })
    )).then(values => {
      values.forEach((value, idx) => {
        if (idx < commonData.categories.length) {
          newState.categories.push(value.data)
        } else if (commonData.artist && idx === commonData.categories.length) {
          newState.artist = value.data
        } else if (value) {
          newState.channels.push(value.data)
        }
      })
    }).then(() => this.setState(newState))
  }

  componentWillUnmount () {
    this.props.clearSelected()
  }

  handleSubmit () {
    return this.props.batchUpdate({
      ids: this.props.selected.map(content => content.id),
      data: stripNullFromObject(this.state.object)
    })
    .then(() => this.props.history.go(-1))
    .catch(() => {
      // stay on page
    })
  }

  render () {
    const capitalizedType = capitalize(this.props.type)
    const type = formatStringType(capitalizedType, this.props.selected.length)
    const header = `${this.props.selected.length} ${type}`

    return (
      <div>
        <EditHeader name={header} type={this.props.type}
          batchEdit
          handleSave={this.handleSubmit}
          selected={this.props.selected}
          destroyObjects={this.destroyObject} />
        <EditWrapPage>
          <StyledOuterContainer>
            <BatchSelectedList header={header} type={this.props.type}
              selected={this.props.selected} />
            <StyledFlexColumnContainer>
              <StyledDetailContainer>
                <IsPublic isPublic={this.state.object.isPublic}
                  handleRadioChange={this.handleRadioChange} />
              </StyledDetailContainer>
              <StyledDetailContainer>
                <FlexContainer>
                  <TextField id='author' name='author' type='text'
                    label='Author'
                    halfWidth
                    marginRight
                    error={this.state.authorError}
                    value={this.state.object.author}
                    onChange={this.handleTextChange} />
                  <TextField id='medium' name='medium' type='text'
                    label='Medium'
                    halfWidth
                    error={this.state.mediumError}
                    value={this.state.object.medium}
                    onChange={this.handleTextChange} />
                </FlexContainer>
                <SuperPicker
                  render={(ctx) => {
                    return (
                      <ArtistPicker artist={this.state.artist}
                        label='Artist' toggleModal={ctx.toggleModal}
                        error={this.state.artistError} />
                    )
                  }}
                  relatedContentType={ARTISTS}
                  name='artist'
                  baseType={ITEMS}
                  getAll
                  currentId={this.state.object.id}
                  changeRelationship={this.changeSelectedContent} />
                <FlexContainer>
                  <TextField id='year' name='year' type='text'
                    label='Year'
                    error={this.state.yearError}
                    value={this.state.object.year}
                    onChange={this.handleTextChange}
                    halfWidth
                    marginRight />
                  <TextField id='dimensions' name='dimensions' type='text'
                    label='Dimensions'
                    error={this.state.dimensionsError}
                    value={this.state.object.dimensions}
                    onChange={this.handleTextChange}
                    halfWidth />
                </FlexContainer>
                <TextField id='description' name='description' type='textarea'
                  label='Description'
                  error={this.state.descriptionError}
                  value={this.state.object.description}
                  onChange={this.handleTextChange} />
                <TextField id='copyright' name='copyright' type='textarea'
                  label='Copyright'
                  error={this.state.copyrightError}
                  value={this.state.object.copyright}
                  onChange={this.handleTextChange} />
                <TextField id='notes' name='notes' type='textarea'
                  label='Notes'
                  error={this.state.notesError}
                  value={this.state.object.notes}
                  onChange={this.handleTextChange} />
                <Tags tags={this.state.object.tags}
                  handleTagsChange={this.handleTagsChange}
                  error={this.state.tagsError}
                  batchEdit />
              </StyledDetailContainer>
              <StyledRelatedContainer>
                <SuperPicker
                  render={(ctx) => {
                    return (
                      <UserList
                        error={this.state.userError}
                        owner={this.state.user}
                        toggleModal={ctx.toggleModal} />
                    )
                  }}
                  relatedContentType={USERS}
                  baseType={ITEMS}
                  getAll
                  currentId={this.state.object.id}
                  changeRelationship={this.changeUser} />
                <CategoryPicker
                  selectedCategories={this.state[CATEGORIES]}
                  removeRelationship={this.removeRelatedContent}
                  createRelationship={this.toggleRelationship} />
                <SuperPicker
                  render={(ctx) => {
                    return (
                      <RelatedContentList
                        relatedContentType={ctx.props.relatedContentType}
                        content={ctx.props.content}
                        removeRelationship={ctx.props.removeRelationship}
                        toggleModal={ctx.toggleModal} />
                    )
                  }}
                  content={this.state[CHANNELS]}
                  relatedContentType={CHANNELS}
                  baseType={ITEMS}
                  getAll
                  currentId={this.state.object.id}
                  createRelationship={this.addRelatedContent}
                  removeRelationship={this.removeRelatedContent} />
                <SuperPicker
                  render={(ctx) => {
                    return (
                      <ProviderContent provider={this.state.provider}
                        toggleModal={ctx.toggleModal}
                        changeRelationship={ctx.props.changeRelationship} />
                    )
                  }}
                  relatedContentType={PROVIDERS}
                  name='provider'
                  baseType={ITEMS}
                  getAll
                  currentId={this.state.object.id}
                  changeRelationship={this.changeSelectedContent} />
              </StyledRelatedContainer>
            </StyledFlexColumnContainer>
          </StyledOuterContainer>
        </EditWrapPage>
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(BatchEditForm)
