import React from 'react'
import { Modal } from 'reactstrap'
import { ModalContent, ModalCloseIcon } from './StyledComponents'
import styled from 'styled-components'

const StyledModal = styled(Modal)`
  margin: 10% auto;
`

export default function AbstractModal (props) {
  return (
    <StyledModal
      isOpen={props.isOpen}
      toggle={props.toggleModal}>
      <ModalContent>
        <ModalCloseIcon className='material-icons' onClick={props.toggleModal}>
          close
        </ModalCloseIcon>
        {props.children}
      </ModalContent>
    </StyledModal>
  )
}
