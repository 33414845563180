import React from 'react'
import startCase from 'lodash/startCase'
import styled from 'styled-components'
import { Bold, FlexContainer, HistoryBadge } from '../StyledComponents'
import { parseBodyToFrontend } from '../../utils/EditorialMarkdown'

const Container = styled.div`
  margin: 28px 0px 0px 20px;
`

const DiffDetail = styled(FlexContainer)`
  margin: 13px 0;
`

const DiffContainer = styled.div`
  padding: 10px 0;
`

const EditorialBodyContainer = styled.pre`
  white-space: pre-wrap;
  word-wrap: break-word;
  font-family: inherit;
  font-size: 16px;
`

const ContentDivider = styled.hr`
  width: 100%;
  height: 100%;
  padding-bottom: 35px;
`

export default function HistoryDetail (props) {
  return (
    <Container>
      {Object.keys(props.diff).map(field => {
        const fieldObj = props.diff[field]
        let oldVal = fieldObj.old
        let newVal = fieldObj.new
        let divider

        if (field === 'body') {
          oldVal = (
            <EditorialBodyContainer>{parseBodyToFrontend(fieldObj.old)}</EditorialBodyContainer>
          )
          newVal = <EditorialBodyContainer>{parseBodyToFrontend(fieldObj.new)}</EditorialBodyContainer>
          divider = <ContentDivider />
        } else if (Array.isArray(fieldObj.old)) {
          oldVal = fieldObj.old.join(', ')
          newVal = fieldObj.new.join(', ')
        }

        return (
          <div key={field}>
            <Bold key={field}>{startCase(field).toLowerCase()}</Bold>
            <DiffContainer>
              <DiffDetail>
                <HistoryBadge color='mediumGray' pill>Old</HistoryBadge>
                {oldVal}
              </DiffDetail>
              {divider}
              <DiffDetail>
                <HistoryBadge color='green' pill>New</HistoryBadge>
                {newVal}
              </DiffDetail>
            </DiffContainer>
          </div>
        )
      })}
    </Container>
  )
}
