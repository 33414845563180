import React from 'react'
import marked from 'marked'
import { stripMd } from '../utils/general'

const myRenderer = new marked.Renderer()

myRenderer.link = function (href, title, text) {
  const target = href[0] === '/' ? '_self' : '_blank'
  return `<a href="${href}" target="${target}" alt="${text}">${text}</a>`
}

marked.setOptions({
  renderer: myRenderer,
  gfm: false,
  tables: false,
  breaks: true,
  pedantic: false,
  sanitize: true,
  smartLists: true,
  smartypants: true
})

export default function Markdown (props) {
  if (!props.text) return <div />

  let text
  if (props.limit) {
    text = stripMd(props.text)

    if (props.text.length > props.limit) {
      text = text.slice(0, props.limit).trim() + '...'
    }
  } else {
    text = props.text
  }

  return (
    <div style={{display: 'block'}}
      dangerouslySetInnerHTML={{ __html: marked(text) }} />
  )
}
