import * as constants from '../constants/SelectedContentConstants'

const _nullSelectedContent = {
  type: '',
  content: []
}

export default function SelectedContentReducer (state = _nullSelectedContent, action) {
  switch (action.type) {
    case constants.SELECT_CONTENT: {
      const selectedContent = [].concat(state.content)
      // Remove selected content
      const idx = selectedContent.findIndex(content => content.id === action.selected.id)
      if (idx > -1) {
        selectedContent.splice(idx, 1)
      } else {
        selectedContent.push(action.selected)
      }

      return Object.assign({}, state, { content: selectedContent, type: action.selectedType })
    }
    case constants.SELECT_ALL_CONTENT: {
      return Object.assign({}, state, {
        content: action.selected
      })
    }
    case constants.CLEAR_SELECTED_CONTENT: {
      return _nullSelectedContent
    }
    default: {
      return state
    }
  }
}
