import React from 'react'
import styled from 'styled-components'
import { FlexContainer, FlexColumnContainer } from '../StyledComponents'
import { CATEGORIES } from '../../constants/ContentConstants'

const OptionsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: auto;
  width: 100%;
  grid-gap: 10px;
  max-height: 500px;
  overflow-x: scroll;
  margin-left: 20px;
`

const Checkbox = styled.input`
  margin-right: 10px;
`

const Label = styled.label`
  margin-bottom: 0px;
  cursor: pointer;
`

const GroupName = styled.div`
  padding: 5px;
  background-color: ${props => props.selected ? props.theme.lighterMediumGray : 'unset'};
  cursor: pointer;
`

export default class CategorySelector extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      selectedGroup: null
    }
  }

  componentDidMount () {
    this.setState({ selectedGroup: this.props.groups[0] })
  }

  render () {
    return (
      <FlexContainer alignItems='start'>
        <FlexColumnContainer>
          {this.props.groups.map((group, idx) => {
            return (
              <GroupName
                selected={this.state.selectedGroup && this.state.selectedGroup.id === group.id}
                key={idx}
                onClick={() => this.setState({ selectedGroup: group })}>
                {group.name}
              </GroupName>
            )
          })}
        </FlexColumnContainer>
        <OptionsContainer>
          {this.state.selectedGroup && this.state.selectedGroup.categories.map(category => {
            return (
              <FlexContainer key={category.id}>
                <Checkbox
                  type='checkbox'
                  value={category.name}
                  id={category.name}
                  checked={this.props.selectedCategories.some(cat => category.id === cat.id)}
                  name={category.name}
                  onChange={() => this.props.createRelationship(category, CATEGORIES)} />
                <Label htmlFor={category.name}>{category.name}</Label>
              </FlexContainer>
            )
          })}
        </OptionsContainer>
      </FlexContainer>
    )
  }
}
