import pickBy from 'lodash/pickBy'
import intersection from 'lodash/intersection'
import isEqual from 'lodash/isEqual'
import pluralize from 'pluralize'
import { CATEGORIES } from '../constants/ContentConstants'

pluralize.addSingularRule(/canvas/i, 'canvas')
pluralize.addPluralRule(/canvas/i, 'canvases')

export const stripNullFromObject = (obj) => {
  return pickBy(obj, (val, key) => {
    if (!!val) {
      return true
    } else {
      if (val === false) {
        return true
      } else {
        return false
      }
    }
  })
}

export const stripEmptyStringFromArray = (arr) => {
  return arr.filter(el => !!el)
}

export const convertObjNullValuesToString = (obj) => {
  Object.keys(obj).forEach(key => {
    obj[key] = obj[key] === null ? '' : obj[key]
  })

  return obj
}

export const formatStringType = (type, count = 1) => {
  if (count <= 1) {
    if (type === CATEGORIES) {
      return 'category'
    } else {
      return type.slice(0, -1)
    }
  } else {
    return type
  }
}

export const mergeIntersections = (arr) => {
  // In: Array of objects, Out: Object of common values of all objects
  // Iterate through list of objects by object key
  // - if key value is array, intersect with baseObj value
  // - if key value of object is not an object or array
  //    - compare to baseObj value, if it is the same, keep going, else set
  //      merged object value of that key to null
  // - if key value is object, check if equal, if so keep going, else set
  //      merged object value of that key to null
  const baseObj = Object.assign({}, arr[0])
  return Object.keys(baseObj).reduce((merged, key) => {
    arr.forEach(obj => {
      const value = obj[key]
      if (Array.isArray(value)) {
        merged[key] = intersection(merged[key], value)
      } else if (typeof value === 'string' || typeof value === 'number') {
        if (merged[key] && merged[key] !== value) {
          merged[key] = null
        }
      } else if (typeof value === 'boolean') {
        if (merged[key] !== value) {
          merged[key] = null
        }
      } else {
        if (merged[key] && !isEqual(merged[key], value)) {
          merged[key] = null
        }
      }
    })

    return merged
  }, baseObj)
}

export const isSuperCurator = (user) => {
  return user.groups && user.groups.includes('Super Curator')
}
