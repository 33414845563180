import { connect } from 'react-redux'
import { getArtist } from '../../actions/ArtistActions'
import { ARTISTS } from '../../constants/ContentConstants'

import ViewContent from '../ViewContent'

const mapStateToProps = state => {
  return {
    type: ARTISTS,
    content: state.artists.current
  }
}

const mapDispatchToProps = dispatch => ({
  getContent: payload => dispatch(getArtist(payload))
})

export default connect(mapStateToProps, mapDispatchToProps)(ViewContent)
