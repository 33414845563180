import React, { Component } from 'react'
import styled from 'styled-components'
import pluralize from 'pluralize'
import {
  WrapContainer, AddCard, Icon, Grid
} from './StyledComponents'
import { SortableContainer, SortableElement, arrayMove } from 'react-sortable-hoc'
import ContentInfo from './ContentInfo'
import ContentCard from './ContentCard'
import ViewHeader from './ViewHeader'
import * as contentTypes from '../constants/ContentConstants'
import InReviewBanner from './reviews/InReviewBanner'
import BatchSelectFooter from './BatchSelectFooter'
import BatchSuperPicker from './edit/BatchSuperPicker'

const ContentSection = styled.div`
  width: 100%;
  height: 100%;
  margin: 25px 0;
`

const FeaturedHeader = styled.h2`
  font-size: 48px;
`

export class RelatedModule extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      contentList: []
    }

    this.onSortEnd = this.onSortEnd.bind(this)
    this.deleteRelatedContent = this.deleteRelatedContent.bind(this)
  }

  componentWillMount () {
    this.setState({ contentList: this.props.relatedContent })
  }

  componentWillReceiveProps (nextProps) {
    // update the state when any related content is added/deleted
    if (this.props.relatedContent.length !== nextProps.relatedContent.length) {
      this.setState({ contentList: nextProps.relatedContent })
    }
  }

  onSortEnd (type) {
    return ({ oldIndex, newIndex }) => {
      const newOrder = arrayMove(this.state.contentList, oldIndex, newIndex)
      this.setState({ contentList: newOrder }, () => {
        this.props.sortRelatedContent(
          this.props.currentObject.id,
          this.state.contentList.map(content => content.id),
          type
        )
      })
    }
  }

  deleteRelatedContent (relatedContent) {
    const { contentType } = this.props.relatedContentParams
    this.props.deleteRelatedContent(relatedContent, this.props.currentObject.id, contentType)
  }

  render () {
    const { contentType } = this.props.relatedContentParams
    // this boolean is used to find and disable content that is not selectable
    // for batch editing after a certain type is selected
    const unselectable = (
      this.props.selectedContent && this.props.allowBatch &&
      this.props.selectedContent.length > 0
    )

    return (
      <ContentSection key={this.props.index}>
        <SortableList
          {...this.props.relatedContentParams}
          currentObject={this.props.currentObject}
          baseType={this.props.baseType}
          selectedContent={this.props.selectedContent}
          unselectable={unselectable}
          relatedContent={this.state.contentList}
          deleteRelatedContent={this.deleteRelatedContent}
          addRelatedContent={this.props.addRelatedContent}
          onSelect={this.props.onSelect}
          onSortEnd={this.onSortEnd(contentType)}
          distance={20}
          axis='xy' />
        {this.props.currentObject &&
          <BatchSelectFooter
            isRelatedContent
            parentType={this.props.baseType}
            parentId={this.props.currentObject.id}
            childType={contentType}
            selectAllOptions={this.props.relatedContent} />
        }
      </ContentSection>
    )
  }
}

const SortableContentCard = SortableElement(props => {
  const { content } = props
  return (
    <ContentCard
      {...content}
      {...props}
      key={content.id}
      content={content} />
  )
})

const SortableList = SortableContainer(props => {
  const {
    allowAdd, contentType, relatedContent, allowSort, allowBatch,
    selectedContent, unselectable, baseType, currentObject
  } = props

  let addRelatedContentPicker
  if (allowAdd) {
    addRelatedContentPicker = (
      <BatchSuperPicker
        render={(ctx) => {
          return (
            <AddCard onClick={!unselectable ? ctx.toggleModal : null}
              color='mediumGray'>
              <Icon className='material-icons'>add</Icon>
            </AddCard>
          )
        }}
        relatedContentType={contentType}
        getAll
        baseType={baseType}
        currentId={currentObject.id}
        afterClose={props.addRelatedContent}
      />
    )
  }

  return (
    <Grid wrap='true'>
      {addRelatedContentPicker}
      {
        relatedContent.map((c, index) => {
          const isSelected = selectedContent &&
            !!selectedContent.some(selected => selected.id === c.id)

          return (
            <SortableContentCard
              key={`item-${index}`}
              index={index}
              type={contentType}
              disabled={!allowSort}
              content={c}
              selected={isSelected}
              allowAdd={allowAdd}
              allowBatch={allowBatch}
              unselectable={unselectable}
              deleteRelatedContent={props.deleteRelatedContent}
              onSelect={props.onSelect}
            />
          )
        })
      }
    </Grid>
  )
})

export default class ViewContent extends Component {
  constructor (props) {
    super(props)

    this.state = {
      loading: true
    }

    this.onSelect = this.onSelect.bind(this)
  }

  componentWillMount () {
    if (this.props.clearSelectedContent) { this.props.clearSelectedContent() }
    this.props.getContent(this.props.match.params.id)
      .then(() => { this.setState({ loading: false }) })
  }

  onSelect (e, content) {
    e.stopPropagation()
    const { onSelect, relatedContent } = this.props
    if (onSelect) onSelect(relatedContent.type, content)
  }

  renderRelatedContent () {
    const { relatedContentOrder } = this.props
    if (!relatedContentOrder) return <div />

    return relatedContentOrder.map((relatedContentParams, index) => {
      const { contentType } = relatedContentParams
      const { type: baseType, content } = this.props
      const { relatedContent, type } = this.props.relatedContent

      const relatedContentList = contentType === type ? relatedContent : []
      return (
        <RelatedModule
          key={index}
          index={index}
          baseType={baseType}
          currentObject={this.props.content}
          relatedContentParams={relatedContentParams}
          relatedContent={relatedContentList}
          selectedContent={this.props.selectedContent}
          selectedContentType={this.props.selectedContentType}
          sortRelatedContent={this.props.sortRelatedContent}
          deleteRelatedContent={this.props.deleteRelatedContent}
          addRelatedContent={this.props.addRelatedContent}
          onSelect={this.onSelect}
          viewAllLink={`/${type.toLowerCase()}/${content.id}/${contentType.toLowerCase()}`} />
      )
    })
  }

  renderFeaturedContent () {
    const { content } = this.props
    return (
      <div style={{marginTop: '100px'}}>
        <ContentSection>
          <FeaturedHeader>Featured {contentTypes.toFrontend(content.contentType)}</FeaturedHeader>
          <ContentCard
            content={content.contentObject}
            key={content.contentObject.id}
            {...content.contentObject}
            type={pluralize(contentTypes.toFrontend(content.contentType))} />
        </ContentSection>
      </div>
    )
  }

  render () {
    if (this.state.loading) return <div />

    const { type, content } = this.props
    return (
      <div>
        <ViewHeader
          type={type}
          content={content}
          editLink={`/${type}/${content.id}/edit`} />
        <WrapContainer>
          <InReviewBanner
            type={type}
            content={content} />
          <ContentInfo
            {...content}
            type={type} />
          { type === contentTypes.EDITORIALS && this.renderFeaturedContent() }
          {this.renderRelatedContent()}
        </WrapContainer>
      </div>
    )
  }
}
