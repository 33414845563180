import React, { Component } from 'react'
import styled from 'styled-components'
import lottie from 'lottie-web'

const AnimContainer = styled.div`
  display: flex;
  height: 1.8rem;
  width: 100%;
`

export default class ButtonLoader extends Component {
  constructor (props) {
    super(props)

    this.node = null
    this.anim = null

    this.startAnimation = this.startAnimation.bind(this)
    this.stopAnimation = this.stopAnimation.bind(this)
  }

  componentDidMount () {
    if (this.props.isLoading) this.startAnimation()
  }

  componentDidUpdate (prevProps, prevState) {
    if ((prevProps.isLoading !== this.props.isLoading) &&
        !this.props.isLoading) {
      this.stopAnimation()
    }
  }

  startAnimation (node) {
    if (!this.anim) {
      this.anim = lottie.loadAnimation({
        container: node,
        renderer: 'svg',
        loop: true,
        autoplay: true,
        path: `/logo_animation_${this.props.color || 'black'}.json`
      })
    }

    this.anim.play()
  }

  stopAnimation () {
    this.anim && this.anim.destroy()
    this.anim = null
  }

  render () {
    const { large } = this.props

    return (
      <AnimContainer large={large} innerRef={this.startAnimation} />
    )
  }
}
