import { getAccountExperience } from '../api/onecloud'
import * as constants from '../constants/FeatureFlagConstants'

export const getFeatureFlag = () => dispatch => {
  return getAccountExperience().then(resp => dispatch({
      type: constants.RECEIVE_ACCOUNT_EXPERIENCE, version: resp.data.experience
  })).catch(error => {
    dispatch({
      type: constants.RECEIVE_ACCOUNT_EXPERIENCE_ERROR, error
    })
  })
}