import { connect } from 'react-redux'
import { getItems, clearItems } from '../../actions/ItemActions'
import {
  selectContent, selectAllContent, clearSelectedContent
} from '../../actions/SelectedContentActions'
import { clearSearchAndFilter } from '../../actions/SearchAndFilterActions'
import Collection from '../ContentCollection'
import { ITEMS } from '../../constants/ContentConstants'

const mapStateToProps = ({ items, searchAndFilter, selectedContent }) => {
  return {
    type: ITEMS,
    content: items.items.data,
    isLast: items.items.isLast,
    count: items.items.count,
    error: items.error,
    searchQueryParams: searchAndFilter,
    allowBatch: true,
    selectedContent: selectedContent.content
  }
}

const mapDispatchToProps = dispatch => ({
  getContent: (pagination, searchAndFilter) => dispatch(getItems({...pagination, ...searchAndFilter})),
  clearContent: () => dispatch(clearItems()),
  clearSearchAndFilter: () => dispatch(clearSearchAndFilter()),
  onSelect: (type, selected) => dispatch(selectContent(type, selected)),
  batchSelect: (type, selected) => dispatch(selectAllContent(type, selected)),
  clearSelectedContent: () => dispatch(clearSelectedContent())
})

export default connect(mapStateToProps, mapDispatchToProps)(Collection)
