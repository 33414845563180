import React from 'react'
import { connect } from 'react-redux'
import * as API from '../../api/Items'
import { getArtist } from '../../api/Artists'
import { updateItem, deleteItem, createItem } from '../../actions/ItemActions'
import { receiveSuccessMessage } from '../../actions/MessageActions'
import { createReview } from '../../actions/ReviewActions'
import { checkForImage } from '../../utils/ImageUtils'
import { FlexContainer } from '../StyledComponents'
import {
  StyledOuterContainer, StyledFlexColumnContainer, StyledStatusContainer,
  StyledDetailContainer, StyledRelatedContainer, PaddingContainer, EditWrapPage
} from '../StyledEditComponents'
import {
  ITEMS, USERS, CATEGORIES, ARTISTS, PROVIDERS, CHANNELS
} from '../../constants/ContentConstants'
import EditHeader from '../edit/EditHeader'
import IsPublic from '../edit/IsPublic'
import IsFeatured from '../edit/IsFeatured'
import TextField from '../edit/TextField'
import SuperPicker from '../edit/SuperPicker'
import ArtistPicker from '../edit/ArtistPicker'
import UserList from '../edit/UserList'
import RelatedContentList from '../edit/RelatedContentList'
import ProviderContent from '../edit/ProviderContent'
import IsPremium from '../edit/IsPremium'
import UploadItem from '../edit/UploadItem'
import AbstractEditForm from '../edit/AbstractEditForm'
import Tags from '../edit/Tags'
import { stripEmptyStringFromArray } from '../../utils/General'
import InReviewBanner from '../reviews/InReviewBanner'
import GeorestrictionPicker from '../edit/GeorestrictionPicker'
import CategoryPicker from '../edit/CategoryPicker'

function mapStateToProps ({ items, auth }) {
  return {
    type: ITEMS,
    object: items.current,
    error: items.error,
    user: auth.user
  }
}

function mapDispatchToProps (dispatch) {
  return {
    updateObject: (id, payload) => dispatch(updateItem(id, payload)),
    createObject: (payload) => dispatch(createItem(payload)),
    destroyObject: (id) => dispatch(deleteItem(id)),
    createReview: (payload) => dispatch(createReview(payload)),
    renderMessage: (message) => dispatch(receiveSuccessMessage(message))
  }
}

class ItemForm extends AbstractEditForm {
  constructor (props) {
    super(props)

    this.state = {
      object: {
        name: '',
        author: '',
        description: '',
        medium: '',
        copyright: '',
        notes: '',
        year: '',
        dimensions: '',
        image: '',
        categories: [],
        channels: [],
        tags: [],
        originalFileName: '',
        isPremium: false,
        premiumDate: null,
        privateDate: null,
        availableCountries: []
      },
      user: {
        logo: '',
        email: ''
      },
      categories: [],
      channels: [],
      provider: {},
      isPremium: false,
      isScheduled: false,
      isGeorestricted: false
    }
  }

  componentDidMount () {
    const id = parseInt(this.props.match.params.id)
    if (id) {
      Promise.all([
        API.getItem(id),
        API.getItemCategories(id),
        API.getItemChannels(id)
      ]).then(([item, categories, channels]) => {
        const object = item.data
        const provider = object.provider
        const newState = {
          object: Object.assign({}, object, {
            user: object.user.id,
            provider: provider ? object.provider.id : null,
            dimensions: object.dimensions || '',
            tags: stripEmptyStringFromArray(object.tags),
            availableCountries: object.availableCountries || []
          }),
          image: checkForImage(object, ITEMS),
          user: object.user,
          categories: categories.data,
          channels: channels.data,
          provider,
          isScheduled: !!object.premiumDate,
          premiumDate: object.premiumDate,
          isGeorestricted: !!object.availableCountries
        }

        if (object.artist) {
          getArtist(object.artist)
          .then(({ data: artist }) => {
            newState.artist = artist
            this.setState(newState)
          })
        } else {
          newState.artist = object.artist
          this.setState(newState)
        }
      })
    } else {
      this.setState({
        user: this.props.user,
        object: Object.assign({}, this.state.object, {
          user: this.props.user.id
        })
      })
    }
  }

  massageObject () {
    const massagedObject = this.massagePremiumData()
    return this.massageGeorestrictionData(massagedObject)
  }

  render () {
    return (
      <form>
        <EditHeader
          {...this.state}
          type={this.props.type}
          name={this.state.object.name}
          deleteImage={this.state.image}
          handleSave={this.handleSubmit}
          handleSubmitForReview={this.handleSubmitForReview}
          currentId={this.state.object.id}
          destroyObjects={this.destroyObject} />
        <EditWrapPage>
          <InReviewBanner
            type={this.props.type}
            content={this.state.object}
            onApprove={this.handleApproveReview}
            onCancel={this.removeReview} />
          <StyledOuterContainer>
            <StyledStatusContainer>
              <UploadItem
                currentId={parseInt(this.props.match.params.id)}
                error={this.state.imageError}
                image={this.state.image}
                handleFileUpload={this.handleFileUpload} />
              <PaddingContainer>
                <h5>{this.state.object.name}</h5>
                <IsPublic
                  canSchedule
                  isPublic={this.state.object.isPublic}
                  privateDate={this.state.object.privateDate}
                  handleRadioChange={this.handleRadioChange}
                  handleDateTimeChange={this.handleDateTimeChange} />
                <IsFeatured isFeatured={this.state.object.isFeatured}
                  handleRadioChange={this.handleRadioChange} />
              </PaddingContainer>
            </StyledStatusContainer>
            <StyledFlexColumnContainer>
              <StyledDetailContainer>
                <TextField id='name' name='name' type='text'
                  label='Title'
                  error={this.state.nameError}
                  value={this.state.object.name}
                  onChange={this.handleTextChange} />
                <TextField
                  type='text'
                  label='Original file name'
                  value={this.state.object.originalFileName}
                  readOnly />
                <SuperPicker
                  render={(ctx) => {
                    return (
                      <ArtistPicker artist={this.state.artist}
                        label='Artist' toggleModal={ctx.toggleModal}
                        error={this.state.artistError} />
                    )
                  }}
                  relatedContentType={ARTISTS}
                  name='artist'
                  baseType={ITEMS}
                  getAll
                  currentId={this.state.object.id}
                  changeRelationship={this.changeSelectedContent} />
                <FlexContainer>
                  <TextField id='author' name='author' type='text'
                    label='Author'
                    halfWidth
                    marginRight
                    error={this.state.authorError}
                    value={this.state.object.author}
                    onChange={this.handleTextChange} />
                  <TextField id='medium' name='medium' type='text'
                    label='Medium'
                    halfWidth
                    error={this.state.mediumError}
                    value={this.state.object.medium}
                    onChange={this.handleTextChange} />
                </FlexContainer>
                <FlexContainer>
                  <TextField id='year' name='year' type='text'
                    label='Year'
                    error={this.state.yearError}
                    value={this.state.object.year}
                    onChange={this.handleTextChange}
                    halfWidth
                    marginRight />
                  <TextField id='dimensions' name='dimensions' type='text'
                    label='Dimensions'
                    error={this.state.dimensionsError}
                    value={this.state.object.dimensions}
                    onChange={this.handleTextChange}
                    halfWidth />
                </FlexContainer>
                <TextField id='description' name='description' type='textarea'
                  label='Description'
                  error={this.state.descriptionError}
                  value={this.state.object.description}
                  onChange={this.handleTextChange} />
                <TextField id='copyright' name='copyright' type='textarea'
                  label='Copyright'
                  error={this.state.copyrightError}
                  value={this.state.object.copyright}
                  onChange={this.handleTextChange} />
                <TextField id='notes' name='notes' type='textarea'
                  label='Notes'
                  error={this.state.notesError}
                  value={this.state.object.notes}
                  onChange={this.handleTextChange} />
                <Tags tags={this.state.object.tags}
                  handleTagsChange={this.handleTagsChange}
                  error={this.state.tagsError} />
              </StyledDetailContainer>
              <IsPremium
                {...this.state}
                type={this.props.type}
                handleRadioChange={this.handleRadioChange}
                handleDateTimeChange={this.handleDateTimeChange} />
              <StyledRelatedContainer>
                <SuperPicker
                  render={(ctx) => {
                    return (
                      <UserList
                        error={this.state.userError}
                        owner={this.state.user}
                        toggleModal={ctx.toggleModal} />
                    )
                  }}
                  relatedContentType={USERS}
                  baseType={ITEMS}
                  getAll
                  currentId={this.state.object.id}
                  changeRelationship={this.changeUser} />
                <CategoryPicker
                  selectedCategories={this.state[CATEGORIES]}
                  removeRelationship={this.removeRelatedContent}
                  createRelationship={this.toggleRelationship} />
                <SuperPicker
                  render={(ctx) => {
                    return (
                      <RelatedContentList
                        relatedContentType={ctx.props.relatedContentType}
                        content={ctx.props.content}
                        removeRelationship={ctx.props.removeRelationship}
                        toggleModal={ctx.toggleModal} />
                    )
                  }}
                  content={this.state[CHANNELS]}
                  relatedContentType={CHANNELS}
                  baseType={ITEMS}
                  allowBatch
                  getAll
                  currentId={this.state.object.id}
                  selectedIds={this.state.object.channels}
                  createRelationship={this.toggleRelationship}
                  removeRelationship={this.removeRelatedContent} />
                <SuperPicker
                  render={(ctx) => {
                    return (
                      <ProviderContent provider={this.state.provider}
                        toggleModal={ctx.toggleModal}
                        changeRelationship={ctx.props.changeRelationship} />
                    )
                  }}
                  relatedContentType={PROVIDERS}
                  name='provider'
                  baseType={ITEMS}
                  getAll
                  currentId={this.state.object.id}
                  changeRelationship={this.changeSelectedContent} />
              </StyledRelatedContainer>
              <GeorestrictionPicker
                {...this.state}
                handleGeorestrictionChange={this.handleGeorestrictionChange}
                handleRadioChange={this.handleRadioChange} />
            </StyledFlexColumnContainer>
          </StyledOuterContainer>

        </EditWrapPage>
      </form>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ItemForm)
