import React from 'react'
import { Provider } from 'react-redux'
import { HashRouter, Route, Switch, Redirect } from 'react-router-dom'
import configureStore from '../store/Store'
import PrimaryLayout from './PrimaryLayout'
import AuthorizedRoute from './AuthorizedRoute'
import LoginRoute from './LoginRoute'

const store = configureStore()

export default function App () {
  return (
    <Provider store={store}>
      <HashRouter>
        <Switch>
          <LoginRoute path='/login'/>
          <AuthorizedRoute path='/' component={PrimaryLayout} />
          <Redirect to='/' />
        </Switch>
      </HashRouter>
    </Provider>
  )
}
