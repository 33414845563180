import { connect } from 'react-redux'
import React, { Component } from 'react'
import { clearMessage } from '../actions/MessageActions'

import { Alert } from 'reactstrap'
import { ERROR } from '../constants/MessageConstants'

import styled from 'styled-components'

const StyledAlert = styled(Alert)`
  background-color: ${props => props.messagetype === ERROR ? props.theme.red : props.theme.blue};
  color: white;
  position: fixed;
  border-radius: 0;
  z-index: 11;
  width: 100%;
  text-align: center;
  padding: 0.5rem;
  border: 0;
`

const mapStateToProps = ({ message }) => {
  return {
    message: message.message,
    hasTimeout: message.hasTimeout,
    messageType: message.type
  }
}

const mapDispatchToProps = dispatch => ({
  clearMessage: () => dispatch(clearMessage())
})

class Message extends Component {
  constructor (props) {
    super(props)

    this.timeout = null
  }

  componentWillReceiveProps (nextProps) {
    if (nextProps.message && nextProps.hasTimeout) {
      if (this.timeout) clearTimeout(this.timeout)
      this.timeout = setTimeout(() => {
        this.props.clearMessage()
      }, 3000)
    }
  }

  render () {
    if (this.props.message) {
      return (
        <StyledAlert messagetype={this.props.messageType}>
          {this.props.message}
        </StyledAlert>
      )
    } else {
      return <div />
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Message)
