import React from 'react'
import { connect } from 'react-redux'
import * as API from '../../api/Playlists'
import {
  updatePlaylist, createPlaylist, deletePlaylist
} from '../../actions/PlaylistActions'
import { receiveSuccessMessage } from '../../actions/MessageActions'
import { createReview } from '../../actions/ReviewActions'
import {
  StyledOuterContainer, StyledFlexColumnContainer, StyledStatusContainer,
  StyledDetailContainer, StyledRelatedContainer, PaddingContainer, EditWrapPage
} from '../StyledEditComponents'
import { ITEMS, PLAYLISTS, USERS, CATEGORIES } from '../../constants/ContentConstants'
import EditHeader from '../edit/EditHeader'
import FeaturedAt from '../edit/FeaturedAt'
import IsPublic from '../edit/IsPublic'
import IsFeatured from '../edit/IsFeatured'
import IsPremium from '../edit/IsPremium'
import TextField from '../edit/TextField'
import SuperPicker from '../edit/SuperPicker'
import ImagePicker from '../edit/ImagePicker'
import UserList from '../edit/UserList'
import CoverImageContainer from '../edit/CoverImageContainer'
import AbstractEditForm from '../edit/AbstractEditForm'
import InReviewBanner from '../reviews/InReviewBanner'
import GeorestrictionPicker from '../edit/GeorestrictionPicker'
import CategoryPicker from '../edit/CategoryPicker'

function mapStateToProps ({ playlists, auth }) {
  return {
    type: PLAYLISTS,
    object: playlists.current,
    error: playlists.error,
    user: auth.user
  }
}

function mapDispatchToProps (dispatch) {
  return {
    createObject: (payload) => dispatch(createPlaylist(payload)),
    updateObject: (id, payload) => dispatch(updatePlaylist(id, payload)),
    destroyObject: (id) => dispatch(deletePlaylist(id)),
    createReview: (payload) => dispatch(createReview(payload)),
    renderMessage: (message) => dispatch(receiveSuccessMessage(message))
  }
}

class PlaylistForm extends AbstractEditForm {
  constructor (props) {
    super(props)

    this.state = {
      object: {
        name: '',
        description: '',
        coverItem: null,
        user: null,
        categories: [],
        items: [],
        isPremium: false,
        premiumDate: null,
        privateDate: null,
        availableCountries: []
      },
      coverItem: {},
      user: {},
      categories: [],
      isScheduled: false,
      isGeorestricted: false
    }
  }

  componentDidMount () {
    const id = parseInt(this.props.match.params.id)
    if (id) {
      Promise.all([
        API.getPlaylist(id),
        API.getPlaylistCategories(id)
      ]).then(values => {
        const object = values[0].data

        this.setState({
          object: Object.assign({}, object, {
            coverItem: object.coverItem && object.coverItem.id,
            user: object.user.id,
            availableCountries: object.availableCountries || []
          }),
          coverItem: object.coverItem || {},
          user: object.user,
          categories: values[1].data,
          isScheduled: !!object.premiumDate,
          premiumDate: object.premiumDate,
          isGeorestricted: !!object.availableCountries
        })
      })
    } else {
      this.setState({
        user: this.props.user,
        object: Object.assign({}, this.state.object, {
          user: this.props.user.id
        })
      })
    }
  }

  massageObject () {
    const massagedObject = this.massagePremiumData()
    return this.massageGeorestrictionData(massagedObject)
  }

  render () {
    const coverImg = this.state.coverItem.imageThumb || this.state.coverItem.imageThumbOptimized
    return (
      <form>
        <EditHeader
          {...this.state}
          type={this.props.type}
          name={this.state.object.name}
          deleteImage={coverImg}
          handleSave={this.handleSubmit}
          handleSubmitForReview={this.handleSubmitForReview}
          currentId={this.state.object.id}
          destroyObjects={this.destroyObject} />
        <EditWrapPage>
          <InReviewBanner
            type={this.props.type}
            content={this.state.object}
            onApprove={this.handleApproveReview}
            onCancel={this.removeReview} />
          <StyledOuterContainer>
            <StyledStatusContainer>
              <CoverImageContainer
                image={coverImg}
                count={this.state.object.items.length} />
              <PaddingContainer>
                <h5>{this.state.object.name}</h5>
                <IsPublic
                  canSchedule
                  isPublic={this.state.object.isPublic}
                  privateDate={this.state.object.privateDate}
                  handleRadioChange={this.handleRadioChange}
                  handleDateTimeChange={this.handleDateTimeChange} />
                <IsFeatured
                  isFeatured={this.state.object.isFeatured}
                  handleRadioChange={this.handleRadioChange} />
                <FeaturedAt
                  currentDateTime={this.state.object.featuredAt}
                  handleChange={this.handleDateTimeChange} />
              </PaddingContainer>
            </StyledStatusContainer>
            <StyledFlexColumnContainer>
              <StyledDetailContainer>
                <TextField id='name' name='name' type='text'
                  label='Title'
                  error={this.state.nameError}
                  value={this.state.object.name}
                  onChange={this.handleTextChange} />
                <TextField id='description' name='description' type='textarea'
                  label='Description'
                  error={this.state.descriptionError}
                  value={this.state.object.description}
                  onChange={this.handleTextChange} />
                <SuperPicker
                  render={(ctx) => {
                    return (
                      <ImagePicker image={this.state.coverItem}
                        error={this.state.coverItemError}
                        label='Cover' toggleModal={ctx.toggleModal} />
                    )
                  }}
                  relatedContentType={ITEMS}
                  name='coverItem'
                  baseType={PLAYLISTS}
                  currentId={this.state.object.id}
                  changeRelationship={this.changeSelectedContent} />
              </StyledDetailContainer>
              <IsPremium
                {...this.state}
                type={PLAYLISTS}
                handleRadioChange={this.handleRadioChange}
                handleDateTimeChange={this.handleDateTimeChange} />
              <StyledRelatedContainer>
                <SuperPicker
                  render={(ctx) => {
                    return (
                      <UserList
                        error={this.state.userError}
                        owner={this.state.user}
                        toggleModal={ctx.toggleModal} />
                    )
                  }}
                  relatedContentType={USERS}
                  baseType={PLAYLISTS}
                  getAll
                  currentId={this.state.object.id}
                  changeRelationship={this.changeUser} />
                <CategoryPicker
                  selectedCategories={this.state[CATEGORIES]}
                  removeRelationship={this.removeRelatedContent}
                  createRelationship={this.toggleRelationship} />
              </StyledRelatedContainer>
              <GeorestrictionPicker
                {...this.state}
                handleGeorestrictionChange={this.handleGeorestrictionChange}
                handleRadioChange={this.handleRadioChange} />
            </StyledFlexColumnContainer>
          </StyledOuterContainer>
        </EditWrapPage>
      </form>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PlaylistForm)
