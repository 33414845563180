import React from 'react'
import styled from 'styled-components'
import HistoryEvent from './HistoryEvent'
import { formatHistoryDate } from '../../utils/DateFormatter'

const Date = styled.div`
  color: ${props => props.theme.mediumGray};
  margin: 0 0 20px 40px;
  font-size: 14px;
`
export default function HistoryDate (props) {
  const { events, date } = props
  return (
    <div key={date}>
      <Date>{formatHistoryDate(date)}</Date>
      {events.map(event => <HistoryEvent key={event.date} event={event} />)}
    </div>
  )
}
