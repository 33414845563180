import React from 'react'
import ComposerPreview from './ComposerPreview'
import styled from 'styled-components'
import { BlueButton, StyledLink } from '../StyledComponents'

const PreviewContainer = styled.div`
  margin-top: 50px;
  padding-top: 40px;
  width: 100%;
  max-width: 840px;
  height: 909px;
  background: white;
  position: relative;
`

const Gradient = styled.div`
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.06),
    #ffffff);
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
`

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 120px;
  position: absolute;
  bottom: 0;
  width: 100%;
  background: white;
`

const StyledButton = styled(BlueButton)`
  max-width: 760px;
  margin: auto;
`

export default function ViewEditorialPreview (props) {
  const { id, body } = props.content

  return (
    <PreviewContainer>
      <Gradient>
        <ButtonContainer>
          <StyledLink to={`/editorials/${id}/compose`} display={'flex'}>
            <StyledButton fullwidth='true'>Composer</StyledButton>
          </StyledLink>
        </ButtonContainer>
      </Gradient>
      <ComposerPreview body={body} />
    </PreviewContainer>
  )
}
