import React from 'react'
import { connect } from 'react-redux'
import { updateProvider, deleteProvider, createProvider } from '../../actions/ProviderActions'
import { receiveSuccessMessage } from '../../actions/MessageActions'
import { createReview } from '../../actions/ReviewActions'
import { PROVIDERS } from '../../constants/ContentConstants'
import {
  StyledOuterContainer, StyledFlexColumnContainer, StyledStatusContainer,
  StyledDetailContainer, PaddingContainer, EditWrapPage
} from '../StyledEditComponents'
import EditHeader from '../edit/EditHeader'
import TextField from '../edit/TextField'
import AbstractEditForm from '../edit/AbstractEditForm'
import * as API from '../../api/Providers'
import InReviewBanner from '../reviews/InReviewBanner'

function mapStateToProps ({ providers, auth }) {
  return {
    type: PROVIDERS,
    object: providers.current,
    error: providers.error,
    user: auth.user
  }
}

function mapDispatchToProps (dispatch) {
  return {
    updateObject: (id, payload) => dispatch(updateProvider(id, payload)),
    createObject: (payload) => dispatch(createProvider(payload)),
    destroyObject: (id) => dispatch(deleteProvider(id)),
    createReview: (payload) => dispatch(createReview(payload)),
    renderMessage: (message) => dispatch(receiveSuccessMessage(message))
  }
}

class ProviderForm extends AbstractEditForm {
  constructor (props) {
    super(props)

    this.state = {
      object: {
        name: ''
      }
    }
  }

  componentWillMount () {
    const id = parseInt(this.props.match.params.id)
    if (id) {
      API.getProvider(id).then(response => {
        this.setState({ object: response.data })
      })
    }
  }

  render () {
    return (
      <form>
        <EditHeader
          type={this.props.type}
          name={this.state.object.name}
          handleSave={this.handleSubmit}
          handleSubmitForReview={this.handleSubmitForReview}
          currentId={this.state.object.id}
          destroyObjects={this.destroyObject} />
        <EditWrapPage>
          <InReviewBanner
            type={this.props.type}
            content={this.state.object}
            onApprove={this.handleApproveReview}
            onCancel={this.removeReview} />
          <StyledOuterContainer>
            <StyledStatusContainer>
              <PaddingContainer>
                <h5>{this.state.object.name}</h5>
              </PaddingContainer>
            </StyledStatusContainer>
            <StyledFlexColumnContainer>
              <StyledDetailContainer>
                <TextField
                  id='name'
                  name='name'
                  type='text'
                  label='Title'
                  error={this.state.nameError}
                  value={this.state.object.name}
                  onChange={this.handleTextChange} />
              </StyledDetailContainer>
            </StyledFlexColumnContainer>
          </StyledOuterContainer>
        </EditWrapPage>
      </form>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProviderForm)
