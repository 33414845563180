import React from 'react'
import styled from 'styled-components'

import {
  FlexColumnContainer, FlexHorizontalCenterContainer, GrayButton, StyledLabel
} from '../StyledComponents'
import defaultArtist from '../../../assets/placeholders/artist.png'

const StyledImg = styled.img`
  width: 55px;
  height: 55px;
  border-radius: 50%;
`

const StyledEmail = styled.span`
  width: 55%;
`

const StyledFlexColumnContainer = styled(FlexColumnContainer)`
  width: 100%;
`

export default class UserList extends React.Component {
  render () {
    return (
      <StyledFlexColumnContainer>
        <StyledLabel error={this.props.error}>Owner</StyledLabel>
        <FlexHorizontalCenterContainer justifyContent='space-between'>
          <StyledImg src={(this.props.owner && this.props.owner.logo) || defaultArtist} />
          <StyledEmail>{this.props.owner && this.props.owner.email}</StyledEmail>
          <GrayButton small='true' error={this.props.error}
            onClick={this.props.toggleModal}>
            Change
          </GrayButton>
        </FlexHorizontalCenterContainer>
      </StyledFlexColumnContainer>
    )
  }
}
