import fetchWrapper from '../utils/FetchWrapper'
import { formatQueryString } from '../utils/FormatQueryString'

export function getEditorials (queryParams) {
  return fetchWrapper(`curator`, `/articles${formatQueryString(queryParams)}`)
}

export function getEditorial (id) {
  return fetchWrapper(`curator`, `/articles/${id}`)
}

export function updateEditorial (id, data) {
  return fetchWrapper('curator', `/articles/${id}`, {
    method: 'PUT',
    body: data
  })
}

export function createEditorial (data) {
  return fetchWrapper('curator', '/articles', {
    method: 'POST',
    body: data
  })
}

export function deleteEditorial (id) {
  return fetchWrapper('curator', `/articles/${id}`, {
    method: 'DELETE'
  })
}

export function getEditorialArtists (id) {
  return fetchWrapper(`curator`, `/articles/${id}/artists`)
}

export function getEditorialCategories (id) {
  return fetchWrapper(`curator`, `/articles/${id}/categories`)
}

export function getEditorialChannels (id) {
  return fetchWrapper(`curator`, `/articles/${id}/channels`)
}

export function getRelatedEditorials (parentType, parentId, queryParams) {
  return fetchWrapper(`curator`, `/${parentType}/${parentId}/articles${formatQueryString(queryParams)}`)
}

export function uploadFile (file) {
  return fetchWrapper(`curator`, `/files`, {
    method: 'POST',
    body: file
  })
}

export function getFile (id) {
  return fetchWrapper(`curator`, `/files/${id}`)
}
