import Theme from './Theme'

export default `
  .ReactCrop {
    position: relative;
    cursor: crosshair;
    overflow: hidden;
    display: inline-block;
    background-color: #000;
    max-height: 100%;
  }

  .ReactCrop:focus {
    outline: none;
  }

  .ReactCrop--disabled {
    cursor: inherit;
  }

  .ReactCrop__image {
    display: block;
    max-width: 100%;
    height: auto;
    max-height: 63.7rem;
  }

  .ReactCrop--crop-invisible .ReactCrop__image {
    opacity: 0.5;
  }

  .ReactCrop__crop-selection {
    position: absolute;
    top: 0;
    left: 0;
    transform: translate3d(0, 0, 0);
    box-sizing: border-box;
    cursor: move;
    box-shadow: 0 0 0 9999em rgba(255, 255, 255, 0.8);
    border: 0.3rem solid ${Theme.colors.white};
    border-image-slice: 1;
    border-image-repeat: repeat;
  }

  .ReactCrop--disabled .ReactCrop__crop-selection {
    cursor: inherit;
  }

  .ReactCrop__drag-handle {
    position: absolute;
    width: 9px;
    height: 9px;
    box-sizing: border-box;
    outline: 1px solid transparent;
  }

  .ReactCrop .ord-nw {
    top: 0;
    left: 0;
    margin-top: -5px;
    margin-left: -5px;
    cursor: nw-resize;
  }

  .ReactCrop .ord-n {
    top: 0;
    left: 50%;
    margin-top: -5px;
    margin-left: -5px;
    cursor: n-resize;
  }

  .ReactCrop .ord-ne {
    top: 0;
    right: 0;
    margin-top: -5px;
    margin-right: -5px;
    cursor: ne-resize;
  }

  .ReactCrop .ord-e {
    top: 50%;
    right: 0;
    margin-top: -5px;
    margin-right: -5px;
    cursor: e-resize;
  }

  .ReactCrop .ord-se {
    bottom: 0;
    right: 0;
    margin-bottom: -5px;
    margin-right: -5px;
    cursor: se-resize;
  }

  .ReactCrop .ord-s {
    bottom: 0;
    left: 50%;
    margin-bottom: -5px;
    margin-left: -5px;
    cursor: s-resize;
  }

  .ReactCrop .ord-sw {
    bottom: 0;
    left: 0;
    margin-bottom: -5px;
    margin-left: -5px;
    cursor: sw-resize;
  }

  .ReactCrop .ord-w {
    top: 50%;
    left: 0;
    margin-top: -5px;
    margin-left: -5px;
    cursor: w-resize;
  }

  .ReactCrop__disabled .ReactCrop__drag-handle {
    cursor: inherit;
  }

  .ReactCrop__drag-bar {
    position: absolute;
  }

  .ReactCrop__drag-bar.ord-n {
    top: 0;
    left: 0;
    width: 100%;
    height: 6px;
    margin-top: -3px;
  }

  .ReactCrop__drag-bar.ord-e {
    right: 0;
    top: 0;
    width: 6px;
    height: 100%;
    margin-right: -3px;
  }

  .ReactCrop__drag-bar.ord-s {
    bottom: 0;
    left: 0;
    width: 100%;
    height: 6px;
    margin-bottom: -3px;
  }

  .ReactCrop__drag-bar.ord-w {
    top: 0;
    left: 0;
    width: 6px;
    height: 100%;
    margin-left: -3px;
  }

  .ReactCrop--new-crop .ReactCrop__drag-bar,
  .ReactCrop--new-crop .ReactCrop__drag-handle,
  .ReactCrop--fixed-aspect .ReactCrop__drag-bar {
    display: none;
  }

  .ReactCrop--fixed-aspect .ReactCrop__drag-handle.ord-n,
  .ReactCrop--fixed-aspect .ReactCrop__drag-handle.ord-e,
  .ReactCrop--fixed-aspect .ReactCrop__drag-handle.ord-s,
  .ReactCrop--fixed-aspect .ReactCrop__drag-handle.ord-w {
    display: none;
  }

  @media (max-width: 768px) {
    .ReactCrop__drag-handle {
      width: 17px;
      height: 17px;
    }

    .ReactCrop .ord-nw {
      margin-top: -9px;
      margin-left: -9px;
    }

    .ReactCrop .ord-n {
      margin-top: -9px;
      margin-left: -9px;
    }

    .ReactCrop .ord-ne {
      margin-top: -9px;
      margin-right: -9px;
    }

    .ReactCrop .ord-e {
      margin-top: -9px;
      margin-right: -9px;
    }

    .ReactCrop .ord-se {
      margin-bottom: -9px;
      margin-right: -9px;
    }

    .ReactCrop .ord-s {
      margin-bottom: -9px;
      margin-left: -9px;
    }

    .ReactCrop .ord-sw {
      margin-bottom: -9px;
      margin-left: -9px;
    }

    .ReactCrop .ord-w {
      margin-top: -9px;
      margin-left: -9px;
    }

    .ReactCrop__drag-bar.ord-n {
      height: 14px;
      margin-top: -7px;
    }

    .ReactCrop__drag-bar.ord-e {
      width: 14px;
      margin-right: -7px;
    }

    .ReactCrop__drag-bar.ord-s {
      height: 14px;
      margin-bottom: -7px;
    }

    .ReactCrop__drag-bar.ord-w {
      width: 14px;
      margin-left: -7px;
    }
  }
`
