import React, { Component } from 'react'
import styled from 'styled-components'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import EditorialFileModal from './EditorialFileModal'
import { uploadFile } from '../../api/Editorials'
import { StyledInput } from '../StyledComponents'
import { receiveErrorMessage, receiveSuccessMessage } from '../../actions/MessageActions'
import { Icon } from './ComposerAddButtons'

const HiddenInput = styled(StyledInput)`
  display: none;
`

const Label = styled.label`
  height: 100%;
`

const mapDispatchToProps = (dispatch) => bindActionCreators({
  receiveErrorMessage,
  receiveSuccessMessage
}, dispatch)

class EditorialFileUpload extends Component {
  constructor (props) {
    super(props)

    this.state = {
      file: {},
      isModalOpen: false
    }

    this.toggleModal = this.toggleModal.bind(this)
    this.handleUploadAndClose = this.handleUploadAndClose.bind(this)
  }

  toggleModal () {
    this.setState({ isModalOpen: !this.state.isModalOpen })
  }

  handleUploadAndClose (e) {
    const file = e.target.files[0]
    const fd = new FormData()
    fd.append('generic_file', file)
    fd.append('name', file.name)
    uploadFile(fd)
      .then(this.props.receiveSuccessMessage('Uploading file', false))
      .then((payload) => {
        const fileType = payload.data.fileType.split('/')[0]
        const url = payload.data.genericFile
        const id = payload.data.id
        this.props.receiveSuccessMessage('File uploaded')
        this.setState({ file: { fileType, url, id } }, this.toggleModal)
      }).catch(err => {
        this.props.receiveErrorMessage(err)
      })

    this.props.toggleOptions()
  }

  render () {
    return (
      <React.Fragment>
        <Label htmlFor='fileUpload'>
          <Icon className='material-icons'>cloud_upload</Icon>
          <HiddenInput
            id='fileUpload'
            type='file'
            accept='*'
            name='file'
            onChange={this.handleUploadAndClose} />
        </Label>
        <EditorialFileModal
          file={this.state.file}
          isModalOpen={this.state.isModalOpen}
          toggleModal={this.toggleModal}
          onSave={this.props.handleAddFile.bind(this, this.state.file)} />
      </React.Fragment>
    )
  }
}

export default connect(null, mapDispatchToProps)(EditorialFileUpload)
