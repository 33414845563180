import React, { Component, Fragment } from 'react'
import capitalize from 'lodash/capitalize'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { OuterHeader, CenteredSpaceBetween, WrapContainer } from '../StyledComponents'
import { getUserReviewsByRole } from '../../actions/ReviewActions'
import { toFrontend } from '../../constants/ContentConstants'
import Loader from '../Loader'
import { RelatedModule } from '../ViewContent'
import pluralize from 'pluralize'

const mapStateToProps = state => ({
  reviewer: state.reviews.reviewer.data,
  reviewee: state.reviews.reviewee.data
})

const mapDispatchToProps = dispatch => bindActionCreators({
  getUserReviewsByRole
}, dispatch)

class ReviewCollection extends Component {
  constructor (props) {
    super(props)

    this.state = {
      role: '',
      isLoading: false
    }

    this.getUserReviews = this.getUserReviews.bind(this)
  }

  componentDidMount () {
    this.setState({ role: this.props.location.pathname.split('/')[2] })
  }

  componentDidUpdate (prevProps, prevState) {
    if (prevState.role !== this.state.role) this.getUserReviews()
  }

  getUserReviews () {
    this.setState({ isLoading: true }, () => {
      this.props.getUserReviewsByRole(this.state.role).then(() => {
        this.setState({ isLoading: false })
      })
    })
  }

  render () {
    const role = this.state.role
    if (!role) return <div />

    const headerText = role === 'reviewee' ? 'Pending review' : 'For review'

    const reviewContent = Object.keys(this.props[role]).map((type, idx) => {
      const frontendType = pluralize(toFrontend(type))
      const count = this.props[role][type].length
      const contentParams = { contentType: frontendType }

      return (
        <Fragment key={idx}>
          <h2>{capitalize(frontendType)}</h2>
          <RelatedModule
            key={idx}
            relatedContentParams={contentParams}
            relatedContent={this.props[role][type].map(review => {
              return review.contentObject
            })}
            count={count} />
        </Fragment>
      )
    })

    return (
      <React.Fragment>
        <OuterHeader>
          <CenteredSpaceBetween>
            {headerText}
          </CenteredSpaceBetween>
        </OuterHeader>
        {
          this.state.isLoading
            ? <Loader />
            : <WrapContainer>{reviewContent}</WrapContainer>
        }
      </React.Fragment>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ReviewCollection)
