import fetchWrapper from '../utils/FetchWrapper'
import { formatQueryString } from '../utils/FormatQueryString'

export function getCategories (params) {
  return fetchWrapper(`curator`, `/categories${formatQueryString(params)}`)
}

export function getCategory (id) {
  return fetchWrapper(`curator`, `/categories/${id}`)
}

export function getCategoryArtists (id) {
  return fetchWrapper(`curator`, `/categories/${id}/artists`)
}

export function getCategoryPlaylists (id) {
  return fetchWrapper(`curator`, `/categories/${id}/galleries`)
}

export function getCategoryItems (id, params) {
  const query = params ? formatQueryString(params) : ''
  return fetchWrapper(`curator`, `/categories/${id}/items${query}`)
}

export function getCategoryGroups (id) {
  return fetchWrapper(`curator`, `/categories/${id}/groups`)
}

export function getCategoryChannels (id) {
  return fetchWrapper(`curator`, `/categories/${id}/channels`)
}

export function getCategoryEditorials (id) {
  return fetchWrapper(`curator`, `/categories/${id}/articles`)
}

export function updateCategory (id, data) {
  return fetchWrapper('curator', `/categories/${id}`, {
    method: 'PUT',
    body: data
  })
}

export function createCategory (data) {
  return fetchWrapper('curator', `/categories`, {
    method: 'POST',
    body: data
  })
}

export function deleteCategory (id) {
  return fetchWrapper('curator', `/categories/${id}`, {
    method: 'DELETE'
  })
}

export function getRelatedCategories (parentType, parentId, queryParams) {
  return fetchWrapper(`curator`, `/${parentType}/${parentId}/categories${formatQueryString(queryParams)}`)
}
