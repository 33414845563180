import React from 'react'
import { connect } from 'react-redux'
import { updateChannel, deleteChannel, createChannel } from '../../actions/ChannelActions'
import { CHANNELS, ITEMS } from '../../constants/ContentConstants'
import { receiveSuccessMessage } from '../../actions/MessageActions'
import { createReview } from '../../actions/ReviewActions'
import {
  StyledOuterContainer, StyledFlexColumnContainer, StyledStatusContainer,
  StyledDetailContainer, PaddingContainer, EditWrapPage
} from '../StyledEditComponents'
import EditHeader from '../edit/EditHeader'
import IsPublic from '../edit/IsPublic'
import FeaturedAt from '../edit/FeaturedAt'
import TextField from '../edit/TextField'
import SuperPicker from '../edit/SuperPicker'
import ImagePicker from '../edit/ImagePicker'
import AbstractEditForm from '../edit/AbstractEditForm'
import CoverImageContainer from '../edit/CoverImageContainer'
import FileUploadField from '../edit/FileUpload'
import * as API from '../../api/Channels'
import InReviewBanner from '../reviews/InReviewBanner'
import GeorestrictionPicker from '../edit/GeorestrictionPicker'

function mapStateToProps ({ channels, auth }) {
  return {
    type: CHANNELS,
    object: channels.current,
    error: channels.error,
    user: auth.user
  }
}

function mapDispatchToProps (dispatch) {
  return {
    updateObject: (id, payload) => dispatch(updateChannel(id, payload)),
    createObject: (payload) => dispatch(createChannel(payload)),
    destroyObject: (id) => dispatch(deleteChannel(id)),
    createReview: (payload) => dispatch(createReview(payload)),
    renderMessage: (message) => dispatch(receiveSuccessMessage(message))
  }
}

class ChannelForm extends AbstractEditForm {
  constructor (props) {
    super(props)

    this.state = {
      object: {
        id: null,
        name: '',
        description: '',
        isPublic: false,
        order: '',
        availableCountries: null
      },
      avatar: {},
      hero: {},
      logo: '',
      isGeorestricted: false
    }
  }

  componentWillMount () {
    const id = parseInt(this.props.match.params.id)
    if (id) {
      API.getChannel(id).then(response => {
        this.setState({
          object: Object.assign({}, response.data, {
            avatar: response.data.avatar && response.data.avatar.id,
            hero: response.data.hero && response.data.hero.id,
            description: response.data.description || '',
            availableCountries: response.data.availableCountries || []
          }),
          avatar: response.data.avatar || {},
          hero: response.data.hero || {},
          logo: response.data.logo,
          isGeorestricted: !!response.data.availableCountries
        })
      })
    }
  }

  massageObject () {
    return this.massageGeorestrictionData(this.state.object)
  }

  render () {
    return (
      <form>
        <EditHeader
          type={this.props.type}
          name={this.state.object.name}
          deleteImage={this.state.avatar.imageThumb}
          handleSave={this.handleSubmit}
          handleSubmitForReview={this.handleSubmitForReview}
          currentId={this.state.object.id}
          destroyObjects={this.destroyObject} />
        <EditWrapPage>
          <InReviewBanner
            type={this.props.type}
            content={this.state.object}
            onApprove={this.handleApproveReview}
            onCancel={this.removeReview} />
          <StyledOuterContainer>
            <StyledStatusContainer>
              <CoverImageContainer
                image={this.state.avatar.imageThumb} />
              <PaddingContainer>
                <h5>{this.state.object.name}</h5>
                <IsPublic isPublic={this.state.object.isPublic}
                  handleRadioChange={this.handleRadioChange} />
                <FeaturedAt
                  currentDateTime={this.state.object.featuredAt}
                  handleChange={this.handleDateTimeChange} />
              </PaddingContainer>
            </StyledStatusContainer>
            <StyledFlexColumnContainer>
              <StyledDetailContainer>
                <TextField
                  id='name'
                  name='name'
                  type='text'
                  label='Title'
                  error={this.state.nameError}
                  value={this.state.object.name}
                  onChange={this.handleTextChange} />
                <TextField
                  id='description'
                  name='description'
                  type='textarea'
                  label='Description'
                  error={this.state.descriptionError}
                  value={this.state.object.description}
                  onChange={this.handleTextChange} />
                <TextField
                  id='order'
                  name='order'
                  type='text'
                  label='Order'
                  error={this.state.orderError}
                  value={this.state.object.order}
                  onChange={this.handleTextChange} />
                <FileUploadField
                  id='logo'
                  name='logo'
                  label='Logo'
                  value={this.state.logo}
                  onChange={this.handleFileUpload}
                  />
                <SuperPicker
                  render={(ctx) => {
                    return (
                      <ImagePicker
                        error={this.state.avatarError}
                        image={this.state.avatar}
                        label='Avatar'
                        toggleModal={ctx.toggleModal} />
                    )
                  }}
                  relatedContentType={ITEMS}
                  getAll
                  name='avatar'
                  baseType={CHANNELS}
                  currentId={this.state.object.id}
                  changeRelationship={this.changeSelectedContent} />
                <SuperPicker
                  render={(ctx) => {
                    return (
                      <ImagePicker
                        error={this.state.heroError}
                        image={this.state.hero}
                        label='Hero'
                        toggleModal={ctx.toggleModal} />
                    )
                  }}
                  relatedContentType={ITEMS}
                  getAll
                  name='hero'
                  baseType={CHANNELS}
                  currentId={this.state.object.id}
                  changeRelationship={this.changeSelectedContent} />
              </StyledDetailContainer>
              <GeorestrictionPicker
                {...this.state}
                handleGeorestrictionChange={this.handleGeorestrictionChange}
                handleRadioChange={this.handleRadioChange} />
            </StyledFlexColumnContainer>
          </StyledOuterContainer>
        </EditWrapPage>
      </form>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ChannelForm)
