import * as constants from '../constants/EditorialConstants'
import { EDITORIALS } from '../constants/ContentConstants'
import { DELETE_REVIEW, RECEIVE_REVIEW } from '../constants/ReviewConstants'

const _nullEditorials = {
  error: null,
  editorials: {
    isLast: false,
    data: [],
    count: null
  },
  current: {
    body: [],
    id: null,
    pendingReviews: []
  },
  selected: {}
}

export default function EditorialReducer (state = _nullEditorials, action) {
  switch (action.type) {
    case constants.RECEIVE_EDITORIALS: {
      const { isLast, data, count } = action.editorials
      const { newQuery } = action
      const newEditorials = {
        isLast: isLast,
        data: newQuery ? data : [].concat(state.editorials.data, data),
        count
      }
      return Object.assign({}, state, { editorials: newEditorials })
    }
    case constants.RECEIVE_EDITORIAL: {
      return Object.assign({}, state, {
        current: Object.assign({}, state.current, action.editorial)
      })
    }
    case DELETE_REVIEW: {
      if (!action.contentType === EDITORIALS) return state

      const newPendingReviews = Object.assign({}, {
        pendingReviews: state.current.pendingReviews
          .filter(review => review.id !== action.reviewId)
      })

      return Object.assign({}, state, {
        current: Object.assign({}, state.current, newPendingReviews)
      })
    }
    case RECEIVE_REVIEW: {
      if (!action.contentType === EDITORIALS) return state

      return Object.assign({}, state, {
        current: Object.assign({}, state.current, action.review.contentObject)
      })
    }
    case constants.RECEIVE_EDITORIALS_ERROR: {
      return Object.assign({}, state, { error: action.error })
    }
    case constants.CLEAR_EDITORIALS: {
      return Object.assign({}, _nullEditorials, {selected: state.selected})
    }
    default: {
      return state
    }
  }
}
