import React, { Component } from 'react'
import styled from 'styled-components'

const StyledImg = styled.img`
  transition: 0.2s ease opacity;
  opacity: ${props => props.show ? '1' : '0'};
  object-fit: cover;
  ${props => {
    if (props.cover) {
      return `
        width: 100%;
        height: 100%;
      `
    } else {
      return `
        width: ${props => props.sizeByHeight ? 'auto' : '100%'};
        height: ${props => props.sizeByHeight ? '100%' : 'auto'};
      `
    }
  }}
  display: block;
  border-radius: ${props => props.circle ? '50%' : '0%'};
`

export default class ImgFadeIn extends Component {
  constructor (props) {
    super(props)

    this.state = { isLoaded: false }
    this.isInstalled = false

    this._isMounted = false
    this.showImage = this.showImage.bind(this)
    this.installListener = this.installListener.bind(this)
    this.shouldComponentUpdate = this.shouldComponentUpdate.bind(this)
  }

  componentDidMount () {
    this._isMounted = true
  }

  componentWillUnmount () {
    this._isMounted = false
  }

  shouldComponentUpdate (nextProps, nextState) {
    if ((nextState.isLoaded && !this.state.isLoaded) ||
        (nextProps.src !== this.props.src)) {
      return true
    } else {
      return false
    }
  }

  showImage () {
    setTimeout(() => {
      if (this._isMounted) this.setState({ isLoaded: true })
    })
  }

  installListener (node) {
    if (node) {
      if (node.complete || node.naturalWidth !== 0) {
        this.showImage()
      } else {
        node.addEventListener('load', this.showImage)
      }
    }
  }

  render () {
    if (this.props.render) {
      return this.props.render(this.installListener, this.state.isLoaded)
    }

    return (
      <StyledImg
        alt={this.props.alt}
        src={this.props.src}
        cover={this.props.cover}
        show={this.state.isLoaded}
        sizeByHeight={this.props.sizeByHeight}
        innerRef={this.installListener}
        circle={this.props.circle} />
    )
  }
}
