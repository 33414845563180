import React, { Component } from 'react'
import styled from 'styled-components'
import videojs from 'video.js'
import { SmallSpan } from '../../styles/Text'
import { Spacer, Flex } from '../../styles/Box'
import { formatDuration } from '../../utils/general'

const VideoContainer = styled.div`
  flex-direction: column;
  margin-bottom: 7rem;
`

const Video = styled.video`
  width: 100%;
  height: 100%;
`

const VideoData = styled.div`
  flex-direction: column;
  border-bottom: 0.1rem solid ${props => props.theme.colors.lightGray};
`

const VideoDetail = styled(Flex)`
  justify-content: space-between !important;
`

export default class EditorialVideo extends Component {
  constructor (props) {
    super(props)

    this.state = {
      videoLoaded: false
    }

    this.onLoadedMetadata = this.onLoadedMetadata.bind(this)
  }

  componentDidMount () {
    this.player = videojs(this.video, {
      controls: true,
      fluid: true
    })

    this.player.on('loadedmetadata', this.onLoadedMetadata)
  }

  onLoadedMetadata () {
    this.setState({ videoLoaded: true })
  }

  componentWillUnmount () {
    if (this.player) {
      this.player.off('loadedmetadata', this.onLoadedMetadata)
      this.player.dispose()
    }
  }

  render () {
    return (
      <VideoContainer>
        <Video
          className='video-js vjs-16-9'
          innerRef={domNode => { this.video = domNode }}>
          <source src={this.props.content.url} />
          <p>
            Your browse doesn't support HTML5 video. Here is a
            <a href={this.props.content.url}>link to the video</a> instead.
          </p>
        </Video>
        <VideoData>
          <Spacer marginTop='2rem' />
          <VideoDetail>
            <SmallSpan bold>{this.props.content.name}</SmallSpan>
            <SmallSpan>
              {this.state.videoLoaded && formatDuration(this.player.duration())}
            </SmallSpan>
          </VideoDetail>
          <SmallSpan>{this.props.content.caption}</SmallSpan>
          <Spacer marginBottom='2rem' />
        </VideoData>
      </VideoContainer>
    )
  }
}
