import React from 'react'
import styled from 'styled-components'
import LogoLoader from './LogoLoader'

const StyledButton = styled.button`
  &:focus {
    outline: none;
  }
`

export default function ButtonLoader (props) {
  const { isLoading, large, children, ...rest } = props

  return (
    <StyledButton disabled={isLoading} {...rest}>
      {isLoading
        ? <LogoLoader large={large} />
        : children}
    </StyledButton>
  )
}
