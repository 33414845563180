import * as constants from '../constants/SearchAndFilterConstants'

export const updateSearchString = (string, contentType) => dispatch => {
  return dispatch({type: constants.UPDATE_SEARCH_STRING, string, contentType})
}

export const updateFilters = (newFilter, contentType) => dispatch => {
  return dispatch({type: constants.UPDATE_FILTERS, newFilter, contentType})
}

export const updateSortField = (field, contentType) => dispatch => {
  return dispatch({type: constants.UPDATE_SORT_FIELD, field, contentType})
}

export const updateSortOrder = (order, contentType) => dispatch => {
  return dispatch({type: constants.UPDATE_SORT_ORDER, order, contentType})
}

export const clearFilters = () => dispatch => {
  return dispatch({type: constants.CLEAR_FILTERS})
}

export const clearSearchAndFilter = () => dispatch => {
  return new Promise((resolve, reject) => {
    return resolve(dispatch({type: constants.CLEAR_SEARCH_AND_FILTER}))
  })
}
