import fetchWrapper from '../utils/FetchWrapper'
import { formatQueryString } from '../utils/FormatQueryString'

export function getProviders (params) {
  return fetchWrapper(`curator`, `/providers${formatQueryString(params)}`)
}

export function getProvider (id) {
  return fetchWrapper(`curator`, `/providers/${id}`)
}

export function getProviderItems (id) {
  return fetchWrapper(`curator`, `/providers/${id}/items`)
}

export function getProviderPlaylists (id) {
  return fetchWrapper(`curator`, `/providers/${id}/galleries`)
}

export function getRelatedProviders (parentType, parentId, queryParams) {
  return fetchWrapper(`curator`, `/${parentType}/${parentId}/providers${formatQueryString(queryParams)}`)
}

export function updateProvider (id, data) {
  return fetchWrapper('curator', `/providers/${id}`, {
    method: 'PUT',
    body: data
  })
}

export function createProvider (data) {
  return fetchWrapper('curator', `/providers`, {
    method: 'POST',
    body: data
  })
}

export function deleteProvider (id) {
  return fetchWrapper('curator', `/providers/${id}`, {
    method: 'DELETE'
  })
}
