import * as constants from '../constants/HistoryConstants'

const _nullHistory = {
  events: [],
  type: '',
  object: {},
  error: ''
}

export default function ReviewReducer (state = _nullHistory, action) {
  switch (action.type) {
    case constants.RECEIVE_HISTORY: {
      return Object.assign({}, action.data)
    }
    case constants.CLEAR_HISTORY: {
      return _nullHistory
    }
    case constants.RECEIVE_HISTORY_ERROR: {
      return {
        ...state,
        error: action.error
      }
    }
    default: {
      return state
    }
  }
}
