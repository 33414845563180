import React from 'react'
import styled, { css } from 'styled-components'
import { FlexContainer } from './StyledComponents'
import { checkForImage } from '../utils/ImageUtils'
import { ITEMS } from '../constants/ContentConstants'

const GridContainer = styled.div`
  display: grid;
  grid-template-rows: repeat(2, 50%);
  grid-template-columns: repeat(3, 33%);
  grid-gap: 1px;
  height: 157px;
  border: 1px solid ${props => props.theme.white};
  border-radius: 4px;
`

const borderStyles = css`
  border-top-left-radius: ${props => props.borderTopLeft && '5px'};
  border-bottom-left-radius: ${props => props.borderBottomLeft && '5px'};
  border-top-right-radius: ${props => props.borderTopRight && '5px'};
  border-bottom-right-radius: ${props => props.borderBottomRight && '5px'};
`

const Image = styled.img`
  object-fit: cover;
  width: 100%;
  height: 100%;
  ${borderStyles}
`

const LeftImageContainer = styled.div`
  grid-column: 1 / 3;
  grid-row: 1 / 3;
  background-color: ${props => props.theme.lighterMediumGray};
  ${borderStyles}
`

const RightImageContainer = styled.div`
  grid-column: 3;
  grid-row: ${props => props.gridRow};
  position: relative;
  height: 100%;
  background-color: ${props => props.theme.lighterMediumGray};
  ${borderStyles}
`

const Overlay = styled.div`
  position: absolute;
  color: ${props => props.theme.white};
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  font-size: 18px;
  background-color: rgba(0, 0, 0, 0.3);
  ${borderStyles}
`

export default function PreviewItemGrid (props) {
  const firstPreviewItem = props.previewItems[0]
  const secondPreviewItem = props.previewItems[1]
  const thirdPreviewItem = props.previewItems[2]

  return (
    <GridContainer>
      <LeftImageContainer
        borderTopLeft
        borderBottomLeft>
        {
          firstPreviewItem &&
          <Image
            src={checkForImage(firstPreviewItem, ITEMS)}
            borderTopLeft
            borderBottomLeft />
        }
      </LeftImageContainer>
      <RightImageContainer gridRow={1} borderTopRight>
        {
          secondPreviewItem &&
          <Image
            src={checkForImage(secondPreviewItem, ITEMS)}
            borderTopRight />
        }
      </RightImageContainer>
      <RightImageContainer gridRow={2} borderBottomRight>
        {
          thirdPreviewItem &&
          <Image
            src={checkForImage(thirdPreviewItem, ITEMS)}
            borderBottomRight />
        }
        {
          props.count > 3 &&
          <Overlay borderBottomRight>
            <FlexContainer justifyContent='center' fullHeight>
              {props.count}
            </FlexContainer>
          </Overlay>
        }
      </RightImageContainer>
    </GridContainer>
  )
}
