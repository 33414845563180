import fetchWrapper from '../utils/FetchWrapper'
import { formatQueryString } from '../utils/FormatQueryString'

export function getGroups (params) {
  return fetchWrapper(`curator`, `/groups${formatQueryString(params)}`)
}

export function getGroup (id) {
  return fetchWrapper(`curator`, `/groups/${id}`)
}

export function getGroupCategories (id) {
  return fetchWrapper(`curator`, `/groups/${id}/categories`)
}

export function updateGroup (id, data) {
  return fetchWrapper('curator', `/groups/${id}`, {
    method: 'PUT',
    body: data
  })
}

export function createGroup (data) {
  return fetchWrapper('curator', `/groups`, {
    method: 'POST',
    body: data
  })
}

export function deleteGroup (id) {
  return fetchWrapper('curator', `/groups/${id}`, {
    method: 'DELETE'
  })
}

export function getRelatedGroups (parentType, parentId, queryParams) {
  return fetchWrapper(`curator`, `/${parentType}/${parentId}/groups${formatQueryString(queryParams)}`)
}
