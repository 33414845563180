import React from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import merge from 'lodash/merge'
import * as API from '../../api/Editorials'
import {
  EDITORIALS, ITEMS, CATEGORIES, AUTHORS, PLAYLISTS, ARTISTS
} from '../../constants/ContentConstants'
import { updateEditorial, createEditorial, deleteEditorial } from '../../actions/EditorialActions'
import { receiveSuccessMessage } from '../../actions/MessageActions'
import { createReview } from '../../actions/ReviewActions'
import EditHeader from '../edit/EditHeader'
import IsPublic from '../edit/IsPublic'
import FeaturedAt from '../edit/FeaturedAt'
import TextField from '../edit/TextField'
import SuperPicker from '../edit/SuperPicker'
import ImagePicker from '../edit/ImagePicker'
import AuthorPicker from '../edit/AuthorPicker'
import AbstractEditForm from '../edit/AbstractEditForm'
import CoverImageContainer from '../edit/CoverImageContainer'
import { GrayButton, FlexContainer } from '../StyledComponents'
import {
  StyledOuterContainer, StyledFlexColumnContainer, StyledStatusContainer,
  StyledDetailContainer, StyledRelatedContainer, PaddingContainer, EditWrapPage
} from '../StyledEditComponents'
import { checkForImage } from '../../utils/ImageUtils'
import { stripItemsAndFilesFromBody } from '../../utils/EditorialMarkdown'
import RelatedContentList from '../edit/RelatedContentList'
import FeaturedContent from '../edit/FeaturedContent'
import InReviewBanner from '../reviews/InReviewBanner'
import GeorestrictionPicker from '../edit/GeorestrictionPicker'
import CategoryPicker from '../edit/CategoryPicker'

const ComposerButtonContainer = styled(GrayButton)`
  max-width: 204px;
  display: inline block;
`

const mapStateToProps = ({ editorials, auth }) => {
  return {
    type: EDITORIALS,
    object: editorials.current,
    error: editorials.error,
    user: auth.user
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    updateObject: (id, payload) => dispatch(updateEditorial(id, payload)),
    createObject: (payload) => dispatch(createEditorial(payload)),
    destroyObject: (id) => dispatch(deleteEditorial(id)),
    createReview: (payload) => dispatch(createReview(payload)),
    renderMessage: (message) => dispatch(receiveSuccessMessage(message))
  }
}

class EditorialForm extends AbstractEditForm {
  constructor (props) {
    super(props)

    this.state = {
      object: {
        name: '',
        author: null,
        hero: null,
        contentObject: {
          objectId: null,
          contentType: 'gallery'
        },
        isPublic: false,
        body: [],
        contentType: 'gallery',
        categories: [],
        artists: [],
        availableCountries: null
      },
      hero: {},
      author: {},
      categories: [],
      contentObject: {},
      artists: [],
      isGeorestricted: false
    }

    this.changeFeaturedContent = this.changeFeaturedContent.bind(this)
  }

  componentDidMount () {
    const id = parseInt(this.props.match.params.id)
    if (id) {
      Promise.all([
        API.getEditorial(id),
        API.getEditorialCategories(id),
        API.getEditorialArtists(id)
      ]).then(values => {
        const object = values[0].data
        const newState = {
          object: Object.assign({}, object, {
            hero: object.hero && object.hero.id,
            author: object.author.id,
            body: stripItemsAndFilesFromBody(object.body),
            availableCountries: object.availableCountries || [],
            contentObject: {
              objectId: object.contentObject.id,
              contentType: object.contentType
            }
          }),
          hero: object.hero,
          author: object.author,
          categories: values[1].data,
          contentObject: object.contentObject,
          artists: values[2].data,
          isGeorestricted: !!object.availableCountries
        }
        this.setState(newState)
      })
    }
  }

  massageObject () {
    return this.massageGeorestrictionData(this.state.object)
  }

  changeFeaturedContent (contentObject) {
    this.setState({
      contentObject,
      object: merge({}, this.state.object, {
        contentObject: { objectId: contentObject.id },
        objectId: contentObject.id
      }),
      contentObjectError: null
    })
  }

  render () {
    let launchComposer
    if (this.state.object.id) {
      launchComposer = (
        <StyledDetailContainer>
          <FlexContainer justifyContent='space-between'>
            <span>Want to edit the article contents?</span>
            <ComposerButtonContainer onClick={() => this.props.history.push(`/editorials/${this.state.object.id}/compose`)} small='true'>
              Launch Composer
            </ComposerButtonContainer>
          </FlexContainer>
        </StyledDetailContainer>
      )
    }

    return (
      <form>
        <EditHeader
          type={this.props.type}
          name={this.state.object.name}
          deleteImage={this.state.hero && this.state.hero.image}
          handleSave={this.handleSubmit}
          handleSubmitForReview={this.handleSubmitForReview}
          currentId={this.state.object.id}
          destroyObjects={this.destroyObject} />
        <EditWrapPage>
          <InReviewBanner
            type={this.props.type}
            content={this.state.object}
            onApprove={this.handleApproveReview}
            onCancel={this.removeReview} />
          <StyledOuterContainer>
            <StyledStatusContainer>
              <CoverImageContainer image={checkForImage(this.state.hero)} />
              <PaddingContainer>
                <IsPublic isPublic={this.state.object.isPublic}
                  handleRadioChange={this.handleRadioChange} />
                <FeaturedAt
                  currentDateTime={this.state.object.featuredAt}
                  handleChange={this.handleDateTimeChange} />
              </PaddingContainer>
            </StyledStatusContainer>
            <StyledFlexColumnContainer>
              {launchComposer}
              <StyledDetailContainer>
                <TextField id='name' name='name' type='text'
                  label='Title'
                  error={this.state.nameError}
                  value={this.state.object.name}
                  onChange={this.handleTextChange} />
                <TextField id='seriesName' name='seriesName' type='text'
                  label='Series name'
                  error={this.state.seriesNameError}
                  value={this.state.object.seriesName}
                  onChange={this.handleTextChange} />
                <TextField id='subheader' name='subheader' type='text'
                  label='Subheader'
                  error={this.state.subheaderError}
                  value={this.state.object.subheader}
                  onChange={this.handleTextChange} />
                <SuperPicker
                  render={(ctx) => {
                    return (
                      <AuthorPicker author={this.state.author.name}
                        error={this.state.authorError}
                        label='Author' toggleModal={ctx.toggleModal} />
                    )
                  }}
                  relatedContentType={AUTHORS}
                  name='author'
                  baseType={EDITORIALS}
                  currentId={this.state.object.id}
                  changeRelationship={this.changeSelectedContent}
                  getAll
                  />
                <SuperPicker
                  render={(ctx) => {
                    return (
                      <ImagePicker image={this.state.hero}
                        error={this.state.heroError}
                        label='Hero' toggleModal={ctx.toggleModal} />
                    )
                  }}
                  relatedContentType={ITEMS}
                  name='hero'
                  baseType={EDITORIALS}
                  currentId={this.state.object.id}
                  changeRelationship={this.changeSelectedContent}
                  getAll
                  />
              </StyledDetailContainer>
              <StyledRelatedContainer>
                <CategoryPicker
                  selectedCategories={this.state[CATEGORIES]}
                  removeRelationship={this.removeRelatedContent}
                  createRelationship={this.toggleRelationship} />
                <SuperPicker
                  render={(ctx) => {
                    return (
                      <RelatedContentList
                        relatedContentType={ctx.props.relatedContentType}
                        content={ctx.props.content}
                        removeRelationship={ctx.props.removeRelationship}
                        toggleModal={ctx.toggleModal}
                        error={this.state.artistsError} />
                    )
                  }}
                  content={this.state[ARTISTS]}
                  relatedContentType={ARTISTS}
                  baseType={EDITORIALS}
                  allowBatch
                  getAll
                  currentId={this.state.object.id}
                  selectedIds={this.state.object.artists}
                  createRelationship={this.toggleRelationship}
                  removeRelationship={this.removeRelatedContent} />
              </StyledRelatedContainer>
              <StyledRelatedContainer>
                <SuperPicker
                  render={(ctx) => {
                    return (
                      <FeaturedContent
                        contentObject={this.state.contentObject}
                        contentType={this.state.object.contentType}
                        toggleModal={ctx.toggleModal}
                        error={this.state.contentObjectError} />
                    )
                  }}
                  relatedContentType={PLAYLISTS}
                  baseType={EDITORIALS}
                  getAll
                  currentId={this.state.object.id}
                  selectedIds={this.state.object.categories}
                  createRelationship={this.changeFeaturedContent}
                  />
              </StyledRelatedContainer>
              <GeorestrictionPicker
                {...this.state}
                handleGeorestrictionChange={this.handleGeorestrictionChange}
                handleRadioChange={this.handleRadioChange} />
            </StyledFlexColumnContainer>
          </StyledOuterContainer>
        </EditWrapPage>
      </form>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(EditorialForm)
