import React, { Component } from 'react'
import styled from 'styled-components'
import merge from 'lodash/merge'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { FlexContainer, FlexColumnContainer } from '../StyledComponents'
import {
  renderMarkdownToSchema, addTextAtCaret, parseBodyToFrontend,
  stripItemsAndFilesFromBody
} from '../../utils/EditorialMarkdown'
import ComposerHeader from './ComposerHeader'
import ComposerEditor from './ComposerEditor'
import ComposerPreview from './ComposerPreview'
import { updateEditorial, getEditorial } from '../../actions/EditorialActions'
import { ITEM, AUDIO, VIDEO, IMAGE } from '../../constants/EditorialConstants'
import { getv0Item } from '../../api/Items'
import { getFile } from '../../api/Editorials'

const PreviewContainer = styled.div`
  width: 50%;
  height: calc(100vh - 63px);
  padding: 52px;
  background-color: ${props => props.theme.white};
`

const mapStateToProps = ({ editorials }) => ({
  editorial: editorials.current
})

const mapDispatchToProps = (dispatch) => bindActionCreators({
  updateEditorial,
  getEditorial
}, dispatch)

class EditorialComposer extends Component {
  constructor (props) {
    super(props)

    this.state = {
      id: null,
      backendBody: [],
      frontendBody: [],
      textBuffer: '',
      itemCache: [],
      fileCache: []
    }

    this.handleBodyChange = this.handleBodyChange.bind(this)
    this.addItemFromPicker = this.addItemFromPicker.bind(this)
    this.addFileToMarkdownAndCache = this.addFileToMarkdownAndCache.bind(this)
    this.addItemsAndGenericFiles = this.addItemsAndGenericFiles.bind(this)
    this.handleSave = this.handleSave.bind(this)
    this.handleInsertion = this.handleInsertion.bind(this)
  }

  componentDidMount () {
    if (!this.props.editorial.id) {
      const id = this.props.location.pathname.split('/')[2]
      this.props.getEditorial(id)
    }
  }

  static getDerivedStateFromProps (nextProps, prevState) {
    if (nextProps.editorial && nextProps.editorial.id !== prevState.id) {
      return {
        id: nextProps.editorial.id,
        frontendBody: nextProps.editorial.body,
        backendBody: stripItemsAndFilesFromBody(nextProps.editorial.body),
        textBuffer: parseBodyToFrontend(nextProps.editorial.body)
      }
    }

    return null
  }

  componentDidUpdate (prevProps, prevState) {
    const itemFetchQueue = []
    const fileFetchQueue = []
    this.state.backendBody.forEach(el => {
      if (el.type === ITEM) {
        const id = parseInt(el.content.id)
        if (!this.state.itemCache.find(item => item.id === id)) {
          itemFetchQueue.push(id)
        }
      } else if (el.type === AUDIO || el.type === VIDEO || el.type === IMAGE) {
        const id = parseInt(el.content.id)
        if (id && !this.state.fileCache.find(file => file.id === id)) {
          fileFetchQueue.push(id)
        }
      }
    })

    if (itemFetchQueue.length || fileFetchQueue.length) {
      Promise.all(itemFetchQueue.map(id => getv0Item(id)).concat(
        fileFetchQueue.map(id => getFile(id))
      )).then(values => {
        this.setState({
          itemCache: this.state.itemCache.concat(
            values.slice(0, itemFetchQueue.length).map(value => value.data)
          ),
          fileCache: this.state.fileCache.concat(
            values.slice(itemFetchQueue.length, values.length).map(value => value.data)
          )
        })
      }).then(() => this.handleBodyChange(this.state.textBuffer))
    }

    if (!prevState.id && this.state.id && this.input) {
      this.setCursorPosition(0, 0)
    }
  }

  addItemsAndGenericFiles (schema) {
    let frontendBody = []
    for (let i = 0; i < schema.length; i++) {
      const element = merge({}, schema[i])
      if (element.type === ITEM) {
        const id = parseInt(element.content.id)
        const found = this.state.itemCache.find(item => item.id === id)
        if (found) {
          element.content = { item: found }
        } else {
          element.content = { item: { id } }
        }
      } else if (element.type === AUDIO || element.type === VIDEO ||
                 element.type === IMAGE) {
        const id = parseInt(element.content.id)
        let found
        if (!id && element.type === IMAGE) {
          // set broken image file
          found = {
            genericFile: 'https://cdn.meural.com/curator/BrokenImage_Landscape.jpg'
          }
        } else {
          found = this.state.fileCache.find(file => file.id === id)
        }

        if (found) element.content.url = found.genericFile
      }

      frontendBody.push(element)
    }

    return frontendBody
  }

  setCursorPosition (cursorPosition, scrollPosition) {
    this.input.focus()
    this.input.setSelectionRange(cursorPosition, cursorPosition)
    this.input.scrollTop = scrollPosition
  }

  handleInsertion (md) {
    const markdown = addTextAtCaret(md, this.input)
    // move cursor to two lines below added item markdown
    const newCursorPosition = this.input.selectionStart + md.length + 3
    const newScrollPosition = this.input.scrollTop
    this.handleBodyChange(markdown, newCursorPosition, newScrollPosition)
  }

  addItemFromPicker (item) {
    const itemMarkdown = `![item](${item.id})`
    this.handleInsertion(itemMarkdown)
  }

  addFileToMarkdownAndCache (file, name, caption) {
    const { fileType, id } = file
    const meta = fileType !== 'audio' ? `[${name}]{${caption}}` : `${name}`
    const fileMarkdown = `![${fileType}](${id} "${meta}")`
    this.handleInsertion(fileMarkdown)
  }

  handleBodyChange (markdown, newCursorPosition, newScrollPosition) {
    const meuralSchema = renderMarkdownToSchema(markdown)
    this.setState({
      textBuffer: markdown,
      backendBody: meuralSchema,
      frontendBody: this.addItemsAndGenericFiles(meuralSchema)
    }, () => {
      if (newCursorPosition) {
        this.setCursorPosition(newCursorPosition, newScrollPosition)
      }
    })
  }

  handleSave () {
    this.props.updateEditorial(this.state.id, { body: this.state.backendBody })
      .then(() => this.props.history.push(`/editorials/${this.state.id}`))
      .catch(() => {})
  }

  render () {
    return (
      <FlexColumnContainer>
        <ComposerHeader
          history={this.props.history}
          editorialId={this.state.id}
          name={this.props.editorial.name}
          onSave={this.handleSave} />
        <FlexContainer>
          <ComposerEditor
            textBuffer={this.state.textBuffer}
            handleBodyChange={this.handleBodyChange}
            handleAddItem={this.addItemFromPicker}
            handleAddFile={this.addFileToMarkdownAndCache}
            contentObjectId={this.props.editorial &&
                            this.props.editorial.contentObject &&
                            this.props.editorial.contentObject.id}
            innerRef={inputNode => { this.input = inputNode }} />
          <PreviewContainer>
            <ComposerPreview body={this.state.frontendBody} scrolling={'yes'} />
          </PreviewContainer>
        </FlexContainer>
      </FlexColumnContainer>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(EditorialComposer)
