import React from 'react'
import styled from 'styled-components'

import loader from '../../assets/loader-bronze.gif'

const CenterContent = styled.div`
  width: 100%;
  padding: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 50px;
`

const LoadingText = styled.h3`
  margin-top: 20px;
`

const StyledImg = styled.img`
  width: 150px
`

export default function Loader (props) {
  return (
    <CenterContent>
      <StyledImg src={loader} />
      <LoadingText>Loading...</LoadingText>
    </CenterContent>
  )
}
