import MarkedEditorial from '../../../vendor/marked/marked'
import { ITEM, AUDIO, VIDEO, IMAGE, PARAGRAPH } from '../constants/EditorialConstants'
import pick from 'lodash/pick'

const myRenderer = new MarkedEditorial.Renderer()

// Format: "[Name]{caption}"
// Extracts the name and caption from video and image elements
const getNameAndCaption = function (text) {
  const groups = text && text.match(/\[([^[\]{}]*)\]{([^[\]{}]*)\}/)
  const name = groups && groups[1]
  const caption = groups && groups[2]

  return { name, caption }
}

// Every line is a paragraph even if there's no text or if it's another element.
myRenderer.paragraph = function (text) {
  // If it's not an empty line, try to parse the text to see if it's already a
  // JSON string (e.g video, item, etc would already be parsed as JSON). If
  // parsing fails, the text must be a paragraph element and not any of the
  // other elements.
  if (text !== 'undefined') {
    try {
      return JSON.stringify(JSON.parse(text))
    } catch (e) {
      return JSON.stringify({
        type: PARAGRAPH,
        content: { text }
      })
    }
  } else {
    return ''
  }
}

myRenderer.image = function (identifier, text, type) {
  switch (type) {
    case ITEM:
      return JSON.stringify({
        type,
        content: { id: parseInt(identifier) }
      })
    case VIDEO:
    case IMAGE:
      return JSON.stringify({
        type,
        content: Object.assign(
          { id: parseInt(identifier) }, getNameAndCaption(text)
        )
      })
    case AUDIO:
      const name = text && text.slice(1, -1)
      return JSON.stringify({
        type,
        content: { id: parseInt(identifier), name }
      })
  }
}

myRenderer.strong = function (text) {
  return `**${text}**`
}

myRenderer.em = function (text) {
  return `*${text}*`
}

myRenderer.del = function (text) {
  return `~~${text}~~`
}

myRenderer.link = function (href, title, text) {
  const massagedTitle = title ? ` ${title}` : ''
  return `[${text}](${href}${massagedTitle})`
}

myRenderer.heading = function (text, level, raw) {
  return JSON.stringify({
    type: PARAGRAPH,
    content: { text: `${'#'.repeat(level)} ${text}` }
  })
}

MarkedEditorial.setOptions({
  renderer: myRenderer,
  gfm: false,
  tables: false,
  breaks: true,
  pedantic: false,
  sanitize: true,
  smartLists: true,
  smartypants: false
})

function parse (body) {
  let openCount = 0
  let breakPoints = []
  for (let i = 0; i < body.length; i++) {
    let char = body[i]
    let idx = i

    if (char === '{') {
      openCount += 1
    } else if (char === '}') {
      openCount -= 1
    }

    if (openCount === 0) {
      breakPoints.push(idx + 1)
    }
  }

  let lastPoint = 0
  let parsedBody = []
  for (let j = 0; j < breakPoints.length; j++) {
    let breakPoint = breakPoints[j]
    let sliced = body.slice(lastPoint, breakPoint)
    lastPoint = breakPoint

    try {
      parsedBody.push(JSON.parse(sliced))
    } catch (e) {}
  }

  return parsedBody
}

// MarkedEditorial returns a large JSON string of all the objects. This method
// converts the JSON string into an array of objects
export function renderMarkdownToSchema (body) {
  return parse(MarkedEditorial(body))
}

export const parseBodyToFrontend = (body) => {
  let bodyText = ''
  body.forEach(constituent => {
    const content = constituent.content
    switch (constituent.type) {
      case ITEM:
        bodyText += `![item](${content.id || content.item.id})\n\n`
        break
      case VIDEO:
      case IMAGE:
        let details = ''
        if (content.name && content.caption) {
          details += ` "[${content.name}]{${content.caption}}"`
        } else if (content.name) {
          details += ` "[${content.name}]{}"`
        } else if (content.caption) {
          details += ` "[]{${content.caption}}"`
        }

        bodyText += `![${constituent.type}](${content.id}${details})\n\n`

        break
      case AUDIO:
        const name = content.name ? ` "${content.name}"` : ''
        bodyText += `![audio](${content.id}${name})\n\n`
        break
      case PARAGRAPH:
        bodyText += `${content.text}\n\n`
        break
      default:
        break
    }
  })

  return bodyText
}

export function stripItemsAndFilesFromBody (body) {
  return body.reduce((updatedBody, bodyEl) => {
    if (bodyEl.type === ITEM) {
      updatedBody.push({ type: ITEM, content: { id: bodyEl.content.item.id } })
    } else if (bodyEl.type === IMAGE && !bodyEl.content.id) {
      updatedBody.push({ type: IMAGE, content: { id: null } })
    } else if (bodyEl.type === IMAGE || bodyEl.type === VIDEO ||
               bodyEl.type === AUDIO) {
      updatedBody.push({
        type: bodyEl.type,
        content: pick(bodyEl.content, ['id', 'name', 'caption'])
      })
    } else {
      updatedBody.push(bodyEl)
    }

    return updatedBody
  }, [])
}

export const addTextAtCaret = (text, input) => {
  const start = input.selectionStart
  const beforeStart = input.value.slice(0, start)
  const afterStart = input.value.slice(start)
  const newLineCount = (beforeStart.slice(-4).match(/\n+/g) || []).length
  const newLines = Array(2 - newLineCount).fill('\n').join('')
  return `${beforeStart}${newLines}${text}\n\n${afterStart}`
}

export default MarkedEditorial
