import React from 'react'
import styled from 'styled-components'
import { withRouter } from 'react-router-dom'
import {
  FlexContainer, OuterHeader, CenteredSpaceBetween, GraySpan, GrayButtonLink
} from '../StyledComponents'
import { getMeuralUrl, convertTypeForUrl } from '../../utils/GetMeuralUrl'
import SubmitReviewBtn from './SubmitReviewBtn'
import SaveButton from './SaveButton'
import DeleteButton from './DeleteButton'

const ButtonContainer = styled(FlexContainer)`
  width: 30%;
  justify-content: flex-end;
`

const BackIcon = styled.i`
  font-size: 24px;
  margin-right: 10px;
  padding: 3px;
  cursor: pointer;

  &:hover {
    background-color: ${props => props.theme.lightGray};
    border-radius: 1px solid ${props => props.theme.lightGray};
    border-radius: 3px;
  }
`

class EditHeader extends React.Component {
  render () {
    const headerText = this.props.currentId || this.props.batchEdit
                     ? 'Editing'
                     : 'Creating'
    const previewBtn = !this.props.currentId || this.props.batchEdit
                      ? null
                      : (
                        <GrayButtonLink small='true' marginleft='10px'>
                          <a href={`${getMeuralUrl()}/${convertTypeForUrl(this.props.type)}/${this.props.currentId}`}
                            className='button-link' target='_blank'>
                            Preview
                          </a>
                        </GrayButtonLink>
                      )

    return (
      <OuterHeader>
        <CenteredSpaceBetween>
          <FlexContainer>
            <BackIcon className='material-icons' onClick={this.props.history.goBack}>
              keyboard_arrow_left
            </BackIcon>
            <div><GraySpan>{`${headerText} : `}</GraySpan>{this.props.name}</div>
          </FlexContainer>
          <ButtonContainer>
            {previewBtn}
            <DeleteButton {...this.props} />
            <SubmitReviewBtn
              currentId={this.props.currentId}
              onSave={(reviewerId) => {
                return this.props.handleSubmitForReview(this.props.type, reviewerId)
              }} />
            <SaveButton {...this.props} />
          </ButtonContainer>
        </CenteredSpaceBetween>
      </OuterHeader>
    )
  }
}

export default withRouter(EditHeader)
