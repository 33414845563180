import React, { Component } from 'react'
import {
  Thumbnail, PaddedContainer, MarginContainer, FlexContainer
} from './StyledComponents'
import ContentDetails from './ContentDetails'
import ViewEditorialPreview from './editorials/ViewEditorialPreview'
import styled from 'styled-components'
import marked from 'marked'
import {
  ITEMS, PLAYLISTS, ARTISTS, CATEGORIES, CHANNELS,
  PROVIDERS, GROUPS, EDITORIALS
} from '../constants/ContentConstants'
import { checkForImage } from '../utils/ImageUtils'
import { formatPublicationDate } from '../utils/DateFormatter'

const InfoContainer = styled.div`
  display: block;
  width: 80%;
  margin-right: 20px;
`

const Title = styled.h2`
  font-weight: bold;
  font-size: 28px;
`

const Subtitle = styled.h3`
  font-size: 20px;
`

const Description = styled.p`
  font-size: 14px;
  color: ${props => props.theme.darkPurple};
`

const ItemImage = styled.img`
  margin: auto;
  display: block;
  width: 100%;
  height: 422px;
  object-fit: contain;
  background-color: ${props => props.theme.lightGray};
  border: 1px solid ${props => props.theme.lightGray};
  border-radius: 5px;
  margin-top: 52px;
`

export default class ContentInfo extends Component {
  formatProps () {
    const { type } = this.props
    switch (type) {
      case ITEMS:
        const delimiter = this.props.author && this.props.year ? ', ' : ''
        const formattedSubtitle = `${this.props.author}${delimiter}${this.props.year}`
        return {
          key: this.props.id,
          title: this.props.name,
          subtitle: formattedSubtitle,
          description: this.props.description,
          image: this.props.imageThumbOptimized || this.props.imageThumb,
          details: {
            isPublic: this.props.isPublic,
            publishedAt: formatPublicationDate(this.props.publishedAt),
            isFeatured: this.props.isFeatured,
            featuredAt: formatPublicationDate(this.props.featuredAt),
            favorites: this.props.favoriteCount,
            provider: this.props.provider,
            medium: this.props.medium,
            dimensions: this.props.dimensions,
            id: this.props.id,
            galleryCount: this.props.galleryCount,
            deviceCount: this.props.deviceCount,
            isScheduled: this.props.premiumDate && !this.props.isPremium,
            isPremium: this.props.isPremium
          }
        }
      case PLAYLISTS:
        return {
          key: this.props.id,
          description: this.props.description,
          title: this.props.name,
          image: checkForImage(this.props.coverItem, PLAYLISTS),
          details: {
            publishedAt: formatPublicationDate(this.props.publishedAt),
            isPublic: this.props.isPublic,
            isFeatured: this.props.isFeatured,
            featuredAt: formatPublicationDate(this.props.featuredAt),
            deviceCount: this.props.deviceCount,
            favorites: this.props.favoriteCount,
            id: this.props.id,
            isScheduled: this.props.premiumDate && !this.props.isPremium,
            isPremium: this.props.isPremium
          }
        }
      case ARTISTS:
        return {
          key: this.props.id,
          description: this.props.biography,
          title: `${this.props.firstName} ${this.props.surname}`,
          image: checkForImage(this.props.hero, ARTISTS),
          details: {
            isPublic: this.props.isPublic,
            publishedAt: formatPublicationDate(this.props.publishedAt),
            isFeatured: this.props.isFeatured,
            featuredAt: formatPublicationDate(this.props.featuredAt),
            favorites: this.props.favoriteCount,
            birth: this.props.birth,
            death: this.props.death,
            nationality: this.props.nationality
          }
        }
      case CATEGORIES:
        return {
          key: this.props.id,
          title: this.props.name,
          description: this.props.description,
          image: checkForImage(this.props.avatar, CATEGORIES),
          details: {
            isPublic: this.props.isPublic,
            isVisible: this.props.isVisible,
            publishedAt: formatPublicationDate(this.props.publishedAt),
            items: 'TODO',
            artists: 'TODO'
          }
        }
      case CHANNELS:
        return {
          key: this.props.id,
          title: this.props.name,
          description: this.props.description,
          image: checkForImage(this.props.avatar, CHANNELS),
          details: {
            isPublic: this.props.isPublic,
            publishedAt: formatPublicationDate(this.props.publishedAt),
            featuredAt: formatPublicationDate(this.props.featuredAt),
            favorites: this.props.favoriteCount
          }
        }
      case PROVIDERS:
        return {
          key: this.props.id,
          title: this.props.name,
          description: this.props.description,
          image: checkForImage(this.props.image, PROVIDERS),
          details: {
            publishedAt: formatPublicationDate(this.props.publishedAt)
          }
        }
      case GROUPS:
        return {
          key: this.props.id,
          title: this.props.name,
          details: {
            isPublic: this.props.isPublic,
            publishedAt: formatPublicationDate(this.props.publishedAt)
          }
        }
      case EDITORIALS:
        return {
          key: this.props.id,
          title: this.props.name,
          subtitle: this.props.author.name,
          details: {
            isPublic: this.props.isPublic,
            publishedAt: formatPublicationDate(this.props.publishedAt),
            isFeatured: this.props.isFeatured,
            featuredAt: formatPublicationDate(this.props.featuredAt),
            id: `#${this.props.id}`
          }
        }
    }
  }

  render () {
    const { type } = this.props
    const { title, subtitle, description, image, details } = this.formatProps()

    return (
      <PaddedContainer padding='0px 0px 72px 0px' fullWidth>
        <FlexContainer justifyContent='space-between' alignItems='flex-start'>
          {
            image && type !== ITEMS &&
            <MarginContainer margin='0px 20px 0px 0px'>
              <Thumbnail image={image} size={174} />
            </MarginContainer>
          }
          <FlexContainer justifyContent='space-between' alignItems='flex-start' fullWidth>
            <InfoContainer>
              <Title>{title}</Title>
              {subtitle && <Subtitle>{subtitle}</Subtitle>}
              {
                description && (
                  <Description
                    dangerouslySetInnerHTML={{__html: marked(description)}}
                    />
                )
              }
              { type === ITEMS && <ItemImage src={image} /> }
            </InfoContainer>
            <ContentDetails details={details} />
          </FlexContainer>
        </FlexContainer>
        { type === EDITORIALS &&
          <ViewEditorialPreview content={this.props} />
      }
      </PaddedContainer>
    )
  }
}
