import { connect } from 'react-redux'
import { getCategories, clearCategories } from '../../actions/CategoryActions'
import { clearSearchAndFilter } from '../../actions/SearchAndFilterActions'
import Collection from '../ContentCollection'
import { CATEGORIES } from '../../constants/ContentConstants'

const mapStateToProps = ({ categories, searchAndFilter }) => {
  return {
    type: CATEGORIES,
    content: categories.categories.data,
    isLast: categories.categories.isLast,
    count: categories.categories.count,
    error: categories.error,
    searchQueryParams: searchAndFilter
  }
}

const mapDispatchToProps = dispatch => ({
  getContent: (pagination, searchAndFilter) => dispatch(getCategories({...pagination, ...searchAndFilter})),
  clearContent: () => dispatch(clearCategories()),
  clearSearchAndFilter: () => dispatch(clearSearchAndFilter())
})

export default connect(mapStateToProps, mapDispatchToProps)(Collection)
