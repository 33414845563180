import React from 'react'
import styled from 'styled-components'
import { Block } from '../../styles/Box'
import { SmallSpan } from '../../styles/Text'
import { media } from '../../styles/MediaQueries'
import RectImage from '../Image/RectImage'

const ImageContainer = styled(Block)`
  width: 100%;
  margin-bottom: 7rem;
`

const ImageDetail = styled.div`
  padding-top: 2rem;
  padding-bottom: 1.3rem;
  flex-direction: column;
  ${media.mobile`
    line-height: 2rem;
    padding: 2rem 0rem 1.4rem 0rem;
  `}
`

const RectImageContainer = styled.div`
  ${media.mobile`
    margin: 0 -2rem;
    width: calc(100% + 4rem);
  `}
`

export default function EditorialImage (props) {
  const { content } = props
  const caption = content.caption && content.caption.trim()
  const name = content.name && content.name.trim()
  let imageDetail
  if (caption || name) {
    imageDetail = (
      <Block fullWidth>
        <ImageDetail>
          <SmallSpan bold>{content.name}</SmallSpan>
          <SmallSpan>{content.caption}</SmallSpan>
        </ImageDetail>
        <hr />
      </Block>
    )
  }
  return (
    <ImageContainer>
      <RectImageContainer>
        <RectImage src={content.url} />
      </RectImageContainer>
      {imageDetail}
    </ImageContainer>
  )
}
