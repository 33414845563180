import * as constants from '../constants/PickerConstants'
import {
  CATEGORIES, CHANNELS, ITEMS, PLAYLISTS, USERS, PROVIDERS, ARTISTS, GROUPS,
  AUTHORS, EDITORIALS
} from '../constants/ContentConstants'
import { getUsers } from '../api/Auth'
import { getCategories, getRelatedCategories } from '../api/Categories'
import { getChannels, getRelatedChannels } from '../api/Channels'
import { getItems, getRelatedItems } from '../api/Items'
import { getPlaylists, getRelatedPlaylists } from '../api/Playlists'
import { getArtists, getRelatedArtists } from '../api/Artists'
import { getProviders } from '../api/Providers'
import { getGroups } from '../api/Groups'
import { getAuthors } from '../api/Authors'
import { getEditorials, getRelatedEditorials } from '../api/Editorials'

const PICKER_API_ALL_OBJECTS = {
  [USERS]: getUsers,
  [CATEGORIES]: getCategories,
  [CHANNELS]: getChannels,
  [ITEMS]: getItems,
  [PLAYLISTS]: getPlaylists,
  [ARTISTS]: getArtists,
  [PROVIDERS]: getProviders,
  [GROUPS]: getGroups,
  [AUTHORS]: getAuthors,
  [EDITORIALS]: getEditorials
}

const PICKER_API_RELATED_OBJECTS = {
  [CATEGORIES]: getRelatedCategories,
  [CHANNELS]: getRelatedChannels,
  [ITEMS]: getRelatedItems,
  [PLAYLISTS]: getRelatedPlaylists,
  [ARTISTS]: getRelatedArtists,
  [EDITORIALS]: getRelatedEditorials
}

export const getPickerContent = (params) => dispatch => {
  // if the relatedContentType is ITEMS, we are choosing a cover item or
  // avatar/hero so we need to fetch the specific type's items
  if (params.getAll) {
    const relatedContentType = params.relatedContentType
    const getContent = PICKER_API_ALL_OBJECTS[relatedContentType]
    return getContent(params).then(content => dispatch({
      type: constants.RECEIVE_PICKER_CONTENT,
      content,
      pageNumber: params.page
    }))
  } else {
    const parentType = params.type
    const relatedContentType = params.relatedContentType
    const parentId = params.id
    const getContent = PICKER_API_RELATED_OBJECTS[relatedContentType]
    return getContent(parentType, parentId, params).then(content => dispatch({
      type: constants.RECEIVE_PICKER_CONTENT,
      content,
      pageNumber: params.page
    }))
  }
}

export const clearPickerContent = () => dispatch => dispatch({
  type: constants.CLEAR_PICKER_CONTENT
})
