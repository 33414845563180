import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import styled from 'styled-components'
import { MarginFlexContainer } from '../StyledEditComponents'
import { Modal, ModalHeader, ModalBody, ModalFooter, Alert } from 'reactstrap'
import { RedButton, GrayButton, SquareImg, FlexContainer } from '../StyledComponents'
import { checkIfImageExists } from '../../utils/ImageUtils'
import { receiveSuccessMessage } from '../../actions/MessageActions'
import { formatStringType } from '../../utils/General'

const ButtonContainer = styled(ModalFooter)`
  justify-content: flex-start;
`

const StyledModal = styled(Modal)`
  margin: 10% auto;
`

const StyledModalHeader = styled(ModalHeader)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const StyledAlert = styled(Alert)`
  background-color: ${props => props.theme.red};
  color: white;
  position: absolute;
  border-radius: 0;
  z-index: 3;
  width: 100%;
  text-align: center;
  padding: 0.5rem;
  border: 0;
`

const StyledModalBody = styled(ModalBody)`
  max-height: 500px;
  overflow-y: scroll;
`

const Icon = styled.i`
  color: ${props => props.error ? props.theme.red : props.theme.green};
`

function DeleteModalElement (props) {
  const { element, isDeleting, errors, contentList, type } = props
  const image = element.imageThumbOptimized || element.imageThumb || element.image
  let successIcon
  if (isDeleting && errors) {
    // If the item in the original selected list (set in state) is found in the
    // selected list, that means it wasn't deleted
    const foundIdx = contentList.findIndex(content => {
      return content.id === element.id
    })

    if (foundIdx === -1) {
      successIcon = <Icon className='material-icons' error={false}>check</Icon>
    } else {
      successIcon = <Icon className='material-icons' error>close</Icon>
    }
  }

  return (
    <MarginFlexContainer>
      <FlexContainer justifyContent='space-between' fullWidth>
        <FlexContainer>
          <SquareImg image={checkIfImageExists(image, type)} />
          <span className='content-title'>{element.name}</span>
        </FlexContainer>
        {successIcon}
      </FlexContainer>
    </MarginFlexContainer>
  )
}

const mapDispatchToProps = (dispatch) => ({
  renderMessage: (message) => dispatch(receiveSuccessMessage(message))
})

class DeleteModal extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      errors: '',
      selectedList: [],
      isDeleting: false
    }

    this.destroyObjects = this.destroyObjects.bind(this)
    this.toggleDeleteModal = this.toggleDeleteModal.bind(this)
  }

  componentDidMount () {
    this.setState({ selectedList: this.props.contentList })
  }

  componentWillReceiveProps (nextProps) {
    // Since we're now reading from the state, if we are just deleting a single
    // object, the data might not have been fetched yet so we need to set
    // the state again. We don't want to set the state for batch deleting
    // until the user hits Cancel
    if (this.state.selectedList.length === 1 &&
        this.state.selectedList.length === nextProps.contentList.length) {
      this.setState({ selectedList: nextProps.contentList })
    }
  }

  destroyObjects () {
    this.setState({ isDeleting: true }, () => {
      this.props.destroyObjects()
        .then(() => this.props.history.push(`/${this.props.type}`))
        .catch(err => {
          this.setState({ errors: err.errors || err.detail })
        })
    })
  }

  toggleDeleteModal () {
    // Once the user hits cancel, set the modal state list to the updated list.
    this.setState({
      errors: '', selectedList: this.props.contentList
    }, this.props.toggleDeleteModal)

    // renders another toast to show how many items were successfully deleted
    if (this.state.errors) {
      const numOfDeleted = (
        this.state.selectedList.length - this.props.contentList.length
      )
      const type = formatStringType(this.props.type, this.state.selectedList.length)
      this.props.renderMessage(
        `${numOfDeleted} of ${this.state.selectedList.length} ${type} deleted`
      )
    }
  }

  render () {
    let contentType = this.props.type.toLowerCase()
    contentType = formatStringType(contentType, this.state.selectedList.length)

    const content = this.state.selectedList.map((selected, idx) => {
      return (
        <DeleteModalElement
          {...this.state}
          key={idx}
          type={this.props.type}
          element={selected}
          contentList={this.props.contentList} />
      )
    })

    let errors
    if (this.state.errors) {
      errors = <StyledAlert>{this.state.errors}</StyledAlert>
    } else {
      errors = null
    }

    return (
      <StyledModal isOpen={this.props.isModalOpen}
        toggle={this.props.toggleDeleteModal}>
        {errors}
        <StyledModalHeader toggle={this.props.toggleDeleteModal}>
          Delete items
        </StyledModalHeader>
        <StyledModalBody>
          <span>
            {
              `Are you sure you want to delete ${this.state.selectedList.length}
              ${contentType}?`
            }
          </span>
          {content}
        </StyledModalBody>
        <ButtonContainer>
          <RedButton small='true' onClick={this.destroyObjects}>Delete</RedButton>{' '}
          <GrayButton onClick={this.toggleDeleteModal} small='true'>
            Cancel
          </GrayButton>
        </ButtonContainer>
      </StyledModal>
    )
  }
}

export default withRouter(connect(null, mapDispatchToProps)(DeleteModal))
