import styled from 'styled-components'
import { media } from './MediaQueries'

export const LargeBody = styled.p`
  font-size: 2.1rem;

  ${media.mobile`
    font-size: 1.9rem;
  `}
`

export const FinePrint = styled.p`
  font-size: 1.2rem;

  ${media.mobile`
    font-size: 1.3rem;
  `}
`

export const h7 = styled.h6`
  font-size: 1.2rem;
  line-height: 1.4em;

  ${media.mobile`
    font-size: 1.3rem;
  `}
`

export const SmallUpperCaseSpan = styled.span`
  text-transform: uppercase;
  font-size: 1.2rem;
  letter-spacing: 0.1rem;
  line-height: 1.4em;
  font-weight: bold;
`

export const SmallSpan = styled.span`
  font-size: 1.2rem;
  line-height: 1.5em;
  font-weight: ${props => props.bold ? 'bold' : 'normal'};
`

export const MediumSpan = styled.span`
  font-size: 1.6rem;
  line-height: 1.5em;
  font-weight: ${props => props.bold ? 'bold' : 'normal'};
  color: ${props => props.color ? props.theme.colors[props.color] : 'auto'};
`

export const LargeSpan = styled.span`
  font-size: 2.1rem;
  line-height: 1.5em;
  font-weight: ${props => props.bold ? 'bold' : 'normal'};
  text-decoration: ${props => props.textDecoration ? props.textDecoration : 'none'};

  ${media.mobile`
    font-size: 1.9rem;
  `}
`

export const MediumSpanEllipsis = styled(MediumSpan)`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`

export const H5Ellipsis = styled.h5`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;

  ${media.mobile`
    font-size: 1.6rem;
  `}
`

export const H4Ellipsis = styled.h4`
  font-weight: ${props => props.bold ? 'bold' : 'normal'};
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  margin-bottom: ${props => props.noMargin ? '0rem' : 'unset'};
`
