import React from 'react'
import merge from 'lodash/merge'
import { formatStringType } from '../../utils/General'
import { toBackend } from '../../constants/ContentConstants'

export default class AbstractEditForm extends React.Component {
  constructor (props) {
    super(props)

    this.setFieldErrors = this.setFieldErrors.bind(this)
    this.handleRadioChange = this.handleRadioChange.bind(this)
    this.handleDateTimeChange = this.handleDateTimeChange.bind(this)
    this.handleTextChange = this.handleTextChange.bind(this)
    this.changeSelectedContent = this.changeSelectedContent.bind(this)
    this.changeUser = this.changeUser.bind(this)
    this.addRelatedContent = this.addRelatedContent.bind(this)
    this.removeRelatedContent = this.removeRelatedContent.bind(this)
    this.toggleRelationship = this.toggleRelationship.bind(this)
    this.destroyObject = this.destroyObject.bind(this)
    this.handleTagsChange = this.handleTagsChange.bind(this)
    this.handleFileUpload = this.handleFileUpload.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleSubmitForReview = this.handleSubmitForReview.bind(this)
    this.handleApproveReview = this.handleApproveReview.bind(this)
    this.removeReview = this.removeReview.bind(this)
    this.handleGeorestrictionChange = this.handleGeorestrictionChange.bind(this)
  }

  componentWillReceiveProps (nextProps) {
    this.setFieldErrors(nextProps)
  }

  setFieldErrors (data) {
    const errors = {}
    if (data.error) {
      Object.keys(data.error).forEach(errorKey => {
        errors[`${errorKey}Error`] = data.error[errorKey]
      })
      this.setState(errors)
    }
  }

  handleSubmit () {
    let objectData
    if (this.massageObject) {
      objectData = this.massageObject()
    } else {
      objectData = this.state.object
    }

    if (this.state.object.id) {
      return this.props.updateObject(objectData.id, objectData)
        .then(this.props.renderMessage(`Editing ${formatStringType(this.props.type)}`))
        .then(action => { if (!action.error) this.props.history.goBack() })
    } else {
      return this.props.createObject(objectData)
        .then(this.props.renderMessage(`Creating ${formatStringType(this.props.type)}`))
        .then(action => {
          if (!action.error) {
            this.props.history.push(`/${this.props.type}/${this.props.object.id}`)
          }
        })
    }
  }

  handleSubmitForReview (contentType, reviewerId) {
    const backendType = toBackend(contentType)
    const reviewData = {
      reviewer: reviewerId,
      reviewee: this.props.user.id,
      contentType: backendType,
      objectId: this.state.object.id
    }

    this.props.createReview(reviewData)
      .then(action => {
        if (!action.error) this.props.history.goBack()
      })
  }

  handleRadioChange (e, saveToObject = true) {
    const value = e.target.type === 'checkbox'
      ? e.target.checked === true
      : e.target.value === 'true'
    if (!saveToObject) {
      this.setState({ [e.target.name]: value })
    } else {
      this.setState({
        object: merge(this.state.object, { [e.target.name]: value })
      })
    }
  }

  handleDateTimeChange (date, field) {
    let data
    if (!date) {
      data = { [field]: null }
    } else {
      data = { [field]: date }
    }
    this.setState({ object: merge(this.state.object, data) })
  }

  handleTextChange (e) {
    this.setState({
      object: merge(this.state.object, {
        [e.target.name]: e.target.value
      }),
      [`${e.target.name}Error`]: null
    })
  }

  handleTagsChange (tags) {
    this.setState({
      object: Object.assign(this.state.object, { tags })
    })
  }

  handleGeorestrictionChange (availableCountries) {
    this.setState({
      object: Object.assign(this.state.object, { availableCountries })
    })
  }

  handleFileUpload (e) {
    const name = e.target.name
    const file = e.target.files[0]
    const reader = new FileReader()
    reader.onload = (e) => {
      this.setState({
        object: merge(this.state.object, { [name]: file }),
        [name]: e.target.result,
        [`${name}Error`]: null
      })
    }

    reader.readAsDataURL(file)
  }

  handleApproveReview () {
    this.props.history.push(`/${this.props.type}/${this.state.object.id}`)
  }

  changeSelectedContent (selectedContent, contentType, name) {
    this.setState({
      object: merge(this.state.object, { [name]: selectedContent.id }),
      [name]: selectedContent,
      [`${name}Error`]: null
    })
  }

  changeProvider (provider) {
    this.setState({
      object: merge(this.state.object, { [provider]: provider.id }),
      provider
    })
  }

  changeUser (user) {
    this.setState({
      object: merge(this.state.object, { user: user.id }),
      user,
      userError: null
    })
  }

  changeAuthor (author) {
    this.setState({
      object: merge(this.state.object, { author: author.id }),
      author,
      authorError: null
    })
  }

  addRelatedContent (content, contentType) {
    // this is for submitting the list of galleries in the PUT request
    const convertedContentType = contentType === 'playlists'
                               ? 'galleries'
                               : contentType
    const newState = Object.assign({}, this.state)
    const relatedContent = newState.object[convertedContentType]
    newState.object[convertedContentType] = relatedContent.concat([content.id])
    newState[contentType] = newState[contentType].concat([content])
    newState[`${contentType}Error`] = null
    this.setState(newState)
  }

  removeRelatedContent (content, contentType) {
    const convertedContentType = contentType === 'playlists'
                               ? 'galleries'
                               : contentType
    const newState = Object.assign({}, this.state)
    const newObject = Object.assign({}, this.state.object, {[convertedContentType]: [...this.state.object[convertedContentType]]})

    newObject[convertedContentType] = newObject[convertedContentType].filter(itemId => {
      return itemId !== content.id
    })

    newState[convertedContentType] = newState[contentType].filter(item => {
      return item.id !== content.id
    })

    this.setState(Object.assign({}, newState, {object: newObject}))
  }

  removeReview (reviewId) {
    const newReviews = this.state.object.pendingReviews.filter(review => {
      return review.id !== reviewId
    })

    this.setState({ object: Object.assign(
      {}, this.state.object, { pendingReviews: newReviews })
    })
  }

  toggleRelationship (content, contentType) {
    if (this.state.object[contentType].includes(content.id)) {
      this.removeRelatedContent(content, contentType)
    } else {
      this.addRelatedContent(content, contentType)
    }
  }

  destroyObject () {
    if (this.state.object.id) {
      return this.props.destroyObject(this.state.object.id)
    } else {
      return this.props.destroyObjects(this.props.selected)
    }
  }

  // On handleSubmit, this method is called to massage data properly to the backend.
  massagePremiumData () {
    let updatedData
    if (!this.state.isScheduled && !this.state.object.isPremium) {
      updatedData = { premiumDate: null }
    }

    return Object.assign({}, this.state.object, updatedData)
  }

  massageGeorestrictionData (object) {
    const countries = object.availableCountries && object.availableCountries.length
      ? object.availableCountries
      : null
    const availableCountries = this.state.isGeorestricted ? countries : null

    return Object.assign({}, object, { availableCountries })
  }
}
