import React from 'react'
import { connect } from 'react-redux'
import { CATEGORIES } from '../../constants/ContentConstants'
import { Modal, ModalHeader, ModalBody } from 'reactstrap'
import styled from 'styled-components'
import SuperPicker from './SuperPicker'
import RelatedContentList from './RelatedContentList'
import { getGroups } from '../../actions/GroupActions'
import { getCategories } from '../../actions/CategoryActions'
import CategorySelector from './CategorySelector'
import Loader from '../Loader'

const StyledModal = styled(Modal)`
  max-width: calc(100vw - 300px);
  margin: 5% auto;
`

const mapStateToProps = ({ groups, categories }) => ({
  groups: groups.groups,
  categories: categories.categories
})

const mapDispatchToProps = dispatch => ({
  getGroups: (params) => dispatch(getGroups(params)),
  getCategories: (params) => dispatch(getCategories(params))
})

class CategoryModal extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      groups: [],
      isLoading: false
    }

    this.groupCategories = this.groupCategories.bind(this)
  }

  componentDidMount () {
    this.setState({ isLoading: true })

    // Avoid fetching categories and groups again
    if (this.props.categories.isLast && this.props.groups.isLast) {
      this.groupCategories()
    } else {
      this.props.getGroups({ count: 1000, page: 1, newQuery: true })
        .then(() => this.props.getCategories({ count: 10000, page: 1, newQuery: true }))
        .then(this.groupCategories)
    }
  }

  groupCategories () {
    let seenCategoryIds = []
    const groups = []
    // Organize and sort categories by alphabetical order in groups
    this.props.groups.data.forEach(group => {
      seenCategoryIds = seenCategoryIds.concat(group.categories)
      const categories = group.categories.map(categoryId => {
        return this.props.categories.data.find(category => category.id === categoryId)
      }).sort((cat1, cat2) => cat1.name < cat2.name ? -1 : 1)
      groups.push(Object.assign({}, group, { categories }))
    })
    // Create 'Other' group for any categories that are not in any groups
    const otherCategories = this.props.categories.data.filter(category => {
      return !seenCategoryIds.includes(category.id)
    }).sort((cat1, cat2) => cat1.name < cat2.name ? -1 : 1)
    groups.push({ name: 'Other', categories: otherCategories })
    this.setState({ groups, isLoading: false })
  }

  render () {
    return (
      <StyledModal isOpen={this.props.isModalOpen} toggle={this.props.toggleModal}>
        <ModalHeader toggle={this.props.toggleModal}>
          Select categories
        </ModalHeader>
        <ModalBody>
          {this.state.isLoading
            ? <Loader />
            : <CategorySelector
              groups={this.state.groups}
              selectedCategories={this.props.selectedCategories}
              createRelationship={this.props.createRelationship} />
          }
        </ModalBody>
      </StyledModal>
    )
  }
}

const CategoryPickerModal = connect(mapStateToProps, mapDispatchToProps)(CategoryModal)

export default function CategoryPicker (props) {
  return (
    <SuperPicker
      {...props}
      render={(ctx) => (
        <RelatedContentList
          relatedContentType={CATEGORIES}
          content={ctx.props.selectedCategories}
          removeRelationship={ctx.props.removeRelationship}
          toggleModal={ctx.toggleModal} />
      )}>
      <CategoryPickerModal {...props} />
    </SuperPicker>
  )
}
