import omit from 'lodash/omit'
import objectToFormData from 'object-to-formdata'
import * as constants from '../constants/ItemConstants'
import * as selectedContentConstants from '../constants/SelectedContentConstants'
import { ITEMS } from '../constants/ContentConstants'
import * as API from '../api/Items'
import { receiveSuccessMessage, receiveErrorMessage } from './MessageActions'
import { RECEIVE_RELATED_CONTENT } from '../constants/RelatedContentConstants'
import { getRelatedContent as getRelatedContentAction } from './RelatedContentActions'
import { getRelatedContent } from '../api/General'
import { stripNullFromObject } from '../utils/General'

const processData = (data) => {
  if (data.image instanceof File) {
    data = stripNullFromObject(data)
    const fileType = data.image.type.split('/')[0]
    // send as a video if it's a video or image if it's an image
    if (fileType === 'video') {
      data.video = data.image
      data = omit(data, ['image'])
    } else if (fileType === 'image') {
      data = omit(data, ['video'])
    }
    data = objectToFormData(data)
  } else {
    data = omit(data, ['image', 'video'])
  }

  return data
}

export const getItems = (queryParams) => dispatch => {
  return API.getItems(queryParams).then(items => dispatch({
    type: constants.RECEIVE_ITEMS, items, newQuery: queryParams.newQuery
  })).catch(error => dispatch({ type: constants.RECEIVE_ITEMS_ERROR, error }))
}

const getOnlyItem = (id) => dispatch => {
  return API.getItem(id)
    .then(({ data: item }) => dispatch({ type: constants.RECEIVE_ITEM, item }))
    .catch(error => dispatch({ type: constants.RECEIVE_ITEMS_ERROR, error }))
}

export const getItem = (id, childType, opts = { queryParams: null }) => dispatch => {
  if (!childType) return dispatch(getOnlyItem(id))

  if (!opts.queryParams.newQuery) {
    return dispatch(getRelatedContentAction(ITEMS, id, childType, opts.queryParams))
  } else {
    return Promise.all([
      API.getItem(id),
      getRelatedContent(ITEMS, id, childType, opts.queryParams)
    ]).then(values => {
      const item = values[0].data
      const relatedContent = values[1]
      dispatch({ type: constants.RECEIVE_ITEM, item })
      dispatch({
        type: RECEIVE_RELATED_CONTENT,
        content: relatedContent,
        contentType: childType,
        newQuery: opts.queryParams.newQuery
      })
    }).catch(error => dispatch({ type: constants.RECEIVE_ITEMS_ERROR, error }))
  }
}

export const updateItem = (itemId, data) => dispatch => {
  const updatedData = processData(data)

  return API.updateItem(itemId, updatedData)
    .then(({ data: item }) => {
      dispatch({ type: constants.RECEIVE_ITEM, item })
    })
    .then(() => dispatch(receiveSuccessMessage('Item updated')))
    .catch(error => {
      dispatch(receiveErrorMessage(error))
      return dispatch({ type: constants.RECEIVE_ITEMS_ERROR, error })
    })
}

export const createItem = (data) => dispatch => {
  const updatedData = processData(data)

  return API.createItem(updatedData).then(({ data: item }) => {
    dispatch({ type: constants.RECEIVE_ITEM, item })
  }).then(() => dispatch(receiveSuccessMessage('Item created')))
    .catch(error => {
      dispatch(receiveErrorMessage(error))
      return dispatch({ type: constants.RECEIVE_ITEMS_ERROR, error })
    })
}

export const deleteItem = (id) => dispatch => {
  return API.deleteItem(id)
    .then(() => dispatch(receiveSuccessMessage('Item deleted')))
    .catch(error => {
      dispatch({ type: constants.RECEIVE_ITEMS_ERROR, error })
      return Promise.reject(error)
    })
}

export const batchUpdateItems = (data) => dispatch => {
  return API.batchUpdateItems(data)
    .then(() => {
      dispatch(receiveSuccessMessage('Items updated'))
    })
    .catch(({ data }) => {
      dispatch(receiveErrorMessage(data.errors))
      dispatch({ type: constants.RECEIVE_ITEMS_ERROR, error: data.errors })
      return Promise.reject(data.errors)
    })
}

export const batchDeleteItems = (items) => dispatch => {
  const itemIds = items.map(item => item.id)
  return Promise.all(itemIds.map(itemId => {
    // The catch makes it possible for all promises to resolve
    return API.deleteItem(itemId)
              .catch(error => error)
  })).then((values) => {
    let error
    // make sure all items are removed before dispatching the error
    values.forEach(value => {
      if (Object.keys(value).length > 0) {
        error = value
      } else {
        const itemId = parseInt(value.url.split('/').slice(-1)[0])
        dispatch({
          type: selectedContentConstants.SELECT_CONTENT,
          selected: items.find(item => item.id === itemId),
          selectedType: ITEMS
        })
      }
    })

    if (error) {
      dispatch({ type: constants.RECEIVE_ITEMS_ERROR, error })
      return Promise.reject(error)
    }
  }).then(() => dispatch(receiveSuccessMessage('Items deleted')))
}

export const cropItem = (itemId, data) => dispatch => {
  return API.cropItem(itemId, data)
    .then(({ data: item }) => {
      dispatch({ type: constants.RECEIVE_ITEM, item })
    })
    .then(() => dispatch(receiveSuccessMessage('Item cropped')))
    .catch(error => {
      dispatch({ type: constants.RECEIVE_ITEMS_ERROR, error })
      return Promise.reject(error)
    })
}

export const clearItems = () => dispatch => dispatch({ type: constants.CLEAR_ITEMS })
