import React from 'react'
import { GrayButton } from '../StyledComponents'
import DeleteModal from './DeleteModal'

export default class DeleteButton extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      isDeleteModalOpen: false
    }

    this.toggleDeleteModal = this.toggleDeleteModal.bind(this)
  }

  toggleDeleteModal () {
    this.setState({ isDeleteModalOpen: !this.state.isDeleteModalOpen })
  }

  render () {
    const contentList = this.props.batchEdit
                      ? this.props.selected
                      : [{ name: this.props.name, image: this.props.deleteImage }]

    if (contentList.length === 1 && !contentList[0].name && !contentList[0].image) {
      return null
    }

    return (
      <React.Fragment>
        <GrayButton small='true' marginleft='10px' onClick={this.toggleDeleteModal}>
          Delete
        </GrayButton>
        <DeleteModal
          isModalOpen={this.state.isDeleteModalOpen}
          toggleDeleteModal={this.toggleDeleteModal}
          type={this.props.type}
          contentList={contentList}
          destroyObjects={this.props.destroyObjects} />
      </React.Fragment>
    )
  }
}
