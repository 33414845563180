import * as constants from '../constants/FeatureFlagConstants'

const _nullFlag = {
  version: "v2",
  error: null
}

export default function FeatureFlagReducer (state = _nullFlag, action) {
  switch (action.type) {
    case constants.RECEIVE_ACCOUNT_EXPERIENCE: {
      localStorage.setItem('ae', action.version)
      return Object.assign({}, state, {
        version: action.version
      })
    }
    case constants.RECEIVE_ACCOUNT_EXPERIENCE_ERROR: {
      localStorage.setItem('ae', "v2")
      return Object.assign({}, state, { error: action.error })
    }
    default: {
      return state
    }
  }
}
