import React, { Component } from 'react'
import { MarginContainer } from '../StyledComponents'
import { StyledDetailContainer } from '../StyledEditComponents'
import RadioButtons from './RadioButtons'
import { countriesByRegion } from '../../constants/GeorestrictionConstants'
import RegionSelector from './RegionSelector'

export default class GeorestrictionPicker extends Component {
  constructor (props) {
    super(props)

    this.state = {
      selectedRegion: countriesByRegion[0]
    }

    this.handleRegionChange = this.handleRegionChange.bind(this)
    this.handleSelectCountry = this.handleSelectCountry.bind(this)
    this.handleSelectAllCountries = this.handleSelectAllCountries.bind(this)
  }

  componentDidUpdate (prevProps, prevState) {
    const { isGeorestricted, object } = this.props
    if (isGeorestricted && !prevProps.isGeorestricted && object.availableCountries.length) {
      // if possible, show user a region where countries are selected
      const selectedRegion = countriesByRegion.find(region => {
        const codeMap = region.countries.map(country => country.code)
        return codeMap.includes(this.props.object.availableCountries[0])
      })

      this.setState({ selectedRegion })
    }
  }

  handleRegionChange (selectedRegion) {
    this.setState({ selectedRegion })
  }

  handleSelectCountry (e) {
    const { availableCountries } = this.props.object
    const countryCode = e.target.value
    let selectedCountries = availableCountries.slice()

    if (e.target.checked) {
      selectedCountries.push(countryCode)
    } else {
      const codeIdx = availableCountries.indexOf(countryCode)
      selectedCountries.splice(codeIdx, 1)
    }

    this.props.handleGeorestrictionChange(selectedCountries)
  }

  handleSelectAllCountries (e, region) {
    const regionCountryCodes = region.countries.map(country => country.code)
    let selectedCountries = this.props.object.availableCountries.slice()

    if (e.target.checked) {
      const countriesWithDups = selectedCountries.concat(regionCountryCodes)
      selectedCountries = [...new Set(countriesWithDups)]
    } else {
      selectedCountries = selectedCountries.filter(country => !regionCountryCodes.includes(country))
    }

    this.props.handleGeorestrictionChange(selectedCountries)
  }

  render () {
    const georestrictionParams = {
      name: 'isGeorestricted',
      value: this.props.isGeorestricted,
      label: 'Georestriction',
      options: [
        { value: false, description: 'Make this content available for users everywhere' },
        { value: true, description: 'Restrict access by country' }
      ]
    }

    return (
      <StyledDetailContainer>
        <MarginContainer margin='-30px 0 0 0'>
          <RadioButtons
            content={georestrictionParams}
            handleRadioChange={(e) => this.props.handleRadioChange(e, false)} />
        </MarginContainer>
        {this.props.isGeorestricted &&
        <RegionSelector
          handleSelectCountry={this.handleSelectCountry}
          handleSelectAllCountries={this.handleSelectAllCountries}
          selectedRegion={this.state.selectedRegion}
          selectedCountries={this.props.object.availableCountries || []}
          handleRegionChange={this.handleRegionChange} />}
      </StyledDetailContainer>
    )
  }
}
