import React from 'react'
import { connect } from 'react-redux'
import { updateCategory, deleteCategory, createCategory } from '../../actions/CategoryActions'
import { receiveSuccessMessage } from '../../actions/MessageActions'
import { createReview } from '../../actions/ReviewActions'
import { CATEGORIES, ITEMS } from '../../constants/ContentConstants'
import {
  StyledOuterContainer, StyledFlexColumnContainer, StyledStatusContainer,
  StyledDetailContainer, PaddingContainer, EditWrapPage
} from '../StyledEditComponents'
import EditHeader from '../edit/EditHeader'
import IsPublic from '../edit/IsPublic'
import TextField from '../edit/TextField'
import SuperPicker from '../edit/SuperPicker'
import ImagePicker from '../edit/ImagePicker'
import AbstractEditForm from '../edit/AbstractEditForm'
import CoverImageContainer from '../edit/CoverImageContainer'
import * as API from '../../api/Categories'
import InReviewBanner from '../reviews/InReviewBanner'

function mapStateToProps ({ categories, auth }) {
  return {
    type: CATEGORIES,
    object: categories.current,
    error: categories.error,
    user: auth.user
  }
}

function mapDispatchToProps (dispatch) {
  return {
    updateObject: (id, payload) => dispatch(updateCategory(id, payload)),
    createObject: (payload) => dispatch(createCategory(payload)),
    destroyObject: (id) => dispatch(deleteCategory(id)),
    createReview: (payload) => dispatch(createReview(payload)),
    renderMessage: (message) => dispatch(receiveSuccessMessage(message))
  }
}

class CategoryForm extends AbstractEditForm {
  constructor (props) {
    super(props)

    this.state = {
      object: {
        id: null,
        name: '',
        description: '',
        avatar: null,
        hero: null,
        isPublic: false,
        order: ''
      },
      avatar: {},
      hero: {}
    }
  }

  componentWillMount () {
    const id = parseInt(this.props.match.params.id)
    if (id) {
      API.getCategory(id).then(({ data: category }) => {
        this.setState({
          object: Object.assign({}, category, {
            avatar: category.avatar && category.avatar.id,
            hero: category.hero && category.hero.id,
            description: category.description || ''
          }),
          avatar: category.avatar || {},
          hero: category.hero || {}
        })
      })
    }
  }

  render () {
    return (
      <form>
        <EditHeader
          type={this.props.type}
          name={this.state.object.name}
          deleteImage={this.state.avatar.imageThumb}
          handleSave={this.handleSubmit}
          handleSubmitForReview={this.handleSubmitForReview}
          currentId={this.state.object.id}
          destroyObjects={this.destroyObject} />
        <EditWrapPage>
          <InReviewBanner
            type={this.props.type}
            content={this.state.object}
            onApprove={this.handleApproveReview}
            onCancel={this.removeReview} />
          <StyledOuterContainer>
            <StyledStatusContainer>
              <CoverImageContainer
                type={CATEGORIES}
                image={this.state.avatar.imageThumb} />
              <PaddingContainer>
                <h5>{this.state.object.name}</h5>
                <IsPublic isPublic={this.state.object.isPublic}
                  handleRadioChange={this.handleRadioChange} />
              </PaddingContainer>
            </StyledStatusContainer>
            <StyledFlexColumnContainer>
              <StyledDetailContainer>
                <TextField
                  id='name'
                  name='name'
                  type='text'
                  label='Title'
                  error={this.state.nameError}
                  value={this.state.object.name}
                  onChange={this.handleTextChange} />
                <TextField
                  id='description'
                  name='description'
                  type='textarea'
                  label='Description'
                  error={this.state.descriptionError}
                  value={this.state.object.description}
                  onChange={this.handleTextChange} />
                <TextField
                  id='order'
                  name='order'
                  type='text'
                  label='Order'
                  error={this.state.orderError}
                  value={this.state.object.order}
                  onChange={this.handleTextChange} />
                <SuperPicker
                  render={(ctx) => {
                    return (
                      <ImagePicker
                        error={this.state.avatarError}
                        image={this.state.avatar}
                        label='Avatar'
                        toggleModal={ctx.toggleModal} />
                    )
                  }}
                  relatedContentType={ITEMS}
                  name='avatar'
                  baseType={CATEGORIES}
                  currentId={this.state.object.id}
                  changeRelationship={this.changeSelectedContent} />
                <SuperPicker
                  render={(ctx) => {
                    return (
                      <ImagePicker
                        error={this.state.heroError}
                        image={this.state.hero}
                        label='Hero'
                        toggleModal={ctx.toggleModal} />
                    )
                  }}
                  relatedContentType={ITEMS}
                  name='hero'
                  baseType={CATEGORIES}
                  currentId={this.state.object.id}
                  changeRelationship={this.changeSelectedContent} />
              </StyledDetailContainer>
            </StyledFlexColumnContainer>
          </StyledOuterContainer>
        </EditWrapPage>
      </form>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CategoryForm)
