import React from 'react'
import RadioButtons from './RadioButtons'

export default function IsFeatured (props) {
  const isFeaturedParams = {
    name: 'isFeatured',
    value: props.isFeatured,
    label: 'Featured',
    options: [
      { value: true, badgeColor: 'red', badgeText: 'Featured', description: 'Featured items show in the home page hero' },
      { value: false, badgeColor: 'mediumGray', badgeText: 'Not featured', description: 'Items do not show in the home page hero' }
    ]
  }

  return (
    <RadioButtons
      content={isFeaturedParams}
      handleRadioChange={props.handleRadioChange} />
  )
}
