import React, { Component } from 'react'
import styled from 'styled-components'
import HistoryDetail from './HistoryDetail'
import { formatHistoryTime } from '../../utils/DateFormatter'
import { FlexContainer, Icon, FlexColumnContainer, Bold } from '../StyledComponents'

const StyledIcon = styled(Icon)`
  color: #007bff;
  border: 2px #f5f6f8;
  font-size: 15px;
  z-index: 2;
  position: absolute;
  margin-top: 14px;
  margin-left: -7px;
`

const Container = styled(FlexColumnContainer)`
  line-height: normal;
  margin: 0px 0px 30px 23px;
  padding: 11px;
  cursor: pointer;
  background-color: ${props => props.backgroundColor
    ? props.backgroundColor : props.theme.lighterGray};
`

const EventUser = styled.div`
  width: 91px;
`

const EventTime = styled.div`
  font-size: 14px;
  text-align: right;
  color: ${props => props.theme.mediumGray};
  padding-right: 31px;
`

const HiddenBorderContainer = styled.div`
  outline: 2px solid ${props => props.outline ? props.theme.lighterGray : `none`};
  outline-offset: 0px;
`

const DrawBorderLine = styled.div`
  width: 1px;
  background-color: ${props => props.theme.lightGray};
  min-height: 20px;
  position: absolute;
  margin-left: -1px;
  margin-top: -1px;
`

export default class HistoryEvent extends Component {
  constructor (props) {
    super(props)

    this.state = {
      showDetail: false
    }

    this.toggleDetail = this.toggleDetail.bind(this)
  }

  toggleDetail () {
    this.setState({ showDetail: !this.state.showDetail })
  }

  render () {
    const { event } = this.props
    const user = event.user ? event.user.username : 'Curator'

    let eventDetail, backgroundColor
    if (this.state.showDetail && event.diff) {
      eventDetail = <HistoryDetail diff={event.diff} />
      backgroundColor = 'white'
    }

    // hide border below and draw in line above last StyledIcon if event is last in table
    // do not draw line if event is only and last in table
    const drawOutline = event.isLast || event.isOnly
    const drawBorderLine = event.isLast && !event.isOnly ? <DrawBorderLine /> : null

    return (
      <HiddenBorderContainer outline={drawOutline}>
        {drawBorderLine}
        <StyledIcon className='material-icons'>
          brightness_1
        </StyledIcon>
        <Container backgroundColor={backgroundColor}>
          <FlexContainer justifyContent='space-between' onClick={this.toggleDetail}>
            <FlexContainer>
              <EventUser><Bold>{user}</Bold></EventUser>
              {event.summary}
            </FlexContainer>
            <EventTime>{formatHistoryTime(event.date)}</EventTime>
          </FlexContainer>
          {eventDetail}
        </Container>
      </HiddenBorderContainer>
    )
  }
}
