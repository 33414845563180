import * as constants from '../constants/ProviderConstants'
import { PROVIDERS } from '../constants/ContentConstants'
import { DELETE_REVIEW, RECEIVE_REVIEW } from '../constants/ReviewConstants'

const _nullProviders = {
  error: null,
  providers: {
    data: [],
    isLast: false,
    count: null
  },
  current: {
    pendingReviews: []
  }
}

export default function ProviderReducer (state = _nullProviders, action) {
  switch (action.type) {
    case constants.RECEIVE_PROVIDERS: {
      const { isLast, data, count } = action.providers
      const { newQuery } = action
      const newProviders = {
        isLast: isLast,
        data: newQuery ? data : [].concat(state.providers.data, data),
        count
      }
      return Object.assign({}, state, { providers: newProviders })
    }
    case constants.RECEIVE_PROVIDER: {
      return Object.assign({}, state, {
        current: Object.assign({}, state.current, action.provider)
      })
    }
    case DELETE_REVIEW: {
      if (!action.contentType === PROVIDERS) return state

      const newPendingReviews = Object.assign({}, {
        pendingReviews: state.current.pendingReviews
          .filter(review => review.id !== action.reviewId)
      })

      return Object.assign({}, state, {
        current: Object.assign({}, state.current, newPendingReviews)
      })
    }
    case RECEIVE_REVIEW: {
      if (!action.contentType === PROVIDERS) return state

      return Object.assign({}, state, {
        current: Object.assign({}, state.current, action.review.contentObject)
      })
    }
    case constants.RECEIVE_PROVIDERS_ERROR: {
      return Object.assign({}, state, { error: action.error })
    }
    case constants.CLEAR_PROVIDERS: {
      return Object.assign({}, _nullProviders)
    }
    default: {
      return state
    }
  }
}
