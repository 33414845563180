import React from 'react'
import styled from 'styled-components'
import { FlexContainer, FlexColumnContainer, StyledInput } from '../StyledComponents'
import { countriesByRegion } from '../../constants/GeorestrictionConstants'

const RegionLabel = styled.span`
  padding: 10px;
  background-color: ${props => props.selected ? props.theme.lighterMediumGray : 'unset'};
  cursor: pointer;
`

const CountriesContainer = styled.div`
  width: 100%;
  -moz-column-count: 2;
  -webkit-column-count: 2;
  column-count: 2;
  -moz-column-gap: 20px;
  -webkit-column-gap: 20px;
  column-gap: 20px;
`

const CountryContainer = styled(FlexContainer)`
  margin-bottom: 6px;

  & > label {
    cursor: pointer;
    margin-bottom: 0px;
  }
`

const RegionsContainer = styled(FlexColumnContainer)`
  margin-right: 52px;
  width: 40%;
`

export default function RegionSelector (props) {
  const allCountriesSelected = props.selectedRegion.countries.filter(country =>
    props.selectedCountries.includes(country.code)).length === props.selectedRegion.countries.length

  const regionCountryCount = {}
  countriesByRegion.forEach(region => {
    region.countries.forEach(country => {
      if (props.selectedCountries.includes(country.code)) {
        if (!regionCountryCount[region.name]) {
          regionCountryCount[region.name] = 1
        } else {
          regionCountryCount[region.name] += 1
        }
      }
    })
  })

  return (
    <FlexContainer alignItems='flex-start'>
      <RegionsContainer>
        <h6>Regions</h6>
        {countriesByRegion.map(region => (
          <RegionLabel
            onClick={() => props.handleRegionChange(region)}
            key={region.name}
            value={region.name}
            selected={region === props.selectedRegion}>
            {region.name} {regionCountryCount[region.name] && `(${regionCountryCount[region.name]})`}
          </RegionLabel>
        ))}
      </RegionsContainer>
      <CountriesContainer>
        <CountryContainer alignItems='flex-start'>
          <StyledInput
            type='checkbox'
            id={props.selectedRegion.name}
            checked={allCountriesSelected}
            onChange={(e) => props.handleSelectAllCountries(e, props.selectedRegion)}
            name={`All of ${props.selectedRegion.name}`} />
          <label htmlFor={props.selectedRegion.name}>
            All of {props.selectedRegion.name}
          </label>
        </CountryContainer>
        {props.selectedRegion.countries.map(country => (
          <CountryContainer key={country.name} alignItems='flex-start'>
            <StyledInput
              type='checkbox'
              value={country.code}
              id={country.code}
              checked={props.selectedCountries.includes(country.code)}
              onChange={props.handleSelectCountry}
              name={country.name} />
            <label htmlFor={country.code}>{country.name}</label>
          </CountryContainer>
        ))}
      </CountriesContainer>
    </FlexContainer>
  )
}
