import React from 'react'
import styled from 'styled-components'
import HistoryDate from './HistoryDate'
import { getDate } from '../../utils/DateFormatter'

const Container = styled.div`
  border-left: 1px solid ${props => props.theme.lightGray};
`

// hide border above first StyledIcon
const HideBorder = styled.div`
  position: absolute;
  min-height: 64px;
  min-width: 1px;
  background-color: ${props => props.theme.lighterGray};
`

export default function HistoryTable (props) {
  const { events, preview } = props
  const historyArray = preview ? events.slice(0, 3) : events

  // group events by date, assign 'isOnly' and 'isLast' keys for styling
  const dateMap = {}
  historyArray.map(event => {
    if (event === historyArray[historyArray.length - 1]) {
      event.isLast = true
    }
    if (historyArray.length === 1) {
      event.isOnly = true
    }
    const eventDate = getDate(event.date)
    if (!dateMap[eventDate]) {
      dateMap[eventDate] = [event]
    } else {
      dateMap[eventDate].push(event)
    }
  })

  return (
    <div>
      <HideBorder />
      <Container>
        {Object.keys(dateMap).map(date => (
          <HistoryDate
            events={dateMap[date]}
            date={date}
            key={date} />
        )
      )}
      </Container>
    </div>
  )
}
