import { connect } from 'react-redux'
import { getPlaylists, clearPlaylists } from '../../actions/PlaylistActions'
import { clearSearchAndFilter } from '../../actions/SearchAndFilterActions'
import Collection from '../ContentCollection'
import { PLAYLISTS } from '../../constants/ContentConstants'

const mapStateToProps = ({ playlists, searchAndFilter }) => {
  return {
    type: PLAYLISTS,
    content: playlists.playlists.data,
    isLast: playlists.playlists.isLast,
    count: playlists.playlists.count,
    error: playlists.error,
    searchQueryParams: searchAndFilter
  }
}

const mapDispatchToProps = dispatch => ({
  getContent: (pagination, searchAndFilter) => dispatch(getPlaylists({...pagination, ...searchAndFilter})),
  clearContent: () => dispatch(clearPlaylists()),
  clearSearchAndFilter: () => dispatch(clearSearchAndFilter())
})

export default connect(mapStateToProps, mapDispatchToProps)(Collection)
