import React, { Fragment } from 'react'
import { singular } from 'pluralize'
import { Bold, MarginContainer, PurpleBadge } from '../StyledComponents'
import { StyledDetailContainer } from '../StyledEditComponents'
import { PLAYLISTS } from '../../constants/ContentConstants'
import RadioButtons from './RadioButtons'
import DatePicker from './DatePicker'
import { getDate } from '../../utils/DateFormatter'

const PremiumDate = class Component extends DatePicker {}
PremiumDate.defaultProps = {
  field: 'premiumDate'
}

function SchedulerSection (props) {
  const warning = props.type === PLAYLISTS
    ? `. All items in this playlist will also be made premium and removed from all Canvases
      and non-premium playlists`
    : ' and playlists'

  const warningText = `Once this date has been saved, it can be altered but not
    removed. After the date has passed, this ${singular(props.type)}
    will leave the Membership, enter the Marketplace, and be removed from all Canvases${warning}.
    At that time, it will no longer be possible to return this ${singular(props.type)}
    to the membership.`

  return (
    <Fragment>
      <p><Bold>Scheduled to become premium</Bold></p>
      <p>{warningText}</p>
      <PremiumDate
        currentDateTime={props.currentDateTime}
        handleChange={props.handleChange} />
    </Fragment>
  )
}

function PremiumDataSection (props) {
  return (
    <div>
      <p>This {singular(props.type)} is Premium and can no longer be returned to the Membership.</p>
      {props.premiumDate &&
      <p><Bold>Became premium on: </Bold>{getDate(props.premiumDate)}</p>}
    </div>
  )
}

function PremiumSelector (props) {
  const scheduledParams = {
    name: 'isScheduled',
    value: props.isScheduled,
    label: 'Collection',
    options: [
      { value: false, description: 'Membership' },
      { value: true, description: 'Premium' }
    ]
  }

  return (
    <MarginContainer margin='-30px 0 -10px 0'>
      <RadioButtons
        content={scheduledParams}
        handleRadioChange={(e) => props.handleRadioChange(e, false)} />
    </MarginContainer>
  )
}

export default class IsPremium extends React.Component {
  render () {
    if (this.props.object.isSampler) return null

    let badgeText
    if (this.props.object.isPremium) badgeText = 'Premium'
    else if (this.props.object.premiumDate) badgeText = 'Scheduled'

    let collectionIndicator = this.props.premiumDate || this.props.object.isPremium
      ? (
        <MarginContainer margin='0 0 10px 0'>
          <PurpleBadge>{badgeText}</PurpleBadge>
        </MarginContainer>
      ) : (
        <PremiumSelector
          isScheduled={this.props.isScheduled}
          handleRadioChange={this.props.handleRadioChange} />
      )

    let premiumDateDetail = this.props.object.isPremium
    ? <PremiumDataSection premiumDate={this.props.object.premiumDate} type={this.props.type} />
    : (
      <SchedulerSection
        type={this.props.type}
        currentDateTime={this.props.object.premiumDate}
        handleChange={this.props.handleDateTimeChange} />
    )

    return (
      <StyledDetailContainer>
        {collectionIndicator}
        <div>
          {this.props.isScheduled || this.props.object.isPremium
            ? premiumDateDetail
            : <p>This content will be available to everyone who has an active Meural Membership.</p>
          }
        </div>
      </StyledDetailContainer>
    )
  }
}
