import fetchWrapper from '../utils/FetchWrapper'

export function getUser () {
  return fetchWrapper(`curator`, `/user`)
}

export function authenticate (username, password) {
  return fetchWrapper(`curator`, `/authenticate`, {
    body: { username, password },
    method: 'POST'
  })
}

export function getUsers (params) {
  return fetchWrapper(`curator`, `/users?count=${params.count}&page=${params.page}`)
}
