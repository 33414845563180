import React, { Component } from 'react'
import styled from 'styled-components'
import ComposerAddButtons from './ComposerAddButtons'

const EditorContainer = styled.div`
  width: 50%;
  height: calc(100vh - 63px);
  overflow-y: scroll;
  border-right: 0.1rem solid ${props => props.theme.lightGray};
  padding: 52px 109px;
  background-color: ${props => props.theme.white};
`

const Textarea = styled.textarea`
  border: none;
  resize: none;
  outline: none;
  display: block;
  width: 100%;
  height: 100%;
  font-size: 21px;
`

export default class ComposerEditor extends Component {
  constructor (props) {
    super(props)

    this.state = {
      inputNode: false
    }

    this.setInputNode = this.setInputNode.bind(this)
  }

  componentDidUpdate (prevProps, prevState) {
    if (this.state.inputNode && prevState.inputNode !== this.state.inputNode) {
      this.state.inputNode && this.state.inputNode.focus()
    }
  }

  setInputNode (inputNode) {
    this.setState({ inputNode })
    this.props.innerRef(inputNode)
  }

  render () {
    return (
      <EditorContainer>
        <Textarea
          innerRef={this.setInputNode}
          value={this.props.textBuffer}
          onChange={(e) => this.props.handleBodyChange(e.target.value)} />
        <ComposerAddButtons
          inputNode={this.state.inputNode}
          handleAddItem={this.props.handleAddItem}
          handleAddFile={this.props.handleAddFile}
          handleUpload={this.props.handleUpload}
          contentObjectId={this.props.contentObjectId} />
      </EditorContainer>
    )
  }
}
