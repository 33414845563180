import React from 'react'
import { MarginContainer } from './StyledComponents'
import AbstractContentDetails, { OVERRIDE_DEFAULT_KEY_DISPLAY} from './AbstractContentDetails'
import styled from 'styled-components'

const DetailContainer = styled.div`
  display: block;
  width: 20%;
`

const DetailHeader = styled.div`
  text-transform: uppercase;
  font-size: 10px;
  letter-spacing: 1px;
  line-height: 1.5;
  display: block;
`

const DetailData = styled.div`
  font-size: 14px;
  display: block;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow:hidden;
`

const StyledText = styled.div`
  color: ${props => props.theme.darkPurple};
`

export default class ContentDetails extends AbstractContentDetails {
  renderDetails () {
    const { isPublic, isFeatured, isVisible, ...rest } = this.props.details

    const formatKey = key => {
      return OVERRIDE_DEFAULT_KEY_DISPLAY[key]
        ? OVERRIDE_DEFAULT_KEY_DISPLAY[key].label
        : key
    }

    return (
      <StyledText>
        {
          Object.keys(rest).map(key =>
            <MarginContainer margin={'5px'} key={key}>
              <DetailHeader>{formatKey(key)}</DetailHeader>
              <DetailData>{this.formatValue(key, rest[key])}</DetailData>
            </MarginContainer>
          )
        }
      </StyledText>
    )
  }

  render () {
    if (!this.props.details) return null
    return (
      <DetailContainer>
        {this.renderBadges()}
        {this.renderDetails()}
      </DetailContainer>
    )
  }
}
