import omit from 'lodash/omit'
import objectToFormData from 'object-to-formdata'
import * as constants from '../constants/ChannelConstants'
import * as API from '../api/Channels'
import { receiveSuccessMessage, receiveErrorMessage } from './MessageActions'
import { stripNullFromObject } from '../utils/General'
import { RECEIVE_RELATED_CONTENT } from '../constants/RelatedContentConstants'
import { CHANNELS } from '../constants/ContentConstants'
import { getRelatedContent as getRelatedContentAction } from './RelatedContentActions'
import { getRelatedContent } from '../api/General'

const processLogo = (data) => {
  let updatedData
  if (data.logo instanceof File) {
    updatedData = objectToFormData(stripNullFromObject(data))
  } else {
    updatedData = omit(data, ['logo'])
  }

  return updatedData
}

export const getChannels = (queryParams) => dispatch => {
  return API.getChannels(queryParams).then(channels => dispatch({
    type: constants.RECEIVE_CHANNELS, channels, newQuery: queryParams.newQuery
  })).catch(error => dispatch({ type: constants.RECEIVE_CHANNELS_ERROR, error }))
}

const getOnlyChannel = (id) => dispatch => {
  return API.getChannel(id)
    .then(({ data: channel }) => dispatch({
      type: constants.RECEIVE_CHANNEL, channel
    })).catch(error => dispatch({ type: constants.RECEIVE_CHANNELS_ERROR, error }))
}

export const getChannel = (id, childType, opts = { queryParams: null }) => dispatch => {
  if (!childType) return dispatch(getOnlyChannel(id))

  if (!opts.queryParams.newQuery) {
    return dispatch(getRelatedContentAction(CHANNELS, id, childType, opts.queryParams))
  } else {
    return Promise.all([
      API.getChannel(id),
      getRelatedContent(CHANNELS, id, childType, opts.queryParams)
    ]).then(values => {
      const channel = values[0].data
      const relatedContent = values[1]
      dispatch({ type: constants.RECEIVE_CHANNEL, channel })
      dispatch({
        type: RECEIVE_RELATED_CONTENT,
        content: relatedContent,
        contentType: childType,
        newQuery: opts.queryParams.newQuery
      })
    }).catch(error => dispatch({ type: constants.RECEIVE_CHANNELS_ERROR, error }))
  }
}

export const updateChannel = (channelId, data) => dispatch => {
  const updatedData = processLogo(data)
  return API.updateChannel(channelId, updatedData).then(({ data: channel }) => {
    dispatch({ type: constants.RECEIVE_CHANNEL, channel })
  }).then(() => dispatch(receiveSuccessMessage('Channel updated')))
    .catch(error => {
      dispatch(receiveErrorMessage(error))
      return dispatch({ type: constants.RECEIVE_CHANNELS_ERROR, error })
    })
}

export const createChannel = (data) => dispatch => {
  const updatedData = processLogo(data)
  return API.createChannel(updatedData).then(({ data: channel }) => {
    dispatch({ type: constants.RECEIVE_CHANNEL, channel })
  }).then(() => dispatch(receiveSuccessMessage('Channel created')))
    .catch(error => {
      dispatch(receiveErrorMessage(error))
      return dispatch({ type: constants.RECEIVE_CHANNELS_ERROR, error })
    })
}

export const deleteChannel = (id) => dispatch => {
  return API.deleteChannel(id)
    .then(() => dispatch(receiveSuccessMessage('Channel deleted')))
    .catch(error => {
      dispatch({ type: constants.RECEIVE_CHANNELS_ERROR, error })
      return Promise.reject(error)
    })
}

export const clearChannels = () => dispatch => dispatch({ type: constants.CLEAR_CHANNELS })
