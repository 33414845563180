import isPlainObject from 'lodash/isPlainObject'

export const parseMessage = (message) => {
  if (typeof message === 'string') {
    return message
  } else if (Array.isArray(message)) {
    return message.join('. ')
  } else if (isPlainObject(message)) {
    return Object.keys(message)
            .map(key => parseMessage(message[key]))
            .join('. ')
  }
}
