import * as constants from '../constants/SearchAndFilterConstants'

const _nullQuery = {
  contentType: null,
  searchString: '',
  filters: {},
  sort: {
    field: null,
    order: constants.ASCENDING
  }
}

export default function SearchAndFilterReducer (state = _nullQuery, action) {
  switch (action.type) {
    case constants.UPDATE_SEARCH_STRING: {
      return Object.assign({}, state, {
        searchString: action.string,
        contentType: action.contentType
      })
    }
    case constants.UPDATE_SORT_ORDER: {
      return Object.assign({}, state, { contentType: action.contentType }, {
        sort: Object.assign({}, state.sort, {
          order: action.order
        })
      })
    }
    case constants.UPDATE_SORT_FIELD: {
      return Object.assign({}, state, { contentType: action.contentType }, {
        sort: Object.assign({}, state.sort, {
          field: action.field,
          contentType: action.contentType
        })
      })
    }
    case constants.UPDATE_FILTERS: {
      return Object.assign({}, state, { contentType: action.contentType }, {
        filters: Object.assign({}, state.filters, action.newFilter)
      })
    }
    case constants.CLEAR_FILTERS: {
      return Object.assign({}, state, {
        filters: {}
      })
    }
    case constants.CLEAR_SEARCH_AND_FILTER: {
      return Object.assign({}, _nullQuery)
    }
    default: {
      return state
    }
  }
}
