export default {
  maxPageWidth: '140rem',
  fontFamily: {
    serif: 'Libre',
    sansSerif: 'Nunito'
  },
  colors: {
    veryLightGray: '#f5f6f8',
    lightGray: '#dadce5',
    gray: '#a1a4b2',
    darkGray: '#646478',
    hoverGray: '#e5e6ed',
    brandColor: '#7313bd',
    darkPurple: '#160424',
    lightPurple: '#a380b3',
    white: '#ffffff',
    black: '#000000',
    lightRed: '#c85d47',
    red: '#d46363',
    lightGreen: '#74d48b',
    green: '#5acc98',
    transparentPurple: 'rgba(115, 19, 189, 0.7)',
    yellow: '#f5e269',
    gold: '#edb45e'
  },
  space: {
    none: '0',
    xxs: '0.5rem',
    xs: '0.8rem',
    sm: '1.3rem',
    md: '2rem',
    lg: '3.2rem',
    xl: '5.2rem',
    xxl: '8.4rem'
  },
  responsiveSpace: {
    none: '0',
    xxs: '0.3rem',
    xs: '0.5rem',
    sm: '0.8rem',
    md: '1.3rem',
    lg: '2rem',
    xl: '3.2rem',
    xxl: '5.2rem'
  }
}
