import React from 'react'
import AbstractContentDetails from './AbstractContentDetails'
import styled from 'styled-components'
import { FlexContainer } from './StyledComponents'

const DetailsContainer = styled.div`
  display: ${props => props.displayInline ? 'inline-block' : 'block'};
  margin-bottom: 5px;
  font-size: 14px;
  margin-right: 15px;
`

const IconContainer = styled.div`
  margin-right: 5px;
  display: inline-flex;
  align-self: center;
  padding-bottom: 2px;
`

const StyledIcon = styled.i`
  font-size: 16px;
  color: ${props => props.theme.mediumGray};
`

const StyledText = styled.div`
  color: ${props => props.theme.darkPurple};
  margin-bottom: 13px;
`

const KEY_ICONS = {
  publishedAt: 'calendar_today',
  deviceCount: 'get_app',
  favorites: 'favorite',
  artist: 'person',
  provider: 'home',
  items: 'image',
  medium: 'brush',
  year: 'today'
}

export default class ContentCardDetails extends AbstractContentDetails {
  renderDetails () {
    const { isPublic, isFeatured, isVisible, ...rest } = this.props.details

    return (
      <StyledText>
        {
          Object.keys(rest).map(key =>
            <DetailsContainer
              key={key}
              displayInline={['favorites', 'deviceCount'].includes(key)}>
              <FlexContainer>
                <IconContainer>
                  <StyledIcon className='material-icons'>{KEY_ICONS[key]}</StyledIcon>
                </IconContainer>
                {this.formatValue(key, rest[key])}
              </FlexContainer>
            </DetailsContainer>
          )
        }
      </StyledText>
    )
  }

  render () {
    if (!this.props.details) return null
    return (
      <div>
        {this.renderDetails()}
        {this.renderBadges()}
      </div>
    )
  }
}
