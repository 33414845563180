import React from 'react'
import styled from 'styled-components'
import { OuterHeader, CenteredSpaceBetween, GrayButton, BlueButton } from '../StyledComponents'

const InnerContainer = styled(CenteredSpaceBetween)`
  width: 100%;
  padding: 15px 20px;
`

export default function ComposerHeader (props) {
  return (
    <OuterHeader>
      <InnerContainer>
        <span>{props.name}</span>
        <div>
          <GrayButton small='true' onClick={() => props.history.push(`/editorials/${props.editorialId}/edit`)}>
            Edit details
          </GrayButton>
          <BlueButton small='true' marginleft='5px' onClick={props.onSave}>
            Done writing
          </BlueButton>
        </div>
      </InnerContainer>
    </OuterHeader>
  )
}
