import React from 'react'
import { connect } from 'react-redux'
import * as API from '../../api/Groups'
import { updateGroup, deleteGroup, createGroup } from '../../actions/GroupActions'
import { receiveSuccessMessage } from '../../actions/MessageActions'
import { createReview } from '../../actions/ReviewActions'
import {
  StyledOuterContainer, StyledFlexColumnContainer, StyledStatusContainer,
  StyledDetailContainer, StyledRelatedContainer, PaddingContainer, EditWrapPage
} from '../StyledEditComponents'
import { GROUPS, CATEGORIES } from '../../constants/ContentConstants'
import EditHeader from '../edit/EditHeader'
import IsPublic from '../edit/IsPublic'
import TextField from '../edit/TextField'
import AbstractEditForm from '../edit/AbstractEditForm'
import InReviewBanner from '../reviews/InReviewBanner'
import CategoryPicker from '../edit/CategoryPicker'

function mapStateToProps ({ groups, auth }) {
  return {
    type: GROUPS,
    object: groups.current,
    error: groups.error,
    user: auth.user
  }
}

function mapDispatchToProps (dispatch) {
  return {
    updateObject: (id, payload) => dispatch(updateGroup(id, payload)),
    createObject: (payload) => dispatch(createGroup(payload)),
    destroyObject: (id) => dispatch(deleteGroup(id)),
    createReview: (payload) => dispatch(createReview(payload)),
    renderMessage: (message) => dispatch(receiveSuccessMessage(message))
  }
}

class EditGroupForm extends AbstractEditForm {
  constructor (props) {
    super(props)

    this.state = {
      object: {
        id: null,
        name: '',
        isPublic: false,
        categories: []
      },
      categories: []
    }
  }

  componentWillMount () {
    const id = parseInt(this.props.match.params.id)
    if (id) {
      Promise.all([
        API.getGroup(id),
        API.getGroupCategories(id)
      ]).then(values => {
        this.setState({
          object: values[0].data,
          categories: values[1].data
        })
      })
    }
  }

  render () {
    return (
      <form>
        <EditHeader
          type={this.props.type}
          name={this.state.object.name}
          handleSave={this.handleSubmit}
          handleSubmitForReview={this.handleSubmitForReview}
          currentId={this.state.object.id}
          destroyObjects={this.destroyObject} />
        <EditWrapPage>
          <InReviewBanner
            type={this.props.type}
            content={this.state.object}
            onApprove={this.handleApproveReview}
            onCancel={this.removeReview} />
          <StyledOuterContainer>
            <StyledStatusContainer>
              <PaddingContainer>
                <h5>{this.state.object.name}</h5>
                <IsPublic isPublic={this.state.object.isPublic}
                  handleRadioChange={this.handleRadioChange} />
              </PaddingContainer>
            </StyledStatusContainer>
            <StyledFlexColumnContainer>
              <StyledDetailContainer>
                <TextField
                  id='name'
                  name='name'
                  type='text'
                  label='Title'
                  error={this.state.nameError}
                  value={this.state.object.name}
                  onChange={this.handleTextChange} />
              </StyledDetailContainer>
              <StyledRelatedContainer>
                <CategoryPicker
                  selectedCategories={this.state[CATEGORIES]}
                  removeRelationship={this.removeRelatedContent}
                  createRelationship={this.toggleRelationship} />
              </StyledRelatedContainer>
            </StyledFlexColumnContainer>
          </StyledOuterContainer>
        </EditWrapPage>
      </form>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(EditGroupForm)
