import React from 'react'
import styled from 'styled-components'
import EditorialItem from './EditorialItem'
import EditorialParagraph from './EditorialParagraph'
import EditorialImage from './EditorialImage'
import EditorialVideo from './EditorialVideo'
import EditorialAudio from './EditorialAudio'
import { PageMediumWidth } from '../../styles/Box'

const Container = styled(PageMediumWidth)`
  flex-shrink: 0;
  margin: 2rem auto 0 auto !important;
`

export default function EditorialBody (props) {
  return (
    <Container>
      {
        props.body.map((bodyEl, idx) => {
          switch (bodyEl.type) {
            case 'paragraph':
              return <EditorialParagraph content={bodyEl.content} key={idx} />
            case 'item':
              return <EditorialItem content={bodyEl.content} key={idx} />
            case 'image':
              return <EditorialImage content={bodyEl.content} key={idx} />
            case 'video':
              return <EditorialVideo content={bodyEl.content} key={idx} />
            case 'audio':
              return <EditorialAudio content={bodyEl.content} key={idx} />
          }
        })
      }
    </Container>
  )
}
