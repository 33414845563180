import React from 'react'
import styled from 'styled-components'
import { FormGroup, InputGroup, InputGroupAddon } from 'reactstrap'
import { StyledLabel, StyledInput } from '../StyledComponents'

const StyledFormGroup = styled(FormGroup)`
  margin-bottom: 15px;
  width: ${props => props.halfwidth ? '50%' : 'auto'};
  margin-right: ${props => props.marginright ? '10px' : '0'}
`

export default function TextField (props) {
  const rows = props.type === 'textarea' ? '6' : '0'
  return (
    <StyledFormGroup
      halfwidth={props.halfWidth ? props.halfWidth.toString() : props.halfWidth}
      marginright={props.marginRight ? props.marginRight.toString() : props.marginRight}>
      <StyledLabel for={props.id}
        error={props.error}>
        {props.label}
      </StyledLabel>
      <InputGroup>
        <InputGroupAddon addonType='prepend'>{props.prefix}</InputGroupAddon>
        <StyledInput
          type={props.type}
          rows={rows}
          name={props.name}
          id={props.id}
          value={props.value}
          onChange={props.onChange}
          error={props.error}
          readOnly={props.readOnly} />
      </InputGroup>
    </StyledFormGroup>
  )
}
