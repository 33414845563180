import styled from 'styled-components'
import { media } from './MediaQueries'

export const IconList = styled.ul`
  display: flex;
  align-items: center;
  margin-right: -${props => props.marginRight ? props.marginRight : '1.8rem'};
  & > * {
    padding-right: ${props => props.marginRight ? props.marginRight : '1.8rem'};
  }
`

const IconBase = styled.i`
  font-family: 'icon';
  transition: 0.2s ease color;
  color: ${props => props.theme.colors.darkPurple};
  &:hover {
    color: ${props => props.theme.colors.gray};
  }
`

export const AddIcon = styled(IconBase)`
  &:before {
    content: "\\e902";
  }
`
export const EditIcon = styled(IconBase)`
  &:before {
    content: "\\e91d";
  }
`

export const OrganizeIcon = styled(IconBase)`
  &:before {
    content: "\\e91c";
  }
`

export const CheckIcon = styled(IconBase)`
  &:before {
    content: "\\e90f";
  }
`

export const SendToCanvasIcon = styled(IconBase)`
  &:before {
    content: "\\e944";
  }
`

export const HeartIcon = styled(IconBase)`
  &:before {
    content: ${props => props.filled ? '"\\e927"' : '"\\e928"'};
  }
`

export const RightArrowIcon = styled(IconBase)`
  &:before {
    content: "\\e914";
  }
`

export const LeftArrowIcon = styled(IconBase)`
  &:before {
    content: "\\e913";
  }
`

export const UpArrowIcon = styled(IconBase)`
  &:before {
    content: "\\e915";
  }
`

export const DownArrowIcon = styled(IconBase)`
  &:before {
    content: "\\e912";
  }
`

export const SearchIcon = styled(IconBase)`
  &:before {
    content: "\\e942";
  }
`

export const UploadIcon = styled(IconBase)`
  &:before {
    content: "\\e94f";
  }
`

export const UserIcon = styled(IconBase)`
  &:before {
    content: "\\e951";
  }
`

export const XIcon = styled(IconBase)`
  &:before {
    content: "\\e91";
  }
`

export const FacebookIcon = styled(IconBase)`
  &:before {
    content: "\\e920";
  }
`

export const TwitterIcon = styled(IconBase)`
  &:before {
    content: "\\e94e";
  }
`

export const PinterestIcon = styled(IconBase)`
  &:before {
    content: "\\e95a";
  }
`

export const EmailIcon = styled(IconBase)`
  &:before {
    content: "\\e943";
  }
`

export const LinkIcon = styled(IconBase)`
  &:before {
    content: "\\e92f";
  }
`

export const ShareIcon = styled(IconBase)`
  &:before {
    content: "\\e947";
  }
`

export const TrashIcon = styled(IconBase)`
  &:before {
    content: "\\e94c";
  }
`

export const DeleteIcon = styled(IconBase)`
  &:before {
    content: "\\ae3a";
  }
`
export const CropIcon = styled(IconBase)`
  &:before {
    content: "\\e91b";
  }
`

export const RotateIcon = styled(IconBase)`
  &:before {
    content: "\\e940";
  }
`

export const LongArrowLeftIcon = styled(IconBase)`
  &:before {
    content: "\\e904";
  }
`

export const LongArrowRightIcon = styled(IconBase)`
  &:before {
    content: "\\e905";
  }
`

export const LongArrowUpIcon = styled(IconBase)`
  &:before {
    content: "\\e906";
  }
`

export const LongArrowDownIcon = styled(IconBase)`
  &:before {
    content: "\\e903";
  }
`

export const CaratUpIcon = styled(IconBase)`
  &:before {
    content: "\\e90c";
  }
`

export const ErrorIcon = styled(IconBase)`
  &:before {
    content: "\\e91e";
  }
`

export const SleepIcon = styled(IconBase)`
  &:before {
    content: "\\6b9c";
  }
`

export const WakeIcon = styled(IconBase)`
  &:before {
    content: "\\ba4";
  }
`

export const SurpriseIcon = styled(IconBase)`
  &:before {
    content: "\\e93f";
  }
`

export const PlaylistIcon = styled(IconBase)`
  &:before {
    content: "\\ae9a";
  }
`

export const RefreshIcon = styled(IconBase)`
  &:before {
    content: "\\e93d";
  }
`

export const CartIcon = styled(IconBase)`
  &:before {
    content: "\\e95b";
  }
`

export const PreviewIcon = styled(IconBase)`
  &:before {
    content: "\\e948";
  }
`
export const DotDotDotIcon = styled(IconBase)`
  &:before {
    content: "\\e933";
  }
`

export const ImageIcon = styled(IconBase)`
  &:before {
    content: "\\ad63";
  }
`

export const FavoriteHeartContainer = styled.div`
  position: absolute;
  top: 2rem;
  left: 2rem;
  z-index: 2;
  opacity: 0;

  ${media.tablet`
    top: 1.5rem;
    left: 1rem;
  `}

  @media (-moz-touch-enabled: 0), (pointer: fine) {
    ${props => props.container}:hover & {
      opacity: 1;
      transition: 0.2s ease opacity;
    }

    i {
      color: ${props => props.theme.colors.white};
      transition: 0.2s ease color;

      &:hover {
        color: ${props => props.theme.colors.white};
        opacity: 0.7;
      }
    }
  }
`

export default `
  [class^="icon-"], [class*=" icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icomoon' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .icon-icon-shopping-cart:before {
    content: "\\e95b";
  }

  .icon-icon-scheduler-wake:before {
    content: "\\e93e";
  }
  .icon-icon-scheduler-surprise:before {
    content: "\\e93f";
  }
  .icon-icon-scheduler-sleep:before {
    content: "\\e950";
  }
  .icon-icon-scheduler-playlist:before {
    content: "\\e959";
  }
  .icon-icon-3d-rotate:before {
    content: "\\e900";
  }
  .icon-icon-accessibility:before {
    content: "\\e901";
  }
  .icon-icon-add:before {
    content: "\\e902";
  }
  .icon-icon-arrow-down:before {
    content: "\\e903";
  }
  .icon-icon-arrow-left:before {
    content: "\\e904";
  }
  .icon-icon-arrow-right:before {
    content: "\\e905";
  }
  .icon-icon-arrow-up:before {
    content: "\\e906";
  }
  .icon-icon-backspace:before {
    content: "\\e907";
  }
  .icon-icon-camera-enhance:before {
    content: "\\e908";
  }
  .icon-icon-camera-rotate:before {
    content: "\\e909";
  }
  .icon-icon-camera:before {
    content: "\\e90a";
  }
  .icon-icon-carat-down:before {
    content: "\\e90b";
  }
  .icon-icon-carat-up:before {
    content: "\\e90c";
  }
  .icon-icon-check-box-outline-blank:before {
    content: "\\e90d";
  }
  .icon-icon-check-box:before {
    content: "\\e90e";
  }
  .icon-icon-check:before {
    content: "\\e90f";
  }
  .icon-icon-checkbox-selected:before {
    content: "\\e910";
  }
  .icon-icon-checkbox-unselected:before {
    content: "\\e911";
  }
  .icon-icon-chevron-down:before {
    content: "\\e912";
  }
  .icon-icon-chevron-left:before {
    content: "\\e913";
  }
  .icon-icon-chevron-right:before {
    content: "\\e914";
  }
  .icon-icon-chevron-up:before {
    content: "\\e915";
  }
  .icon-icon-clear .path1:before {
    content: "\\e916";
    color: rgb(22, 4, 36);
  }
  .icon-icon-clear .path2:before {
    content: "\\e95b";
    margin-left: -1em;
    color: rgb(255, 255, 255);
  }
  .icon-icon-close:before {
    content: "\\e91";
  }
  .icon-icon-credit-card:before {
    content: "\\e918";
  }
  .icon-icon-crop-free:before {
    content: "\\e919";
  }
  .icon-icon-crop-orginal:before {
    content: "\\e91a";
  }
  .icon-icon-crop:before {
    content: "\\e91b";
  }
  .icon-icon-dashboard:before {
    content: "\\e91c";
  }
  .icon-icon-edit:before {
    content: "\\e91d";
  }
  .icon-icon-error:before {
    content: "\\e91e";
  }
  .icon-icon-exit:before {
    content: "\\e91f";
  }
  .icon-icon-fast-forward:before {
    content: "\\e921";
  }
  .icon-icon-fast-rewind:before {
    content: "\\e922";
  }
  .icon-icon-flash-auto:before {
    content: "\\e923";
  }
  .icon-icon-flash-off:before {
    content: "\\e924";
  }
  .icon-icon-flash-on:before {
    content: "\\e925";
  }
  .icon-icon-fullscreen:before {
    content: "\\e926";
  }
  .icon-icon-heart-filled:before {
    content: "\\e927";
  }
  .icon-icon-heart:before {
    content: "\\e928";
  }
  .icon-icon-image:before {
    content: "\\e929";
  }
  .icon-icon-indeterminate-check-box:before {
    content: "\\e92a";
  }
  .icon-icon-info-wifitethering:before {
    content: "\\e92b";
  }
  .icon-icon-info:before {
    content: "\\e92c";
  }
  .icon-icon-landscape:before {
    content: "\\e92d";
  }
  .icon-icon-lightbulb:before {
    content: "\\e92e";
  }
  .icon-icon-link:before {
    content: "\\e92f";
  }
  .icon-icon-lock-open:before {
    content: "\\e930";
  }
  .icon-icon-lock:before {
    content: "\\e931";
  }
  .icon-icon-menu:before {
    content: "\\e932";
  }
  .icon-icon-more-horiz:before {
    content: "\\e933";
  }
  .icon-icon-more-vert:before {
    content: "\\e934";
  }
  .icon-icon-new:before {
    content: "\\e935";
  }
  .icon-icon-paid:before {
    content: "\\e936";
  }
  .icon-icon-pause:before {
    content: "\\e937";
  }
  .icon-icon-photo-resize:before {
    content: "\\e938";
  }
  .icon-icon-play:before {
    content: "\\e939";
  }
  .icon-icon-portrait:before {
    content: "\\e93a";
  }
  .icon-icon-radio-button-checked:before {
    content: "\\e93b";
  }
  .icon-icon-radio-button-unchecked:before {
    content: "\\e93c";
  }
  .icon-icon-refresh:before {
    content: "\\e93d";
  }
  .icon-icon-rotate-left:before {
    content: "\\e940";
  }
  .icon-icon-rotate-right:before {
    content: "\\e941";
  }
  .icon-icon-search:before {
    content: "\\e942";
  }
  .icon-icon-send-to-canvas:before {
    content: "\\e944";
  }
  .icon-icon-send:before {
    content: "\\e945";
  }
  .icon-icon-settings:before {
    content: "\\e946";
  }
  .icon-icon-share:before {
    content: "\\e947";
  }
  .icon-icon-show:before {
    content: "\\e948";
  }
  .icon-icon-sleep:before {
    content: "\\e949";
  }
  .icon-icon-star:before {
    content: "\\e94a";
  }
  .icon-icon-time:before {
    content: "\\e94b";
  }
  .icon-icon-trash:before {
    content: "\\e94c";
  }
  .icon-icon-tune:before {
    content: "\\e94d";
  }
  .icon-icon-upload:before {
    content: "\\e94f";
  }
  .icon-icon-user:before {
    content: "\\e951";
  }
  .icon-icon-volume-down:before {
    content: "\\e952";
  }
  .icon-icon-volume-mute:before {
    content: "\\e953";
  }
  .icon-icon-volume-off:before {
    content: "\\e954";
  }
  .icon-icon-volume-up:before {
    content: "\\e955";
  }
  .icon-icon-wake:before {
    content: "\\e956";
  }
  .icon-icon-wallet:before {
    content: "\\e957";
  }
  .icon-icon-zoom:before {
    content: "\\e958";
  }
  .icon-icon-facebook:before {
    content: "\\e920";
  }
  .icon-icon-send-email:before {
    content: "\\e943";
  }
  .icon-icon-twitter:before {
    content: "\\e94e";
  }
  .icon-icon-social-pinterest:before {
    content: "\\e95a";
  }
  .icon-icon-playlist:before {
    content: "\\ad63";
  }
`
