import React, { Component } from 'react'
import styled from 'styled-components'
import { FormGroup, Label, Input, Form } from 'reactstrap'
import { getUsers } from '../../api/Auth'
import { BlueButton } from '../StyledComponents'
import AbstractModal from '../AbstractModal'

const ReviewerLabel = styled(Label)`
  font-weight: 500;
`

export default class ReviewerModal extends Component {
  constructor (props) {
    super(props)

    this.state = {
      reviewers: [],
      reviewer: null
    }

    this.handleSave = this.handleSave.bind(this)
    this.handleChange = this.handleChange.bind(this)
  }

  componentDidMount () {
    getUsers({ page: 1, count: 1000 })
      .then(({ data: users }) => {
        const reviewers = users.filter(user => user.groups.includes('Super Curator'))
        this.setState({ reviewers, reviewer: reviewers[0] })
      })
  }

  handleChange (e) {
    this.setState({ reviewer: this.state.reviewers[e.target.value] })
  }

  handleSave (e) {
    e.preventDefault()

    this.props.onSave(this.state.reviewer.id)
    this.props.toggleModal()
  }

  render () {
    return (
      <AbstractModal
        isOpen={this.props.isOpen}
        toggleModal={this.props.toggleModal}>
        <Form onSubmit={this.handleSave}>
          <FormGroup>
            <ReviewerLabel for='reviewer'>Reviewer</ReviewerLabel>
            <Input type='select' name='select' id='reviewer' onChange={this.handleChange}>
              {
                this.state.reviewers.map((reviewer, idx) => (
                  <option key={idx} value={idx}>{reviewer.email}</option>
                ))
              }
            </Input>
          </FormGroup>
          <BlueButton small='true' fullwidth='true' onClick={this.handleSave}>
            Save
          </BlueButton>
        </Form>
      </AbstractModal>
    )
  }
}
