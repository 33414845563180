import React, { Component } from 'react'
import { GrayButton } from '../StyledComponents'
import ReviewerModal from './ReviewerModal'

export default class SubmitReviewBtn extends Component {
  constructor (props) {
    super(props)

    this.state = {
      isModalOpen: false
    }

    this.toggleModal = this.toggleModal.bind(this)
  }

  toggleModal () {
    this.setState({ isModalOpen: !this.state.isModalOpen })
  }

  render () {
    if (!this.props.currentId) return null

    return (
      <React.Fragment>
        <GrayButton small='true' marginleft='10px' onClick={this.toggleModal}>
          Submit for review
        </GrayButton>
        <ReviewerModal
          isOpen={this.state.isModalOpen}
          toggleModal={this.toggleModal}
          onSave={this.props.onSave} />
      </React.Fragment>
    )
  }
}
