import React, { Component } from 'react'
import { Modal, ModalBody } from 'reactstrap'
import styled from 'styled-components'
import TextField from '../edit/TextField'
import { GrayButton, BlueButton } from '../StyledComponents'

const StyledModalBody = styled(ModalBody)`
  padding: 20px;
`

const ButtonContainer = styled.div`
  display: flex;

  button {
    width: 50%;
  }
`

const MediaContainer = styled.div`
  width: 100%;
  height: ${props => props.fixedHeight ? '250px' : '100%'};
  max-height: ${props => props.fixedHeight ? '250px' : 'auto'};
`

const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`

const Audio = styled.audio`
  width: 100%;
`

const Video = Image.withComponent('video')

const TextContainer = styled.div`
  margin-top: 28px;
`

export default class EditorialFileModal extends Component {
  constructor (props) {
    super(props)

    this.state = {
      name: '',
      caption: ''
    }

    this.handleTextChange = this.handleTextChange.bind(this)
    this.handleSave = this.handleSave.bind(this)
  }

  componentDidUpdate (prevProps, prevState) {
    if (!this.props.isModalOpen &&
        this.props.isModalOpen !== prevProps.isModalOpen) {
      this.setState({ name: '', caption: '' })
    }
  }

  handleTextChange (e, field) {
    this.setState({ [field]: e.target.value })
  }

  handleSave (e) {
    e.preventDefault()

    this.props.onSave(this.state.name, this.state.caption)
    this.props.toggleModal()
  }

  render () {
    const { fileType, url } = this.props.file

    let media
    switch (fileType) {
      case 'image':
        media = <Image src={url} />
        break
      case 'audio':
        media = <Audio src={url} controls />
        break
      case 'video':
        media = <Video src={url} controls />
        break
    }

    let caption
    if (fileType !== 'audio') {
      caption = (
        <TextField
          type='textarea'
          id='caption'
          label='Caption'
          value={this.state.caption}
          onChange={(e) => this.handleTextChange(e, 'caption')} />
      )
    }

    return (
      <Modal isOpen={this.props.isModalOpen} toggle={this.props.toggleModal}>
        <StyledModalBody>
          <form onSubmit={this.handleSave}>
            <MediaContainer fixedHeight={fileType !== 'audio'}>
              {media}
            </MediaContainer>
            <TextContainer>
              <TextField
                type='text'
                id='name'
                label='Name'
                value={this.state.name}
                onChange={(e) => this.handleTextChange(e, 'name')} />
              {caption}
            </TextContainer>
            <ButtonContainer>
              <GrayButton small='true' onClick={this.props.toggleModal}>Cancel</GrayButton>
              <BlueButton
                small='true'
                marginleft='0.7rem'
                onClick={this.handleSave}>
                Save
              </BlueButton>
            </ButtonContainer>
          </form>
        </StyledModalBody>
      </Modal>
    )
  }
}
