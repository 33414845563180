import * as constants from '../constants/AuthConstants'

const _nullAuth = {
  loading: true,
  loggedIn: false,
  error: null,
  user: {
    pendingReviewerReviews: [],
    pendingRevieweeReviews: []
  },
  item: {}
}

export default function AuthReducer (state = _nullAuth, action) {
  switch (action.type) {
    case constants.RECEIVE_USER: {
      return Object.assign({}, state, {
        user: action.user.data, loggedIn: true, loading: false, error: null
      })
    }
    case constants.LOGOUT: {
      return Object.assign(_nullAuth, { loading: false })
    }
    case constants.RECEIVE_AUTH_ERROR: {
      return Object.assign({}, state, { loading: false, error: action.error })
    }
    case constants.RECEIVE_RANDOM_ITEM: {
      return Object.assign({}, state, { item: action.item })
    }
    case constants.RECEIVE_USER_REVIEWS: {
      const newUserState = Object.assign({}, state.user, {
        pendingRevieweeReviews: action.data.pendingRevieweeReviews,
        pendingReviewerReviews: action.data.pendingReviewerReviews
      })
      return Object.assign({}, state, { user: newUserState })
    }
    default: {
      return state
    }
  }
}
