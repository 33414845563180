import React from 'react'
import styled from 'styled-components'
import { withRouter, NavLink } from 'react-router-dom'
import { FlexContainer } from './StyledComponents'

const TabLink = styled(NavLink)`
  color: ${props => props.selected
    ? props.theme.darkBlue
    : props.theme.darkPurple};
  transition: 0.2s ease all;
  text-align: center;
  border-bottom: 1px solid ${props => props.selected
    ? props.theme.darkBlue
    : props.theme.white};
  font-size: 13px;
  font-weight: bold;
  padding-bottom: 8px;
  margin-right: 18px;

  &:hover {
    color: ${props => props.theme.blue};
    border-bottom: 1px solid ${props => props.selected
      ? props.theme.blue
      : props.theme.white};
    text-decoration: none;
  }
`

function MainHeaderTabs (props) {
  return (
    <FlexContainer>
      {
        props.tabs.map((tab, idx) => (
          <TabLink
            key={idx}
            to={tab.route}
            selected={tab.route === props.location.pathname}>
            {tab.displayName}
          </TabLink>
        ))
      }
    </FlexContainer>
  )
}

export default withRouter(MainHeaderTabs)
