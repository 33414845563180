import moment from 'moment'

export const formatPublicationDate = (date) => {
  if (date) {
    return moment(date).format('MM/DD/YYYY [at] h:mmA')
  }
}

export const getDate = (date) => {
  if (date) {
    return moment(date).format('YYYY-MM-DD')
  }
  return ''
}

export const getTime = (date) => {
  if (date) {
    return moment(date).format('HH:mm')
  }
  return ''
}

export const formatDateAndTime = (date) => {
  return moment(date).format('YYYY-MM-DD HH:mm')
}

export const isValidDate = (date) => {
  return moment(date).isValid()
}

export const getCurrentDate = () => moment()

export const formatHistoryTime = (date) => {
  return moment(date).format('h:mma')
}

export const formatHistoryDate = (date) => {
  return moment(date).format('MMM D').toUpperCase()
}
