import fetchWrapper from '../utils/FetchWrapper'
import { formatQueryString } from '../utils/FormatQueryString'

export function getChannels (params) {
  return fetchWrapper(`curator`, `/channels${formatQueryString(params)}`)
}

export function getChannel (id) {
  return fetchWrapper(`curator`, `/channels/${id}`)
}

export function getChannelCategories (id) {
  return fetchWrapper(`curator`, `/channels/${id}/categories`)
}

export function getChannelPlaylists (id) {
  return fetchWrapper(`curator`, `/channels/${id}/galleries`)
}

export function getChannelArtists (id) {
  return fetchWrapper(`curator`, `/channels/${id}/artists`)
}

export function getChannelEditorials (id) {
  return fetchWrapper('curator', `/channels/${id}/articles`)
}

export function getChannelItems (id) {
  return fetchWrapper(`curator`, `/channels/${id}/items`)
}

export function updateChannel (id, data) {
  return fetchWrapper(`curator`, `/channels/${id}`, {
    method: 'PUT',
    body: data
  })
}

export function createChannel (data) {
  return fetchWrapper(`curator`, `/channels`, {
    method: 'POST',
    body: data
  })
}

export function deleteChannel (id) {
  return fetchWrapper('curator', `/channels/${id}`, {
    method: 'DELETE'
  })
}

export function getRelatedChannels (parentType, parentId, queryParams) {
  return fetchWrapper(`curator`, `/${parentType}/${parentId}/channels${formatQueryString(queryParams)}`)
}
