import React from 'react'
import styled from 'styled-components'
import { Block } from '../../styles/Box'

const StyledImg = styled.img`
  object-fit: ${props => props.cover ? 'cover' : 'initial'};
  width: ${props => props.sizeByHeight ? 'auto' : '100%'};
  display: block;
  height: ${props => props.sizeByHeight ? '100%' : 'auto'};
  max-width: 100%;
  max-height: 100%;
`

export default function RectImg (props) {
  return (
    <Block height={props.sizeByHeight ? '100%' : 'auto'} width={props.sizeByHeight ? 'auto' : '100%'}>
      <StyledImg
        src={props.src}
        cover={props.cover}
        sizeByHeight={props.sizeByHeight}
        alt={props.alt} />
    </Block>
  )
}
