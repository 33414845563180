import capitalize from 'lodash/capitalize'
import * as constants from './constants'
import { pluralize } from '../../constants/models'

const _nullUser = {
  isLoading: false,
  hasFetched: false,
  firstName: '',
  lastName: '',
  country: '',
  favoriteArtists: [],
  paymentLast4: null,
  subscriptionCancelAtPeriodEnd: null,
  favoriteChannels: [],
  favoriteCategories: [],
  favoriteArticles: [],
  subscriptionInterval: null,
  subscriptionIsActive: null,
  paymentBrand: null,
  favoriteGalleries: [],
  subscriptionStatus: null,
  favoriteItems: [],
  purchasedItems: [],
  purchasedGalleries: [],
  id: null,
  subscriptionCurrentPeriodEnd: null,
  email: null
}

export default function UserReducer (state = _nullUser, action) {
  switch (action.type) {
    case constants.RECEIVE_USER_LOADING_STATE: {
      return Object.assign({}, state, {
        isLoading: action.isLoading,
        hasFetched: state.hasFetched ? true : !action.isLoading
      })
    }
    case constants.RECEIVE_USER: {
      const { favoritePartners, ...rest } = action.user
      return Object.assign({}, state, {
        isLoading: false,
        favoriteChannels: favoritePartners,
        ...rest
      })
    }
    case constants.RECEIVE_FAVORITE: {
      const modelType = capitalize(pluralize(action.modelType.toLowerCase()))
      const favoriteType = `favorite${modelType}`
      const updatedFavoriteIds = [].concat(state[favoriteType])
      updatedFavoriteIds.push(action.modelId)
      return Object.assign({}, state, {
        [favoriteType]: updatedFavoriteIds
      })
    }
    case constants.REMOVE_FAVORITE: {
      const modelType = capitalize(pluralize(action.modelType.toLowerCase()))
      const favoriteType = `favorite${modelType}`
      const updatedFavoriteIds = [].concat(state[favoriteType])
      const idx = updatedFavoriteIds.findIndex(favoriteId => favoriteId === action.modelId)
      if (idx !== -1) updatedFavoriteIds.splice(idx, 1)
      return Object.assign({}, state, {
        [favoriteType]: updatedFavoriteIds
      })
    }
    case constants.RECEIVE_PURCHASE: {
      let updatedState = Object.assign({}, state)
      if (!updatedState.paymentLast4 && !updatedState.paymentBrand) {
        updatedState.paymentLast4 = action.paymentLast4
        updatedState.paymentBrand = action.paymentBrand
      }
      if (action.contentType === 'item') {
        updatedState.purchasedItems.push(action.content.id)
      } else if (action.contentType === 'playlist') {
        updatedState.purchasedGalleries.push(action.content.id)
        updatedState.purchasedItems = updatedState.purchasedItems.concat(action.content.itemIds)
      }
      return updatedState
    }
    case constants.CLEAR_USER: {
      return Object.assign({}, _nullUser, { hasFetched: true })
    }
    default: {
      return state
    }
  }
}
