import React, { Component, Fragment } from 'react'
import { Collapse } from 'reactstrap'
import styled from 'styled-components'
import { FlexContainer } from './StyledComponents'

const NavItem = styled.li`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: ${props => props.theme.white};
  border-top: 1px solid ${props => props.theme.grayRGBA(0.1)};
  cursor: pointer;

  &:hover {
    color: ${props => props.theme.lightGray};
  }
`

const NavItemText = styled.h6`
  margin-bottom: 0px;
  padding: 20px 10px;
  font-weight: ${props => props.bold ? '500' : '100'};
`

const Icon = styled.i`
  transform: rotate(${props => props.isOpen ? '180deg' : '0deg'});
  transition: 0.3s ease transform;
`

export default class SidebarNavItem extends Component {
  constructor (props) {
    super(props)

    this.state = {
      isSubNavOpen: this.props.isOpen
    }

    this.toggleDropdown = this.toggleDropdown.bind(this)
  }

  toggleDropdown () {
    this.setState({ isSubNavOpen: !this.state.isSubNavOpen })
  }

  render () {
    const { children, text, bold, onClick, renderBadge } = this.props
    return (
      <Fragment>
        <NavItem onClick={onClick || this.toggleDropdown}>
          <FlexContainer>
            <NavItemText bold={bold}>{text}</NavItemText>
            {renderBadge}
          </FlexContainer>
          {
            children &&
              <Icon
                className='material-icons'
                onClick={this.toggleDropdown}
                isOpen={this.state.isSubNavOpen}>
                keyboard_arrow_up
              </Icon>
          }
        </NavItem>
        <Collapse isOpen={this.state.isSubNavOpen}>
          {children}
        </Collapse>
      </Fragment>
    )
  }
}
