import pluralize, { singular } from 'pluralize'
import * as constants from '../constants/RelatedContentConstants'
import * as API from '../api/General'
import { receiveSuccessMessage } from './MessageActions'
import { clearSelectedContent } from './SelectedContentActions'

export const getRelatedContent = (parentType, parentId, childType, queryParams) => dispatch => {
  return API.getRelatedContent(parentType, parentId, childType, queryParams)
    .then(relatedContent => dispatch({
      type: constants.RECEIVE_RELATED_CONTENT,
      contentType: childType,
      content: relatedContent,
      newQuery: queryParams.newQuery
    }))
}

export const addRelatedContent = (parentType, parentId, childType, children) => dispatch => {
  return API.addRelatedContent(parentType, parentId, childType, children.map(child => child.id))
    .then(({ data }) => {
      const child = pluralize(childType, children.length)
      const successMsg = `${data.success.length} of ${children.length} ${child} added to ${singular(parentType)}`
      dispatch(receiveSuccessMessage(successMsg))

      if (data.errors) dispatch(receiveRelatedContentError(data.errors, children, childType))

      return data.success
    })
    .then(successes => dispatch({
      type: constants.RECEIVE_RELATED_CONTENT,
      contentType: childType,
      content: children.filter(child => successes.includes(child.id))
    }))
    .then(() => dispatch(clearSelectedContent()))
    .catch(({ data }) => dispatch(receiveRelatedContentError(data.errors, children, childType)))
}

export const deleteRelatedContent = (parentType, parentId, childType, children) => dispatch => {
  return API.deleteRelatedContent(parentType, parentId, childType, children.map(child => child.id))
    .then(({ data }) => {
      const child = pluralize(childType, children.length)
      const successMsg = `${data.success.length} of ${children.length} ${child} removed from ${singular(parentType)}`
      dispatch(receiveSuccessMessage(successMsg))

      if (data.errors) dispatch(receiveRelatedContentError(data.errors, children, childType))

      return data.success
    })
    .then(successes => dispatch({
      type: constants.REMOVE_RELATED_CONTENT,
      contentType: childType,
      content: children.filter(child => successes.includes(child.id))
    }))
    .then(() => dispatch(clearSelectedContent()))
    .catch(({ data }) => dispatch(receiveRelatedContentError(data.errors, children, childType)))
}

export const clearRelatedContent = () => dispatch => dispatch({
  type: constants.CLEAR_RELATED_CONTENT
})

export const receiveRelatedContentError = (errors, children, type) => dispatch => dispatch({
  type: constants.RECEIVE_RELATED_CONTENT_ERROR,
  errors,
  content: children,
  relatedContentType: type
})

export const clearRelatedContentError = () => dispatch => dispatch({
  type: constants.CLEAR_RELATED_CONTENT_ERROR
})
