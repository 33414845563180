import Theme from './Theme'

export default `
  html {
    font-size: 10px;
    box-sizing: border-box;
  }

  body {
    min-width: 32rem;
    width: 100%;
    height: 100%;
    margin: 0px;
    padding: 0px;
    overflow-x: hidden;
    background: ${Theme.colors.white};
    background-color: ${Theme.colors.white};
    font-family: ${Theme.fontFamily.sansSerif};
    font-size: 1.4rem;
    line-height: 1.4em;
    color: ${Theme.colors.darkPurple};
    text-rendering: optimizeLegibility;
    font-smoothing: antialiased;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  *, *::before, *::after {
    box-sizing: inherit;
  }

  article,
  aside,
  figcaption,
  figure,
  button ,
  main {
    display: block;
  }

  main {
    width: 100%;
  }

  div, ul, li, section,
  header, footer, nav {
    display: flex;
  }

  pre {
    font-family: monospace, monospace;
    font-size: 1rem;
  }

  hr {
    border: 0;
    height: 1px;
    background: ${Theme.colors.lightGray};
  }

  .group:after {
    content: "";
    clear: both;
    display: block;
  }

  ::-webkit-input-placeholder {
    color: ${Theme.colors.gray};
  }
  ::-moz-placeholder {
    color: ${Theme.colors.gray};
  }
  :-ms-input-placeholder {
    color: ${Theme.colors.gray};
  }
  :-moz-placeholder {
    color: ${Theme.colors.gray};
  }
`
