import React, { Component } from 'react'
import styled from 'styled-components'
import videojs from 'video.js'
import { formatDuration } from '../../utils/general'
import { FinePrint } from '../../styles/Text'

const AudioContainer = styled.div`
  flex-direction: column;
  background-color: ${props => props.theme.colors.veryLightGray};
  padding: 1rem;
  margin-bottom: 7rem;
  height: ${props => props.height};
`

const AudioData = styled.div`
  flex-direction: column;
  padding: 1rem 1.4rem;
`

const AudioName = styled.h6`
  margin-bottom: 0.4rem;
`

export default class EditorialAudio extends Component {
  constructor (props) {
    super(props)

    this.state = {
      audioLoaded: false
    }

    this.onLoadedMetadata = this.onLoadedMetadata.bind(this)
  }

  componentDidMount () {
    this.player = videojs(this.audio, {
      controls: true,
      fluid: true
    })

    this.player.on('loadedmetadata', this.onLoadedMetadata)
  }

  onLoadedMetadata () {
    this.setState({ audioLoaded: true })
  }

  componentWillUnmount () {
    if (this.player) {
      this.player.dispose()
      this.player.off('loadedmetadata', this.onLoadedMetadata)
    }
  }

  render () {
    // This is used to set the height for the audio when it didn't load yet but
    // there's a gray box so we need a pre-defined height
    const MAX_CHARS_IN_LINE = 84
    const longName = this.props.content.name && this.props.content.name.length > MAX_CHARS_IN_LINE

    return (
      <AudioContainer height={longName ? '15rem' : '13.1rem'}>
        <AudioData>
          <AudioName>{this.audio && this.props.content.name}</AudioName>
          <FinePrint>
            {this.state.audioLoaded && formatDuration(this.player.duration())}
          </FinePrint>
        </AudioData>
        <audio
          className='video-js'
          ref={domNode => { this.audio = domNode }}>
          <source src={this.props.content.url} />
          <p>
            Your browse doesn't support HTML5 audio. Here is a
            <a href={this.props.content.url}>link to the audio</a> instead.
          </p>
        </audio>
      </AudioContainer>
    )
  }
}
