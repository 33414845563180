import React from 'react'
import styled from 'styled-components'

import {
  FlexColumnContainer, FlexHorizontalCenterContainer, GrayButton, StyledLabel
} from '../StyledComponents'

const StyledName = styled.span`
  width: 55%;
`

const StyledFlexColumnContainer = styled(FlexColumnContainer)`
  width: 100%;
`

export default function AuthorPicker (props) {
  let authorPicker
  if (!props.author) {
    authorPicker = (
      <div>
        <GrayButton small='true' onClick={props.toggleModal}
          error={props.error}>
          Choose
        </GrayButton>
      </div>
    )
  } else {
    authorPicker = (
      <FlexHorizontalCenterContainer justifyContent='space-between'>
        <StyledName>{props.author}</StyledName>
        <GrayButton small='true' error={props.error}
          onClick={props.toggleModal}>
          Change
        </GrayButton>
      </FlexHorizontalCenterContainer>
    )
  }

  return (
    <StyledFlexColumnContainer>
      <StyledLabel error={props.error}>Author</StyledLabel>
      {authorPicker}
    </StyledFlexColumnContainer>
  )
}
