import * as constants from '../constants/MessageConstants'

const _nullMessage = {
  message: '',
  hasTimeout: true,
  type: null
}

export default function MessageReducer (state = _nullMessage, action) {
  switch (action.type) {
    case constants.RECEIVE_SUCCESS_MESSAGE: {
      return {
        message: action.message, hasTimeout: action.hasTimeout, type: constants.SUCCESS
      }
    }
    case constants.RECEIVE_ERROR_MESSAGE: {
      return {
        message: action.message, hasTimeout: action.hasTimeout, type: constants.ERROR
      }
    }
    case constants.CLEAR_MESSAGE: {
      return { message: '', type: null }
    }
    default:
      return state
  }
}
