import React, { Component } from 'react'
import { connect } from 'react-redux'
import { selectContent } from '../../actions/SelectedContentActions'
import SuperPicker from './SuperPicker'

const mapStateToProps = ({ selectedContent }) => ({
  selectedContent: selectedContent.content
})

const mapDispatchToProps = dispatch => ({
  onSelect: (type, content) => dispatch(selectContent(type, content))
})

class BatchSuperPicker extends Component {
  constructor (props) {
    super(props)

    this.afterClose = this.afterClose.bind(this)
  }

  afterClose () {
    const { selectedContent, currentId, relatedContentType } = this.props
    if (selectedContent.length) {
      this.props.afterClose(selectedContent, currentId, relatedContentType)
    }
  }

  render () {
    const { selectedContent, relatedContentType } = this.props

    return (
      <SuperPicker
        {...this.props}
        allowBatch
        createRelationship={(content) => this.props.onSelect(relatedContentType, content)}
        selectedIds={selectedContent.map(selected => selected.id)}
        afterClose={this.afterClose} />
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(BatchSuperPicker)
