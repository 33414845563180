import moment from 'moment'
import {
  ITEM, EDITORIAL, PLAYLIST, CHANNEL, CATEGORY, ARTIST
} from '../constants/models'

export function readableDate (date) {
  if (!date) return ''

  return moment(date).format('MMM D, YYYY')
}

export function formatDuration (duration) {
  const seconds = moment.duration(Math.floor(duration), 'seconds')
  const hours = seconds.asHours()

  let formattedTime
  if (hours >= 1) {
    formattedTime = moment(seconds.asMilliseconds()).format('Hh:mm:ss')
  } else {
    formattedTime = moment(seconds.asMilliseconds()).format('mm:ss')
  }

  return formattedTime
}

export function stripMd (text) {
  if (typeof text !== 'string') return ''
  const mdLink = /\[([^\])]+)\]\(([^\])]+)\)/g
  const mdBold = /\*\*([^*]+)\*\*/g
  const mdItalic = /\*([^*]+)\*/g
  return text.replace(mdLink, '$1').replace(mdBold, '$1').replace(mdItalic, '$1')
}

export function getImageByType (type, content) {
  switch (type) {
    case PLAYLIST:
      return content.cover
    case ITEM:
      return content.image
    case ARTIST:
      return content.avatar
    case CHANNEL:
    case CATEGORY:
      return content.avatar && content.avatar.image
    case EDITORIAL:
      return content.hero.hero
    default:
      throw new Error('Invalid content type')
  }
}
