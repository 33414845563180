import { css } from 'styled-components'

export const media = {
  xLargeDesktop: (...args) => css`
    @media (min-width: 1440px) {
      ${css(...args)}
    }
  `,
  largeDesktop: (...args) => css`
    @media (max-width: 1439px) {
      ${css(...args)}
    }
  `,
  mediumDesktop: (...args) => css`
    @media (max-width: 1199px) {
      ${css(...args)}
    }
  `,
  desktop: (...args) => css`
    @media (max-width: 1023px) {
      ${css(...args)}
    }
  `,
  tablet: (...args) => css`
    @media (max-width: 768px) {
      ${css(...args)}
    }
  `,
  mobile: (...args) => css`
    @media (max-width: 499px) {
      ${css(...args)}
    }
  `
}
