import * as constants from '../constants/ItemConstants'
import { DELETE_REVIEW, RECEIVE_REVIEW } from '../constants/ReviewConstants'
import { ITEMS } from '../constants/ContentConstants'

const _nullItems = {
  error: null,
  items: {
    data: [],
    isLast: false,
    count: null
  },
  current: {
    pendingReviews: []
  }
}

export default function ItemReducer (state = _nullItems, action) {
  switch (action.type) {
    case constants.RECEIVE_ITEMS: {
      const { isLast, data, count } = action.items
      const { newQuery } = action
      const newItems = {
        isLast: isLast,
        data: newQuery ? data : [].concat(state.items.data, data),
        count
      }
      return Object.assign({}, state, { items: newItems })
    }
    case constants.RECEIVE_ITEM: {
      return Object.assign({}, state, {
        current: Object.assign({}, state.current, action.item)
      })
    }
    case constants.RECEIVE_ITEMS_ERROR: {
      return Object.assign({}, state, { error: action.error })
    }
    case constants.CLEAR_ITEMS: {
      return Object.assign({}, _nullItems, {selected: state.selected})
    }
    case constants.REMOVE_SELECTED_ITEM: {
      const newSelected = Object.assign({}, state.selected)
      delete newSelected[action.itemId]
      return Object.assign({}, state, { selected: newSelected })
    }
    case DELETE_REVIEW: {
      if (!action.contentType === ITEMS) return state

      const newPendingReviews = Object.assign({}, {
        pendingReviews: state.current.pendingReviews
          .filter(review => review.id !== action.reviewId)
      })

      return Object.assign({}, state, {
        current: Object.assign({}, state.current, newPendingReviews)
      })
    }
    case RECEIVE_REVIEW: {
      if (!action.contentType === ITEMS) return state

      return Object.assign({}, state, {
        current: Object.assign({}, state.current, action.review.contentObject)
      })
    }
    default: {
      return state
    }
  }
}
