import { connect } from 'react-redux'
import { getProviders, clearProviders } from '../../actions/ProviderActions'
import { clearSearchAndFilter } from '../../actions/SearchAndFilterActions'
import Collection from '../ContentCollection'
import { PROVIDERS } from '../../constants/ContentConstants'

const mapStateToProps = ({ providers, searchAndFilter }) => {
  return {
    type: PROVIDERS,
    content: providers.providers.data,
    isLast: providers.providers.isLast,
    count: providers.providers.count,
    error: providers.error,
    searchQueryParams: searchAndFilter
  }
}

const mapDispatchToProps = dispatch => ({
  getContent: (pagination, searchAndFilter) => dispatch(getProviders({...pagination, ...searchAndFilter})),
  clearContent: () => dispatch(clearProviders()),
  clearSearchAndFilter: () => dispatch(clearSearchAndFilter())
})

export default connect(mapStateToProps, mapDispatchToProps)(Collection)
