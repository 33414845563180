import * as constants from './constants'
import { parseMessage } from '../../utils/message'

const _nullMessage = {
  messageType: null,
  message: '',
  progress: constants.AUTO,
  action: {
    buttonText: '',
    url: '',
    content: null,
    contentType: null
  }
}

export default function MessageReducer (state = _nullMessage, action) {
  switch (action.type) {
    case constants.SUCCESS: {
      return Object.assign({}, state, {
        messageType: constants.SUCCESS,
        message: action.message,
        action: action.action || _nullMessage.action
      })
    }
    case constants.ERROR: {
      const message = parseMessage(action.message)
      return Object.assign({}, state, {
        messageType: constants.ERROR,
        message: message,
        errors: action.errors || [],
        action: action.action || _nullMessage.action
      })
    }
    case constants.LOADING: {
      return Object.assign({}, state, {
        messageType: constants.LOADING,
        message: action.message,
        progress: action.progress || state.progress,
        action: _nullMessage.action
      })
    }
    case constants.STATIC: {
      return Object.assign({}, state, {
        messageType: constants.STATIC,
        message: action.message,
        action: _nullMessage.action
      })
    }
    case constants.CLEAR_MESSAGE: {
      return _nullMessage
    }
    default: {
      return state
    }
  }
}
