import React from 'react'
import { connect } from 'react-redux'
import { CATEGORIES, ITEMS, ARTISTS } from '../../constants/ContentConstants'
import {
  StyledOuterContainer, StyledFlexColumnContainer, StyledStatusContainer,
  StyledDetailContainer, StyledRelatedContainer, PaddingContainer, EditWrapPage
} from '../StyledEditComponents'
import { receiveSuccessMessage } from '../../actions/MessageActions'
import { FlexContainer } from '../StyledComponents'
import { updateArtist, deleteArtist, createArtist } from '../../actions/ArtistActions'
import { createReview } from '../../actions/ReviewActions'
import EditHeader from '../edit/EditHeader'
import IsPublic from '../edit/IsPublic'
import IsFeatured from '../edit/IsFeatured'
import FeaturedAt from '../edit/FeaturedAt'
import TextField from '../edit/TextField'
import SuperPicker from '../edit/SuperPicker'
import ImagePicker from '../edit/ImagePicker'
import AbstractEditForm from '../edit/AbstractEditForm'
import CoverImageContainer from '../edit/CoverImageContainer'
import * as API from '../../api/Artists'
import InReviewBanner from '../reviews/InReviewBanner'
import GeorestrictionPicker from '../edit/GeorestrictionPicker'
import CategoryPicker from '../edit/CategoryPicker'

function mapStateToProps ({ artists, auth }) {
  return {
    type: ARTISTS,
    object: artists.current,
    error: artists.error,
    user: auth.user
  }
}

function mapDispatchToProps (dispatch) {
  return {
    updateObject: (id, payload) => dispatch(updateArtist(id, payload)),
    createObject: (payload) => dispatch(createArtist(payload)),
    destroyObject: (id) => dispatch(deleteArtist(id)),
    createReview: (payload) => dispatch(createReview(payload)),
    renderMessage: (message) => dispatch(receiveSuccessMessage(message))
  }
}

class ArtistForm extends AbstractEditForm {
  constructor (props) {
    super(props)

    this.state = {
      object: {
        id: null,
        firstName: '',
        surname: '',
        gender: '',
        nationality: '',
        birth: '',
        death: '',
        biography: '',
        categories: []
      },
      avatar: {},
      hero: {},
      categories: [],
      isGeorestricted: false
    }
  }

  componentWillMount () {
    const id = parseInt(this.props.match.params.id)
    if (id) {
      Promise.all([
        API.getArtist(id),
        API.getArtistCategories(id)
      ]).then(values => {
        this.setState({
          object: Object.assign({}, values[0].data, {
            avatar: values[0].data.avatar && values[0].data.avatar.id,
            hero: values[0].data.hero && values[0].data.hero.id,
            availableCountries: values[0].data.availableCountries || []
          }),
          avatar: values[0].data.avatar || {},
          hero: values[0].data.hero || {},
          categories: values[1].data,
          isGeorestricted: !!values[0].data.availableCountries
        })
      })
    }
  }

  massageObject () {
    return this.massageGeorestrictionData(this.state.object)
  }

  render () {
    return (
      <form>
        <EditHeader
          type={this.props.type}
          name={this.state.object.firstName + ' ' + this.state.object.surname}
          deleteImage={this.state.avatar.imageThumb}
          handleSave={this.handleSubmit}
          handleSubmitForReview={this.handleSubmitForReview}
          currentId={this.state.object.id}
          destroyObjects={this.destroyObject} />
        <EditWrapPage>
          <InReviewBanner
            type={this.props.type}
            content={this.state.object}
            onApprove={this.handleApproveReview}
            onCancel={this.removeReview} />
          <StyledOuterContainer>
            <StyledStatusContainer>
              <CoverImageContainer
                image={this.state.avatar.imageThumb} />
              <PaddingContainer>
                <h5>{this.state.object.firstName + ' ' + this.state.object.surname}</h5>
                <IsPublic isPublic={this.state.object.isPublic}
                  handleRadioChange={this.handleRadioChange} />
                <IsFeatured isFeatured={this.state.object.isFeatured}
                  handleRadioChange={this.handleRadioChange} />
                <FeaturedAt
                  currentDateTime={this.state.object.featuredAt}
                  handleChange={this.handleDateTimeChange} />
              </PaddingContainer>
            </StyledStatusContainer>
            <StyledFlexColumnContainer>
              <StyledDetailContainer>
                <FlexContainer>
                  <TextField
                    id='firstName'
                    name='firstName'
                    type='text'
                    label='First Name'
                    error={this.state.firstNameError}
                    value={this.state.object.firstName}
                    onChange={this.handleTextChange}
                    halfWidth
                    marginRight />
                  <TextField
                    id='surname'
                    name='surname'
                    type='text'
                    label='Last Name'
                    error={this.state.surnameError}
                    value={this.state.object.surname}
                    onChange={this.handleTextChange}
                    halfWidth />
                </FlexContainer>
                <FlexContainer>
                  <TextField
                    id='gender'
                    name='gender'
                    type='text'
                    label='Gender'
                    error={this.state.genderError}
                    value={this.state.object.gender}
                    onChange={this.handleTextChange}
                    halfWidth marginRight />
                  <TextField
                    id='nationality'
                    name='nationality'
                    type='text'
                    label='Nationality'
                    error={this.state.nationalityError}
                    value={this.state.object.nationality}
                    onChange={this.handleTextChange}
                    halfWidth />
                </FlexContainer>
                <FlexContainer>
                  <TextField
                    id='birth'
                    name='birth'
                    type='text'
                    label='Birth'
                    error={this.state.birthError}
                    value={this.state.object.birth}
                    onChange={this.handleTextChange}
                    halfWidth marginRight />
                  <TextField
                    id='death'
                    name='death'
                    type='text'
                    label='Death'
                    error={this.state.deathError}
                    value={this.state.object.death}
                    onChange={this.handleTextChange}
                    halfWidth />
                </FlexContainer>
                <TextField
                  id='biography'
                  name='biography'
                  type='textarea'
                  label='Biography'
                  error={this.state.biographyError}
                  value={this.state.object.biography}
                  onChange={this.handleTextChange} />
                <SuperPicker
                  render={(ctx) => {
                    return (
                      <ImagePicker image={this.state.avatar}
                        error={this.state.avatarError}
                        label='Avatar' toggleModal={ctx.toggleModal} />
                    )
                  }}
                  relatedContentType={ITEMS}
                  name='avatar'
                  baseType={ARTISTS}
                  currentId={this.state.object.id}
                  changeRelationship={this.changeSelectedContent} />
                <SuperPicker
                  render={(ctx) => {
                    return (
                      <ImagePicker image={this.state.hero}
                        error={this.state.heroError}
                        label='Hero' toggleModal={ctx.toggleModal} />
                    )
                  }}
                  relatedContentType={ITEMS}
                  name='hero'
                  baseType={ARTISTS}
                  currentId={this.state.object.id}
                  changeRelationship={this.changeSelectedContent} />
              </StyledDetailContainer>
              <StyledRelatedContainer>
                <CategoryPicker
                  selectedCategories={this.state[CATEGORIES]}
                  removeRelationship={this.removeRelatedContent}
                  createRelationship={this.toggleRelationship} />
              </StyledRelatedContainer>
              <GeorestrictionPicker
                {...this.state}
                handleGeorestrictionChange={this.handleGeorestrictionChange}
                handleRadioChange={this.handleRadioChange} />
            </StyledFlexColumnContainer>
          </StyledOuterContainer>
        </EditWrapPage>
      </form>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ArtistForm)
