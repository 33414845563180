import React, { Component } from 'react'
import styled from 'styled-components'
import { Modal } from 'reactstrap'
import { ModalContent, ModalCloseIcon, BlueButton } from '../StyledComponents'

const StyledModal = styled(Modal)`
  margin: 104px auto;
`

const ConfirmApprovalText = styled.p`
  font-weight: 500;
  font-size: 16px;
`

const ApproveReview = styled.span`
  font-weight: 500;
  cursor: pointer;
  margin-left: 23px;
`

function ApproveReviewModal (props) {
  return (
    <StyledModal isOpen={props.isOpen} toggle={props.toggleModal}>
      <ModalContent>
        <ModalCloseIcon className='material-icons' onClick={props.toggleModal}>
          close
        </ModalCloseIcon>
        <ConfirmApprovalText>
          Are you sure you want to make this public?
        </ConfirmApprovalText>
        <BlueButton fullwidth='true' small='true' onClick={props.onApprove}>
          Yes, make it public
        </BlueButton>
      </ModalContent>
    </StyledModal>
  )
}

export default class ApproveReviewButton extends Component {
  constructor (props) {
    super(props)

    this.state = {
      isOpen: false
    }

    this.toggleModal = this.toggleModal.bind(this)
    this.approveAndToggle = this.approveAndToggle.bind(this)
  }

  toggleModal () {
    this.setState({ isOpen: !this.state.isOpen })
  }

  approveAndToggle () {
    this.props.onApprove()
    this.toggleModal()
  }

  render () {
    return (
      <React.Fragment>
        <ApproveReview onClick={this.toggleModal}>Approve and publish</ApproveReview>
        <ApproveReviewModal
          isOpen={this.state.isOpen}
          toggleModal={this.toggleModal}
          onApprove={this.approveAndToggle} />
      </React.Fragment>
    )
  }
}
