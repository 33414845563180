import merge from 'lodash/merge'
import uniqBy from 'lodash/uniqBy'
import * as constants from '../constants/RelatedContentConstants'

const _nullRelatedContent = {
  type: '',
  relatedContent: [],
  isLast: false,
  count: null,
  errors: []
}

export default function RelatedContentReducer (state = _nullRelatedContent, action) {
  switch (action.type) {
    case constants.RECEIVE_RELATED_CONTENT: {
      const { contentType, content, newQuery } = action
      const { relatedContent } = state

      let newRelatedContent
      // Receive a list of related content
      if (content.data) {
        newRelatedContent = {
          type: contentType,
          relatedContent: newQuery ? content.data : relatedContent.concat(content.data),
          isLast: content.isLast,
          count: content.count
        }
      // Add just one related content
      } else {
        const relatedContentList = uniqBy(content.concat(relatedContent), 'id')
        newRelatedContent = merge({}, state, {
          relatedContent: relatedContentList,
          count: relatedContentList.length
        })
      }

      return Object.assign({}, state, newRelatedContent)
    }
    case constants.REMOVE_RELATED_CONTENT: {
      const { contentType, content } = action
      const newRelatedContent = Object.assign({}, state.relatedContent, {
        type: contentType,
        relatedContent: state.relatedContent.filter(el => {
          return !content.map(deletedContent => deletedContent.id).includes(el.id)
        })
      })

      return Object.assign({}, state, newRelatedContent)
    }
    case constants.CLEAR_RELATED_CONTENT: {
      return Object.assign({}, _nullRelatedContent, {})
    }
    case constants.RECEIVE_RELATED_CONTENT_ERROR: {
      return Object.assign({}, state, {
        errors: action.errors.map(error => {
          const content = action.content.find(content => content.id === error.id)
          return Object.assign({}, error, { content })
        }),
        type: action.relatedContentType
      })
    }
    case constants.CLEAR_RELATED_CONTENT_ERROR: {
      return Object.assign({}, state, { errors: [] })
    }
    default: {
      return state
    }
  }
}
