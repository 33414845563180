import { connect } from 'react-redux'
import { getProvider } from '../../actions/ProviderActions'
import { PROVIDERS } from '../../constants/ContentConstants'

import ViewContent from '../ViewContent'

const mapStateToProps = state => {
  return {
    type: PROVIDERS,
    content: state.providers.current
  }
}

const mapDispatchToProps = dispatch => ({
  getContent: payload => dispatch(getProvider(payload))
})

export default connect(mapStateToProps, mapDispatchToProps)(ViewContent)
