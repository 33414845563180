export const ITEM = 'item'
export const ARTWORK = 'work'
export const PLAYLIST = 'playlist'
export const ARTIST = 'artist'
export const GROUP = 'group'
export const CHANNEL = 'channel'
export const CATEGORY = 'category'
export const GALLERY = 'gallery'
export const PARTNER = 'partner'
export const EDITORIAL = 'editorial'
export const ARTICLE = 'article'
export const CANVAS = 'canvas'

export const pluralize = model => {
  switch (model.toLowerCase()) {
    case ITEM:
    case ARTWORK:
    case PLAYLIST:
    case ARTIST:
    case GROUP:
    case CHANNEL:
    case PARTNER:
    case ARTICLE:
      return `${model}s`
    case CATEGORY:
      return 'categories'
    case GALLERY:
      return 'galleries'
    case 'items':
    case 'works':
    case 'playlists':
    case 'artists':
    case 'groups':
    case 'channels':
    case 'categories':
    case 'articles':
    case 'galleries':
    case EDITORIAL:
      return model
    default:
      throw Error('Invalid model name')
  }
}

export const singularize = model => {
  switch (model.toLowerCase()) {
    case 'items':
    case 'playlists':
    case 'artists':
    case 'groups':
    case 'channels':
    case 'partners':
    case 'works':
    case 'articles':
    case 'editorials':
      return model.slice(0, model.length - 1).toLowerCase()
    case 'categories':
      return CATEGORY
    case 'galleries':
      return GALLERY
    case ITEM:
    case PLAYLIST:
    case ARTIST:
    case GROUP:
    case CHANNEL:
    case PARTNER:
    case CATEGORY:
    case GALLERY:
    case EDITORIAL:
    case ARTICLE:
      return model
    default:
      throw Error('Invalid model name')
  }
}

export const toFrontend = model => {
  switch (model.toLowerCase()) {
    case GALLERY:
      return PLAYLIST
    case PARTNER:
      return CHANNEL
    case ITEM:
      return ARTWORK
    case ARTICLE:
      return EDITORIAL
    default:
      return model.toLowerCase()
  }
}

export const toBackend = model => {
  switch (model.toLowerCase()) {
    case PLAYLIST:
      return GALLERY
    case PARTNER:
      return CHANNEL
    case ARTWORK:
      return ITEM
    case EDITORIAL:
      return ARTICLE
    default:
      return model.toLowerCase()
  }
}
