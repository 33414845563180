import React from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import ReactCrop, { makeAspectCrop } from 'react-image-crop'
import 'react-image-crop/dist/ReactCrop.css'
import {
  CenteredSpaceBetween, OuterHeader, GrayButton, BlueButton, GraySpan,
  FlexContainer, Rectangle, FlexColumnContainer
} from '../StyledComponents'
import { getItem, cropItem } from '../../actions/ItemActions'
import { receiveSuccessMessage } from '../../actions/MessageActions'
import { getImageOrientation } from '../../utils/ImageUtils'

const CropperBackground = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
`

const CropperContainer = styled(FlexContainer)`
  justify-content: center;
  border: 1px solid ${props => props.theme.white};

  .ReactCrop__crop-selection {
    border-image-source: none !important;
    border: 3px solid ${props => props.theme.white} !important;
  }
`

const Background = styled(FlexContainer)`
  align-items: center;
  background-color: ${props => props.theme.darkPurple};
  // 63px accounts for header height
  min-height: calc(100% - 63px);
`

const StyledFlexColumn = styled(FlexColumnContainer)`
  width: 100%;
`

const OrientationContainer = styled(FlexContainer)`
  margin-bottom: 20px;
`

const mapStateToProps = ({ items }) => ({
  item: items.current
})

const mapDispatchToProps = (dispatch) => ({
  getItem: (id) => dispatch(getItem(id)),
  cropItem: (id, payload) => dispatch(cropItem(id, payload)),
  renderMessage: (message) => dispatch(receiveSuccessMessage(message))
})

class ItemCropper extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      crop: {
        x: 0,
        y: 0,
        aspect: 9 / 16
      },
      orientation: '',
      isLoading: false
    }

    this.onChange = this.onChange.bind(this)
    this.handleSave = this.handleSave.bind(this)
    this.changeOrientation = this.changeOrientation.bind(this)
  }

  componentWillMount () {
    if (!this.props.item.id) {
      this.setState({ isLoading: true }, () => {
        this.props.getItem(parseInt(this.props.match.params.id))
          .then(() => this.setState({ isLoading: false }, () => {
            this.setInitialCropParams(this.props.item)
          }))
      })
    } else {
      this.setInitialCropParams(this.props.item)
    }
  }

  setInitialCropParams (item) {
    const { mediaWidth, mediaHeight, imageOptimizedX1, imageOptimizedY1 } = item
    const orientation = getImageOrientation(item)
    this.setState({
      orientation,
      crop: makeAspectCrop({
        x: imageOptimizedX1 / mediaWidth * 100 || 0,
        y: imageOptimizedY1 / mediaHeight * 100 || 0,
        aspect: orientation === 'vertical' ? 9 / 16 : 16 / 9,
        width: 100
      }, mediaWidth / mediaHeight)
    })
  }

  componentDidUpdate (prevProps, prevState) {
    // When the orientation changes, center the crop area
    if (this.state.orientation !== prevState.orientation) {
      // The state's crop width is a percentage relative to the image so
      // subtracting it from the full image width percent (100) and dividing by 2
      // to give the left and right even spaces will center the X and Y coordinates
      const XPercent = (100 - this.state.crop.width) / 2
      const YPercent = (100 - this.state.crop.height) / 2
      this.setState({
        crop: Object.assign({}, this.state.crop, {
          x: this.state.orientation === 'vertical' ? XPercent : 0,
          y: this.state.orientation === 'vertical' ? 0 : YPercent
        })
      })
    }
  }

  changeOrientation (e) {
    const orientation = e.target.getAttribute('orientation')
    this.setState({
      orientation,
      crop: makeAspectCrop({
        x: 0,
        y: 0,
        aspect: orientation === 'vertical' ? 9 / 16 : 16 / 9,
        width: 100
      }, this.props.item.mediaWidth / this.props.item.mediaHeight)
    })
  }

  onChange (crop, pixelCrop) {
    this.setState({ crop })
  }

  handleSave () {
    const { x, y, width, height } = this.state.crop
    const { mediaWidth, mediaHeight } = this.props.item
    // get the right proportions relative to the original width and height,
    // everything is divided by 100 since all the crop attributes are percentages
    const data = {
      x1: x / 100 * mediaWidth,
      x2: (x + width) / 100 * mediaWidth,
      y1: y / 100 * mediaHeight,
      y2: (y + height) / 100 * mediaHeight
    }

    this.props.cropItem(this.props.item.id, data)
      .then(this.props.renderMessage('Cropping item'))
      .then(() => this.props.history.goBack())
  }

  render () {
    if (this.state.isLoading) return <div />

    return (
      <CropperBackground>
        <OuterHeader>
          <CenteredSpaceBetween>
            <span>
              <GraySpan>Cropping :</GraySpan>
              <span>{` ${this.props.item.name}`}</span>
            </span>
            <div>
              <GrayButton small='true'
                onClick={() => this.props.history.goBack()}>Cancel</GrayButton>
              <BlueButton
                marginleft='10px' small='true' onClick={this.handleSave}>
                  Save
              </BlueButton>
            </div>
          </CenteredSpaceBetween>
        </OuterHeader>
        <Background>
          <CenteredSpaceBetween>
            <StyledFlexColumn>
              <OrientationContainer>
                <Rectangle orientation='horizontal' marginright='20px'
                  selected={this.state.orientation === 'horizontal'}
                  onClick={this.changeOrientation} />
                <Rectangle orientation='vertical'
                  selected={this.state.orientation === 'vertical'}
                  onClick={this.changeOrientation} />
              </OrientationContainer>
              <CropperContainer>
                <ReactCrop
                  keepSelection
                  src={this.props.item.imageThumb || this.props.item.image}
                  onChange={this.onChange}
                  crop={this.state.crop} />
              </CropperContainer>
            </StyledFlexColumn>
          </CenteredSpaceBetween>
        </Background>
      </CropperBackground>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ItemCropper)
