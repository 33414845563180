import { connect } from 'react-redux'
import { getGroups, clearGroups } from '../../actions/GroupActions'
import { clearSearchAndFilter } from '../../actions/SearchAndFilterActions'
import Collection from '../ContentCollection'
import { GROUPS } from '../../constants/ContentConstants'

const mapStateToProps = ({ groups, searchAndFilter }) => {
  return {
    type: GROUPS,
    content: groups.groups.data,
    isLast: groups.groups.isLast,
    count: groups.groups.count,
    error: groups.error,
    searchQueryParams: searchAndFilter
  }
}

const mapDispatchToProps = dispatch => ({
  getContent: (pagination, searchAndFilter) => dispatch(getGroups({...pagination, ...searchAndFilter})),
  clearContent: () => dispatch(clearGroups()),
  clearSearchAndFilter: () => dispatch(clearSearchAndFilter())
})

export default connect(mapStateToProps, mapDispatchToProps)(Collection)
