import React from 'react'
import styled from 'styled-components'

import {
  FlexColumnContainer, FlexContainer, GrayButton
} from '../StyledComponents'

const StyledContentContainer = styled.div`
  margin-left: 20px;
`

const StyledFlexColumnContainer = styled(FlexColumnContainer)`
  margin: 20px 0;
  width: 100%;
`

const StyledHeader = styled.h6`
  margin-bottom: 15px;
`

const StyledGrayButton = styled(GrayButton)`
  transition: none;
`

export const IndividualContainer = styled(FlexContainer)`
  line-height: 30px;
  justify-content: space-between;
`

export default function ProviderContent (props) {
  const { provider, toggleModal } = props
  let providerContent
  if (props.provider) {
    providerContent = (
      <IndividualContainer >
        <span>{provider.name}</span>
        <StyledGrayButton small='true' onClick={toggleModal}>
          Change
        </StyledGrayButton>
      </IndividualContainer>
    )
  } else {
    providerContent = (
      <IndividualContainer >
        <StyledGrayButton small='true'
          onClick={toggleModal}>
          Choose
        </StyledGrayButton>
      </IndividualContainer>
    )
  }

  return (
    <StyledFlexColumnContainer>
      <StyledHeader>Provider</StyledHeader>
      <StyledContentContainer>
        {providerContent}
      </StyledContentContainer>
    </StyledFlexColumnContainer>
  )
}
