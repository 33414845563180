import * as contentTypes from './ContentConstants'

export const RELATED_CONTENT = {
  [contentTypes.ARTISTS]: [
    contentTypes.ITEMS, contentTypes.PLAYLISTS, contentTypes.CATEGORIES,
    contentTypes.CHANNELS, contentTypes.EDITORIALS
  ],
  [contentTypes.CATEGORIES]: [
    contentTypes.ITEMS, contentTypes.PLAYLISTS, contentTypes.ARTISTS,
    contentTypes.CHANNELS, contentTypes.EDITORIALS, contentTypes.GROUPS
  ],
  [contentTypes.PLAYLISTS]: [
    contentTypes.ITEMS, contentTypes.CATEGORIES, contentTypes.CHANNELS
  ],
  [contentTypes.CHANNELS]: [
    contentTypes.ITEMS, contentTypes.PLAYLISTS, contentTypes.ARTISTS,
    contentTypes.CATEGORIES, contentTypes.EDITORIALS
  ],
  [contentTypes.ITEMS]: [
    contentTypes.PLAYLISTS, contentTypes.CATEGORIES, contentTypes.CHANNELS
  ],
  [contentTypes.PROVIDERS]: [
    contentTypes.ITEMS, contentTypes.PLAYLISTS
  ],
  [contentTypes.EDITORIALS]: [
    contentTypes.ARTISTS, contentTypes.CHANNELS, contentTypes.CATEGORIES
  ],
  [contentTypes.GROUPS]: [contentTypes.CATEGORIES]
}

export const RECEIVE_RELATED_CONTENT = 'RECEIVE_RELATED_CONTENT'
export const REMOVE_RELATED_CONTENT = 'REMOVE_RELATED_CONTENT'
export const CLEAR_RELATED_CONTENT = 'CLEAR_RELATED_CONTENT'
export const RECEIVE_RELATED_CONTENT_ERROR = 'RECEIVE_RELATED_CONTENT_ERROR'
export const CLEAR_RELATED_CONTENT_ERROR = 'CLEAR_RELATED_CONTENT_ERROR'
