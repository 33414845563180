import { css } from 'styled-components'

export const media = {
  largeDesktop: (...args) => css`
    @media (max-width: 1170px) {
      ${css(...args)}
    }
  `,
  desktop: (...args) => css`
    @media (max-width: 992px) {
      ${css(...args)}
    }
  `,
  tablet: (...args) => css`
    @media (max-width: 768px) {
      ${css(...args)}
    }
  `,
  mobile: (...args) => css`
    @media (max-width: 480px) {
      ${css(...args)}
    }
  `
}
