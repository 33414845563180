import React from 'react'
import { getDate, getTime, formatDateAndTime, isValidDate, getCurrentDate } from '../../utils/DateFormatter'

export default class DateTimePicker extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      date: '',
      time: ''
    }

    this.handleDateChange = this.handleDateChange.bind(this)
    this.handleRefresh = this.handleRefresh.bind(this)
    this.handleClear = this.handleClear.bind(this)
  }

  componentWillReceiveProps (nextProps) {
    this.setState({
      date: getDate(nextProps.currentDateTime),
      time: getTime(nextProps.currentDateTime)
    })
  }

  handleRefresh () {
    this.props.handleChange(formatDateAndTime(getCurrentDate()), this.props.field)
  }

  handleClear () {
    this.props.handleChange(null, this.props.field)
  }

  handleDateChange (e) {
    this.setState({ [e.target.name]: e.target.value }, () => {
      const combinedDateAndTime = `${this.state.date}T${this.state.time}`
      if (isValidDate(combinedDateAndTime)) {
        const convertedDate = formatDateAndTime(combinedDateAndTime)
        this.props.handleChange(convertedDate, this.props.field)
      }
    })
  }
}
