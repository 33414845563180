import React from 'react'
import styled from 'styled-components'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import pluralize, { singular } from 'pluralize'
import {
  selectAllContent, clearSelectedContent
} from '../actions/SelectedContentActions'
import { deleteRelatedContent, addRelatedContent } from '../actions/RelatedContentActions'
import { FlexContainer, GrayButton, BlueButton, CenteredSpaceBetween } from './StyledComponents'
import SuperPicker from './edit/SuperPicker'
import { PLAYLISTS, ITEMS } from '../constants/ContentConstants'

// 250px accounts for the sidebar width
const FixedFooter = styled.div`
  position: fixed;
  left: 250px;
  bottom: 0;
  width: calc(100% - 250px);
  background-color: ${props => props.theme.midLightGray};
  border-top: 1px solid ${props => props.theme.lightGray};

`

const SelectAll = styled.span`
  margin-left: 20px;
  font-size: 16px;
  cursor: pointer;
`

const mapStateToProps = ({ selectedContent }) => ({
  type: selectedContent.type,
  selectedContent: selectedContent.content
})

const mapDispatchToProps = (dispatch) => ({
  batchSelect: content => dispatch(selectAllContent(content)),
  clearSelectedContent: () => dispatch(clearSelectedContent()),
  batchAddToPlaylist: (ids, playlist, type) => dispatch(addRelatedContent(PLAYLISTS, playlist.id, type, ids)),
  deleteRelatedContent: (parentType, id, childType, children) => {
    return dispatch(deleteRelatedContent(parentType, id, childType, children))
  }
})

function BatchSelectFooter (props) {
  const { type, selectedContent, parentType, parentId, isRelatedContent } = props

  const selectedCount = selectedContent.length
  if (!selectedCount) return null

  let editBtn, addToPlaylistBtn
  if (type === ITEMS) {
    editBtn = (
      <BlueButton small='true' marginleft='10px'
        onClick={() => props.history.push(`/${type}/batch`)}>
        Edit
      </BlueButton>
    )
    addToPlaylistBtn = (
      <SuperPicker
        render={(ctx) => {
          return (
            <GrayButton
              small='true'
              marginleft='10px'
              marginbottom='-15px'
              onClick={ctx.toggleModal}>
              Add to playlist
            </GrayButton>
          )
        }}
        relatedContentType={PLAYLISTS}
        getAll
        baseType={props.selectedConentType}
        createRelationship={
          (playlist) => props.batchAddToPlaylist(selectedContent, playlist, type)
        } />
    )
  }

  let removeRelatedBtn
  if (isRelatedContent) {
    removeRelatedBtn = (
      <GrayButton small='true' marginleft='10px' onClick={() => {
        return props.deleteRelatedContent(parentType, parentId, type, selectedContent)
      }}>
        {`Remove ${pluralize(type, selectedContent.length)} from ${singular(parentType)}`}
      </GrayButton>
    )
  }

  return (
    <FixedFooter>
      <CenteredSpaceBetween>
        <FlexContainer>
          {`${pluralize(type, selectedCount, true)} selected`}
          <SelectAll onClick={() => props.batchSelect(props.selectAllOptions)}>
            Select All
          </SelectAll>
        </FlexContainer>
        <FlexContainer alignItems='flex-start'>
          <GrayButton small='true' onClick={() => props.clearSelectedContent()}>
            Cancel
          </GrayButton>
          {addToPlaylistBtn}
          {removeRelatedBtn}
          {editBtn}
        </FlexContainer>
      </CenteredSpaceBetween>
    </FixedFooter>
  )
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(BatchSelectFooter))
