import { connect } from 'react-redux'
import { getEditorial } from '../../actions/EditorialActions'
import { EDITORIALS } from '../../constants/ContentConstants'

import ViewContent from '../ViewContent'

const mapStateToProps = state => {
  return {
    type: EDITORIALS,
    content: state.editorials.current
  }
}

const mapDispatchToProps = dispatch => ({
  getContent: payload => dispatch(getEditorial(payload))
})

export default connect(mapStateToProps, mapDispatchToProps)(ViewContent)
