import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import styled, { ThemeProvider } from 'styled-components'
import { Form } from 'reactstrap'

import {
  BlueButton, FullHeightFlexContainer, StyledAlert
} from './StyledComponents'
import { getLoginRandomItem } from '../actions/AuthActions'
import { getImageOrientation } from '../utils/ImageUtils'

import { theme } from '../../assets/theme.js'
import { media } from '../../assets/media-queries.js'
import meuralLogo from '../../assets/meural-logo-bronze.png'
import { getLoginUrl } from '../utils/Cognito'

const StyledFormContainer = styled.div`
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
`

const StyledLogo = styled.img`
  width: 100%;
  display: block;
  margin: 0 auto;
  max-width: 280px;
`

const StyledLogoContainer = styled.div`
  width: 100%;
  padding-bottom: 40px;
  margin-bottom: 30px;
`

const StyledForm = styled(Form)`
  width: 50%;
`

const StyledImg = styled.img`
  width: ${props => props.orientation === 'vertical' ? '100%' : 'auto'};
  height: ${props => props.orientation === 'vertical' ? 'auto' : '100%'};
  position: ${props => props.orientation === 'horizontal' ? 'absolute' : 'static'};
  left: ${props => props.orientation === 'horizontal' ? `-${props.item.originalWidth / 10}px` : 0};

  ${media.desktop`
    width: auto;
  `}
`

const StyledImgContainer = styled.div`
  width: 50%;
  height: 100%;
  position: relative;
  overflow: hidden;
`

const StyledBlueButton = styled(BlueButton)`
  margin-top: 30px;
`

function mapStateToProps ({ auth }) {
  return {
    loggedIn: auth.loggedIn,
    error: auth.error,
    item: auth.item
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({ getLoginRandomItem }, dispatch)
}

class Auth extends Component {
  constructor (props) {
    super(props)

    this.state = {
      invalidLoginError: null,
    }

    this.handleSubmit = this.handleSubmit.bind(this)
  }

  componentWillMount () {
    if (this.props.loggedIn) {
      this.props.history.push('/')
    } else {
      this.props.getLoginRandomItem()
    }
  }

  componentWillReceiveProps (nextProps) {
    if (nextProps.error) {
      const errorKeys = Object.keys(nextProps.error)
      const stateKeys = Object.keys(this.state)
      const errors = {}
      if (errorKeys.length > 0) {
        errorKeys.forEach(key => {
          const errorValue = nextProps.error[key]
          // non-field error (e.g invalid credentials)
          if (!stateKeys.includes(key)) {
            errors['invalidLoginError'] = errorValue
          } else {
            errors[`${key}Error`] = errorValue
          }
        })
      }

      this.setState(errors)
    }
  }


  handleSubmit (e) {
    e.preventDefault()
    window.location.replace(getLoginUrl())

  }

  render () {
    const alertError = this.state.invalidLoginError
                     ? <StyledAlert color='danger'>{this.state.invalidLoginError}</StyledAlert>
                     : null
    return (
      <ThemeProvider theme={theme}>
        <FullHeightFlexContainer>
          {alertError}
          <StyledFormContainer>
            <StyledForm>
              <StyledLogoContainer>
                <StyledLogo src={meuralLogo} />
              </StyledLogoContainer>
              <StyledBlueButton type='submit' fullwidth='true'
                onClick={this.handleSubmit}><b>Login</b></StyledBlueButton>
            </StyledForm>
          </StyledFormContainer>
          <StyledImgContainer>
            <StyledImg
              src={this.props.item.image}
              item={this.props.item}
              orientation={getImageOrientation(this.props.item)} />
          </StyledImgContainer>
        </FullHeightFlexContainer>
      </ThemeProvider>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Auth)
