import { connect } from 'react-redux'
import { getChannels, clearChannels } from '../../actions/ChannelActions'
import { clearSearchAndFilter } from '../../actions/SearchAndFilterActions'
import Collection from '../ContentCollection'
import { CHANNELS } from '../../constants/ContentConstants'

const mapStateToProps = ({ channels, searchAndFilter }) => {
  return {
    type: CHANNELS,
    content: channels.channels.data,
    isLast: channels.channels.isLast,
    count: channels.channels.count,
    error: channels.error,
    searchQueryParams: searchAndFilter
  }
}

const mapDispatchToProps = dispatch => ({
  getContent: (pagination, searchAndFilter) => dispatch(getChannels({...pagination, ...searchAndFilter})),
  clearContent: () => dispatch(clearChannels()),
  clearSearchAndFilter: () => dispatch(clearSearchAndFilter())
})

export default connect(mapStateToProps, mapDispatchToProps)(Collection)
