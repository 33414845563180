import React from 'react'
import styled from 'styled-components'
import { Icon } from './StyledComponents'

const BatchSelectContainer = styled.div`
  border: 1px solid ${props => props.isOverlay ? props.theme.white : props.theme.mediumGray};
  border-radius: 50%;
  width: 28px;
  height: 28px;
  background-color: ${props => props.selected ? props.theme.blue : 'transparent'};
  transition: 0.2s ease border;

  &:hover {
    border: 1px solid ${props => props.theme.mediumGray};
  }
`

const CheckIcon = styled(Icon)`
  display: ${props => props.selected ? 'block' : 'none'};
  color: ${props => props.theme.white};
`

export default function BatchSelectIcon (props) {
  return (
    <BatchSelectContainer
      selected={props.selected}
      onClick={props.onSelect}
      isOverlay={props.isOverlay}>
      <CheckIcon
        className='material-icons'
        fontSize='24px'
        selected={props.selected}>
        check
      </CheckIcon>
    </BatchSelectContainer>
  )
}
