import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Route, Redirect } from 'react-router-dom'
import { loginUserWithToken } from '../actions/AuthActions'
import { getAuthorizationCode } from '../utils/Cognito'

function mapStateToProps (state) {
  return { auth: state.auth }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({ loginUserWithToken }, dispatch)
}

class AuthorizedRoute extends Component {
  componentWillMount () {
    if (!this.props.auth.loggedIn) {
      getAuthorizationCode(this.props.loginUserWithToken)
    }
  }

  render () {
    const { component: Component, ...rest } = this.props
    const { loggedIn, loading } = this.props.auth
    return (
      <Route {...rest} render={props => {
        if (loading) {
          return <span>Loading...</span>
        } else if (loggedIn) {
          return <Component {...this.props} />
        } else {
          return <Redirect to='/login' />
        }
      }} />
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AuthorizedRoute)
