import * as constants from '../constants/HistoryConstants'
import * as API from '../api/History'
import { ARTISTS } from '../constants/ContentConstants'
import { getObject } from '../api/General'
import { receiveErrorMessage } from './MessageActions'

export const getHistory = (type, id) => dispatch => {
  return Promise.all([
    getObject(type, id),
    API.getHistory(type, id)
  ]).then(values => {
    const object = values[0].data
    const events = values[1].data
    dispatch({
      type: constants.RECEIVE_HISTORY,
      data: { object, events, type }})
  })
  .catch(error => {
    dispatch(receiveErrorMessage(error))
    return dispatch({ type: constants.RECEIVE_HISTORY_ERROR, error })
  })
}

export const clearHistory = () => dispatch => {
  return dispatch({ type: constants.CLEAR_HISTORY })
}
