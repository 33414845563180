import React, { Component } from 'react'
import styled from 'styled-components'
import { RedButton } from '../StyledComponents'
import AbstractModal from '../AbstractModal'

const ConfirmCancelText = styled.p`
  font-weight: 500;
  font-size: 16px;
`

const CancelReview = styled.span`
  font-weight: 500;
  cursor: pointer;
`

function CancelReviewModal (props) {
  return (
    <AbstractModal isOpen={props.isOpen} toggleModal={props.toggleModal}>
      <ConfirmCancelText>
        Are you sure you want to cancel this review?
      </ConfirmCancelText>
      <RedButton fullwidth='true' small='true' onClick={props.onCancel}>
        Yes, cancel this review
      </RedButton>
    </AbstractModal>
  )
}

export default class CancelReviewButton extends Component {
  constructor (props) {
    super(props)

    this.state = {
      isOpen: false
    }

    this.toggleModal = this.toggleModal.bind(this)
    this.cancelAndToggle = this.cancelAndToggle.bind(this)
  }

  toggleModal () {
    this.setState({ isOpen: !this.state.isOpen })
  }

  cancelAndToggle () {
    this.props.onCancel()
    this.toggleModal()
  }

  render () {
    return (
      <React.Fragment>
        <CancelReview onClick={this.toggleModal}>Cancel review</CancelReview>
        <CancelReviewModal
          isOpen={this.state.isOpen}
          toggleModal={this.toggleModal}
          onCancel={this.cancelAndToggle} />
      </React.Fragment>
    )
  }
}
