import fetchWrapper from '../utils/FetchWrapper'
import { formatQueryString } from '../utils/FormatQueryString'

export function getPlaylists (queryParams) {
  return fetchWrapper(`curator`, `/galleries${formatQueryString(queryParams)}`)
}

export function getPlaylist (id) {
  return fetchWrapper(`curator`, `/galleries/${id}`)
}

export function getPlaylistItems (id, count = 1000) {
  return fetchWrapper(`curator`, `/galleries/${id}/items?count=${count}`)
}

export function getPlaylistCategories (id) {
  return fetchWrapper('curator', `/galleries/${id}/categories`)
}

export function getPlaylistChannels (id) {
  return fetchWrapper('curator', `/galleries/${id}/channels`)
}

export function createPlaylist (data) {
  return fetchWrapper('curator', `/galleries`, {
    method: 'POST',
    body: data
  })
}

export function updatePlaylist (id, data) {
  return fetchWrapper('curator', `/galleries/${id}`, {
    method: 'PUT',
    body: data
  })
}

export function deletePlaylist (id) {
  return fetchWrapper('curator', `/galleries/${id}`, {
    method: 'DELETE'
  })
}

export function addRelatedContent (id, childType, childId) {
  return fetchWrapper('curator', `/galleries/${id}/${childType}/${childId}`, {
    method: 'POST'
  })
}

export function getRelatedPlaylists (parentType, parentId, queryParams) {
  return fetchWrapper(`curator`, `/${parentType}/${parentId}/galleries${formatQueryString(queryParams)}`)
}

export function sortPlaylistItems (id, order) {
  return fetchWrapper(`curator`, `/galleries/${id}/sort`, {
    method: 'PUT',
    body: { order }
  })
}

export function sortRelatedContent (id, order, type) {
  // TODO: abstract sorting urls so type can be used to determine the endpoint
  return fetchWrapper(`curator`, `/galleries/${id}/sort`, {
    method: 'PUT',
    body: { order }
  })
}
