import React from 'react'
import styled from 'styled-components'
import FadeInImage from './FadeInImage'
import { Block } from '../../styles/Box'

const BlockSpreader = styled(Block)`
  position: relative;
  width: 100%;
  background-color: ${props => props.theme.colors.lightGray};
  overflow: hidden;
  border-radius: ${props => props.circle ? '50%' : '0%'};

  &:before {
    display: block;
    content: "";
    width: 100%;
    padding-top: ${props => ((props.xHeight / props.xWidth) * 100)}%;
  }
`

const BlockSetter = styled(Block)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`

export default function FixedAspectRatioImage (props) {
  return (
    <BlockSpreader xHeight={props.height} xWidth={props.width} circle={props.circle}>
      <BlockSetter>
        <FadeInImage src={props.src} cover alt={props.alt} circle={props.circle} />
      </BlockSetter>
    </BlockSpreader>
  )
}
