import * as constants from '../constants/PlaylistConstants'
import { DELETE_REVIEW, RECEIVE_REVIEW } from '../constants/ReviewConstants'
import { PLAYLISTS } from '../constants/ContentConstants'

const _nullPlaylists = {
  error: null,
  playlists: {
    data: [],
    isLast: false,
    count: null
  },
  current: {
    pendingReviews: []
  }
}

export default function PlaylistReducer (state = _nullPlaylists, action) {
  switch (action.type) {
    case constants.RECEIVE_PLAYLISTS: {
      const { isLast, data, count } = action.playlists
      const { newQuery } = action
      const newPlaylists = {
        isLast: isLast,
        data: newQuery ? data : [].concat(state.playlists.data, data),
        count
      }
      return Object.assign({}, state, { playlists: newPlaylists })
    }
    case constants.RECEIVE_PLAYLIST: {
      return Object.assign({}, state, {
        current: Object.assign({}, state.current, action.playlist)
      })
    }
    case DELETE_REVIEW: {
      if (!action.contentType === PLAYLISTS) return state

      const newPendingReviews = Object.assign({}, {
        pendingReviews: state.current.pendingReviews
          .filter(review => review.id !== action.reviewId)
      })

      return Object.assign({}, state, {
        current: Object.assign({}, state.current, newPendingReviews)
      })
    }
    case RECEIVE_REVIEW: {
      if (!action.contentType === PLAYLISTS) return state

      return Object.assign({}, state, {
        current: Object.assign({}, state.current, action.review.contentObject)
      })
    }
    case constants.RECEIVE_PLAYLISTS_ERROR: {
      return Object.assign({}, state, { error: action.error })
    }
    case constants.CLEAR_PLAYLISTS: {
      return Object.assign({}, _nullPlaylists)
    }
    default: {
      return state
    }
  }
}
