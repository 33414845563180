import Reset from './Reset'
import Fonts from './Fonts'
import Icons from './Icons'
import Base from './Base'
import Typography from './Typography'
import Cropper from './Cropper'
import Video from './Video'

export default () => `
  ${Reset}
  ${Fonts}
  ${Icons}
  ${Base}
  ${Typography}
  ${Cropper}
  ${Video}
`
