import React from 'react'
import styled from 'styled-components'
import { Label, Input, FormGroup } from 'reactstrap'
import { ColorBadge } from '../StyledComponents'

const RadioButtonsContainer = styled.div`
  padding: 15px 0;
  margin: 15px 0;
`

export default class RadioButtons extends React.Component {
  render () {
    const { value, label, name, options } = this.props.content
    return (
      <RadioButtonsContainer>
        <h6>{label}</h6>
        {
          options.map((option, idx) => {
            return (
              <FormGroup key={idx} check>
                <Label check>
                  <Input type='radio' value={option.value} name={name}
                    id={name} checked={option.value === value}
                    onChange={this.props.handleRadioChange} />
                  <ColorBadge color={option.badgeColor}>{option.badgeText}</ColorBadge>
                  {option.description}
                </Label>
              </FormGroup>
            )
          })
        }
      </RadioButtonsContainer>

    )
  }
}
