import React, { Component, Fragment } from 'react'
import styled from 'styled-components'
import DotDotDotIcon from './DotDotDotIcon'
import { HoverOverlay } from './ContentCard'
import BatchSelectIcon from './BatchSelectIcon'

const SelectedOverlay = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  border-radius: 4px;
  background-color: ${props => props.selected ? 'rgba(0, 0, 0, 0.7)' : ''};
`

const BatchSelectIconContainer = styled.div`
  position: absolute;
  top: 12px;
  left: 12px;
`

export default class Overlay extends Component {
  constructor (props) {
    super(props)

    this.state = {
      showOverlay: false
    }

    this.toggleOverlay = this.toggleOverlay.bind(this)
  }

  toggleOverlay () {
    this.setState({ showOverlay: !this.state.showOverlay })
  }

  render () {
    const {
      content, contentType, allowBatch, allowAdd, selected, onDelete, onEdit,
      onSelect
    } = this.props

    let batchIcon
    if (allowBatch) {
      batchIcon = (
        <BatchSelectIconContainer>
          <BatchSelectIcon selected={selected} onSelect={onSelect} isOverlay />
        </BatchSelectIconContainer>
      )
    }

    return (
      <Fragment>
        <SelectedOverlay selected={this.props.selected}>
          {this.props.selected && batchIcon}
        </SelectedOverlay>
        <HoverOverlay selected={selected} overlay={this.state.showOverlay ? 1 : 0}>
          {batchIcon}
          <DotDotDotIcon
            isOverlay
            allowAdd={allowAdd}
            onClick={this.toggleOverlay}
            onDelete={onDelete}
            onEdit={onEdit}
            content={content}
            contentType={contentType} />
        </HoverOverlay>
      </Fragment>
    )
  }
}
