import React, { Component } from 'react'
import { connect } from 'react-redux'
import HistoryTable from './HistoryTable'
import { getHistory, clearHistory } from '../../actions/HistoryActions'
import { EditWrapPage } from '../StyledEditComponents'
import ViewHeader from '../ViewHeader'

const mapStateToProps = ({ objectHistory }) => ({ objectHistory })

const mapDispatchToProps = dispatch => ({
  getHistory: (type, id) => dispatch(getHistory(type, id)),
  clearHistory: () => dispatch(clearHistory())
})

class ViewHistory extends Component {
  constructor (props) {
    super(props)

    this.state = {
      loading: true
    }
  }

  componentDidMount () {
    const type = this.props.location.pathname.split('/')[1]
    const id = this.props.location.pathname.split('/')[2]
    this.props.getHistory(type, id)
    .then(() => this.setState({ loading: false }))
  }

  componentWillUnmount () {
    this.props.clearHistory()
  }

  render () {
    const { object, events, type } = this.props.objectHistory
    if (!events.length) return <div />

    return (
      <div>
        <ViewHeader
          type={type}
          content={object}
          editLink={`/${type}/${object.id}/edit`} />
        <EditWrapPage>
          <HistoryTable events={events} />
        </EditWrapPage>
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ViewHistory)
