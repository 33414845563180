import * as constants from '../constants/ArtistConstants'
import * as API from '../api/Artists'
import { receiveErrorMessage, receiveSuccessMessage } from './MessageActions'
import { RECEIVE_RELATED_CONTENT } from '../constants/RelatedContentConstants'
import { ARTISTS } from '../constants/ContentConstants'
import { getRelatedContent as getRelatedContentAction } from './RelatedContentActions'
import { getRelatedContent } from '../api/General'

export const getArtists = (queryParams) => dispatch => {
  return API.getArtists(queryParams)
    .then(artists => dispatch({
      type: constants.RECEIVE_ARTISTS, artists, newQuery: queryParams.newQuery
    })).catch(error => dispatch({ type: constants.RECEIVE_ARTISTS_ERROR, error }))
}

const getOnlyArtist = (id) => dispatch => {
  return API.getArtist(id).then(({ data: artist }) => dispatch({
    type: constants.RECEIVE_ARTIST, artist
  })).catch(error => dispatch({ type: constants.RECEIVE_ARTISTS_ERROR, error }))
}

export const getArtist = (id, childType, opts = { queryParams: null }) => dispatch => {
  if (!childType) return dispatch(getOnlyArtist(id))

  if (!opts.queryParams.newQuery) {
    return dispatch(getRelatedContentAction(ARTISTS, id, childType, opts.queryParams))
  } else {
    return Promise.all([
      API.getArtist(id),
      getRelatedContent(ARTISTS, id, childType, opts.queryParams)
    ]).then(values => {
      const artist = values[0].data
      const relatedContent = values[1]
      dispatch({ type: constants.RECEIVE_ARTIST, artist })
      dispatch({
        type: RECEIVE_RELATED_CONTENT,
        content: relatedContent,
        contentType: opts.relatedType,
        newQuery: opts.queryParams.newQuery
      })
    }).catch(error => dispatch({ type: constants.RECEIVE_ARTISTS_ERROR, error }))
  }
}

export const updateArtist = (artistId, data) => dispatch => {
  return API.updateArtist(artistId, data).then(({ data: artist }) => {
    dispatch({ type: constants.RECEIVE_ARTIST, artist })
  }).then(() => dispatch(receiveSuccessMessage('Artist updated')))
    .catch(error => {
      dispatch(receiveErrorMessage(error))
      return dispatch({ type: constants.RECEIVE_ARTISTS_ERROR, error })
    })
}

export const createArtist = (data) => dispatch => {
  return API.createArtist(data).then(({ data: artist }) => {
    dispatch({ type: constants.RECEIVE_ARTIST, artist })
  }).then(() => dispatch(receiveSuccessMessage('Artist created')))
    .catch(error => {
      dispatch(receiveErrorMessage(error))
      return dispatch({ type: constants.RECEIVE_ARTISTS_ERROR, error })
    })
}

export const deleteArtist = (id) => dispatch => {
  return API.deleteArtist(id)
    .then(() => dispatch(receiveSuccessMessage('Artist deleted')))
    .catch(error => {
      dispatch({ type: constants.RECEIVE_ARTISTS_ERROR, error })
      return Promise.reject(error)
    })
}

export const clearArtists = () => dispatch => dispatch({ type: constants.CLEAR_ARTISTS })
