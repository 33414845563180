import artist from '../../assets/placeholders/artist.png'
import category from '../../assets/placeholders/category.png'
import group from '../../assets/placeholders/group.png'
import item from '../../assets/placeholders/item.png'
import { ITEMS, ARTISTS, CATEGORIES, USERS } from '../constants/ContentConstants'
import other from '../../assets/placeholders/other.png'

const PLACEHOLDERS = {
  [ARTISTS]: artist,
  [CATEGORIES]: category,
  [ITEMS]: item,
  [USERS]: artist,
  group
}

export const checkForImage = (image, type) => {
  return image
    ? image.imageThumbOptimized || image.imageThumb
    : PLACEHOLDERS[type] || other
}

// we get different data coming back from the image
export const getImageOrientation = (image) => {
  // v0 endpoint used for the login image
  const {
    croppedHeight, croppedWidth, originalHeight, originalWidth
  } = image
  // curator endpoint
  const {
    mediaWidth, mediaHeight, imageOptimizedWidth, imageOptimizedHeight
  } = image

  if (originalWidth) {
    return getOrientation(
      croppedWidth, croppedHeight, originalWidth, originalHeight
    )
  } else if (mediaWidth) {
    return getOrientation(
      imageOptimizedWidth, imageOptimizedHeight, mediaWidth, mediaHeight
    )
  }
}

const getOrientation = (croppedWidth, croppedHeight, originalWidth, originalHeight) => {
  if (croppedHeight === 0 && croppedWidth === 0) {
    return originalHeight > originalWidth ? 'vertical' : 'horizontal'
  } else {
    return croppedHeight > croppedWidth ? 'vertical' : 'horizontal'
  }
}

export const checkIfImageExists = (image, type) => {
  return image ? image : PLACEHOLDERS[type] || other
}
