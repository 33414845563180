import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import styled from 'styled-components'
import { Alert } from 'reactstrap'
import pluralize from 'pluralize'
import CancelReviewButton from './CancelReviewButton'
import ApproveReviewButton from './ApproveReviewButton'
import { cancelReview, approveReview } from '../../actions/ReviewActions'
import { Bold } from '../StyledComponents'

const ReviewBanner = styled(Alert)`
  width: 100%;
  background-color: ${props => props.theme.orangeRGBA(0.3)};
  border-color: ${props => props.theme.orangeRGBA(0.3)};
  color: ${props => props.theme.darkGray};
  font-size: 14px;
  display: flex;
  justify-content: space-between;
`

const mapStateToProps = ({ auth }) => ({ user: auth.user })

const mapDispatchToProps = (dispatch) => bindActionCreators({
  cancelReview,
  approveReview
}, dispatch)

class InReviewBanner extends Component {
  constructor (props) {
    super(props)

    this.handleApprove = this.handleApprove.bind(this)
    this.handleCancel = this.handleCancel.bind(this)
  }

  handleApprove (reviewId) {
    this.props.approveReview(reviewId, this.props.type)
      .then(() => {
        if (this.props.onApprove) this.props.onApprove()
      })
  }

  handleCancel (reviewId) {
    this.props.cancelReview(reviewId, this.props.type)
      .then(() => {
        if (this.props.onCancel) this.props.onCancel(reviewId)
      })
  }

  render () {
    const { pendingReviews } = this.props.content
    if (!pendingReviews || (pendingReviews && !pendingReviews.length)) return <div />

    const review = pendingReviews[0]
    const reviewBy = review.reviewer.id === this.props.user.id
      ? 'you'
      : review.reviewer.email
    const canApprove = this.props.user.id === review.reviewer.id
    const canCancel = this.props.user.id === review.reviewee.id ||
    this.props.user.id === review.reviewer.id

    let cancelButton
    if (canCancel) {
      cancelButton = (
        <CancelReviewButton
          onCancel={() => this.handleCancel(review.id)} />
      )
    }

    let approveButton
    if (canApprove) {
      approveButton = (
        <ApproveReviewButton onApprove={() => this.handleApprove(review.id)} />
      )
    }

    return (
      <ReviewBanner>
        <span>
          This {`${pluralize.singular(this.props.type)}`} is pending
          review by <Bold>{reviewBy}</Bold>.
        </span>
        <div>
          {cancelButton}
          {approveButton}
        </div>
      </ReviewBanner>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(InReviewBanner)
