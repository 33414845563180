import React, { Component, Fragment } from 'react'
import styled from 'styled-components'
import { withRouter } from 'react-router-dom'
import {
  WrapContainer, BlueButton, Icon, AddCard, Grid
} from './StyledComponents'
import ContentCard from './ContentCard'
import SearchAndFilter from './SearchAndFilter'
import Chrome from './Chrome'
import ViewHeader from './ViewHeader'
import BatchSuperPicker from './edit/BatchSuperPicker'
import Loader from './Loader'
import BatchSelectFooter from './BatchSelectFooter'
import LogoLoader from './LogoLoader'

const PAGE_SIZE = 32

const AddIcon = styled(Icon)`
  font-size: 46px;
  color: ${props => props.theme.lightGray};
`

class Collection extends Component {
  constructor (props) {
    super(props)

    this.state = {
      currentPage: 0,
      disabled: false
    }

    this._isMounted = false

    this.fetchNextPage = this.fetchNextPage.bind(this)
    this.onSelect = this.onSelect.bind(this)
  }

  componentDidMount () {
    this._isMounted = true
    if (this.props.clearSelectedContent) { this.props.clearSelectedContent() }
  }

  componentWillUnmount () {
    this._isMounted = false
    this.props.clearContent()
  }

  componentDidUpdate (prevProps, prevState) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      this.props.clearContent()
    }
  }

  fetchNextPage (pageSize = PAGE_SIZE, newQuery = false) {
    if (this.state.disabled) return

    this.setState({ disabled: true })
    const nextPage = newQuery ? 1 : this.state.currentPage + 1
    this.props.getContent({count: pageSize, page: nextPage}, {...this.props.searchQueryParams, newQuery})
      .then(() => {
        if (this._isMounted) {
          this.setState({ currentPage: nextPage, disabled: false })
        }
      })
  }

  onSelect (e, content) {
    e.stopPropagation()
    const { onSelect, type } = this.props
    if (onSelect) onSelect(type, content)
  }

  renderChrome () {
    const { content, isRelatedContent, parentType, parent } = this.props

    if (isRelatedContent) {
      return (
        <ViewHeader
          type={parentType}
          content={parent}
          editLink={`/${parentType}/${parent.id}/edit`} />
      )
    } else {
      return (
        <Chrome
          isRelatedContent={isRelatedContent}
          selectedContent={this.props.selectedContent}
          count={this.props.count}
          hasSearched={this.state.disabled}
          type={this.props.type}
          onSelectAll={e => this.props.batchSelect(content)}
          clearSelectedContent={this.props.clearSelectedContent}
          />
      )
    }
  }

  renderContent () {
    const { content, type, allowAdd, deleteRelatedContent } = this.props
    return content.map(content => {
      const isSelected = this.props.selectedContent &&
        !!this.props.selectedContent.some(selected => selected.id === content.id)

      return (
        <ContentCard
          key={content.id}
          {...content}
          content={content}
          type={type}
          selected={isSelected}
          allowBatch={this.props.allowBatch}
          allowAdd={allowAdd}
          onSelect={e => this.onSelect(e, content)}
          deleteRelatedContent={deleteRelatedContent}
        />
      )
    })
  }

  renderSearchAndFilter () {
    const { type } = this.props
    const onSearch = () => {
      this.props.clearSelectedContent && this.props.clearSelectedContent()
      this.fetchNextPage(PAGE_SIZE, true)
    }

    return (
      <SearchAndFilter
        type={type}
        fetchResults={onSearch}
        disabled={this.state.disabled}
      />
    )
  }

  renderAddButton () {
    const { isRelatedContent, allowAdd } = this.props
    if (isRelatedContent && allowAdd) {
      return (
        <BatchSuperPicker
          render={(ctx) => {
            return (
              <AddCard onClick={ctx.toggleModal} color='mediumGray'>
                <AddIcon className='material-icons'>add</AddIcon>
              </AddCard>
            )
          }}
          relatedContentType={this.props.type}
          getAll
          baseType={this.props.parentType}
          currentId={this.props.parent.id}
          afterClose={this.props.addRelatedContent}
          key={0}
        />
      )
    } else if (!isRelatedContent) {
      return (
        <AddCard
          onClick={() => { this.props.history.push(`/${this.props.type}/create`) }}
          key={0}>
          <AddIcon className='material-icons'>add</AddIcon>
        </AddCard>
      )
    }
  }

  render () {
    const { content } = this.props

    let loader
    if (this.state.disabled && !content.length && !this.props.isLast) {
      loader = <Loader />
    }

    let batchSelectFooter
    if (this.props.isRelatedContent) {
      batchSelectFooter = (
        <BatchSelectFooter
          isRelatedContent={this.props.isRelatedContent}
          parentType={this.props.parentType}
          parentId={this.props.parent.id}
          selectAllOptions={this.props.content} />
      )
    }

    let loadMoreBtn
    if (!this.props.isLast) {
      loadMoreBtn = (
        <BlueButton
          fullwidth='true'
          onClick={e => this.fetchNextPage()}
          margin={'15px'}
          >
          {
            this.state.disabled
              ? <LogoLoader color='white' />
              : <b>Load More</b>
          }
        </BlueButton>
      )
    }

    return (
      <div>
        {this.renderChrome()}
        {
          loader ||
          <Fragment>
            <WrapContainer>
              {this.renderSearchAndFilter()}
              <Grid>
                {[this.renderAddButton()].concat(this.renderContent())}
              </Grid>
              {loadMoreBtn}
            </WrapContainer>
            {batchSelectFooter}
          </Fragment>
        }
      </div>
    )
  }
}

export default withRouter(Collection)
