import React from 'react'
import styled from 'styled-components'
import capitalize from 'lodash/capitalize'
import {
  FlexColumnContainer, FlexContainer, LightGrayButton, GrayButton, StyledLabel
} from '../StyledComponents'
import { ARTISTS } from '../../constants/ContentConstants'

const StyledContentContainer = styled.div`
  margin: 0 0 20px 20px;
`

const StyledFlexColumnContainer = styled(FlexColumnContainer)`
  margin: 20px 0;
  width: 100%;
`

const Icon = styled.i`
  color: ${props => props.theme.mediumGray};
  font-weight: bold;
`

const StyledGrayButton = styled(GrayButton)`
  transition: none;
`

export const IndividualContainer = styled(FlexContainer)`
  line-height: 30px;
  justify-content: space-between;

  .remove-button {
    visibility: hidden;
  }

  &:hover .remove-button {
    visibility: visible;
  }
`

export default class RelatedContentList extends React.Component {
  render () {
    const { relatedContentType, content } = this.props
    const contentList = content.map((contentLi, idx) => {
      const name = relatedContentType === ARTISTS
        ? `${contentLi.firstName} ${contentLi.surname}`
        : contentLi.name

      return (
        <IndividualContainer key={contentLi.id || idx}>
          <span>{name}</span>
          <StyledGrayButton small='true' className='remove-button'
            onClick={this.props.removeRelationship.bind(this, contentLi, relatedContentType)}>
            Remove
          </StyledGrayButton>
        </IndividualContainer>
      )
    })

    return (
      <StyledFlexColumnContainer>
        <StyledLabel error={this.props.error}>{capitalize(relatedContentType)}</StyledLabel>
        <StyledContentContainer>
          {contentList}
        </StyledContentContainer>
        <LightGrayButton name={relatedContentType} onClick={this.props.toggleModal}
          error={this.props.error}>
          <Icon className='material-icons'>add</Icon>
        </LightGrayButton>
      </StyledFlexColumnContainer>
    )
  }
}
