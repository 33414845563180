import React from 'react'
import PickerModal from './PickerModal'

export default class SuperPicker extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      isModalOpen: false
    }

    this.toggleModal = this.toggleModal.bind(this)
    this.onSelectWrapper = this.onSelectWrapper.bind(this)
  }

  toggleModal () {
    this.setState({ isModalOpen: !this.state.isModalOpen }, () => {
      if (this.props.afterClose && !this.state.isModalOpen) {
        this.props.afterClose()
      }
    })
  }

  onSelectWrapper (...args) {
    if (this.props.createRelationship) {
      this.props.createRelationship(...args)
    } else if (this.props.changeRelationship) {
      this.props.changeRelationship(...args)
    }

    if (!this.props.allowBatch) {
      this.toggleModal()
    }
  }

  render () {
    let pickerModal
    if (this.props.children) {
      pickerModal = React.cloneElement(this.props.children, {
        toggleModal: this.toggleModal, isModalOpen: this.state.isModalOpen
      })
    } else {
      pickerModal = (
        <PickerModal
          allowBatch={this.props.allowBatch}
          isModalOpen={this.state.isModalOpen}
          onSelect={this.onSelectWrapper}
          toggleModal={this.toggleModal}
          relatedContentType={this.props.relatedContentType}
          name={this.props.name}
          getAll={this.props.getAll}
          baseType={this.props.baseType}
          selectedIds={this.props.selectedIds || []}
          currentId={this.props.currentId} />
      )
    }

    return (
      <React.Fragment>
        {this.props.render(this)}
        {pickerModal}
      </React.Fragment>
    )
  }
}
