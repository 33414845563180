import React from 'react'
import pluralize from 'pluralize'
import styled from 'styled-components'
import { RedButton, GrayButton } from '../StyledComponents'
import AbstractModal from '../AbstractModal'
import { getDate } from '../../utils/DateFormatter'
import { toFrontend } from '../../constants/ContentConstants'

const ButtonContainer = styled.div`
  display: flex;
  margin-top: 20px;

  button {
    width: 50%;
  }
`

export default function PremiumConfirmationModal (props) {
  const { object, isOpen, toggleModal, onSave, type } = props

  if (!object) return null

  const canvasCountPhrase = pluralize('canvas', object.deviceCount, true)
  const playlistCountPhrase = pluralize('playlist', object.galleryCount, true)
  const boldPremiumDate = <strong>{getDate(object.premiumDate)}</strong>

  let count, warning
  if ('galleryCount' in object && 'deviceCount' in object) {
    count = <p>This item is in <strong>{playlistCountPhrase}</strong> (including
      user playlists) and on <strong>{canvasCountPhrase}</strong>.</p>
    warning = <p>Scheduling this item to become premium will remove it from
    all playlists and canvases on {boldPremiumDate}.</p>
  } else if ('deviceCount' in object) {
    count = <p>This playlist is on <strong>{canvasCountPhrase}</strong>.</p>
    warning = (
      <p>
        Once this playlist becomes premium on {boldPremiumDate},
         it will be removed from all canvases. Additionally,&nbsp;
         <strong>all items in this playlist will become premium</strong>,
         and they will be removed from all non-premium playlists and canvases.
      </p>
    )
  } else if (!object.id) {
    warning = (
      <p>
        You are scheduling this {toFrontend(type)} to become premium on&nbsp;
        {boldPremiumDate}.
      </p>
    )
  }

  return (
    <AbstractModal
      isOpen={isOpen}
      toggleModal={toggleModal}>
      {count}
      {warning}
      <p>
        The date above can be changed, but
        <strong> this process cannot be cancelled</strong>.
        Are you sure you want to do this?
      </p>
      <ButtonContainer>
        <RedButton small='true' onClick={onSave}>Yes, schedule it</RedButton>
        <GrayButton small='true' onClick={toggleModal} marginleft='20px'>Cancel</GrayButton>
      </ButtonContainer>
    </AbstractModal>
  )
}
