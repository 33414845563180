import { connect } from 'react-redux'
import { getGroup } from '../../actions/GroupActions'
import { GROUPS } from '../../constants/ContentConstants'

import ViewContent from '../ViewContent'

const mapStateToProps = state => {
  return {
    type: GROUPS,
    content: state.groups.current
  }
}

const mapDispatchToProps = dispatch => ({
  getContent: payload => dispatch(getGroup(payload))
})

export default connect(mapStateToProps, mapDispatchToProps)(ViewContent)
