import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Route, Redirect } from 'react-router-dom'
import { getFeatureFlag } from '../actions/FeatureFlagAction'
import Auth_v1 from './Auth_v1'
import Auth from './Auth'

function mapStateToProps ({ae, auth}) {
  return {
    version: ae.version,
    error: ae.error,
    loggedIn: auth.loggedIn
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({ getFeatureFlag }, dispatch)
}

class LoginRoute extends Component {
  componentWillMount () {
    if (!this.props.loggedIn) {
      this.props.getFeatureFlag()
    }
  }

  render () {
    const { version } = this.props
    return (
      version === "v2"? <Route path='/login' component={Auth}/>: <Route path='/login' component={Auth_v1}/>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginRoute)
