import styled from 'styled-components'

/**
 * @param {string} height - The exact value to set height to
 * @param {string} width - The exact value to set width to
 * @param {boolean} fullHeight - Sets height to 100%, overrides height
 * @param {boolean} fullWidth - Sets width to 100%, overrides width
 */
export const Block = styled.div`
  display: block;
  height: ${props => {
    if (props.fullHeight) {
      return '100%'
    } else if (props.height) {
      return props.height
    } else {
      return 'auto'
    }
  }};
  width: ${props => {
    if (props.fullWidth) {
      return '100%'
    } else if (props.width) {
      return props.width
    } else {
      return 'auto'
    }
  }};
`

/**
 * @param {string} height - The exact value to set height to
 * @param {string} width - The exact value to set width to
 * @param {boolean} fullHeight - Sets height to 100%, overrides height
 * @param {boolean} fullWidth - Sets width to 100%, overrides width
 * @param {boolean} alignCenter - Sets align-items to center
 * @param {boolean} justifyCenter - Sets justify-content to center
 */
export const Flex = styled.div`
  display: flex;
  height: ${props => {
    if (props.fullHeight) {
      return '100%'
    } else if (props.height) {
      return props.height
    } else {
      return 'auto'
    }
  }};
  width: ${props => {
    if (props.fullWidth) {
      return '100%'
    } else if (props.width) {
      return props.width
    } else {
      return 'auto'
    }
  }};
  align-items: ${props => props.alignCenter ? 'center' : 'normal'};
  justify-content: ${props => props.justifyCenter ? 'center' : 'normal'};
`

export const FlexColumn = styled(Flex)`
  flex-direction: column;
`

export const FlexWrap = styled(Flex)`
  flex-wrap: wrap;
`

export const FlexCentered = styled(Flex)`
  justify-content: center;
  align-items: center;
`

export const FlexSpaceBetween = styled(Flex)`
  align-items: center;
  justify-content: space-between;
  width: 100%;
`

export const RelativeContainer = styled.div`
  position: relative;
`

export const PageMaxWidth = styled.div`
  max-width: 114rem;
  width: 100%;
  flex-direction: column;
  margin: 0 auto;
  padding: 0 2rem;
`

export const PageMaxWidthPullUp = styled(PageMaxWidth)`
  margin-top: -5.2rem;
`

export const PageMaxWidthPullUpBottom = styled(PageMaxWidth)`
  margin-bottom: -10rem;
`

export const PageMediumWidth = styled.div`
  flex-direction: column;
  margin: 0 auto;
  max-width: 75rem;
  width: 100%;
`

export const Spacer = styled.div`
  flex-direction: inherit;
  margin-top: ${props => props.marginTop || '0'};
  margin-bottom: ${props => props.marginBottom || '0'};
  margin-left: ${props => props.marginLeft || '0'};
  margin-right: ${props => props.marginRight || '0'};
  padding-top: ${props => props.paddingTop || '0'};
  padding-bottom: ${props => props.paddingBottom || '0'};
  padding-left: ${props => props.paddingLeft || '0'};
  padding-right: ${props => props.paddingRight || '0'};
`
