import fetchWrapper from '../utils/FetchWrapper'

export function createReview (data) {
  return fetchWrapper('curator', '/reviews', {
    method: 'POST',
    body: data
  })
}

export function getUserReviews (role) {
  return fetchWrapper('curator', `/user/reviews/${role || ''}`)
}

export function deleteReview (id) {
  return fetchWrapper('curator', `/reviews/${id}`, {
    method: 'DELETE'
  })
}

export function approveReview (id) {
  return fetchWrapper('curator', `/reviews/${id}/approve`, {
    method: 'POST'
  })
}
