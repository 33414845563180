import { connect } from 'react-redux'
import { getEditorials, clearEditorials } from '../../actions/EditorialActions'
import { clearSearchAndFilter } from '../../actions/SearchAndFilterActions'
import Collection from '../ContentCollection'
import { EDITORIALS } from '../../constants/ContentConstants'

const mapStateToProps = ({ editorials, searchAndFilter }) => {
  return {
    type: EDITORIALS,
    content: editorials.editorials.data,
    isLast: editorials.editorials.isLast,
    count: editorials.editorials.count,
    error: editorials.error,
    searchQueryParams: searchAndFilter
  }
}

const mapDispatchToProps = dispatch => ({
  getContent: (pagination, searchAndFilter) => dispatch(getEditorials({...pagination, ...searchAndFilter})),
  clearContent: () => dispatch(clearEditorials()),
  clearSearchAndFilter: () => dispatch(clearSearchAndFilter())
})

export default connect(mapStateToProps, mapDispatchToProps)(Collection)
