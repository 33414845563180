import fetchWrapper from '../utils/FetchWrapper'
import { formatQueryString } from '../utils/FormatQueryString'

export function getArtists (queryParams) {
  return fetchWrapper(`curator`, `/artists${formatQueryString(queryParams)}`)
}

export function getArtist (id) {
  return fetchWrapper(`curator`, `/artists/${id}`)
}

export function getArtistItems (id) {
  return fetchWrapper(`curator`, `/artists/${id}/items`)
}

export function getArtistPlaylists (id) {
  return fetchWrapper(`curator`, `/artists/${id}/galleries`)
}

export function getArtistCategories (id) {
  return fetchWrapper(`curator`, `/artists/${id}/categories`)
}

export function getArtistChannels (id) {
  return fetchWrapper(`curator`, `/artists/${id}/channels`)
}

export function getArtistEditorials (id) {
  return fetchWrapper(`curator`, `/artists/${id}/articles`)
}

export function updateArtist (id, data) {
  return fetchWrapper('curator', `/artists/${id}`, {
    method: 'PUT',
    body: data
  })
}

export function deleteArtist (id) {
  return fetchWrapper('curator', `/artists/${id}`, {
    method: 'DELETE'
  })
}

export function createArtist (data) {
  return fetchWrapper('curator', `/artists`, {
    method: 'POST',
    body: data
  })
}

export function getRelatedArtists (parentType, parentId, queryParams) {
  return fetchWrapper(`curator`, `/${parentType}/${parentId}/artists${formatQueryString(queryParams)}`)
}
