import pluralize from 'pluralize'

export const ITEMS = 'items'
export const PLAYLISTS = 'playlists'
export const ARTISTS = 'artists'
export const CATEGORIES = 'categories'
export const CHANNELS = 'channels'
export const PROVIDERS = 'providers'
export const GROUPS = 'groups'
export const USERS = 'users'
export const EDITORIALS = 'editorials'
export const AUTHORS = 'authors'
export const GALLERIES = 'galleries'
export const ARTICLES = 'articles'

export const toFrontend = (contentType) => {
  const lowerCaseType = pluralize.singular(contentType.toLowerCase())
  switch (lowerCaseType) {
    case 'gallery':
      return 'playlist'
    case 'article':
      return 'editorial'
    default:
      return lowerCaseType
  }
}

export const toBackend = (contentType) => {
  const lowerCaseType = pluralize.singular(contentType.toLowerCase())
  switch (lowerCaseType) {
    case 'playlist':
      return 'gallery'
    case 'editorial':
      return 'article'
    default:
      return lowerCaseType
  }
}

export const toBackendPlural = (contentType) => {
  return (pluralize(toBackend(pluralize.singular(contentType))))
}

export const toFrontendPlural = (contentType) => {
  return (pluralize(toFrontend(pluralize.singular(contentType))))
}
