export const ITEM = 'item'
export const VIDEO = 'video'
export const AUDIO = 'audio'
export const IMAGE = 'image'
export const PARAGRAPH = 'paragraph'

export const RECEIVE_EDITORIAL = 'RECEIVE_EDITORIAL'
export const RECEIVE_EDITORIALS = 'RECEIVE_EDITORIALS'
export const RECEIVE_RELATED_EDITORIAL_CONTENT = 'RECEIVE_RELATED_EDITORIAL_CONTENT'
export const DELETE_RELATED_EDITORIAL_CONTENT = 'DELETE_RELATED_EDITORIAL_CONTENT'
export const RECEIVE_EDITORIALS_ERROR = 'RECEIVE_EDITORIALS_ERROR'
export const CLEAR_EDITORIALS = 'CLEAR_EDITORIALS'
