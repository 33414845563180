import { connect } from 'react-redux'
import { getArtists, clearArtists } from '../../actions/ArtistActions'
import { clearSearchAndFilter } from '../../actions/SearchAndFilterActions'
import Collection from '../ContentCollection'
import { ARTISTS } from '../../constants/ContentConstants'

const mapStateToProps = state => {
  return {
    type: ARTISTS,
    count: state.artists.artists.count,
    content: state.artists.artists.data,
    isLast: state.artists.artists.isLast,
    error: state.artists.error,
    searchQueryParams: state.searchAndFilter
  }
}

const mapDispatchToProps = dispatch => ({
  getContent: (pagination, searchAndFilter) => dispatch(getArtists({...pagination, ...searchAndFilter})),
  clearContent: () => dispatch(clearArtists()),
  clearSearchAndFilter: () => dispatch(clearSearchAndFilter())
})

export default connect(mapStateToProps, mapDispatchToProps)(Collection)
