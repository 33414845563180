import * as constants from '../constants/ArtistConstants'
import { DELETE_REVIEW, RECEIVE_REVIEW } from '../constants/ReviewConstants'
import { ARTISTS } from '../constants/ContentConstants'

const _nullArtists = {
  error: null,
  artists: {
    isLast: false,
    data: [],
    count: null
  },
  current: {
    pendingReviews: []
  }
}

export default function ArtistReducer (state = _nullArtists, action) {
  switch (action.type) {
    case constants.RECEIVE_ARTISTS: {
      const { isLast, data, count } = action.artists
      const { newQuery } = action
      const newArtists = {
        isLast: isLast,
        data: newQuery ? data : [].concat(state.artists.data, data),
        count
      }
      return Object.assign({}, state, { artists: newArtists })
    }
    case constants.RECEIVE_ARTIST: {
      return Object.assign({}, state, {
        current: Object.assign({}, state.current, action.artist)
      })
    }
    case DELETE_REVIEW: {
      if (!action.contentType === ARTISTS) return state

      const newPendingReviews = Object.assign({}, {
        pendingReviews: state.current.pendingReviews
          .filter(review => review.id !== action.reviewId)
      })

      return Object.assign({}, state, {
        current: Object.assign({}, state.current, newPendingReviews)
      })
    }
    case RECEIVE_REVIEW: {
      if (!action.contentType === ARTISTS) return state

      return Object.assign({}, state, {
        current: Object.assign({}, state.current, action.review.contentObject)
      })
    }
    case constants.RECEIVE_ARTISTS_ERROR: {
      return Object.assign({}, state, { error: action.error })
    }
    case constants.CLEAR_ARTISTS: {
      return Object.assign({}, _nullArtists)
    }
    default: {
      return state
    }
  }
}
