import styled from 'styled-components'
import {
  FlexContainer, FlexColumnContainer, FlexHorizontalCenterContainer,
  WrapContainer
} from './StyledComponents'

export const EditWrapPage = styled(WrapContainer)`
`

export const StyledOuterContainer = styled(FlexContainer)`
  align-items: flex-start;
`

export const StyledFlexColumnContainer = styled(FlexColumnContainer)`
  width: 60%;
`

export const StyledStatusContainer = styled(FlexColumnContainer)`
  width: 40%;
  height: 100%;
  margin-right: 20px;
  background: white;
  border-radius: 3px;
  border: ${props => `1px solid ${props.theme.lightGray}`};
`

export const StyledDetailContainer = styled(FlexColumnContainer)`
  width: 100%;
  background: white;
  padding: 30px;
  border-radius: 3px;
  margin-bottom: 15px;
  border: ${props => `1px solid ${props.theme.lightGray}`};
`

export const StyledRelatedContainer = styled(StyledDetailContainer)`
  margin-top: 15px;
`

export const PaddingContainer = styled.div`
  padding: 25px 35px 25px 35px;
`

export const MarginFlexContainer = styled(FlexHorizontalCenterContainer)`
  margin: 15px 0;
  padding: 10px;
  background-color: white;

  .content-title {
    margin-left: 15px;
    font-size: 20px;
    font-weight: 500;
  }
`
