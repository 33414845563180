import * as constants from '../constants/GroupConstants'
import * as API from '../api/Groups'
import { receiveSuccessMessage, receiveErrorMessage } from './MessageActions'
import { RECEIVE_RELATED_CONTENT } from '../constants/RelatedContentConstants'
import { GROUPS } from '../constants/ContentConstants'
import { getRelatedContent as getRelatedContentAction } from './RelatedContentActions'
import { getRelatedContent } from '../api/General'

export const getGroups = (queryParams) => dispatch => {
  return API.getGroups(queryParams).then(groups => dispatch({
    type: constants.RECEIVE_GROUPS, groups, newQuery: queryParams.newQuery
  })).catch(error => dispatch({ type: constants.RECEIVE_GROUPS_ERROR, error }))
}

const getOnlyGroup = (id) => dispatch => {
  return API.getGroup(id).then(({ data: group }) => dispatch({
    type: constants.RECEIVE_GROUP, group
  })).catch(error => dispatch({ type: constants.RECEIVE_GROUPS_ERROR, error }))
}

export const getGroup = (id, childType, opts = { queryParams: null }) => dispatch => {
  if (!childType) return dispatch(getOnlyGroup(id))

  if (!opts.queryParams.newQuery) {
    return dispatch(getRelatedContentAction(GROUPS, id, childType, opts.queryParams))
  } else {
    return Promise.all([
      API.getGroup(id),
      getRelatedContent(GROUPS, id, childType, opts.queryParams)
    ]).then(values => {
      const group = values[0].data
      const relatedContent = values[1]
      dispatch({ type: constants.RECEIVE_GROUP, group })
      dispatch({
        type: RECEIVE_RELATED_CONTENT,
        content: relatedContent,
        contentType: childType,
        newQuery: opts.queryParams.newQuery
      })
    }).catch(error => dispatch({ type: constants.RECEIVE_GROUPS_ERROR, error }))
  }
}

export const updateGroup = (groupId, data) => dispatch => {
  return API.updateGroup(groupId, data).then(({ data: group }) => {
    dispatch({ type: constants.RECEIVE_GROUP, group })
  }).then(() => dispatch(receiveSuccessMessage('Group updated')))
    .catch(error => {
      dispatch(receiveErrorMessage(error))
      return dispatch({ type: constants.RECEIVE_GROUPS_ERROR, error })
    })
}

export const createGroup = (data) => dispatch => {
  return API.createGroup(data).then(({ data: group }) => {
    dispatch({ type: constants.RECEIVE_GROUP, group })
  }).then(() => dispatch(receiveSuccessMessage('Group created')))
    .catch(error => {
      dispatch(receiveErrorMessage(error))
      return dispatch({ type: constants.RECEIVE_GROUPS_ERROR, error })
    })
}

export const deleteGroup = (id) => dispatch => {
  return API.deleteGroup(id)
    .then(() => dispatch(receiveSuccessMessage('Group deleted')))
    .catch(error => {
      dispatch({ type: constants.RECEIVE_GROUPS_ERROR, error })
      return Promise.reject(error)
    })
}

export const clearGroups = () => dispatch => dispatch({ type: constants.CLEAR_GROUPS })
