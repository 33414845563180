import * as constants from '../constants/SelectedContentConstants'

export const selectContent = (selectedType, selected) => dispatch => dispatch({
  type: constants.SELECT_CONTENT,
  selectedType,
  selected
})

export const selectAllContent = selected => dispatch => dispatch({
  type: constants.SELECT_ALL_CONTENT,
  selected
})

export const clearSelectedContent = () => dispatch => dispatch({
  type: constants.CLEAR_SELECTED_CONTENT
})
