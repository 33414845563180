import { HORIZONTAL, VERTICAL } from '../constants/orientation'

export const getItemOrientation = (item) => {
  const width = item.croppedWidth || item.originalWidth
  const height = item.croppedHeight || item.originalHeight
  return width > height ? HORIZONTAL : VERTICAL
}

export function getItemDimensions (item) {
  let fitWidth, fitHeight
  if (item.croppedWidth === 0 && item.croppedHeight === 0) {
    fitWidth = item.originalWidth
    fitHeight = item.originalHeight
  } else {
    fitWidth = item.croppedWidth
    fitHeight = item.croppedHeight
  }

  return [fitWidth, fitHeight]
}
