export const UPDATE_SEARCH_STRING = 'UPDATE_SEARCH_STRING'
export const UPDATE_FILTERS = 'UPDATE_FILTERS'
export const UPDATE_SORT_FIELD = 'UPDATE_SORT_FIELD'
export const UPDATE_SORT_ORDER = 'UPDATE_ASC_DESC'
export const CLEAR_FILTERS = 'CLEAR_QUERY'
export const CLEAR_SEARCH_AND_FILTER = 'CLEAR_SEARCH_AND_FILTER'
export const ASCENDING = 'asc'
export const DESCENDING = 'dsc'

export const ORDERING_DISPLAYS = {
  publishedAt: 'Published Date',
  featuredAt: 'Featured Date',
  createdAt: 'Created At',
  updatedAt: 'Updated At',
  date: 'Date',
  name: 'Name',
  author: 'Author',
  surname: 'Surname',
  favoriteCount: 'Favorites',
  deviceCount: 'Downloads'
}

export const FILTER_DISPLAYS = {
  isPublic: {
    label: 'Publish status',
    options: {
      true: {display: 'public'},
      false: {display: ' private'}
    }
  },
  isFeatured: {
    label: 'Featured',
    options: {
      true: {display: 'featured'},
      false: {display: 'not yet featured'}
    }
  },
  orientation: {
    label: 'Orientation',
    options: {
      landscape: {display: 'Horizontal'},
      portrait: {display: 'Vertical'}
    }
  },
  isSampler: {
    label: 'Is sampler',
    options: {
      true: {display: 'sampler'},
      false: {display: 'not sampler'}
    }
  },
  group: {
    label: 'Group',
    options: {}
  },
  hasCopyright: {
    label: 'Copyright',
    options: {
      true: {display: 'Copyright'},
      false: {display: 'No copyright'}
    }
  }
}
