import React from 'react'
import styled from 'styled-components'
import { Label } from 'reactstrap'
import {
  RelativeContainer, StyledInput, FlexContainer, StyledLink
} from '../StyledComponents'
import { ITEMS } from '../../constants/ContentConstants'
import { checkIfImageExists } from '../../utils/ImageUtils'

const StyledRelativeContainer = styled(RelativeContainer)`
  padding: 15px;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  .item-hover-container {
    visibility: ${props => props.error ? 'visible' : 'hidden'};
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 4;
    opacity: 0.5;
    display: flex;
    align-items: center;
    justify-content: center;
    background: ${props => props.theme.lightGray};
  }

  &:hover {
    .item-hover-container {
      visibility: visible;
      background-color: ${props => props.theme.blue};
      opacity: 0.8;
    }
  }
`

const StyledIcon = styled.i`
  font-size: 30px;
  background-color: ${props => props.theme.white};
  border-radius: 50%;
  padding: 10px;
  margin-right: ${props => props.marginright};
  cursor: pointer;
`

const StyledImg = styled.img`
  cursor: pointer;
  min-width: 100px;
  max-width: 100%;
  max-height: 318px;
  object-fit: contain;
`

const StyledVideo = styled.video`
  cursor: pointer;
  min-width: 100px;
  max-width: 100%;
  max-height: 318px;
  object-fit: contain;
`

const HiddenInput = styled(StyledInput)`
  display: none;
`

const StyledFlex = styled(FlexContainer)`
  border: ${props => props.error ? `1px solid ${props.theme.red}` : 'none'}
`

const UploadIcon = styled(Label)`
  margin-bottom: 0;
`

export default function UploadItem (props) {
  const src = checkIfImageExists(props.image, ITEMS)
  // checks to see if the base64 encoded url is a video
  const isVideo = props.image ? props.image.indexOf('video') >= 0 : false
  const contentTag = isVideo
                   ? <StyledVideo src={src} />
                   : <StyledImg src={src} />
  let cropIcon
  if (props.currentId) {
    cropIcon = (
      <StyledLink to={`/items/${props.currentId}/crop`} color='black'>
        <StyledIcon className='material-icons' marginright='10px'>crop</StyledIcon>
      </StyledLink>
    )
  }

  return (
    <StyledFlex error={props.error}>
      <StyledRelativeContainer error={props.error}>
        {contentTag}
        <div className='item-hover-container'>
          {cropIcon}
          <UploadIcon for='fileItem' error={props.error}>
            <StyledIcon className='material-icons'>cloud_upload</StyledIcon>
            <HiddenInput type='file' id='fileItem' onChange={props.handleFileUpload}
              name='image' accept='image/*, video/*' />
          </UploadIcon>
        </div>
      </StyledRelativeContainer>
    </StyledFlex>
  )
}
