import React from 'react'
import styled from 'styled-components'
import { FormGroup } from 'reactstrap'
import {
  StyledLabel, StyledInput, FlexContainer, SquareImg
} from '../StyledComponents'

const StyledFormGroup = styled(FormGroup)`
  margin-bottom: 15px;
`

const HiddenInput = styled(StyledInput)`
  display: none;
`

const StyledDiv = styled.div`
  cursor: pointer;
  background: ${props => props.theme.lightGray};
  color: black;
  font-weight: 400;
  border: ${props => props.error ? `1px solid ${props.theme.red}` : 'none'};
  font-size: 14px;
  padding: 8px 15px;
  border-radius: 5px;
  line-height: 1.25;

  &:hover {
    background: ${props => props.theme.lightMediumGray};
    color: black;
  }
`

export default function FileUpload (props) {
  return (
    <StyledFormGroup>
      <StyledLabel
        for={props.id}
        error={props.error}
        fullwidth='true'>
        {props.label}
        <FlexContainer justifyContent='space-between'>
          <SquareImg image={props.value} />
          <StyledDiv>Change</StyledDiv>
        </FlexContainer>
        <HiddenInput
          type='file'
          name={props.name}
          id={props.id}
          onChange={props.onChange}
          error={props.error} />
      </StyledLabel>
    </StyledFormGroup>
  )
}
