import * as constants from '../constants/MessageConstants'
import capitalize from 'lodash/capitalize'

export const receiveErrorMessage = (message, hasTimeout = true) => dispatch => {
  if (message.responseJSON) {
    message = message.responseJSON
  } else if (message.statusText) {
    message = message.statusText
  }

  let updatedErrors = message
  if (typeof updatedErrors !== 'string') {
    updatedErrors = ''
    Object.keys(message).forEach(key => {
      const updatedKey = key === 'name' ? 'title' : key
      updatedErrors = updatedErrors + `${capitalize(updatedKey)}: ${message[key]} `
    })
  }
  return dispatch({
    type: constants.RECEIVE_ERROR_MESSAGE, message: updatedErrors, hasTimeout
  })
}

export const receiveSuccessMessage = (message, hasTimeout = true) => dispatch => {
  return dispatch({
    type: constants.RECEIVE_SUCCESS_MESSAGE, message, hasTimeout
  })
}

export const clearMessage = () => dispatch => {
  return dispatch({ type: constants.CLEAR_MESSAGE })
}
