import { connect } from 'react-redux'
import {
  getPlaylist, sortRelatedContent
} from '../../actions/PlaylistActions'
import {
  addRelatedContent, deleteRelatedContent
} from '../../actions/RelatedContentActions'
import { selectContent, clearSelectedContent } from '../../actions/SelectedContentActions'
import { PLAYLISTS, ITEMS } from '../../constants/ContentConstants'

import ViewContent from '../ViewContent'

const mapStateToProps = state => {
  return {
    type: PLAYLISTS,
    content: state.playlists.current,
    relatedContentOrder: [
      {contentType: ITEMS, allowAdd: true, allowSort: true, allowBatch: true}
    ],
    relatedContent: state.relatedContent,
    selectedContent: state.selectedContent.content
  }
}

const mapDispatchToProps = dispatch => ({
  getContent: payload => dispatch(getPlaylist(payload, ITEMS, {
    queryParams: { newQuery: true, count: 1000, page: 1 }
  })),
  sortRelatedContent: (id, order, type) => dispatch(sortRelatedContent(id, order, type)),
  onSelect: (type, selected) => dispatch(selectContent(type, selected)),
  clearSelectedContent: () => dispatch(clearSelectedContent()),
  addRelatedContent: (children, parentId, childType) => dispatch(addRelatedContent(PLAYLISTS, parentId, childType, children)),
  deleteRelatedContent: (children, parentId, childType) => dispatch(deleteRelatedContent(PLAYLISTS, parentId, childType, children))
})

export default connect(mapStateToProps, mapDispatchToProps)(ViewContent)
