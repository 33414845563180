import { combineReducers } from 'redux'
import AuthReducer from './AuthReducer'
import ChannelReducer from './ChannelReducer'
import ProviderReducer from './ProviderReducer'
import PlaylistReducer from './PlaylistReducer'
import ArtistReducer from './ArtistReducer'
import ItemReducer from './ItemReducer'
import CategoryReducer from './CategoryReducer'
import GroupReducer from './GroupReducer'
import EditorialReducer from './EditorialReducer'
import PickerReducer from './PickerReducer'
import MessageReducer from './MessageReducer'
import SearchAndFilterReducer from './SearchAndFilterReducer'
import ReviewReducer from './ReviewReducer'
import HistoryReducer from './HistoryReducer'
import RelatedContentReducer from './RelatedContentReducer'
import SelectedContentReducer from './SelectedContentReducer'
import FeatureFlagReducer from './FeatureFlagReducer'

export default combineReducers({
  ae: FeatureFlagReducer,
  auth: AuthReducer,
  channels: ChannelReducer,
  providers: ProviderReducer,
  playlists: PlaylistReducer,
  artists: ArtistReducer,
  items: ItemReducer,
  categories: CategoryReducer,
  groups: GroupReducer,
  editorials: EditorialReducer,
  picker: PickerReducer,
  message: MessageReducer,
  searchAndFilter: SearchAndFilterReducer,
  reviews: ReviewReducer,
  objectHistory: HistoryReducer,
  relatedContent: RelatedContentReducer,
  selectedContent: SelectedContentReducer
})
