import * as constants from './constants'
import { CLEAR_USER } from '../user/constants'

const _nullCanvas = {
  canvases: []
}

export default function CanvasReducer (state = _nullCanvas, action) {
  switch (action.type) {
    case constants.RECEIVE_USER_CANVASES: {
      return Object.assign({}, state, { canvases: action.canvases })
    }
    case constants.RECEIVE_CANVAS: {
      const canvases = [].concat(state.canvases)
      const canvasIdx = canvases.findIndex(canvas => canvas.id === action.canvas.id)
      if (canvasIdx !== -1) {
        canvases.splice(canvasIdx, 1, action.canvas)
      } else {
        canvases.push(action.canvas)
      }
      return Object.assign({}, state, { canvases })
    }
    case constants.DELETE_CANVAS: {
      const canvases = [].concat(state.canvases)
      const canvasIdx = canvases.findIndex(canvas => canvas.id === action.canvasId)
      canvases.splice(canvasIdx, 1)
      return Object.assign({}, state, { canvases })
    }
    case CLEAR_USER: {
      return Object.assign({}, _nullCanvas)
    }
    default: {
      return state
    }
  }
}
