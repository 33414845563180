import React, { Component } from 'react'
import { Link, withRouter } from 'react-router-dom'
import { CardTitle, CardImgOverlay } from 'reactstrap'
import noop from 'lodash/noop'
import styled from 'styled-components'
import {
  RelativeContainer, CoverImage, StyledCard, FlexHorizontalCenterContainer
} from './StyledComponents'
import ContentCardDetails from './ContentCardDetails'
import DotDotDotIcon from './DotDotDotIcon'
import { checkForImage } from '../utils/ImageUtils'
import { formatPublicationDate } from '../utils/DateFormatter'
import {
  ITEMS, PLAYLISTS, ARTISTS, CATEGORIES, CHANNELS, PROVIDERS, USERS, GROUPS,
  EDITORIALS, AUTHORS
} from '../constants/ContentConstants'
import PreviewItemGrid from './PreviewItemGrid'
import Overlay from './ContentCardOverlay'
import BatchSelectIcon from './BatchSelectIcon'

const Card = styled(StyledCard)`
  font-family: HelveticaNeue;
  border: ${props => props.selected
    ? `2px solid ${props.theme.blueRGBA(1)}`
    : `1px solid ${props => props.theme.lightGray}`
  };
  border-radius: 5px;
  cursor: pointer;
`

// The hover overlay has to be in this file and not in ContentCardOverlay
// because it needs reference to the Card component for the hover styles.
export const HoverOverlay = styled(CardImgOverlay)`
  display: ${props => props.overlay ? 'block' : 'none'};
  background: ${props => props.overlay && !props.selected
    ? 'rgba(0, 0, 0, 0.7)'
    : 'none'};

  ${Card}:hover & {
    background: ${props => props.selected ? 'none' : 'rgba(0, 0, 0, 0.7)'};
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
  }
`

const StyledTitle = styled(CardTitle)`
  color: ${props => props.theme.darkGray};
  line-height: 1.5;
  font-size: 14px;
  margin-top: 10px;
  font-weight: bold;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
`

const FlexContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

const StyledCoverImage = styled(CoverImage)`
  height: ${props => props.circular ? '160px' : '176px'};
  width: ${props => props.circular ? '160px' : 'auto'};
  border-radius: ${props => props.circular ? 'none' : '4px'};
  background-color: ${props => props.theme.lighterGray};
`

const SelectableLink = styled(Link)`
  pointer-events: ${props => props.disabled ? 'none' : 'auto'};
  display: flex;

  &:hover {
    text-decoration: none;
  }
`

class ContentCard extends Component {
  constructor (props) {
    super(props)

    this.onDelete = this.onDelete.bind(this)
    this.onEdit = this.onEdit.bind(this)
    this.onSelect = this.onSelect.bind(this)
  }

  formatProps () {
    const { type } = this.props
    switch (type) {
      case ITEMS:
        return {
          key: this.props.id,
          image: this.props.imageThumbOptimized || this.props.imageThumb,
          title: this.props.name,
          backgroundSize: 'contain',
          details: {
            artist: this.props.author,
            provider: this.props.provider,
            medium: this.props.medium,
            year: this.props.year,
            favorites: this.props.favoriteCount,
            deviceCount: this.props.deviceCount,
            isPublic: this.props.isPublic,
            isScheduled: this.props.premiumDate && !this.props.isPremium,
            isPremium: this.props.isPremium
          }
        }
      case PLAYLISTS:
        return {
          key: this.props.id,
          title: this.props.name,
          image: checkForImage(this.props.coverItem, PLAYLISTS),
          previewItems: this.props.previewItems,
          count: this.props.items ? this.props.items.length : 0,
          details: {
            publishedAt: formatPublicationDate(this.props.publishedAt),
            deviceCount: this.props.deviceCount,
            favorites: this.props.favoriteCount,
            isPublic: this.props.isPublic,
            isFeatured: this.props.isFeatured,
            isScheduled: this.props.premiumDate && !this.props.isPremium,
            isPremium: this.props.isPremium
          }
        }
      case ARTISTS:
        return {
          circular: true,
          key: this.props.id,
          title: `${this.props.firstName} ${this.props.surname}`,
          image: checkForImage(this.props.hero, ARTISTS),
          details: {
            publishedAt: formatPublicationDate(this.props.publishedAt),
            items: 'TODO',
            favorites: this.props.favoriteCount,
            isPublic: this.props.isPublic,
            isFeatured: this.props.isFeatured
          }
        }
      case CATEGORIES:
        return {
          key: this.props.id,
          title: this.props.name,
          image: checkForImage(this.props.avatar, CATEGORIES),
          details: {
            isPublic: this.props.isPublic,
            isVisible: this.props.isVisible
          }
        }
      case CHANNELS:
        return {
          key: this.props.id,
          title: this.props.name,
          image: checkForImage(this.props.avatar, CHANNELS),
          details: {
            favorites: this.props.favoriteCount,
            isPublic: this.props.isPublic
          }
        }
      case PROVIDERS:
        return {
          key: this.props.id,
          title: this.props.name
        }
      case GROUPS:
        return {
          key: this.props.id,
          title: this.props.name,
          details: {
            isPublic: this.props.isPublic
          }
        }
      case USERS:
        return {
          key: this.props.id,
          title: this.props.email,
          image: checkForImage(this.props.logo, USERS)
        }
      case EDITORIALS:
        return {
          key: this.props.id,
          title: this.props.name,
          image: checkForImage(this.props.hero),
          details: {
            isPublic: this.props.isPublic,
            author: this.props.author,
            content: this.props.contentObject
          }
        }
      case AUTHORS:
        return {
          key: this.props.id,
          title: this.props.name
        }
    }
  }

  editLink () {
    const { type, id } = this.props
    return `#/${type.toLowerCase()}/${id}/edit`
  }

  displayCount (count) {
    return count || count === 0
  }

  onDelete (e) {
    e.preventDefault()
    this.props.deleteRelatedContent([this.props.content])
  }

  onEdit (e) {
    e.preventDefault()
    const { content, type } = this.props
    this.props.history.push(`/${type}/${content.id}/edit`)
  }

  onSelect (e) {
    e.preventDefault()
    this.props.onSelect(e, this.props.content)
  }

  renderImage () {
    const { image, count, circular, backgroundSize, previewItems } = this.formatProps()
    if (!image) return null

    const {
      unselectable, hideOverlay, content, selected, allowAdd, allowBatch, type
    } = this.props

    let overlay
    if (!unselectable && !hideOverlay) {
      overlay = (
        <Overlay
          selected={selected}
          allowAdd={allowAdd}
          allowBatch={allowBatch}
          contentType={type}
          onSelect={this.onSelect}
          onDelete={this.onDelete}
          onEdit={this.onEdit}
          content={content} />
      )
    }

    let images
    if (circular) {
      images = <StyledCoverImage circular={circular.toString()} image={image} />
    } else if (previewItems) {
      images = <PreviewItemGrid previewItems={previewItems} count={count} />
    } else {
      images = <StyledCoverImage backgroundSize={backgroundSize} image={image} />
    }

    return (
      <RelativeContainer>
        {images}
        {overlay}
      </RelativeContainer>
    )
  }

  render () {
    const { title, details, image } = this.formatProps()
    const {
      selected, unselectable, allowAdd, content, contentType, allowBatch
    } = this.props

    let moreDropdown
    if (!image) {
      moreDropdown = (
        <DotDotDotIcon
          allowAdd={allowAdd}
          onDelete={this.onDelete}
          onEdit={this.onEdit}
          onSelect={this.onSelect}
          content={content}
          contentType={contentType}
          color='darkPurple' />
      )
    }

    // For content without images (e.g groups and providers), they have a
    // slightly different design where the batch select icon shows above the title
    let titleOrBatchIcon
    if (!image && allowBatch) {
      titleOrBatchIcon = <BatchSelectIcon onSelect={this.onSelect} selected={selected} />
    } else {
      titleOrBatchIcon = <StyledTitle>{title}</StyledTitle>
    }

    return (
      <SelectableLink to={`/${this.props.type.toLowerCase()}/${this.props.id}`}
        disabled={unselectable}>
        <Card
          onClick={this.props.onClick || noop}
          selected={selected}
        >
          {this.renderImage()}
          <FlexContainer>
            <FlexHorizontalCenterContainer justifyContent='space-between'>
              {titleOrBatchIcon}
              {moreDropdown}
            </FlexHorizontalCenterContainer>
            {!image && allowBatch && <StyledTitle>{title}</StyledTitle>}
            <ContentCardDetails details={details} />
          </FlexContainer>
        </Card>
      </SelectableLink>
    )
  }
}

export default withRouter(ContentCard)
