import pluralize from 'pluralize'
import fetchWrapper from '../utils/FetchWrapper'
import { formatQueryString } from '../utils/FormatQueryString'

export function exportCsv (type) {
  return fetchWrapper(`curator`, `/${type}/csv`)
}

export function getObject (type, id) {
  return fetchWrapper(`curator`, `/${type}/${id}`)
}

export function getRelatedContent (parentType, parentId, childType, queryParams) {
  return fetchWrapper(`curator`, `/${parentType}/${parentId}/${childType}${formatQueryString(queryParams)}`)
}

export function addRelatedContent (parentType, parentId, childType, childIds) {
  return fetchWrapper(`curator`, `/${parentType}/${parentId}/${pluralize(childType)}`, {
    method: 'POST',
    body: {
      'ids': childIds
    }
  })
}

export function deleteRelatedContent (parentType, parentId, childType, childIds) {
  return fetchWrapper(`curator`, `/${parentType}/${parentId}/${pluralize(childType)}`, {
    method: 'DELETE',
    body: {
      'ids': childIds
    }
  })
}
