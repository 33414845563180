import React from 'react'
import styled from 'styled-components'
import TagsInput from 'react-tagsinput'
import 'react-tagsinput/react-tagsinput.css'
import { FlexColumnContainer, StyledLabel } from '../StyledComponents'

const StyledTagsContainer = styled(TagsInput)`
  border: 1px solid ${props => props.error ? props.theme.red : props.theme.lightMediumGray};
  border-radius: 4px;

  .react-tagsinput-tag {
    background-color: ${props => props.theme.lightGray};
    color: black;
    border: none;
    margin: 5px;
    padding: 1px 5px;
    border-radius: 4px;
    font-size: 16px;
  }

  .react-tagsinput-remove {
    color: ${props => props.theme.mediumGray} !important;
  }

  .react-tagsinput-input {
    margin-left: 5px;
    color: black;
    font-size: 16px;
    ::placeholder {
      color: transparent;
    }
  }
`

export default class Tags extends React.Component {
  constructor (props) {
    super(props)

    this.state = { tags: [] }

    this.handleChange = this.handleChange.bind(this)
  }

  componentWillMount () {
    this.setState({ tags: this.props.tags })
  }

  componentWillReceiveProps (nextProps) {
    if (this.props.tags && this.props.tags !== nextProps.tags) {
      this.setState({ tags: nextProps.tags })
    }
  }

  handleChange (tags) {
    this.setState({ tags }, () => {
      this.props.handleTagsChange(tags)
    })
  }

  render () {
    return (
      <FlexColumnContainer>
        <StyledLabel error={this.props.error}>Tags</StyledLabel>
        <StyledTagsContainer
          error={this.props.error}
          value={this.state.tags}
          onChange={this.handleChange} />
      </FlexColumnContainer>
    )
  }
}
