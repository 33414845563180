import React from 'react'
import styled from 'styled-components'
import {
  FlexColumnContainer, FlexContainer, StyledLabel, GrayButton, CircleImg
} from '../StyledComponents'
import { checkForImage } from '../../utils/ImageUtils'
import { ARTISTS } from '../../constants/ContentConstants'

const ArtistPickerContainer = styled(FlexContainer)`
  justify-content: space-between;
  align-items: center;
`

const StyledName = styled.div`
  margin-bottom: 0;
`

const StyledFlexColumnContainer = styled(FlexColumnContainer)`
  width: 55%;
`

const OuterContainer = styled(FlexColumnContainer)`
  margin-bottom: 15px;
  width: 100%;
`

export default class ArtistPicker extends React.Component {
  render () {
    let artistPicker
    if (!this.props.artist) {
      artistPicker = (
        <div>
          <GrayButton small='true' onClick={this.props.toggleModal}
            error={this.props.error}>
            Choose
          </GrayButton>
        </div>
      )
    } else {
      const { avatar, firstName, surname } = this.props.artist
      artistPicker = (
        <ArtistPickerContainer name={this.props.name}>
          <CircleImg image={checkForImage(avatar, ARTISTS)} />
          <StyledFlexColumnContainer>
            <StyledName>{firstName + ' ' + surname}</StyledName>
          </StyledFlexColumnContainer>
          <GrayButton small='true' onClick={this.props.toggleModal}>
            Change
          </GrayButton>
        </ArtistPickerContainer>
      )
    }

    return (
      <OuterContainer>
        <StyledLabel error={this.props.error}>{this.props.label}</StyledLabel>
        {artistPicker}
      </OuterContainer>
    )
  }
}
