import React from 'react'
import styled from 'styled-components'
import { CoverImage, RelativeContainer, CountBadge } from '../StyledComponents'
import { checkIfImageExists } from '../../utils/ImageUtils'

const StyledRelativeContainer = styled(RelativeContainer)`
  padding: 15px;
`

const StyledCoverImage = styled(CoverImage)`
  height: 318px;
  background-size: contain;
`

export default function CoverImageContainer (props) {
  const image = checkIfImageExists(props.image, props.type)
  const countBadge = props.count
                   ? <CountBadge pill>{props.count}</CountBadge>
                   : null
  return (
    <StyledRelativeContainer>
      <StyledCoverImage image={image} />
      {countBadge}
    </StyledRelativeContainer>
  )
}
