import React, { Component } from 'react'
import { MarginContainer, GreenBadge, YellowBadge, RedBadge, GrayBadge, PurpleBadge } from './StyledComponents'
import { EMPTY_FIELD } from '../constants/UIConstants'

// mostly we just render the key as it comes back from the server,
// but in a few cases we transform it to a slightly more user-friendly
// display version – providing a label/displayForNullValue pair will override
// the default behavior of displaying 'N/A' for fields without values
export const OVERRIDE_DEFAULT_KEY_DISPLAY = {
  publishedAt: {
    label: 'published',
    displayForNullValue: 'no published date'
  },
  featuredAt: {
    label: 'featured',
    displayForNullValue: 'no featured date'
  },
  death: {
    label: 'death',
    displayForNullValue: EMPTY_FIELD
  },
  medium: {
    label: 'medium',
    displayForNullValue: EMPTY_FIELD
  },
  galleryCount: {
    label: 'times added to user playlist',
    displayForNullValue: EMPTY_FIELD
  },
  deviceCount: {
    label: 'downloads',
    displayForNullValue: EMPTY_FIELD
  }
}

export default class AbstractContentDetails extends Component {
  renderBadges () {
    const {
      isPublic, isFeatured, isVisible, isScheduled, isPremium
    } = this.props.details

    return (
      <MarginContainer margin='5px'>
        {isPublic ? <GreenBadge>{'public'}</GreenBadge> : <YellowBadge>{'private'}</YellowBadge>}
        {isFeatured ? <RedBadge>featured</RedBadge> : ''}
        {isVisible === false ? <GrayBadge>hidden</GrayBadge> : ''}
        {isScheduled ? <PurpleBadge>Scheduled</PurpleBadge> : ''}
        {isPremium ? <PurpleBadge>Premium</PurpleBadge> : ''}
      </MarginContainer>
    )
  }

  formatValue (key, val) {
    // first check if this is a key for which we want to override the default
    // display behavior, then display the value
    let nullDisplay
    if (OVERRIDE_DEFAULT_KEY_DISPLAY[key]) {
      nullDisplay = OVERRIDE_DEFAULT_KEY_DISPLAY[key].displayForNullValue
    } else {
      nullDisplay = EMPTY_FIELD
    }

    // this is for the case that there's an object (e.g provider)
    if (typeof val === 'object') {
      return val ? val.name : nullDisplay
    } else if (key === 'id') {
      return `#${val}`
    } else {
      return val || val === 0 ? val : nullDisplay
    }
  }
}
