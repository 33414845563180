export const VERTICAL = 'vertical'
export const HORIZONTAL = 'horizontal'
export const PORTRAIT = 'portrait'
export const LANDSCAPE = 'landscape'

export const toFrontend = dirty => {
  switch (dirty.toLowerCase()) {
    case PORTRAIT:
      return VERTICAL
    case LANDSCAPE:
      return HORIZONTAL
    case HORIZONTAL:
      return HORIZONTAL
    case VERTICAL:
      return VERTICAL
  }
}
