import React from 'react'
import styled from 'styled-components'
import FixedAspectRatioImage from '../Image/FixedAspectRatioImage'
// import { Link } from '../../routes'
import { FinePrint, SmallSpan } from '../../styles/Text'
import { FlexSpaceBetween, FlexColumn, Spacer } from '../../styles/Box'
// import AddToPlaylistIcon from '../Playlist/AddToPlaylistIcon'
// import FavoriteHeart from '../Favorite/FavoriteHeart'
// import { ITEM } from '../../constants/models'
import { IconList } from '../../styles/Icons'
import { media } from '../../styles/MediaQueries'
// import Badge, { TileBadgeContainer } from '../Badge'
import { getItemDimensions } from '../../utils/item'

const ItemContainer = styled.div`
  width: 100%;
  flex-direction: column;
  margin-bottom: 7rem;
`

const ItemDetail = styled.div`
  flex-direction: column;
  border-bottom: 0.1rem solid ${props => props.theme.colors.lightGray};
`

const RectImageContainer = styled.a`
  position: relative;
  display: block;
  width: 100%;

  ${media.mobile`
    margin: 0 -2rem;
    width: calc(100% + 4rem);
  `}
`

export default function EditorialItem (props) {
  const { item } = props.content

  const [fitWidth, fitHeight] = getItemDimensions(item)

  return (
    <ItemContainer>
      <RectImageContainer>
        <FixedAspectRatioImage
          src={item.image}
          width={fitWidth}
          height={fitHeight} />
      </RectImageContainer>
      <Spacer marginTop='2rem' />
      <ItemDetail>
        <FlexSpaceBetween>
          <FlexColumn>
            <SmallSpan bold>{item.name || ''}</SmallSpan>
            <SmallSpan>{item.author || ''}</SmallSpan>
          </FlexColumn>
          <IconList marginRight='2rem' />
        </FlexSpaceBetween>
        <Spacer marginTop='1.3rem' />
        <FinePrint>{item.caption}</FinePrint>
      </ItemDetail>
    </ItemContainer>
  )
}
