import 'whatwg-fetch'
const API_ORIGIN = process.env.API_ORIGIN
const NETGEAR_ACCOUNT_ORIGIN = process.env.NETGEAR_ACCOUNT_ORIGIN
const CLIENT_ID = process.env.CLIENT_ID

export default function fetchWrapper (prefix, url, data = {}) {
  return new Promise((resolve, reject) => {
    const token = localStorage.getItem('userToken')
    const refresh_token = localStorage.getItem('refreshtoken')
    const method = data.method || 'GET'
    const mode = 'cors'
    const headers = new Headers()
    let fullUrl = prefix
    if (prefix.startsWith(`${NETGEAR_ACCOUNT_ORIGIN}/api/getAccessToken`)) {
      headers.append('Authorization', `Bearer ${refresh_token}`)
      headers.append('appkey', CLIENT_ID)
    } else if (prefix.startsWith(`${NETGEAR_ACCOUNT_ORIGIN}/api/oauth/token`)) {
      headers.append('Content-Type', 'application/json')
    } else if (prefix.startsWith(`${NETGEAR_ACCOUNT_ORIGIN}/api/logoutUser`)) {
      headers.append('Authorization', `Bearer ${refresh_token}`)
      headers.append('appkey', CLIENT_ID)
      headers.append('Content-Type', 'application/json')
    } else if (prefix.startsWith(`${NETGEAR_ACCOUNT_ORIGIN}/api/accounts/experience`)) {
      headers.append('Content-Type', 'application/json')
    } else {
      fullUrl = `${API_ORIGIN}/${prefix}${url.replace('playlists', 'galleries')
        .replace('editorials', 'articles')}`
      if (token) headers.append('Authorization', `Token ${token}`)
    }
    let body
    if (!(data.body instanceof FormData)) {
      headers.append('Content-Type', 'application/json')
      body = JSON.stringify(data.body)
    } else {
      body = data.body
    }

    fetch(fullUrl, {
      headers, method, mode, body
    }).then(response => {
      if (response.status >= 400) {
        response.json().then(parsed => reject(parsed))
      } else if (response.status === 204) {
        resolve(response)
      } else if (response.headers.get('Content-Type') === 'text/csv') {
        const contentDisposition = response.headers.get('Content-Disposition')
        const fileName = contentDisposition.split('filename=')[1].slice(1, -1)
        response.blob().then(blob => resolve({
          url: URL.createObjectURL(blob),
          fileName
        }))
      } else {
        response.json().then(parsed => resolve(parsed))
      }
    })
  })
}
