import React, { Component } from 'react'
import throttle from 'lodash/throttle'
import styled from 'styled-components'
import getCaretCoordinates from 'textarea-caret'
import { RelativeContainer } from '../StyledComponents'
import SuperPicker from '../edit/SuperPicker'
import { ITEMS, PLAYLISTS } from '../../constants/ContentConstants'
import logo from '../../../assets/logo-black.svg'
import EditorialFileUpload from './EditorialFileUpload'

const AddContentContainer = styled.div`
  position: absolute;
  left: 65px;
`

export const Icon = styled.i`
  font-size: 24px;
  padding: 5px;
  border-radius: 50%;
  background-color: ${props => props.theme.lightGray};
  cursor: pointer;
  margin-right: 12px;
  height: 100%;
`

const TransitionBase = styled.div`
  position: absolute;
  transition: 0.2s ease all;
  opacity: ${props => props.show ? '1' : '0'};
  visibility: ${props => props.show ? 'visible' : 'hidden'};
  z-index: ${props => props.show ? '2' : '0'};
`

const AddIcon = styled(TransitionBase.withComponent(Icon))`
  height: auto;
`

const Options = styled(TransitionBase)`
  display: flex;
  left: ${props => props.show ? '0' : '-20px'};
`

const Logo = styled(Icon.withComponent('img'))`
  width: 35px;
`
export default class ComposerAddButtons extends Component {
  constructor (props) {
    super(props)

    this.state = {
      optionsExpanded: false
    }

    this.toggleOptions = this.toggleOptions.bind(this)
    this.setAddContentNodePosition = throttle(this.setAddContentNodePosition.bind(this), 10)
    this.addItemAndClose = this.addItemAndClose.bind(this)
  }

  componentDidUpdate (prevProps, prevState) {
    const { inputNode } = this.props
    if (!prevProps.inputNode && inputNode) {
      inputNode.addEventListener('input', this.setAddContentNodePosition)
      inputNode.addEventListener('click', this.setAddContentNodePosition)
      inputNode.addEventListener('scroll', this.setAddContentNodePosition)
    }

    if (!prevProps.contentObjectId && this.props.contentObjectId) {
      setTimeout(this.setAddContentNodePosition, 500)
    }
  }

  componentWillUnmount () {
    this.props.inputNode.removeEventListener('input', this.setAddContentNodePosition)
    this.props.inputNode.removeEventListener('click', this.setAddContentNodePosition)
    this.props.inputNode.removeEventListener('scroll', this.setAddContentNodePosition)
  }

  setAddContentNodePosition () {
    const { inputNode } = this.props
    const caret = getCaretCoordinates(inputNode, inputNode.selectionStart)
    // The 30px brings the node to the next line (30px below the current line)
    // unless it reaches the end of the textarea
    let top = caret.top + inputNode.offsetTop - inputNode.scrollTop + 30
    // if cursor is out of view, hide node
    if (top < inputNode.offsetTop ||
        top > inputNode.clientHeight + inputNode.offsetTop - 30) {
      this.addContentNode.style.display = 'none'
    } else {
      this.addContentNode.style.display = 'block'
      this.addContentNode.style.top = `${top}px`
    }
  }

  toggleOptions () {
    this.setState({ optionsExpanded: !this.state.optionsExpanded })
  }

  addItemAndClose (...args) {
    this.props.handleAddItem(...args)
    this.toggleOptions()
  }

  render () {
    return (
      <AddContentContainer innerRef={domNode => { this.addContentNode = domNode }}>
        <RelativeContainer>
          <AddIcon className='material-icons'
            onClick={this.toggleOptions}
            show={!this.state.optionsExpanded}>add</AddIcon>
          <Options show={this.state.optionsExpanded}>
            <Icon className='material-icons' onClick={this.toggleOptions}>close</Icon>
            <SuperPicker
              render={(ctx) => {
                return <Logo src={logo} onClick={ctx.toggleModal} />
              }}
              getAll
              relatedContentType={ITEMS}
              createRelationship={this.addItemAndClose} />
            <SuperPicker
              render={(ctx) => {
                return <Icon className='material-icons' onClick={ctx.toggleModal}>dashboard</Icon>
              }}
              baseType={PLAYLISTS}
              relatedContentType={ITEMS}
              currentId={this.props.contentObjectId}
              createRelationship={this.addItemAndClose} />
            <EditorialFileUpload
              handleAddFile={this.props.handleAddFile}
              toggleOptions={this.toggleOptions} />
          </Options>
        </RelativeContainer>
      </AddContentContainer>
    )
  }
}
