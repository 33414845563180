import { connect } from 'react-redux'
import Collection from '../ContentCollection'

import { getItem } from '../../actions/ItemActions'
import { getPlaylist } from '../../actions/PlaylistActions'
import { getArtist } from '../../actions/ArtistActions'
import { getCategory } from '../../actions/CategoryActions'
import { getChannel } from '../../actions/ChannelActions'
import { getProvider } from '../../actions/ProviderActions'
import { getEditorial } from '../../actions/EditorialActions'
import { getGroup } from '../../actions/GroupActions'
import * as contentTypes from '../../constants/ContentConstants'
import {
  clearRelatedContent, addRelatedContent, deleteRelatedContent
} from '../../actions/RelatedContentActions'
import {
  selectContent, selectAllContent, clearSelectedContent
} from '../../actions/SelectedContentActions'

// The default for all the related content types in
// constants/RelatedContentConstants are allowAdd. To avoid repeating
// content types in both lists, you only need to add to the NOT_ALLOW_ADD
// if it's a special case. Otherwise, you can just add to the list
// in constants/RelatedContentConstants
const NOT_ALLOW_ADD = {
  [contentTypes.ARTISTS]: [contentTypes.PLAYLISTS],
  [contentTypes.CATEGORIES]: [],
  [contentTypes.PLAYLISTS]: [],
  [contentTypes.CHANNELS]: [],
  [contentTypes.ITEMS]: [],
  [contentTypes.PROVIDERS]: [contentTypes.ITEMS, contentTypes.PLAYLISTS],
  [contentTypes.EDITORIALS]: [],
  [contentTypes.GROUPS]: []
}

const PARENT_ACTIONS = {
  [contentTypes.PLAYLISTS]: {
    getContent: getPlaylist
  },
  [contentTypes.ITEMS]: {
    getContent: getItem
  },
  [contentTypes.ARTISTS]: {
    getContent: getArtist
  },
  [contentTypes.CATEGORIES]: {
    getContent: getCategory
  },
  [contentTypes.CHANNELS]: {
    getContent: getChannel
  },
  [contentTypes.PROVIDERS]: {
    getContent: getProvider
  },
  [contentTypes.EDITORIALS]: {
    getContent: getEditorial
  },
  [contentTypes.GROUPS]: {
    getContent: getGroup
  }
}

const mapStateToProps = (state, ownProps) => {
  const contentType = ownProps.match.params.childType
  const parentType = ownProps.location.pathname.split('/')[1]
  const allowAdd = NOT_ALLOW_ADD[parentType] && !NOT_ALLOW_ADD[parentType].includes(contentType)
  return {
    type: contentType,
    isRelatedContent: true,
    content: state.relatedContent.relatedContent,
    isLast: state.relatedContent.isLast,
    count: state.relatedContent.count,
    searchQueryParams: state.searchAndFilter,
    parent: state[parentType].current,
    // The default for all the related content types in
    // constants/RelatedContentConstants are allowAdd. To avoid repeating
    // content types in both lists, you only need to add to the NOT_ALLOW_ADD
    // if it's a special case. Otherwise, you can just add to the list
    // in constants/RelatedContentConstants
    allowAdd,
    allowBatch: allowAdd,
    parentType,
    selectedContent: state.selectedContent.content
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  const { childType, id } = ownProps.match.params
  const parentType = ownProps.location.pathname.split('/')[1]
  const getContent = PARENT_ACTIONS[parentType].getContent
  return {
    getContent: (pagination, searchAndFilter) => {
      return dispatch(getContent(id, childType, {
        queryParams: {...pagination, ...searchAndFilter}
      }))
    },
    clearContent: () => dispatch(clearRelatedContent()),
    clearSearchAndFilter: () => Promise.resolve(),
    onSelect: (type, selected) => dispatch(selectContent(type, selected)),
    clearSelectedContent: () => dispatch(clearSelectedContent()),
    batchSelect: (selected) => dispatch(selectAllContent(selected)),
    addRelatedContent: (children) => dispatch(addRelatedContent(parentType, id, childType, children)),
    deleteRelatedContent: (children) => dispatch(deleteRelatedContent(parentType, id, childType, children))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Collection)
