import * as constants from '../constants/ReviewConstants'

const _nullReviews = {
  error: null,
  reviewer: {
    data: {},
    isLast: false,
    count: null
  },
  reviewee: {
    data: {},
    isLast: false,
    count: null
  }
}

export default function ReviewReducer (state = _nullReviews, action) {
  switch (action.type) {
    case constants.RECEIVE_REVIEWER_REVIEWS: {
      return {
        ...state,
        reviewer: {
          data: action.data,
          isLast: action.isLast,
          count: action.count
        }
      }
    }
    case constants.RECEIVE_REVIEWEE_REVIEWS: {
      return {
        ...state,
        reviewee: {
          data: action.data,
          isLast: action.isLast,
          count: action.count
        }
      }
    }
    case constants.RECEIVE_REVIEW: {
      const newState = { ...state };
      ['reviewer', 'reviewee'].forEach(role => {
        Object.keys(state[role].data).forEach(type => {
          const idx = newState[role].data[type].findIndex(el => el.id === action.review.id)
          if (idx) newState[role].data[type].splice(idx, 1, action.review)
        })
      })
      return newState
    }
    case constants.RECEIVE_REVIEWS_ERROR: {
      return {
        ...state,
        error: action.error
      }
    }
    case constants.CLEAR_REVIEWS: {
      return _nullReviews
    }
    default: {
      return state
    }
  }
}
