import React from 'react'
import styled from 'styled-components'
import { Input, FormGroup } from 'reactstrap'
import {
  FlexColumnContainer, FlexContainer, GrayButton
} from '../StyledComponents'
import DateTimePicker from './DateTimePicker'
const StyledOuterContainer = styled(FlexColumnContainer)`
`

const StyledFormGroup = styled(FormGroup)`
  margin-right: 10px;
  width: 55%;
`

export default class FeaturedAt extends DateTimePicker {
  render () {
    return (
      <StyledOuterContainer>
        <h6>Feed Date</h6>
        <FlexColumnContainer>
          <FlexContainer>
            <StyledFormGroup>
              <Input type='date' name='date' id='date' value={this.state.date}
                placeholder='date placeholder' onChange={this.handleDateChange} />
            </StyledFormGroup>
            <FormGroup>
              <Input type='time' name='time' id='time' value={this.state.time}
                placeholder='time placeholder' onChange={this.handleDateChange} />
            </FormGroup>
          </FlexContainer>
          <FlexContainer>
            <GrayButton small='true' onClick={this.handleRefresh}>Refresh</GrayButton>
            <GrayButton small='true' marginleft='10px' onClick={this.handleClear}>Clear</GrayButton>
          </FlexContainer>
        </FlexColumnContainer>
      </StyledOuterContainer>
    )
  }
}

FeaturedAt.defaultProps = {
  field: 'featuredAt'
}
