import React from 'react'
import styled from 'styled-components'
import { withRouter } from 'react-router-dom'
import {
  FlexContainer, StyledLink, FlexColumnContainer
} from './StyledComponents'
import MainHeaderTabs from './MainHeaderTabs'

const MainHeaderContainer = styled(FlexContainer)`
  background-color: ${props => props.theme.white};
  padding: 0px 40px;
  border-bottom: 1px solid ${props => props.theme.lightGray};
`

const HeadingContainer = styled(FlexContainer)`
  font-size: 24px;
  padding-top: 15px;
  padding-bottom: 13px;
`

const Heading = styled.h5`
  font-weight: bold;
  margin-bottom: 0px;
`

const AddIcon = styled.i`
  font-size: 42px;
`

const BackIcon = styled.i`
  color: ${props => props.theme.mediumGray};
  transition: 0.2s ease color;
  cursor: pointer;
  margin-right: 10px;

  &:hover {
    color: ${props => props.theme.darkGray};
  }
`

function MainHeader (props) {
  return (
    <MainHeaderContainer fullWidth justifyContent='space-between'>
      <FlexColumnContainer>
        <HeadingContainer alignItems='center'>
          {
            props.showBackBtn &&
            <BackIcon className='material-icons' onClick={props.history.goBack}>
              keyboard_arrow_left
            </BackIcon>
          }
          <Heading>{props.text}</Heading>
        </HeadingContainer>
        <MainHeaderTabs tabs={props.tabs} />
      </FlexColumnContainer>
      <FlexContainer>
        {props.children}
        <StyledLink
          to={`/${props.type}/create`}
          color='blue'
          hover='darkBlue'
          display='flex'>
          <AddIcon className='material-icons'>add_circle</AddIcon>
        </StyledLink>
      </FlexContainer>
    </MainHeaderContainer>
  )
}

export default withRouter(MainHeader)
