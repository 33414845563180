import * as constants from '../constants/ChannelConstants'
import { CHANNELS } from '../constants/ContentConstants'
import { DELETE_REVIEW, RECEIVE_REVIEW } from '../constants/ReviewConstants'

const _nullChannels = {
  error: null,
  channels: {
    isLast: false,
    data: [],
    count: null
  },
  current: {
    pendingReviews: []
  }
}

export default function ChannelReducer (state = _nullChannels, action) {
  switch (action.type) {
    case constants.RECEIVE_CHANNELS: {
      const { isLast, data, count } = action.channels
      const { newQuery } = action
      const newChannels = {
        isLast: isLast,
        data: newQuery ? data : [].concat(state.channels.data, data),
        count
      }
      return Object.assign({}, state, { channels: newChannels })
    }
    case constants.RECEIVE_CHANNEL: {
      return Object.assign({}, state, {
        current: Object.assign({}, state.current, action.channel)
      })
    }
    case constants.RECEIVE_CHANNELS_ERROR || constants.RECEIVE_CHANNEL_ERROR: {
      return Object.assign({}, state, { error: action.error })
    }
    case DELETE_REVIEW: {
      if (!action.contentType === CHANNELS) return state

      const newPendingReviews = Object.assign({}, {
        pendingReviews: state.current.pendingReviews
          .filter(review => review.id !== action.reviewId)
      })

      return Object.assign({}, state, {
        current: Object.assign({}, state.current, newPendingReviews)
      })
    }
    case RECEIVE_REVIEW: {
      if (!action.contentType === CHANNELS) return state

      return Object.assign({}, state, {
        current: Object.assign({}, state.current, action.review.contentObject)
      })
    }
    case constants.CLEAR_CHANNELS: {
      return Object.assign({}, _nullChannels)
    }
    default: {
      return state
    }
  }
}
