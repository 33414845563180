import React from 'react'
import styled from 'styled-components'
import { Input, FormGroup } from 'reactstrap'
import {
  FlexColumnContainer, FlexContainer, GrayButton
} from '../StyledComponents'
import DateTimePicker from './DateTimePicker'
import { getDate } from '../../utils/DateFormatter'

const StyledFormGroup = styled(FormGroup)`
  margin-right: 10px;
  margin-bottom: 0px;
  width: 100%;
`

// This component sets the default time to 01:00. If the time also needs to
// be modified, use DateTimePicker component.
export default class DatePicker extends DateTimePicker {
  componentDidMount () {
    this.setState({
      date: getDate(this.props.currentDateTime),
      time: '01:00'
    })
  }

  componentDidUpdate (prevProps) {
    if (!this.state.time) this.setState({ time: '01:00' })
  }

  render () {
    return (
      <FlexColumnContainer>
        <FlexContainer alignItems='center' justifyContent='space-between' marginbottom='12px'>
          <StyledFormGroup>
            <Input type='date' name='date' id='date' value={this.state.date}
              placeholder='date placeholder' onChange={this.handleDateChange} />
          </StyledFormGroup>
          <GrayButton small='true' onClick={this.handleRefresh}>Today</GrayButton>
          <GrayButton small='true' marginleft='10px' onClick={this.handleClear}>
            Clear
          </GrayButton>
        </FlexContainer>
      </FlexColumnContainer>
    )
  }
}
