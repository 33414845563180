import { connect } from 'react-redux'
import React, { Component } from 'react'
import styled from 'styled-components'
import isEmpty from 'lodash/isEmpty'
import { withRouter } from 'react-router-dom'
import {
  updateSearchString, updateFilters, clearFilters, updateSortField,
  updateSortOrder, clearSearchAndFilter
} from '../actions/SearchAndFilterActions'
import * as contentTypes from '../constants/ContentConstants'
import {
  ASCENDING, DESCENDING, ORDERING_DISPLAYS, FILTER_DISPLAYS
} from '../constants/SearchAndFilterConstants'
import { FlexContainer, FullWidth, StyledDropdownToggle } from './StyledComponents'
import {
  Form, Dropdown, DropdownItem, DropdownMenu, Input,
  InputGroup, InputGroupAddon, Button
} from 'reactstrap'
import { getGroups } from '../actions/GroupActions'
import ButtonLoader from './ButtonLoader'

const SearchAndFilterContainer = styled(FullWidth)`
  background-color: ${props => props.theme.white};
  border: 1px solid ${props => props.theme.lightGray};
  padding: 24px 17px;
  border-radius: 4px;
`

const StyledDropdown = styled(Dropdown)`
  margin-right: 5px;
  width: auto;
  border-color: ${props => props.theme.lightGray}
`

const StyledInputGroup = styled(InputGroup)`
  margin: 5px;
`

const StyledInput = styled(Input)`
  border-color: ${props => props.theme.lightGray}
  color: ${props => props.theme.lightGray}
`

const StyledAddon = styled(InputGroupAddon)`
  padding: 0;
  border-color: ${props => props.theme.lightGray}
`

const AddonButton = styled(ButtonLoader)`
  height: 100%;
  width: 5rem;
  border: none;
  background-color: ${props => props.theme.lightGray};
  cursor: pointer;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
`

const StyledButton = styled(Button)`
  background-color: ${props => props.selected ? props.theme.mediumGray : 'transparent'};
  color: ${props => props.selected ? 'white' : props.theme.lightGray};
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  &:hover {
    background-color: ${props => props.selected ? props.theme.mediumGray : props.theme.lightGray};
    color: ${props => props.selected ? 'white' : props.theme.mediumGray};
    border: none;
  }
`

const SubtleButton = styled(Button)`
  background-color: transparent;
  border: none;
  cursor: pointer;
`

const Filter = styled(FlexContainer)`
  margin-right: 15px;
`

const Label = styled.span`
  margin-right: 5px;
`

const mapStateToProps = state => {
  return {
    searchString: state.searchAndFilter.searchString,
    filters: state.searchAndFilter.filters,
    sort: state.searchAndFilter.sort,
    groups: state.groups.groups.data
  }
}

const mapDispatchToProps = dispatch => ({
  updateSearchString: (searchString, type) => dispatch(updateSearchString(searchString, type)),
  clearFilters: () => dispatch(clearFilters()),
  updateSortField: (field, type) => dispatch(updateSortField(field, type)),
  updateSortOrder: (dir, type) => dispatch(updateSortOrder(dir, type)),
  updateFilters: (filter, type) => dispatch(updateFilters(filter, type)),
  clearSearchAndFilter: () => dispatch(clearSearchAndFilter()),
  getGroups: (params) => dispatch(getGroups(params))
})

const ORDERING_FIELDS_BY_TYPE = {
  [contentTypes.ARTISTS]: ['publishedAt', 'featuredAt', 'surname'],
  [contentTypes.ITEMS]: ['createdAt', 'publishedAt', 'updatedAt', 'date', 'name', 'author', 'favoriteCount', 'deviceCount'],
  [contentTypes.PLAYLISTS]: ['createdAt', 'publishedAt', 'updatedAt', 'featuredAt', 'favoriteCount', 'deviceCount'],
  [contentTypes.CATEGORIES]: ['createdAt', 'publishedAt', 'updatedAt'],
  [contentTypes.CHANNELS]: ['publishedAt', 'createdAt', 'updatedAt', 'featuredAt', 'name'],
  [contentTypes.PROVIDERS]: ['createdAt', 'updatedAt', 'name'],
  [contentTypes.GROUPS]: ['createdAt', 'updatedAt', 'name'],
  [contentTypes.EDITORIALS]: ['publishedAt', 'createdAt', 'name']
}

const FILTERING_FIELDS_BY_TYPE = {
  [contentTypes.ARTISTS]: ['isPublic', 'isFeatured'],
  [contentTypes.ITEMS]: ['isPublic', 'orientation', 'hasCopyright'],
  [contentTypes.PLAYLISTS]: ['isPublic', 'isSampler', 'orientation'],
  [contentTypes.CATEGORIES]: ['isPublic', 'group'],
  [contentTypes.CHANNELS]: ['isPublic'],
  [contentTypes.PROVIDERS]: [],
  [contentTypes.GROUPS]: ['isPublic'],
  [contentTypes.EDITORIALS]: ['isPublic']
}

class SearchAndFilter extends Component {
  constructor (props) {
    super(props)

    this.state = {
      orderFieldDropdownOpen: false,
      orderDirectionDropdown: false,
      showFilters: false,
      publishedStatusDropdownOpen: false,
      featuredStatusDropdownOpen: false,
      filterDisplays: FILTER_DISPLAYS
    }

    this.toggle = this.toggle.bind(this)
    this.toggleFilters = this.toggleFilters.bind(this)
    this.renderFilter = this.renderFilter.bind(this)
    this.renderSortingDropdowns = this.renderSortingDropdowns.bind(this)
    this.setUrlAndFetchResults = this.setUrlAndFetchResults.bind(this)
    this.updateSortFiltersAndFetch = this.updateSortFiltersAndFetch.bind(this)
  }

  componentDidMount () {
    // disaled is truthy when loading, falsy otherwise.
    // Second mount after first fetch
    if (this.props.disabled && this.props.type === contentTypes.CATEGORIES) {
      this.props.getGroups({ count: 1000, page: 1 })
    }

    // First mount, initiate fetch
    if (!this.props.disabled) {
      this.props.clearSearchAndFilter().then(() => {
        const queryParams = new URLSearchParams(this.props.location.search)
        const containsQueryParams = queryParams.toString().length
        if (!containsQueryParams) {
          this.props.fetchResults()
        } else {
          this.updateSortFiltersAndFetch()
        }
      })
    }
  }

  componentDidUpdate (prevProps, prevState) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      this.props.clearSearchAndFilter()
        .then(() => this.props.fetchResults())
    }
  }

  static getDerivedStateFromProps (nextProps, prevState) {
    if (isEmpty(prevState)) return null

    const groupsExist = Object.keys(prevState.filterDisplays.group.options).length
    if (!groupsExist && nextProps.groups.length) {
      const groupOptions = nextProps.groups.reduce((groupOptions, group) => {
        groupOptions[group.id] = { display: group.name }
        return groupOptions
      }, {})

      return {
        filterDisplays: Object.assign({}, prevState.filterDisplays, {
          group: { options: groupOptions, label: 'Group' }
        })
      }
    }

    return null
  }

  updateSortFiltersAndFetch () {
    const queryParams = new URLSearchParams(this.props.location.search)
    const updateCallbacks = []
    for (const pair of queryParams) {
      const field = pair[0]
      const value = pair[1]
      if (ORDERING_FIELDS_BY_TYPE[this.props.type].includes(field)) {
        updateCallbacks.push(this.props.updateSortField(field, this.props.type))
        updateCallbacks.push(this.props.updateSortOrder(value, this.props.type))
      } else if (FILTERING_FIELDS_BY_TYPE[this.props.type].includes(field)) {
        updateCallbacks.push(this.props.updateFilters({ [field]: value }, this.props.type))
      } else if (field === 'search') {
        updateCallbacks.push(this.props.updateSearchString(value, this.props.type))
      }
    }

    Promise.all(updateCallbacks)
      .then(this.props.fetchResults)
  }

  toggle (dropdown) {
    this.setState({[dropdown]: !this.state[dropdown]})
  }

  toggleFilters () {
    this.setState({showFilters: !this.state.showFilters})
  }

  setUrlAndFetchResults (e) {
    e.preventDefault()

    this.updatePath()
    this.props.fetchResults(true)
  }

  updatePath () {
    const currPath = this.props.location.pathname
    const filterKeys = Object.keys(this.props.filters)

    let queryParams = []
    if (this.props.sort.field) {
      queryParams.push(`${this.props.sort.field}=${this.props.sort.order}`)
    }

    if (this.props.searchString) {
      queryParams.push(`search=${this.props.searchString}`)
    }

    if (filterKeys.length) {
      filterKeys.forEach(key => {
        const value = this.props.filters[key]
        if (value) queryParams.push(`${key}=${value}`)
      })
    }

    this.props.history.replace(`${currPath}?${queryParams.join('&')}`)
  }

  renderSortingDropdowns () {
    const { type } = this.props
    return (
      <FlexContainer>
        <StyledDropdown
          isOpen={this.state.orderFieldDropdownOpen}
          toggle={e => this.toggle('orderFieldDropdownOpen')}
        >
          <StyledDropdownToggle caret color='light'>
            {ORDERING_DISPLAYS[this.props.sort.field] || 'Sort By'}
          </StyledDropdownToggle>
          <DropdownMenu>
            {ORDERING_FIELDS_BY_TYPE[type].map(field => {
              return (
                <DropdownItem
                  key={field}
                  onClick={e => this.props.updateSortField(field, this.props.type)}
                >
                  {ORDERING_DISPLAYS[field]}
                </DropdownItem>
              )
            })}
          </DropdownMenu>
        </StyledDropdown>
        <StyledDropdown
          isOpen={this.state.orderDirectionDropdown}
          toggle={e => this.toggle('orderDirectionDropdown')}
        >
          <StyledDropdownToggle caret color='light'>
            {this.props.sort.order === ASCENDING ? 'Ascending' : 'Descending'}
          </StyledDropdownToggle>
          <DropdownMenu>
            <DropdownItem
              onClick={e => this.props.updateSortOrder(ASCENDING, this.props.type)}
            >
              Ascending
            </DropdownItem>
            <DropdownItem
              onClick={e => this.props.updateSortOrder(DESCENDING, this.props.type)}
            >
              Descending
            </DropdownItem>
          </DropdownMenu>
        </StyledDropdown>
      </FlexContainer>
    )
  }

  renderSearchBar () {
    const { updateSearchString, disabled, type } = this.props
    return (
      <StyledInputGroup>
        <StyledInput
          placeholder='Search for'
          onChange={e => updateSearchString(e.target.value, type)}
          value={this.props.searchString}
        />
        <StyledAddon addonType='append'>
          <AddonButton
            onClick={this.setUrlAndFetchResults}
            isLoading={disabled}
          >
            Go!
          </AddonButton>
        </StyledAddon>
      </StyledInputGroup>
    )
  }

  renderFilter (name) {
    const filterValue = this.props.filters[name]
    let display
    if (typeof filterValue === 'undefined' || filterValue === null) {
      display = '--'
    } else {
      display = this.state.filterDisplays[name].options[filterValue].display
    }
    return (
      <Filter key={name}>
        <Label>{this.state.filterDisplays[name].label}:</Label>
        <StyledDropdown
          isOpen={this.state[`${name}StatusDropdownOpen`]}
          toggle={e => this.toggle(`${name}StatusDropdownOpen`)}
        >
          <StyledDropdownToggle caret color='light'>
            {display}
          </StyledDropdownToggle>
          <DropdownMenu>
            <DropdownItem
              onClick={e => this.props.updateFilters({[name]: null}, this.props.type)}
            >
              --
            </DropdownItem>
            {
              Object.keys(this.state.filterDisplays[name].options).map(optionValue => (
                <DropdownItem
                  key={optionValue}
                  onClick={e => this.props.updateFilters({[name]: optionValue}, this.props.type)}
                >
                  {this.state.filterDisplays[name].options[optionValue].display}
                </DropdownItem>
              ))
            }
          </DropdownMenu>
        </StyledDropdown>
      </Filter>
    )
  }

  renderFilters () {
    const { type } = this.props

    return (
      <FullWidth>
        <FlexContainer justifyContent='space-between'>
          <FlexContainer>
            {
              FILTERING_FIELDS_BY_TYPE[type].map(field => this.renderFilter(field))
            }
          </FlexContainer>
          <div>
            <SubtleButton onClick={this.props.clearFilters} color='light'>
              Clear Filters
            </SubtleButton>
          </div>
        </FlexContainer>
      </FullWidth>
    )
  }

  render () {
    const { type } = this.props

    return (
      <SearchAndFilterContainer>
        <Form onSubmit={this.fetchResults}>
          <FlexContainer>
            {this.renderSortingDropdowns()}
            {this.renderSearchBar()}
            { FILTERING_FIELDS_BY_TYPE[type].length > 0 &&
              <StyledButton onClick={this.toggleFilters} selected={this.state.showFilters}>
                <i className='material-icons'>filter_list</i>
              </StyledButton>
            }
          </FlexContainer>
          {this.state.showFilters && this.renderFilters()}
        </Form>
      </SearchAndFilterContainer>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SearchAndFilter))
