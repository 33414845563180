import React from 'react'
import styled from 'styled-components'
import Markdown from '../Markdown'
import { Block } from '../../styles/Box'
import { media } from '../../styles/MediaQueries'

const TextContainer = styled(Block)`
  padding: 0rem 4.85rem;
  flex-direction: column;
  margin-bottom: 5rem;
  margin-top: -2rem;

  ${media.mobile`
      padding: 0;
  `}

  p {
    font-size: 2.1rem;
    margin-bottom: 0;
    line-height: 1.53em;
    font-weight: 300;

    ${media.mobile`
      font-size: 1.9rem;
  `}
  }

  blockquote > p {
    font-family: ${props => props.theme.fontFamily.serif};
    font-size: 2.8rem;
    font-style: italic;
    line-height: 1.3;
    margin: 0 4rem;
    word-wrap: break-word;
  }
`

export default function EditorialParagraph (props) {
  return (
    <TextContainer>
      <Markdown text={props.content.text} />
    </TextContainer>
  )
}
