import fetchWrapper from '../utils/FetchWrapper'
import { formatQueryString } from '../utils/FormatQueryString'

export function getItems (queryParams) {
  return fetchWrapper(`curator`, `/items${formatQueryString(queryParams)}`)
}

export function getRandomItem () {
  return fetchWrapper('v0', '/items/random')
}

export function getItem (id) {
  return fetchWrapper(`curator`, `/items/${id}`)
}

export function getv0Item (id) {
  return fetchWrapper(`v0`, `/items/${id}`)
}

export function getItemPlaylists (id) {
  return fetchWrapper(`curator`, `/items/${id}/playlists`)
}

export function getItemCategories (id) {
  return fetchWrapper(`curator`, `/items/${id}/categories`)
}

export function getItemChannels (id) {
  return fetchWrapper(`curator`, `/items/${id}/channels`)
}

export function getRelatedItems (parentType, parentId, queryParams) {
  return fetchWrapper(`curator`, `/${parentType}/${parentId}/items${formatQueryString(queryParams)}`)
}

export function updateItem (id, data) {
  return fetchWrapper(`curator`, `/items/${id}`, {
    method: 'PUT',
    body: data
  })
}

export function createItem (data) {
  return fetchWrapper(`curator`, `/items`, {
    method: 'POST',
    body: data
  })
}

export function deleteItem (id) {
  return fetchWrapper('curator', `/items/${id}`, {
    method: 'DELETE'
  })
}

export function batchUpdateItems (data) {
  return fetchWrapper('curator', '/items/batch', {
    method: 'PUT',
    body: data
  })
}

export function cropItem (id, data) {
  return fetchWrapper('curator', `/items/${id}/crop`, {
    method: 'PUT',
    body: data
  })
}
