import * as constants from '../constants/CategoryConstants'
import * as API from '../api/Categories'
import { receiveSuccessMessage, receiveErrorMessage } from './MessageActions'
import { RECEIVE_RELATED_CONTENT } from '../constants/RelatedContentConstants'
import { CATEGORIES } from '../constants/ContentConstants'
import { getRelatedContent as getRelatedContentAction } from './RelatedContentActions'
import { getRelatedContent } from '../api/General'

export const getCategories = (queryParams) => dispatch => {
  return API.getCategories(queryParams).then(categories => dispatch({
    type: constants.RECEIVE_CATEGORIES, categories, newQuery: queryParams.newQuery
  })).catch(error => dispatch({ type: constants.RECEIVE_CATEGORIES_ERROR, error }))
}

const getOnlyCategory = (id) => dispatch => {
  return API.getCategory(id).then(({ data: category }) => dispatch({
    type: constants.RECEIVE_CATEGORY, category
  })).catch(error => dispatch({ type: constants.RECEIVE_CATEGORIES_ERROR, error }))
}

export const getCategory = (id, childType, opts = { queryParams: null }) => dispatch => {
  if (!childType) return dispatch(getOnlyCategory(id))

  if (!opts.queryParams.newQuery) {
    return dispatch(getRelatedContentAction(CATEGORIES, id, childType, opts.queryParams))
  } else {
    return Promise.all([
      API.getCategory(id),
      getRelatedContent(CATEGORIES, id, childType, opts.queryParams)
    ]).then(values => {
      const category = values[0].data
      const relatedContent = values[1]
      dispatch({ type: constants.RECEIVE_CATEGORY, category })
      dispatch({
        type: RECEIVE_RELATED_CONTENT,
        content: relatedContent,
        contentType: opts.relatedType,
        newQuery: opts.queryParams.newQuery
      })
    }).catch(error => dispatch({ type: constants.RECEIVE_CATEGORIES_ERROR, error }))
  }
}

export const updateCategory = (categoryId, data) => dispatch => {
  return API.updateCategory(categoryId, data).then(({ data: category }) => {
    dispatch({ type: constants.RECEIVE_CATEGORY, category })
  }).then(() => dispatch(receiveSuccessMessage('Category updated')))
    .catch(error => {
      dispatch(receiveErrorMessage(error))
      return dispatch({ type: constants.RECEIVE_CATEGORIES_ERROR, error })
    })
}

export const createCategory = (data) => dispatch => {
  return API.createCategory(data).then(({ data: category }) => {
    dispatch({ type: constants.RECEIVE_CATEGORY, category })
  }).then(() => dispatch(receiveSuccessMessage('Category created')))
    .catch(error => {
      dispatch(receiveErrorMessage(error))
      return dispatch({ type: constants.RECEIVE_CATEGORIES_ERROR, error })
    })
}

export const deleteCategory = (id) => dispatch => {
  return API.deleteCategory(id)
    .then(() => dispatch(receiveSuccessMessage('Category deleted')))
    .catch(error => {
      dispatch({ type: constants.RECEIVE_CATEGORIES_ERROR, error })
      return Promise.reject(error)
    })
}

export const clearCategories = () => dispatch => dispatch({ type: constants.CLEAR_CATEGORIES })
