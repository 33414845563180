import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { Popover } from 'reactstrap'
import { Icon, StyledTooltip } from './StyledComponents'
import { isSuperCurator } from '../utils/General'

const DotDotDotIcon = styled(Icon)`
  color: ${props => props.isOverlay ? props.theme.white : props.theme.darkPurple};
  position: ${props => props.isOverlay ? 'absolute' : 'static'};
  top: ${props => props.isOverlay ? '11px' : '0px'};
  right: ${props => props.isOverlay ? '11px' : '0px'};
  transition: 0.2s ease color;

  &:hover {
    color: ${props => props.theme.mediumGray};
  }
`

const StyledPopover = styled(Popover)`
  width: 300px;
  border-radius: 0px;
`

const DropdownItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 21px;
  font-size: 21px;
  font-weight: bold;
  border-bottom: 1px solid ${props => props.theme.lightGray};
  transition: 0.2s ease background-color;
  cursor: ${props => props.disabled ? 'auto' : 'pointer'};
  color: ${props => props.disabled ? props.theme.mediumGray : props.theme.darkPurple};

  &:hover {
    background-color: ${props => props.theme.midLightGray};
  }
`

const ActionIcon = styled(Icon)`
  font-size: 24px;
`

const mapStateToProps = ({ auth }) => ({ user: auth.user })

class DotDotDotIconDropdown extends Component {
  constructor (props) {
    super(props)

    this.state = {
      isOpen: false
    }

    this.toggleModal = this.toggleModal.bind(this)
  }

  toggleModal (e) {
    e.preventDefault()
    this.setState({ isOpen: !this.state.isOpen })
    this.props.onClick && this.props.onClick()
  }

  render () {
    const { content, contentType, user, allowAdd, onDelete, isOverlay } = this.props

    const hasPendingReviews = content.pendingReviews && content.pendingReviews.length
    const canEdit = isSuperCurator(user) || !hasPendingReviews

    let deleteIcon
    if (allowAdd) {
      deleteIcon = (
        <DropdownItem onClick={onDelete}>
          Remove
          <ActionIcon className='material-icons'>delete</ActionIcon>
        </DropdownItem>
      )
    }

    let tooltip
    if (!canEdit) {
      tooltip = (
        <StyledTooltip
          placement='right'
          target={`edit-tooltip-${contentType}-${content.id}`}
          offset='0, 5'>
          You must cancel review to edit
        </StyledTooltip>
      )
    }

    return (
      <Fragment>
        <DotDotDotIcon
          className='material-icons'
          onClick={this.toggleModal}
          id={`more-popover-${this.props.content.id}`}
          isOverlay={isOverlay}>
          more_horiz
        </DotDotDotIcon>
        <StyledPopover
          placement='bottom'
          isOpen={this.state.isOpen}
          target={`more-popover-${this.props.content.id}`}
          toggle={this.toggleModal}>
          <DropdownItem
            id={`edit-tooltip-${contentType}-${content.id}`}
            onClick={(e) => { canEdit && this.props.onEdit(e) }}
            disabled={!canEdit}>
            Edit
            <ActionIcon className='material-icons'>edit</ActionIcon>
          </DropdownItem>
          {tooltip}
          {deleteIcon}
        </StyledPopover>
      </Fragment>
    )
  }
}

export default connect(mapStateToProps, null)(DotDotDotIconDropdown)
