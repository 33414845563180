import * as constants from '../constants/PickerConstants'

const _nullPicker = {
  content: [],
  isLast: false,
  pageNumber: 1
}

export default function PickerReducer (state = _nullPicker, action) {
  switch (action.type) {
    case constants.RECEIVE_PICKER_CONTENT: {
      const { isLast, data } = action.content
      const { pageNumber } = action
      return Object.assign({}, state, {
        content: pageNumber === 1 ? data : [].concat(state.content, data),
        isLast,
        pageNumber: pageNumber + 1
      })
    }
    case constants.CLEAR_PICKER_CONTENT: {
      return _nullPicker
    }
    default: {
      return state
    }
  }
}
