import * as constants from '../constants/AuthConstants'
import * as API from '../api/Auth'
import { getUserReviews } from '../api/Reviews'
import { getRandomItem } from '../api/Items'
import { getFeatureFlag, logout, netgearAccountPortallogout } from '../utils/Cognito'



export const loginUserWithToken = () => dispatch => {
  return API.getUser().then(user => dispatch({
    type: constants.RECEIVE_USER, user
  })).catch(error => {
    dispatch({ type: constants.RECEIVE_AUTH_ERROR, error })
    dispatch(logoutUser(false))
  })
}

export const loginUserWithAuth = ({ username, password }) => dispatch => {
  return API.authenticate(username, password).then(payload => {
    localStorage.setItem('userToken', payload.token)
    return dispatch(loginUserWithToken())
  }).catch(error => {
    dispatch({ type: constants.RECEIVE_AUTH_ERROR, error })
    dispatch(logoutUser(false))
  })
}

export const getLoginRandomItem = () => dispatch => {
  return getRandomItem().then(({ data: item }) => dispatch({
    type: constants.RECEIVE_RANDOM_ITEM, item
  })).catch(error => dispatch({ type: constants.RECEIVE_AUTH_ERROR, error }))
}

export const logoutUser = (redirect=true) => dispatch => {
  const flag = getFeatureFlag()
  localStorage.removeItem('userToken')
  if (redirect && flag === 'v2') {
    return netgearAccountPortallogout(dispatch)
  } else {
    return dispatch({ type: constants.LOGOUT })
  }

}

export const getAllUserReviews = () => dispatch => {
  return getUserReviews()
    .then((payload) => {
      dispatch({ type: constants.RECEIVE_USER_REVIEWS, data: payload.data })
    })
}
