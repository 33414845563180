import React from 'react'
import { connect } from 'react-redux'
import { Modal, ModalHeader, ModalBody } from 'reactstrap'
import styled from 'styled-components'
import ContentCard from '../ContentCard'
import { getPickerContent, clearPickerContent } from '../../actions/PickerActions'
import { clearSearchAndFilter } from '../../actions/SearchAndFilterActions'
import SearchAndFilter from '../SearchAndFilter'
import { Grid } from '../StyledComponents'
import { USERS, AUTHORS } from '../../constants/ContentConstants'
import Loader from '../Loader'

const COUNT = 10

const StyledModal = styled(Modal)`
  max-width: calc(100vw - 300px);
  margin: 5% auto;
`

const ScrollContainer = styled.div`
  max-height: calc(100vh - 150px);
  overflow-y: scroll;
`

const StyledModalBody = styled(ModalBody)`
  background-color: ${props => props.theme.lighterGray};
  display: flex;
  flex-wrap: wrap;
`

function mapStateToProps ({ picker, searchAndFilter }) {
  return {
    searchAndFilter,
    choices: picker.content,
    isLast: picker.isLast,
    pageNumber: picker.pageNumber
  }
}

function mapDispatchToProps (dispatch) {
  return {
    getContent: (params) => dispatch(getPickerContent(params)),
    clearContent: () => dispatch(clearPickerContent()),
    clearSearchAndFilter: () => dispatch(clearSearchAndFilter())
  }
}

class PickerModal extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      isLoading: false
    }

    this.scrollNode = null
    this.fetchContent = this.fetchContent.bind(this)
    this.handleScroll = this.handleScroll.bind(this)
    this.handleSelect = this.handleSelect.bind(this)
  }

  componentWillReceiveProps (nextProps) {
    const prevProps = this.props
    if (nextProps.isModalOpen !== prevProps.isModalOpen) {
      if (nextProps.isModalOpen && !this.displaySearch()) {
        this.fetchContent(this.props.relatedContentType)
      } else if (!nextProps.isModalOpen) {
        this.props.clearContent()
      }
    }
  }

  displaySearch () {
    const { relatedContentType } = this.props
    return relatedContentType !== USERS && relatedContentType !== AUTHORS
  }

  fetchContent (relatedContentType, newQuery = false) {
    if (this.state.isLoading || (this.props.isLast && !newQuery)) return

    this.setState({ isLoading: true }, () => {
      this.props.getContent({
        id: this.props.currentId,
        type: this.props.baseType,
        relatedContentType: relatedContentType || this.props.relatedContentType,
        getAll: this.props.getAll,
        count: COUNT,
        page: newQuery ? 1 : this.props.pageNumber,
        ...this.props.searchAndFilter
      }).then(() => {
        this.setState({ isLoading: false }, this.handleScroll)
      })
    })
  }

  handleScroll () {
    if (!this.scrollNode || this.state.isLoading || !this.props.isModalOpen) return

    if ((this.scrollNode.scrollTop + this.scrollNode.clientHeight) >=
        (this.scrollNode.scrollHeight - 400)) {
      this.fetchContent()
    }
  }

  handleSelect (e, content, batchSelecting) {
    e.preventDefault()
    const { allowBatch, onSelect } = this.props
    if ((batchSelecting && allowBatch) || (!batchSelecting && !allowBatch)) {
      onSelect(content, this.props.relatedContentType, this.props.name)
    }
  }

  render () {
    const { relatedContentType, allowBatch, selectedIds } = this.props
    const contentList = this.props.choices.map(
      (content, idx) => {
        const isSelected = selectedIds.includes(content.id)
        return (
          <ContentCard
            allowBatch={allowBatch}
            key={idx}
            {...content}
            content={content}
            hideOverlay={!allowBatch}
            type={this.props.relatedContentType}
            selected={isSelected}
            onSelect={e => this.handleSelect(e, content, true)}
            onClick={e => this.handleSelect(e, content, false)}
        />
        )
      })

    let modalBody
    if (this.state.isLoading && !this.props.choices.length &&
        !this.props.isLast) {
      modalBody = <Loader />
    } else {
      modalBody = (
        <React.Fragment>
          {
            this.displaySearch() && (
              <SearchAndFilter
                type={relatedContentType}
                fetchResults={() => this.fetchContent(relatedContentType, true)}
                disabled={this.state.isLoading}
                />
            )
          }
          <Grid>{contentList}</Grid>
        </React.Fragment>
      )
    }

    return (
      <StyledModal isOpen={this.props.isModalOpen} toggle={this.props.toggleModal}>
        <ScrollContainer onScroll={this.handleScroll}
          innerRef={(domNode) => { this.scrollNode = domNode }}>
          <ModalHeader toggle={this.props.toggleModal}>
            {`Select ${this.props.relatedContentType}`}
          </ModalHeader>
          <StyledModalBody>
            {modalBody}
          </StyledModalBody>
        </ScrollContainer>
      </StyledModal>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PickerModal)
