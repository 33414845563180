import * as constants from '../constants/PlaylistConstants'
import * as API from '../api/Playlists'
import { receiveSuccessMessage, receiveErrorMessage } from './MessageActions'
import { RECEIVE_RELATED_CONTENT } from '../constants/RelatedContentConstants'
import { PLAYLISTS } from '../constants/ContentConstants'
import { getRelatedContent as getRelatedContentAction } from './RelatedContentActions'
import { getRelatedContent } from '../api/General'

export const getPlaylists = (queryParams) => dispatch => {
  return API.getPlaylists(queryParams).then(playlists => dispatch({
    type: constants.RECEIVE_PLAYLISTS, playlists, newQuery: queryParams.newQuery
  })).catch(error => dispatch({ type: constants.RECEIVE_PLAYLISTS_ERROR, error }))
}

const getOnlyPlaylist = (id) => dispatch => {
  return API.getPlaylist(id)
    .then(({ data: playlist }) => dispatch({
      type: constants.RECEIVE_PLAYLIST, playlist
    }))
    .catch(error => dispatch({ type: constants.RECEIVE_PLAYLISTS_ERROR, error }))
}

export const getPlaylist = (id, childType, opts = { queryParams: null }) => dispatch => {
  if (!childType) return dispatch(getOnlyPlaylist(id))

  if (!opts.queryParams.newQuery) {
    return dispatch(getRelatedContentAction(PLAYLISTS, id, childType, opts.queryParams))
  } else {
    return Promise.all([
      API.getPlaylist(id),
      getRelatedContent(PLAYLISTS, id, childType, opts.queryParams)
    ]).then(values => {
      const playlist = values[0].data
      const relatedContent = values[1]
      dispatch({ type: constants.RECEIVE_PLAYLIST, playlist })
      dispatch({
        type: RECEIVE_RELATED_CONTENT,
        content: relatedContent,
        contentType: childType,
        newQuery: opts.queryParams.newQuery
      })
    }).catch(error => dispatch({ type: constants.RECEIVE_PLAYLISTS_ERROR, error }))
  }
}

export const updatePlaylist = (playlistId, data) => dispatch => {
  return API.updatePlaylist(playlistId, data).then(({ data: playlist }) => {
    dispatch({ type: constants.RECEIVE_PLAYLIST, playlist })
  }).then(() => dispatch(receiveSuccessMessage('Playlist updated')))
    .catch(error => {
      dispatch(receiveErrorMessage(error))
      return dispatch({ type: constants.RECEIVE_PLAYLISTS_ERROR, error })
    })
}

export const deletePlaylist = (id) => dispatch => {
  return API.deletePlaylist(id)
    .then(() => dispatch(receiveSuccessMessage('Playlist deleted')))
    .catch(error => {
      dispatch({ type: constants.RECEIVE_PLAYLISTS_ERROR, error })
      return Promise.reject(error)
    })
}

export const createPlaylist = (data) => dispatch => {
  return API.createPlaylist(data).then(({ data: playlist }) => {
    dispatch({ type: constants.RECEIVE_PLAYLIST, playlist })
  }).then(() => dispatch(receiveSuccessMessage('Playlist created')))
    .catch(error => {
      dispatch(receiveErrorMessage(error))
      return dispatch({ type: constants.RECEIVE_PLAYLISTS_ERROR, error })
    })
}

export const sortRelatedContent = (id, order, type) => dispatch => {
  // TODO: abstract action to be more general by including the parent type and
  // child type to determine the right dispatch type
  return API.sortRelatedContent(id, order, type)
    .then(({ data: playlist }) => dispatch({ type: constants.RECEIVE_PLAYLIST, playlist }))
    .then(() => dispatch(receiveSuccessMessage(`Playlist ${type} sorted`)))
    .catch(error => dispatch({ type: constants.RECEIVE_PLAYLISTS_ERROR, error }))
}

export const clearPlaylists = () => dispatch => dispatch({ type: constants.CLEAR_PLAYLISTS })
